<template>
	<breadcrumb-content menu="Pembuatan Kurikulum" subMenu="Identitas Kurikulum" />
	<div class="card mb-5 mb-xxl-8">
		<div class="pb-10">
			<div class="card-body pt-9 pb-0">
				<h1 class="anchor fw-bold mb-5">Detail Identitas Kurikulum</h1>
				<div class="p-5">
					<div class="row mb-5">
						<label class="form-label">Institusi Pemberi Gelar</label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<input class="form-control form-control-solid-bg" v-model="curriculum.inst_pemberi_gelar_ind" readonly />
							<div class="form-check mt-2">
								<input type="checkbox" id="doubleTitleCheck" class="form-check-input" v-model="curriculum.is_dobel_pemberi_gelar" disabled />
								<label class="form-label" for="doubleTitleCheck"><span>Gelar Ganda</span></label>
							</div>
						</div>
						<div class="col-6">
							<flag-image bahasa="Inggris" />
							<input class="form-control form-control-solid-bg" v-model="curriculum.inst_pemberi_gelar_eng" readonly />
						</div>
						<div class="col-12" v-if="curriculum.is_dobel_pemberi_gelar">
							<div class="row doubleTitleCheck">
								<div class="col-6">
									<input class="form-control form-control-solid-bg" v-model="curriculum.inst_pemberi_gelar_dobel_ind" readonly />
								</div>
								<div class="col-6">
									<input class="form-control form-control-solid-bg" v-model="curriculum.inst_pemberi_gelar_dobel_eng" readonly />
								</div>
							</div>
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Institusi Penyelenggara</label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<input class="form-control form-control-solid-bg" v-model="curriculum.inst_penyelenggara_ind" readonly />
							<div class="form-check mt-2">
								<input type="checkbox" id="doubleInstitutionCheck" class="form-check-input" v-model="curriculum.is_dobel_penyelenggara" disabled />
								<label class="form-label" for="doubleInstitutionCheck"><span>Gelar Ganda</span></label>
							</div>
						</div>
						<div class="col-6">
							<flag-image bahasa="Inggris" />
							<input class="form-control form-control-solid-bg" v-model="curriculum.inst_penyelenggara_eng" readonly />
						</div>
						<div class="col-12" v-if="curriculum.is_dobel_penyelenggara">
							<div class="row doubleInstitutionCheck">
								<div class="col-6">
									<input class="form-control form-control-solid-bg" v-model="curriculum.inst_penyelenggara_dobel_ind" readonly />
								</div>
								<div class="col-6">
									<input class="form-control form-control-solid-bg" v-model="curriculum.inst_penyelenggara_dobel_eng" readonly />
								</div>
							</div>
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Rumpun Ilmu</label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<input class="form-control form-control-solid-bg" v-model="group.nama_ind" readonly />
						</div>
						<div class="col-6">
							<flag-image bahasa="Inggris" />
							<input class="form-control form-control-solid-bg" v-model="group.nama_ind" readonly />
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Fakultas</label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<input class="form-control form-control-solid-bg" v-model="faculty.nama_ind" readonly />
						</div>
						<div class="col-6">
							<flag-image bahasa="Inggris" />
							<input class="form-control form-control-solid-bg" v-model="faculty.nama_ind" readonly />
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Departemen</label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<input class="form-control form-control-solid-bg" v-model="department.nama_ind" readonly />
						</div>
						<div class="col-6">
							<flag-image bahasa="Inggris" />
							<input class="form-control form-control-solid-bg" v-model="department.nama_ind" readonly />
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Program Studi</label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<input class="form-control form-control-solid-bg" v-model="program.nama_ind" readonly />
						</div>
						<div class="col-6">
							<flag-image bahasa="Inggris" />
							<input class="form-control form-control-solid-bg" v-model="program.nama_ind" readonly />
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Jenjang</label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<input class="form-control form-control-solid-bg" v-model="grade.nama_ind" readonly />
						</div>
						<div class="col-6">
							<flag-image bahasa="Inggris" />
							<input class="form-control form-control-solid-bg" v-model="grade.nama_eng" readonly />
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Visi Program Studi</label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<textarea class="form-control form-control-solid-bg" rows="3" v-model="program.visi_ind" readonly></textarea>
						</div>
						<div class="col-6">
							<flag-image bahasa="Inggris" />
							<textarea class="form-control form-control-solid-bg" rows="3" v-model="program.visi_eng" readonly></textarea>
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Misi Program Studi</label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<textarea class="form-control form-control-solid-bg" rows="3" v-model="program.misi_ind" readonly></textarea>
						</div>
						<div class="col-6">
							<flag-image bahasa="Inggris" />
							<textarea class="form-control form-control-solid-bg" rows="3" v-model="program.misi_eng" readonly></textarea>
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Tujuan Program Studi </label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<textarea class="form-control form-control-solid-bg" rows="3" v-model="program.tujuan_ind" readonly></textarea>
						</div>
						<div class="col-6">
							<flag-image bahasa="Inggris" />
							<textarea class="form-control form-control-solid-bg" rows="3" v-model="program.tujuan_eng" readonly></textarea>
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Gelar Yang Diberikan</label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<input class="form-control form-control-solid-bg" v-model="curriculum.nama_gelar_ind" readonly />
							<div class="form-check mt-2">
								<input type="checkbox" id="doubleDegreeAwardedCheck" class="form-check-input" v-model="curriculum.is_dobel_gelar" disabled>
								<label class="form-label" for="doubleDegreeAwardedCheck"><span>Gelar Ganda</span></label>
							</div>
						</div>
						<div class="col-6">
							<flag-image bahasa="Inggris" />
							<input class="form-control form-control-solid-bg" v-model="curriculum.nama_gelar_eng" readonly />
						</div>
						<div class="col-12" v-if="curriculum.is_dobel_gelar">
							<div class="row doubleDegreeAwardedCheck">
								<div class="col-6">
									<input class="form-control form-control-solid-bg" v-model="curriculum.gelar_diberikan_dobel_ind" readonly />
								</div>
								<div class="col-6">
									<input class="form-control form-control-solid-bg" v-model="curriculum.gelar_diberikan_dobel_eng" readonly />
								</div>
							</div>
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Kode Kurikulum </label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<input class="form-control form-control-solid-bg" v-model="curriculum.kurikulum_kd" readonly />
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Nama Kurikulum </label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<input class="form-control form-control-solid-bg" v-model="curriculum.nama_ind" readonly />
						</div>
						<div class="col-6">
							<flag-image bahasa="Inggris" />
							<input class="form-control form-control-solid-bg" v-model="curriculum.nama_eng" readonly />
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Deskripsi Kurikulum</label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<textarea class="form-control form-control-solid-bg" rows="3" v-model="curriculum.deskripsi_ind" readonly></textarea>
						</div>
						<div class="col-6">
							<flag-image bahasa="Inggris" />
							<textarea class="form-control form-control-solid-bg" rows="3" v-model="curriculum.deskripsi_eng" readonly></textarea>
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Tahun Ajar (Awal - Akhir) </label>
						<div class="col-6">
							<div class="row">
								<div class="col-6">
									<input class="form-control form-control-solid-bg tahun_awal" :value="parseDateToView(curriculum.tahun_awal)" readonly />
								</div>
								<div class="col-6">
									<input class="form-control form-control-solid-bg tahun_akhir" :value="parseDateToView(curriculum.tahun_akhir)" readonly />
								</div>
							</div>
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Bahasa Pengantar</label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<input class="form-control form-control-solid-bg" v-model="curriculum.bhs_pengantar_nama_ind" readonly />
						</div>
						<div class="col-6">
							<flag-image bahasa="Inggris" />
							<input class="form-control form-control-solid-bg" v-model="curriculum.bhs_pengantar_nama_eng" readonly />
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Skema Belajar</label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<input class="form-control form-control-solid-bg" v-model="curriculum.skema_bljr_nama_ind" readonly />
						</div>
						<div class="col-6">
							<flag-image bahasa="Inggris" />
							<input class="form-control form-control-solid-bg" v-model="curriculum.skema_bljr_nama_eng" readonly />
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Persyaratan Masuk</label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<textarea class="form-control form-control-solid-bg" rows="3" v-model="curriculum.syarat_masuk_ind" readonly></textarea>
						</div>
						<div class="col-6">
							<flag-image bahasa="Inggris" />
							<textarea class="form-control form-control-solid-bg" rows="3" v-model="curriculum.syarat_masuk_eng" readonly></textarea>
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Masa Tempuh Kurikulum </label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<input class="form-control form-control-solid-bg" v-model="curriculum.lama_bljr_min" readonly />
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Masa Studi </label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<input class="form-control form-control-solid-bg" v-model="curriculum.lama_bljr_max" readonly />
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Status Profesi</label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<input class="form-control form-control-solid-bg" v-model="curriculum.status_profesi_ind" readonly />
						</div>
						<div class="col-6">
							<flag-image bahasa="Inggris" />
							<input type="input" class="form-control form-control-solid-bg" v-model="curriculum.status_profesi_eng" readonly />
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Status Kurikulum </label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<input class="form-control form-control-solid-bg" :value="((curriculum.is_aktif == true) ? ('Aktif') : ('Tidak Aktif'))" readonly />
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Tanggal Pengajuan</label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<input class="form-control form-control-solid-bg" :value="parseDateToView(curriculum.tgl_pengajuan)" readonly />
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Pengguna Lulusan </label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<input type="text" ref="editor1" disabled />
						</div>
						<div class="col-6">
							<flag-image bahasa="Inggris" />
							<input type="text" ref="editor2" disabled />
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Landasan Filosofis </label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<input type="text" ref="editor3" disabled />
						</div>
						<div class="col-6">
							<flag-image bahasa="Inggris" />
							<input type="text" ref="editor4" disabled />
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Landasan Sosiologis </label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<input type="text" ref="editor5" disabled />
						</div>
						<div class="col-6">
							<flag-image bahasa="Inggris" />
							<input type="text" ref="editor6" disabled />
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Landasan Psikologis </label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<input type="text" ref="editor7" disabled />
						</div>
						<div class="col-6">
							<flag-image bahasa="Inggris" />
							<input type="text" ref="editor8" disabled />
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Landasan Historis </label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<input type="text" ref="editor9" disabled />
						</div>
						<div class="col-6">
							<flag-image bahasa="Inggris" />
							<input type="text" ref="editor10" disabled />
						</div>
					</div>
					<hr />
					<div class="d-flex justify-content-between align-items-center">
						<h3>Data Semester Lainnya</h3>
					</div>
					<div class="table-responsive mt-3">
						<TableSemester :curriculumSemesterData="curriculumSemesterData.datatable" />
					</div>
					<div class="mt-10">
						<div class="col-lg-6">
							<router-link :to="{ name: 'IdentitasKurikulum' }" class="btn btn-secondary btn-kembali">Kembali</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { onMounted, ref } from 'vue';
   import { useRouter } from 'vue-router';
   import request from '@/utils/request';
	import TableSemester from './TableSemester.vue';
	import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

	const loading = ref(false);
	const router = useRouter();
	const id = router.currentRoute.value.params.id;
	const curriculum = ref({});
	const program = ref({});
	const grade = ref({});
	const department = ref({});
	const faculty = ref({});
	const group = ref({});
	const curriculumSemesterData = ref({'datatable': []});
	const parseDateToView = ((date) => {
		const formatted = new Date(date);
		return `${ String(formatted.getDate()).padStart(2, '0') }-${ String(formatted.getMonth() + 1).padStart(2, '0') }-${ formatted.getFullYear() }`;
	});
	const editor1 = ref(null);
	const editor2 = ref(null);
	const editor3 = ref(null);
	const editor4 = ref(null);
	const editor5 = ref(null);
	const editor6 = ref(null);
	const editor7 = ref(null);
	const editor8 = ref(null);
	const editor9 = ref(null);
	const editor10 = ref(null);

	onMounted(async () => {
		try {
			curriculum.value = (await request({ url:`/kurikulum/${ id }` })).data;
			program.value = (await request({ url:`/prodi/${ curriculum.value.prodi_id_fk }` })).data;
			grade.value = (await request({ url:`/jenjang/${ program.value.jenjang_id_fk }` })).data;
			department.value = (await request({ url:`/departemen/${ program.value.departemen_id_fk }` })).data;
			faculty.value = (await request({ url:`/fakultas/${ department.value.fakultas_id_fk }` })).data;
			group.value = (await request({ url:`/ri/${ faculty.value.ri_id_fk }` })).data;

			(await ClassicEditor.create(editor1.value)).setData(curriculum.value.pengguna_lulusan_ind);
			(await ClassicEditor.create(editor2.value)).setData(curriculum.value.pengguna_lulusan_eng);
			(await ClassicEditor.create(editor3.value)).setData(curriculum.value.landasan_filosofis_ind);
			(await ClassicEditor.create(editor4.value)).setData(curriculum.value.landasan_filosofis_eng);
			(await ClassicEditor.create(editor5.value)).setData(curriculum.value.landasan_sosiologis_ind);
			(await ClassicEditor.create(editor6.value)).setData(curriculum.value.landasan_sosiologis_eng);
			(await ClassicEditor.create(editor7.value)).setData(curriculum.value.landasan_psikologis_ind);
			(await ClassicEditor.create(editor8.value)).setData(curriculum.value.landasan_psikologis_eng);
			(await ClassicEditor.create(editor9.value)).setData(curriculum.value.landasan_historis_ind);
			(await ClassicEditor.create(editor10.value)).setData(curriculum.value.landasan_historis_eng);
			
			(curriculum.value.kurikulum_semester).map((v) => {
				curriculumSemesterData.value.datatable.push({ semesterTypeName: v.nama_ind, numberOfSemester: v.jml_semester, numberOfWeek: v.jml_minggu });
			});
		} catch (error) {
			console.error('Error fetching data: ', error);
		} finally {
			loading.value = false;
		}
	});
</script>