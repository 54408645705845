<template>
   <breadcrumb-content menu="Profil" subMenu="Fakultas" />
   <div class="card mb-5 mb-xxl-8">
      <div class="pb-10">
         <div class="card-body pt-9 pb-0">
            <h1 class="anchor fw-bold mb-5">Tambah Fakultas</h1>
            <div class="p-5">
               <Form id="form-fakultas-add" class="kt-form" method="post" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
                  <LoadingOverlay :loading="loading" />
                  <div class="row mb-5">
                     <label class="form-label">Perguruan Tinggi</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <select class="form-control form-control-solid-bg university" data-control="select2" data-placeholder="-- Pilih Perguruan Tinggi --">
                           <option></option>
                           <option :key="university.pt_id" :value="university.pt_id">{{ university.nama_ind }}</option>
                        </select>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input type="text" class="form-control form-control-solid-bg bg-light-dark" v-model="universityDetail.nama_eng" placeholder="Input University" readonly />
                     </div>
                  </div>
                  <div class="row mb-5">
                     <label class="form-label">Rumpun Ilmu</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <select :class="`form-control form-control-solid-bg group`" data-control="select2" data-placeholder="-- Pilih Rumpun Ilmu --">
                           <option></option>
                           <option v-for="(row, index) in group" :key="index" :value="row.ri_id">{{ row.nama_ind }}</option>
                        </select>
                        <Field type="hidden" name="ri_id_fk" :class="{ 'is-invalid': errors.ri_id_fk }" v-model="groupDetail.ri_id" readonly />
                        <div id="ri_id_fk-error" class="invalid-feedback">{{ errors.ri_id_fk }}</div>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input type="text" class="form-control form-control-solid-bg bg-light-dark" v-model="groupDetail.nama_eng" placeholder="Input Group of Knowledge" readonly />
                     </div>
                  </div>
                  <div class="row mb-5">
                     <label class="form-label"><span style="color:red;">*</span> Kode Fakultas</label>
                     <div class="col-6">
								<div style="display:flex; flex-wrap:nowrap;">
									<div style="width:80px;">
										<input class="form-control form-control-solid-bg" v-model="universityDetail.pt_kd" readonly style="background-color:var(--kt-input-solid-bg); text-align:center;" />
									</div>
                           <div style="width:80px;">
										<input class="form-control form-control-solid-bg" v-model="groupDetail.ri_kd" readonly style="background-color:var(--kt-input-solid-bg); text-align:center;" />
									</div>
									<div style="width:100%;">
										<Field type="text" name="fakultas_kd" class="form-control form-control-solid-bg" placeholder="Masukkan Kode Fakultas" />
									</div>
								</div>
                        <div v-if="errors.fakultas_kd" class="invalid-feedback" style="display:block;">Kode Fakultas harus diisi</div>
                     </div>
                  </div>
                  <div class="row mb-5">
                     <label class="form-label"><span style="color: red">*</span> Nama Fakultas </label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <Field type="text" name="nama_ind" class="form-control form-control-solid-bg" :class="{ 'is-invalid': errors.nama_ind }" placeholder="Masukkan Nama Fakultas" />
                        <div id="nama_ind-error" class="invalid-feedback">{{ errors.nama_ind }}</div>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <Field type="text" name="nama_eng" class="form-control form-control-solid-bg" :class="{ 'is-invalid': errors.nama_eng }" placeholder="Input Faculty Name" />
                        <div id="nama_eng-error" class="invalid-feedback">{{ errors.nama_eng }}</div>
                     </div>
                  </div>
                  <div class="row mb-5">
                     <label class="form-label">Deskripsi Fakultas</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <Field as="textarea" name="deskripsi_ind" class="form-control form-control-solid-bg" rows="3" placeholder="Masukkan Deskripsi Fakultas" />
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <Field as="textarea" name="deskripsi_eng" class="form-control form-control-solid-bg" rows="3" placeholder="Input Faculty Description" />
                     </div>
                  </div>
                  <div class="row mb-5">
                     <label class="form-label">
                        <span style="color: red">*</span>Visi Fakultas </label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <Field as="textarea" name="visi_ind" class="form-control form-control-solid-bg" rows="3" :class="{ 'is-invalid': errors.visi_ind }" placeholder="Masukkan Visi Fakultas" />
                        <div id="visi_ind-error" class="invalid-feedback">{{ errors.visi_ind }}</div>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <Field as="textarea" name="visi_eng" class="form-control form-control-solid-bg" rows="3" :class="{ 'is-invalid': errors.visi_eng }" placeholder="Input Faculty Vision" />
                        <div id="visi_eng-error" class="invalid-feedback">{{ errors.visi_eng }}</div>
                     </div>
                  </div>
                  <div class="row mb-5">
                     <label class="form-label">
                        <span style="color: red">*</span>Misi Fakultas </label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <Field as="textarea" name="misi_ind" class="form-control form-control-solid-bg" rows="3" :class="{ 'is-invalid': errors.misi_ind }" placeholder="Masukkan Misi Fakultas" />
                        <div id="misi_ind-error" class="invalid-feedback">{{ errors.misi_ind }}</div>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <Field as="textarea" name="misi_eng" class="form-control form-control-solid-bg" rows="3" :class="{ 'is-invalid': errors.misi_eng }" placeholder="Input Faculty Mission" />
                        <div id="misi_eng-error" class="invalid-feedback">{{ errors.misi_eng }}</div>
                     </div>
                  </div>
                  <div class="mt-10">
                     <div class="col-lg-6">
                        <button type="submit" class="btn btn-primary">Simpan</button> &nbsp; <button @click="redirectToFakultasList" class="btn btn-secondary">Kembali</button>
                     </div>
                  </div>
               </Form>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup>
   import { onMounted, ref } from 'vue';
   import { useRouter } from 'vue-router';
   import { Form, Field } from 'vee-validate';
   import LoadingOverlay from '@/components/LoadingOverlay';
   import { sendRequest } from '@/api/common';
   import request from '@/utils/request';

   const schema = { ri_id_fk: 'required', fakultas_kd: 'required', nama_ind: 'required|max:200', nama_eng: 'required|max:200', visi_ind: 'required', visi_eng: 'required', misi_ind: 'required', misi_eng: 'required' };
   const loading = ref(false);
   const router = useRouter();
   const university = ref({});
   const universityDetail = ref({});
   const group = ref({});
   const groupDetail = ref({});
   const redirectToFakultasList = () => router.push({ name: 'Fakultas' });
   const handleElSelect = (async ({ target, self, child, point, handleSelectObject }, event) => {
		const el = event.target;
		handleSelect(handleSelectObject);
		handleSelectedOption({ url: ((target == '/pt') ? (target) : (`${ target }${ el.value }`)), self, child, point });
	});
	const handleSelectedOption = (async ({ url, self, child, point }) => {
		eval(`${ self }Detail`).value = (await request({ url })).data;
      if (child && point) eval(child).value = eval(`${ self }Detail`).value[point];
	});
	const handleSelect = (({ universitySelect = false, groupSelect = false }) => {
		if (!universitySelect) { university.value = {}; universityDetail.value = {}; }
		if (!groupSelect) { group.value = {}; groupDetail.value = {}; }
	});
   const onSubmit = (async (values) => await sendRequest({ url: '/fakultas', method: 'POST', body: values, loading: loading, context: 'Fakultas' }) );

	onMounted(async () => {
		try {
			university.value = (await request({ url:'/pt' })).data;

         // eslint-disable-next-line no-undef
         $('select.university').select2().on('change', (event) => { handleElSelect({ target: '/pt', self: 'university', child: 'group', point: 'rumpun_ilmu', handleSelectObject: { universitySelect: true } }, event); });

         // eslint-disable-next-line no-undef
         $('select.group').select2().on('change', (event) => { handleElSelect({ target: '/ri/', self: 'group', handleSelectObject: { universitySelect: true, groupSelect: true } }, event); });
		} catch (error) {
			console.error('Error fetching data: ', error);
		} finally {
			loading.value = false;
		}
	});
</script>