<template>
	<div>
		<breadcrumb-content menu="Master Data" subMenu="Kompetensi" />
		<div class="card mb-5 mb-xxl-8 placeholder-glow">
			<div class="pb-10">
				<div class="card-body pt-9 pb-0">
					<h1 class="anchor fw-bold mb-5">Detail Kompetensi</h1>
					<div class="p-5">
						<LoadingOverlay :loading="loading" />

						<FormInput
							labelInd="Jenis Kompetensi"
							customName="jenis_kompetensi_id_fk"
							:valueInd="dataKomp.jenis_kompetensi_id_fk ? getDataRelation.nama_ind : ''"
							:valueEng="dataKomp.jenis_kompetensi_id_fk ? getDataRelation.nama_eng : ''"
							mode="show"
						/>
						<FormInput
							labelInd="Kode Kompetensi"
							customName="kompetensi_kd"
							:valueInd="dataKomp.kompetensi_kd"
							:isEnglish="false"
							mode="show"
						/>
						<FormInput
							labelInd="Nama Kompetensi"
							columnName="nama"
							:valueInd="dataKomp.nama_ind"
							:valueEng="dataKomp.nama_eng"
							mode="show"
						/>
						<FormInput
							inputType="textarea"
							rows="5"
							labelInd="Deskripsi"
							columnName="deskripsi"
							:valueInd="dataKomp.deskripsi_ind ? dataKomp.deskripsi_ind : '-'"
							:valueEng="dataKomp.deskripsi_eng ? dataKomp.deskripsi_eng : '-'"
							:isRequired="false"
							mode="show"
						/>
						<FormInput
							labelInd="Status"
							customName="is_aktif"
							:valueInd="dataKomp.is_aktif == true ? 'Aktif' : 'Tidak Aktif'"
							:valueEng="dataKomp.is_aktif == true ? 'Active' : 'Inactive'"
							mode="show"
						/>

						<FormInput
							labelInd="Dibuat pada"
							columnName="created"
							:isEnglish="false"
							:valueInd="formattedCreatedAt ? formattedCreatedAt : '-'"
							:isRequired="false"
							mode="show"
						/>
						<FormInput
							labelInd="Diubah pada"
							columnName="updated"
							:isEnglish="false"
							:valueInd="formattedUpdatedAt ? formattedUpdatedAt : '-'"
							:isRequired="false"
							mode="show"
						/>

						<div class="mt-10">
							<button @click="redirectToDataList" class="btn btn-secondary">Kembali</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script setup>
import { onMounted, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'

import FormInput from '@/components/FormInput.vue'

const loading = ref(true)
const router = useRouter()
const dataKomp = ref({})
const getDataRelation = ref({})
const kompetensi_id = router.currentRoute.value.params.id

import { formatReadableDate } from '@/utils/date'
const formattedCreatedAt = computed(() => formatReadableDate(dataKomp.value.created_at))
const formattedUpdatedAt = computed(() => formatReadableDate(dataKomp.value.updated_at))

const redirectToDataList = () => router.push({ name: 'Kompetensi' })

onMounted(async () => {
	try {
		const { data } = await request(`/kompetensi/${kompetensi_id}`)
		dataKomp.value = data
		
		const dataJK = await request(`/jenis-kompetensi/${data.jenis_kompetensi_id_fk}`)
		getDataRelation.value = dataJK.data
	} catch (error) {
		console.error('Error fetching data:', error)
	} finally {
		loading.value = false
	}
})
</script>