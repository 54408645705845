<template>
   <div class="card mb-5 mb-xxl-8 border-top border-primary border-2">
      <div class="card-body">
         <div class="row">
            <div class="col-md-6 col-12">
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Rumpun Ilmu</label>
                  <div class="col-sm">
                     <input type="text" class="form-control form-control-sm" v-model="SearchInfo.group" readonly />
                  </div>
               </div>
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Fakultas</label>
                  <div class="col-sm">
                     <input type="text" class="form-control form-control-sm" v-model="SearchInfo.faculty" readonly />
                  </div>
               </div>
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Departemen</label>
                  <div class="col-sm">
                     <input type="text" class="form-control form-control-sm" v-model="SearchInfo.department" readonly />
                  </div>
               </div>
            </div>
            <div class="col-md-6 col-12">
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Program Studi</label>
                  <div class="col-sm">
                     <input type="text" class="form-control form-control-sm" v-model="SearchInfo.program" readonly />
                  </div>
               </div>
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Jenjang</label>
                  <div class="col-sm">
                     <input type="text" class="form-control form-control-sm" v-model="SearchInfo.grade" readonly />
                  </div>
               </div>
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Kurikulum</label>
                  <div class="col-sm">
                     <div style="display:flex; flex-direction:column; justify-content:center; border:1px solid #e4e6ef; border-radius:.475rem; padding:.775rem 1rem;">
                        <span style="font-size:12px;">{{ SearchInfo.curriculum.code }}</span>
                        <span style="font-size:15px;">{{ SearchInfo.curriculum.name }}</span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup>
   import { ref, defineProps } from 'vue';

   const props = defineProps({ SearchInfo: Object });
   const SearchInfo = ref(props.SearchInfo);
</script>