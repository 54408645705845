<template>
	<breadcrumb-content menu="Master Data" subMenu="Taksonomi Bloom Detail" />

	<div class="card mb-5 mb-xxl-8">
		<div class="pb-10">
			<div class="card-body pt-9 pb-0">
				<h1 class="anchor fw-bold mb-5">Tambah Taksonomi Bloom Detail</h1>
				<div class="p-5">
					<Form
						id="form-taksonomi-d-create"
						class="kt-form"
						method="post"
						@submit="onSubmit"
						:validation-schema="schema"
						v-slot="{ errors }"
					>
						<LoadingOverlay :loading="loading" />

                        <div class="row mb-5">
                            <label class="form-label">
                                Taksonomi Bloom
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <select class="form-control form-control-solid-bg taksonomi_bloom" data-control="select2" data-placeholder="-- Pilih Taksonomi Bloom --">
                                    <option></option>
                                    <option v-for="data in taksonomi_bloom_list" :key="data.taksonomi_h_id" :value="data.taksonomi_h_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind"> {{ data.nama_ind }} </option>
                                </select>
                                <Field type="hidden" id="taksonomi_h_id_fk" name="taksonomi_h_id_fk" v-model="selectedTaksonomiBloom" readonly/>
								<div id="taksonomi_h_id_fk-error" class="invalid-feedback">Taksonomi Bloom harus diisi</div>
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <input type="text" class="form-control form-control-solid-bg bg-light-dark taksonomi_bloom" readonly placeholder="Select Bloom's Taxonomy" v-model="taksonomi_bloom_nama_eng">
                            </div>
                        </div>

						<FormInput
							labelInd="Nama Taksonomi Bloom Detail"
							labelEng="Name of Bloom's Taxonomy Details"
							columnName="nama"
							:errorInd="errors.nama_ind"
							:errorEng="errors.nama_eng"
						/>

						<div class="mt-10">
							<button type="submit" class="btn btn-primary">Simpan</button>
							&nbsp;
							<button @click="redirectToDataList" class="btn btn-secondary">Kembali</button>
						</div>
					</Form>
				</div>
			</div>
		</div>
	</div>
</template>


<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { Form, configure } from 'vee-validate'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'
import { sendRequest } from '@/api/common'

import { Field } from 'vee-validate'
import FormInput from '@/components/FormInput.vue'

const schema = {
	taksonomi_h_id_fk: 'required',
	nama_ind: 'required',
	nama_eng: 'required',
}

const loading = ref(false)
const router = useRouter()

const redirectToDataList = () => router.push({ name: 'TaksonomiBloomDetail' })

const onSubmit = async values => {
	values.taksonomi_h_id_fk = parseInt(values.taksonomi_h_id_fk)

	const param = {
		url: '/taksonomi-d',
		method: 'POST',
		body: values,
		loading: loading,
		context: 'TaksonomiBloomDetail'
	}

	return await sendRequest(param)
}

// custom message error
import { localize } from '@vee-validate/i18n'
const validationConfig = {
	id: {
		names: {
			taksonomi_h_id_fk: 'Taksonomi Bloom',
			nama_ind: 'Nama Taksonomi Bloom Detail',
			nama_eng: 'Name of Bloom`s Taxonomy Details',
		}
	}
}
configure({ generateMessage: localize(validationConfig) })

const taksonomi_bloom_list = ref([])
const taksonomi_bloom_nama_eng = ref('')
const selectedTaksonomiBloom = ref('')

onMounted(async () => {
	try {
		// eslint-disable-next-line no-undef
		$("select").select2();

		const response = await request(`/taksonomi-h?per_page=-1`)
		taksonomi_bloom_list.value = response.data.data

		// eslint-disable-next-line no-undef
		$('body').on('change', 'select.taksonomi_bloom', async (event) => {
			const id = event.target.value;
			const { data } = await request(`/taksonomi-h/${id}`);

			selectedTaksonomiBloom.value = id
			taksonomi_bloom_nama_eng.value = data.nama_eng + ' (' + data.taksonomi_h_kd + ')'
		})

		// eslint-disable-next-line no-undef
		$('button[type="submit"]').click(function() {
            // eslint-disable-next-line no-undef
            if ($(`select.taksonomi_bloom`).val() == '') {
                // eslint-disable-next-line no-undef
                $(`select.taksonomi_bloom.select2-hidden-accessible`).addClass('is-invalid')
                // eslint-disable-next-line no-undef
                $('span').find(`.select2-selection.taksonomi_bloom`).addClass('is-invalid')
            } else {
                // eslint-disable-next-line no-undef
                $(`select.taksonomi_bloom.select2-hidden-accessible`).removeClass('is-invalid')
                // eslint-disable-next-line no-undef
                $('span').find(`.select2-selection.taksonomi_bloom`).removeClass('is-invalid')
            }
        });
	} catch (error) {
		console.error('Error fetching data:', error)
	} 
});
</script>