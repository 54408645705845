import { useAccessStore } from '@/stores/accessStore' 

export function checkHasPermission(routeName) {
	const {menu, permissions} = useAccessStore();

	if (menu === null || permissions === null) return false

	const permissionActions = permissions.map(permission => permission.permission_action)
	const matchedMenu = findMenuByRoute(menu, routeName, permissionActions)

	if (matchedMenu === null) return false

	const permissionsMenu = assignPermissionToMenu(matchedMenu)

	return permissionsMenu.includes(routeName)
}

export function generatePermission(indexRoute, method) {
	const permissionByMethod = [
		{ method: 'POST', permission: 'Store' },
		{ method: 'PUT', permission: 'Update' },
		{ method: 'DELETE', permission: 'Delete' }
	]

	// Find the permission object based on the given method
	const matchingPermission = permissionByMethod.find(item => item.method === method)

	if (matchingPermission) {
		// If a matching permission is found, return it
		return `${matchingPermission.permission}${indexRoute}`
	}

	return false
}

function findMenuByRoute(menus, routeName, permissions) {
	const regex = new RegExp(permissions.join('|'), 'g')
	const menuWithoutAction = routeName.replace(regex, '')

	for (const menu of menus) {
		if (menu.route != '' && menuWithoutAction === menu.route) {
			return menu
		}
		if (menu.child_menu && menu.child_menu.length > 0) {
			const nestedMatch = findMenuByRoute(menu.child_menu, routeName, permissions)
			if (nestedMatch) {
				return nestedMatch
			}
		}
	}
	// If no matching menu is found in this menu array or its children, return null
	return null
}

function assignPermissionToMenu(menu) {
	const route = menu.route

	const permissionNames = menu.permissions.map(permission => `${permission.permission_action}${route}`)

	permissionNames.unshift(menu.route)

	return permissionNames
}
