<template>
    <label class="form-label">
        <img :src="getImagePath(`assets/flag/${bahasa.toLowerCase()}.png`)" alt="{{ bahasa }}" width="25" v-if="bahasa">
        {{ bahasa === 'Indonesia' ? 'Dalam Bahasa ' + bahasa : 'In English' }}
    </label>
</template>

<script>
export default {
    props: {
        bahasa: {
            type: String,
            default: null,
        }
    },
    methods: {
        getImagePath(relativePath) {
        return process.env.BASE_URL + relativePath;
        },
    },
}
</script>