<template>
   <table :id="props.text" class="table table-row-bordered align-middle gy-3 display" style="border:1px solid #c5c6cc !important;">
      <thead>
         <tr class="text-center" style="border:1px solid #c5c6cc !important; font-weight:600;">
            <th style="width: 5% !important;">No</th>
            <th>Jenis Semester</th>
            <th>Jumlah Semester</th>
            <th>Jumlah Minggu/Semester</th>
            <template v-if="props.curriculumID !== undefined">
               <th class="px-1" style="width:10% !important;">Aksi</th>
            </template>
         </tr>
      </thead>
      <tbody style="border:1px solid #c5c6cc !important;">
         <template v-if="props.curriculumSemesterData.length > 0">
            <tr v-for="(row, rowIndex) in props.curriculumSemesterData" :key="rowIndex">
               <td class="text-center">{{ rowIndex + 1 }}</td>
               <td>{{ row.semesterTypeName }}</td>
               <td>{{ row.numberOfSemester }}</td>
               <td>{{ row.numberOfWeek }}</td>
               <template v-if="props.curriculumID !== undefined">
                  <td class="text-center">
                     <span :data-id="row.id" class="fa fa-edit" style="cursor:pointer; font-size:15px; color:#7239ea;" @click="editCurriculumSemester"></span>
                     &nbsp;
                     <span :data-id="row.id" class="fa fa-trash" style="cursor:pointer; font-size:15px; color:#f1416c;" @click="removeCurriculumSemester"></span>
                  </td>
               </template>
            </tr>
         </template>
         <template v-else>
            <tr><td class="text-center fw-bold" :colspan="((props.curriculumID !== undefined) ? (5) : (4))">Belum ada data</td></tr>
         </template>
      </tbody>
   </table>
</template>

<script setup>
   import { defineProps, defineEmits } from 'vue';
   import request from '@/utils/request';
   import swal from 'sweetalert2';

   const props = defineProps({ curriculumSemesterData: Array, curriculumID: Number });
   const emits = defineEmits(['editCurriculumSemester', 'removeCurriculumSemester']);
   const removeCurriculumSemester = ((event) => {
      const id = event.target.dataset.id;
      swal.fire({ title: `Apakah Anda yakin untuk menghapus kurikulum semester ini?`, icon: 'warning', showCancelButton: true, confirmButtonText: 'Hapus', cancelButtonText: 'Batal' })
         .then(async (result) => {
            if (result.isConfirmed) {
               const res = await request({ url: `/kurikulum-semester/${ id }`, method: 'DELETE' });
               if (res.status == 'SUCCESS') {
                  swal.fire({ title: 'Dihapus!', text: 'Data kurikulum semester telah berhasil dihapus', icon: 'success'})
                     .then(() => {
                        emits('removeCurriculumSemester', { id, type: 'curriculumSemesterData' });
                     });
               }
            }
         });
   });
   const editCurriculumSemester = (async (event) => {
      const id = event.target.dataset.id;
      emits('editCurriculumSemester', { data: await request(`/kurikulum-semester/${ id }`), target: 'semester-form', dynamic: 'dynamicComponent' });
   });
</script>