<template>
    <breadcrumb-content menu="Pembuatan Kurikulum" subMenu="CPL"/>

    <div class="card mb-5 mb-xxl-8">
        <div class="card-body pt-9 pb-0">
            <router-link :to="{ name: 'CreateViewCPL'}" class="btn btn-primary mb-5">
                <span class="fa fa-plus"></span>
                Tambah CPL
            </router-link>
            <hr>

            <div class="row" style="display:flex; justify-content:center;">
                <div class="col-6">
                    <div class="row mb-5">
                        <label class="form-label" for="ri_id">
                            Rumpun Ilmu
                        </label>
                        <div class="col-12">
                            <select id="ri_id" name="ri_id" class="form-control form-control-solid-bg ri" data-control="select2" data-placeholder="-- Pilih Rumpun Ilmu --">
                                <option></option>
                                <option v-for="data in ri_list" :key="data.ri_id" :value="data.ri_id" :data-nama_eng="data.nama_eng"> {{ data.nama_ind }} </option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <label class="form-label" for="fakultas_id">
                            Fakultas
                        </label>
                        <div class="col-12">
                            <select id="fakultas_id" name="fakultas_id" class="form-control form-control-solid-bg fakultas" data-control="select2" data-placeholder="-- Pilih Fakultas --">
                                <option></option>
                                <option v-for="data in fakultas_list" :key="data.fakultas_id" :value="data.fakultas_id" :data-nama_eng="data.nama_eng"> {{ data.nama_ind }} </option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <label class="form-label" for="departemen_id">
                            Departemen
                        </label>
                        <div class="col-12">
                            <select id="departemen_id" name="departemen_id" class="form-control form-control-solid-bg departemen" data-control="select2" data-placeholder="-- Pilih Departemen --">
                                <option></option>
                                <option v-for="data in departemen_list" :key="data.departemen_id" :value="data.departemen_id" :data-nama_eng="data.nama_eng"> {{ data.nama_ind }} </option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <label class="form-label" for="prodi_id">
                            Program Studi
                        </label>
                        <div class="col-12">
                            <select id="prodi_id" name="prodi_id" class="form-control form-control-solid-bg prodi" data-control="select2" data-placeholder="-- Pilih Program Studi --">
                                <option></option>
                                <option v-for="data in prodi_list" :key="data.prodi_id" :value="data.prodi_id" :data-nama_eng="data.nama_eng"> {{ data.nama_ind }} </option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <label class="form-label" for="kurikulum_id">
                            Kurikulum
                        </label>
                        <div class="col-12">
                            <select id="kurikulum_id" name="kurikulum_id" class="form-control form-control-solid-bg kurikulum" data-control="select2" data-placeholder="-- Pilih Kurikulum --">
                                <option></option>
                                <option v-for="data in kurikulum_list" :key="data.kurikulum_id" :value="data.kurikulum_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind" :data-kurikulum_kd="data.kurikulum_kd" :data-is_aktif="data.is_aktif === 1 ? 'Aktif' : 'Tidak Aktif'"> {{ data.nama_ind }} </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <LoadingOverlay :loading="loading" />

            <div class="slide-down" v-if="kurikulumKD">
                <SSDatatable
                    ref="datatableRef"
                    url="/cpl-datatable"
                    primaryKey="cpl_id"
                    :columns="columns"
                    :show="handleShow"
                    :edit="handleEdit"
                    filterName="kurikulum_kd"
                    :filterData="kurikulumKD"
                />
            </div>
            <div v-else>
                <hr class="mt-2 mb-7">
                <div class="alert alert-primary alert-dismissible fade show fs-4 text-center mb-7" role="alert">
                    <i class="fas fa-info-circle fs-3 text-primary mx-1"></i>
                    <strong>Pilih kurikulum</strong> terlebih dahulu untuk menampilkan data <strong>CPL</strong>.
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import LoadingOverlay from '@/components/LoadingOverlay'
import SSDatatable from '@/components/SSDatatable.vue'
// import { sendRequest } from '@/api/common'
import request from '@/utils/request'

const datatableRef = ref(null)
const loading = ref(true)
const router = useRouter()

const columns = [
	{ title: 'No', data: 'rownum', searchable: false, width: '7%' },
	{ title: 'Capaian Pembelajaran Lulusan', data: 'cpl_taksonomi', width: '29%', render: (data, type, row) => renderMenuCPL(data, type, row) },
    { title: 'Kerangka Kerja', data: 'cpl_kk', width: '29%', render: (data, type, row) => renderMenuCPLKK(data, type, row) },
    { title: 'Kompetensi', data: 'cpl_kompetensi', width: '29%', render: (data, type, row) => renderMenuCPLKomp(data, type, row) },
	{ title: 'Aksi', data: 'actions', searchable: false, width: '6%' }
]

function renderMenuCPL(data, type, row) {
    if (type === 'display' && row) {
        const taksonomiHkdString = [...new Set(data.map(item => item.taksonomi_h_kd))].join(', ');

        return `
            <td>
                <span class="fw-bold">${row.cpl_kd}: ${row.name_ind}</span>
                <hr class="m-1">
                <p class="m-0" style="text-align: justify;">${row.deskripsi_ind}[${taksonomiHkdString}]</p>
            </td>
        `;
    } else {
        return `
            <td>
                <p class="m-0" style="text-align: justify;">${row.deskripsi_ind}</p>
            </td>
        `;
    }
}
function renderMenuCPLKK(data, type, row) {
    if (type === 'display' && row) {
        let menuHTML = data
            .map((item, index, array) => {
                return `
                    <td>
                        <span class="fw-bold">(${item.kk_kd}):</span> <p class="m-0" style="text-align: justify;">${item.nama_ind}</p>
                        ${array.length > 1 && index < array.length - 1 ? '<br />' : ''}
                    </td>
                `;
            })
            .join('');
    
    return menuHTML;
    }
    
    return ''
}
function renderMenuCPLKomp(data, type, row) {
    if (type === 'display' && row) {
        let menuHTML = data
            .map((item, index, array) => {
                return `
                    <td>
                        <span class="fw-bold">(${item.kompetensi_kd}):</span><p class="m-0" style="text-align: justify;">${item.nama_ind}</p>
                        ${array.length > 1 && index < array.length - 1 ? '<br />' : ''}
                    </td>
                `;
            })
            .join('');
    
    return menuHTML;
    }

    return ''
}

const handleShow = async cpl_id => router.push({ name: 'ShowViewCPL', params: { id: cpl_id } })
const handleEdit = async cpl_id => router.push({ name: 'EditViewCPL', params: { id: cpl_id } })
// const handleDelete = async cpl_id => {
// 	const param = {
// 		url: `/cpl/${cpl_id}`,
// 		method: 'DELETE',
// 		loading: loading,
// 		context: 'CPL'
// 	}

// 	return await sendRequest(param)
// }

const ri_list = ref([])
const fakultas_list = ref([])
const departemen_list = ref([])
const prodi_list = ref([])
const kurikulum_list = ref([])

const kurikulumKD = ref('')
const renderCustomOption = () => {
    const customSelect = (ress) => {
        if (!ress.id) return ress.text;

        // eslint-disable-next-line no-undef
        return $(`<label style="display:flex; flex-direction:column; cursor:pointer;"><span style="font-size:10px;"><b>${ $(ress.element).data('kurikulum_kd') } (${ $(ress.element).data('is_aktif') })</b></span><span style="font-size:14px;">${ $(ress.element).data('nama_ind') }</span></label>`);
    };
    // eslint-disable-next-line no-undef
    $('select.kurikulum').select2({ templateResult: customSelect, templateSelection: customSelect });
}

async function filteredSelect() {
    // eslint-disable-next-line no-undef
    $('body').on('change', 'select.ri', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/ri/${id}`);

        loading.value = true
        fakultas_list.value = data.fakultas
        departemen_list.value = null
        prodi_list.value = null
        kurikulum_list.value = null
        kurikulumKD.value = null
        loading.value = false
    }).on('change', 'select.fakultas', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/fakultas/${id}`);

        loading.value = true
        departemen_list.value = data.departemen
        prodi_list.value = null
        kurikulum_list.value = null
        kurikulumKD.value = null
        loading.value = false
    }).on('change', 'select.departemen', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/departemen/${id}`);

        loading.value = true
        prodi_list.value = data.prodi
        kurikulum_list.value = null
        kurikulumKD.value = null
        loading.value = false
    }).on('change', 'select.prodi', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/prodi/${id}`);

        loading.value = true
        kurikulum_list.value = data.kurikulum
        kurikulumKD.value = null
        loading.value = false

        renderCustomOption();
    }).on('change', 'select.kurikulum', async (event) => {
        kurikulumKD.value = null
        
        const id = event.target.value;
        const { data } = await request(`/kurikulum/${id}`);

        kurikulumKD.value = data.kurikulum_kd
    });
}

onMounted( async () => {
    try {
        const response = await request('/ri')
        ri_list.value = response.data.data
        
        await nextTick(() => { initializeSelect2(); });
        filteredSelect();
    } catch (error) {
		console.error('Error fetching data:', error)
	} finally {
		loading.value = false
	}
})

// eslint-disable-next-line no-undef
const initializeSelect2 = () => { $("select").select2(); };
</script>

<style scoped>
.slide-down { animation: slideDown 5s ease; }
@keyframes slideDown {
    0% { transform: translateY(-5%); opacity: 0; }
    5% { transform: translateY(0); opacity: 1; }
}
</style>