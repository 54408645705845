<template>
	<div>
		<breadcrumb-content menu="Master Data" subMenu="Evaluasi Kurikulum" />
		<div class="card mb-5 mb-xxl-8 placeholder-glow">
			<div class="pb-10">
				<div class="card-body pt-9 pb-0">
					<h1 class="anchor fw-bold mb-5">Detail Evaluasi Kurikulum</h1>
					<div class="p-5">
						<LoadingOverlay :loading="loading" />

						<FormInput
							labelInd="Rumpun Ilmu"
							columnName="ri_nama_ind"
							:valueInd="dataEK.ri_nama_ind"
							:valueEng="dataEK.ri_nama_eng"
							:isRequired="false"
							mode="show"
						/>
						<FormInput
							labelInd="Fakultas"
							columnName="fakultas_nama_ind"
							:valueInd="dataEK.fakultas_nama_ind"
							:valueEng="dataEK.fakultas_nama_eng"
							:isRequired="false"
							mode="show"
						/>
						<FormInput
							labelInd="Departemen"
							columnName="departemen_nama_ind"
							:valueInd="dataEK.departemen_nama_ind"
							:valueEng="dataEK.departemen_nama_eng"
							:isRequired="false"
							mode="show"
						/>
						<FormInput
							labelInd="Program Studi"
							columnName="prodi_nama_ind"
							:valueInd="dataEK.prodi_nama_ind"
							:valueEng="dataEK.prodi_nama_eng"
							:isRequired="false"
							mode="show"
						/>
						<FormInput
							labelInd="Jenjang"
							columnName="jenjang_nama_ind"
							:valueInd="dataEK.jenjang_nama_ind"
							:valueEng="dataEK.jenjang_nama_eng"
							:isRequired="false"
							mode="show"
						/>
						<div class="row mb-5">
                            <label class="form-label">
								<span style="color: red">*</span>
                                Kurikulum
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <select id="kurikulum_id" name="kurikulum_id" class="form-control form-control-solid-bg kurikulum" data-control="select2" data-placeholder="-- Pilih Kurikulum --" v-model="kurikulum_id" disabled>
                                    <option></option>
                                    <option v-for="data in kurikulum_list" :key="data.kurikulum_id" :value="data.kurikulum_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind" :data-kurikulum_kd="data.kurikulum_kd" :data-is_aktif="data.is_aktif === 1 ? 'Aktif' : 'Tidak Aktif'" :selected="kurikulumKD === data.kurikulum_kd"> {{ data.nama_ind }} </option>
                                </select>
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <div class="form-control form-control-solid" v-if="getCurriculumEng" v-html="getCurriculumEng"></div>
                            </div>
                        </div>

						<hr>
						
						<FormInput
							labelInd="Evaluasi Kurikulum"
							columnName="evaluasi_kurikulum"
							:valueInd="dataEK.evaluasi_kurikulum_ind"
							:valueEng="dataEK.evaluasi_kurikulum_eng"
							:ckeditor="true"
							mode="show"
						/>
						<FormInput
							labelInd="Evaluasi Sumatif"
							columnName="evaluasi_sumatif"
							:valueInd="dataEK.evaluasi_sumatif_ind"
							:valueEng="dataEK.evaluasi_sumatif_eng"
							:ckeditor="true"
							mode="show"
						/>
						<FormInput
							labelInd="Evaluasi Formatif"
							columnName="evaluasi_formatif"
							:valueInd="dataEK.evaluasi_formatif_ind"
							:valueEng="dataEK.evaluasi_formatif_eng"
							:ckeditor="true"
							mode="show"
						/>
						<FormInput
							labelInd="Studi Penelusuran"
							columnName="tracer_study"
							:valueInd="dataEK.tracer_study_ind"
							:valueEng="dataEK.tracer_study_eng"
							:ckeditor="true"
							mode="show"
						/>

						<FormInput
							labelInd="Dibuat pada"
							columnName="created"
							:isEnglish="false"
							:valueInd="formattedCreatedAt ? formattedCreatedAt : '-'"
							:isRequired="false"
							mode="show"
						/>
						<FormInput
							labelInd="Diubah pada"
							columnName="updated"
							:isEnglish="false"
							:valueInd="formattedUpdatedAt ? formattedUpdatedAt : '-'"
							:isRequired="false"
							mode="show"
						/>

						<div class="mt-10">
							<button @click="redirectToDataList" class="btn btn-secondary">Kembali</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script setup>
import { onMounted, ref, computed, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'

import FormInput from '@/components/FormInput.vue'

const loading = ref(true)
const router = useRouter()
const kurikulum_id = router.currentRoute.value.params.id

const dataEK = ref({})

import { formatReadableDate } from '@/utils/date'
const formattedCreatedAt = computed(() => formatReadableDate(dataEK.value.created_at))
const formattedUpdatedAt = computed(() => formatReadableDate(dataEK.value.updated_at))

const redirectToDataList = () => router.push({ name: 'EvaluasiKurikulum' })

const kurikulum_list = ref([])
const kurikulumKD = ref('')
const renderCustomOption = () => {
    const customSelect = (ress) => {
        if (!ress.id) return ress.text;

        // eslint-disable-next-line no-undef
        return $(`<label style="display:flex; flex-direction:column; cursor:pointer;"><span style="font-size:10px;"><b>${ $(ress.element).data('kurikulum_kd') } (${ $(ress.element).data('is_aktif') })</b></span><span style="font-size:14px;">${ $(ress.element).data('nama_ind') }</span></label>`);
    };
    // eslint-disable-next-line no-undef
    $('select.kurikulum').select2({ templateResult: customSelect, templateSelection: customSelect });
}
const getCurriculumEng = computed(() => {
    const selectedData = kurikulum_list.value.find(data => data.kurikulum_kd === kurikulumKD.value);
    if (selectedData) {
        const { kurikulum_kd, is_aktif, nama_eng } = selectedData;
        return `<label style="display:flex; flex-direction:column; cursor:pointer;">
                <span style="font-size:10px;"><b>${kurikulum_kd} (${is_aktif === 1 ? 'Active' : 'Not Active'})</b></span>
                <span style="font-size:14px;">${nama_eng}</span>
                </label>`;
    }
    return '';
})

onMounted(async () => {
	try {
		const response = await request('/kurikulum')
        kurikulum_list.value = response.data.data
				
		const { data } = await request(`/kurikulum/${kurikulum_id}`)
		dataEK.value = data
		kurikulumKD.value = data.kurikulum_kd

		await nextTick(() => { initializeSelect2(); });
        renderCustomOption();
	} catch (error) {
		console.error('Error fetching data:', error)
	} finally {
		loading.value = false
	}
})

// eslint-disable-next-line no-undef
const initializeSelect2 = () => { $("select").select2(); };
</script>