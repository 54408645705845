<template>
	<router-view />
</template>
<style>
/* fixed datatable hover when dark-mode  */
[data-theme='dark'] {
	.dataTable tr:hover {
		background-color: var(--kt-secondary);
	}
}
</style>
