<template>
	<div v-if="props.loading" class="page-loader flex-column">
		<span class="spinner-border text-primary" role="status"></span>
		<span class="text-muted fs-6 fw-semibold mt-5">Loading...</span>
	</div>
</template>

<script setup>
import { defineProps } from 'vue'

const props = defineProps({
	loading: {
		type: Boolean,
		required: true
	}
})
</script>

<style>
.page-loader {
	opacity: 0.8;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000; /* Ensure it's above other content */
}
</style>
