<template>
	<breadcrumb-content menu="Role Management" subMenu="Menu Permission" />
	<div class="card mb-5 mb-xxl-8">
		<div class="card-body pt-9 pb-0">
			<LoadingOverlay :loading="loading" />

			<SSDatatable
				ref="datatableRef"
				url="/menu-permission-datatable"
				primaryKey="role_id"
				:columns="columns"
				:show="handleShow"
				:edit="handleEdit"
			/>
		</div>
	</div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import LoadingOverlay from '@/components/LoadingOverlay'
import SSDatatable from '@/components/SSDatatable.vue'

const datatableRef = ref(null)
const loading = ref(false)
const router = useRouter()
const columns = [
	{
		title: 'No',
		data: 'rownum',
		searchable: false,
		width: '8%',
		className: 'px-1'
	},
	{ title: 'Nama', data: 'role_name', width: '15%' },
	{ title: 'Permission', data: 'permission_access', render: (data, type, row) => renderPermission(data, type, row) },
	{ title: 'Aksi', data: 'actions', searchable: false }
]

function renderPermission(data, type, row) {
	if (type === 'display' && row) {
		let menuHTML = data.map(item => `<code>${item}</code>`).join('')

		return menuHTML
	}
	return ''
}

const handleShow = async id => router.push({ name: 'ShowViewMenuPermission', params: { id: id } })
const handleEdit = async id => router.push({ name: 'EditViewMenuPermission', params: { id: id } })
</script>
