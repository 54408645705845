<template>
	<breadcrumb-content menu="Master Data" subMenu="Kompetensi" />

	<div class="card mb-5 mb-xxl-8">
		<div class="pb-10">
			<div class="card-body pt-9 pb-0">
				<h1 class="anchor fw-bold mb-5">Tambah Kompetensi</h1>
				<div class="p-5">
					<Form
						id="form-kompetensi-create"
						class="kt-form"
						method="post"
						@submit="onSubmit"
						:validation-schema="schema"
						v-slot="{ errors }"
					>
						<LoadingOverlay :loading="loading" />

						<div class="row mb-5">
                            <label class="form-label">
								<span style="color: red">*</span>
                                Jenis Kompetensi
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <select class="form-control form-control-solid-bg jenis_kompetensi" data-control="select2" data-placeholder="-- Pilih Jenis Kompetensi --">
                                    <option></option>
                                    <option v-for="data in jenis_kompetensi_list" :key="data.jenis_kompetensi_id" :value="data.jenis_kompetensi_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind"> {{ data.nama_ind }} </option>
                                </select>
                                <Field type="hidden" id="jenis_kompetensi_id_fk" name="jenis_kompetensi_id_fk" v-model="selectedJenisKompetensi" readonly/>
								<div id="jenis_kompetensi_id_fk-error" class="invalid-feedback">Jenis Kompetensi harus diisi</div>
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <input type="text" class="form-control form-control-solid-bg bg-light-dark jenis_kompetensi" readonly placeholder="Select Competency Type" v-model="jenis_kompetensi_nama_eng">
                            </div>
                        </div>

						<FormInput
							labelInd="Kode Kompetensi"
							customName="kompetensi_kd"
							:errorInd="errors.kompetensi_kd"
							:isEnglish="false"
						/>
						<FormInput
							labelInd="Nama Kompetensi"
							labelEng="Name of Competency"
							columnName="nama"
							:errorInd="errors.nama_ind"
							:errorEng="errors.nama_eng"
						/>
						<FormInput
							inputType="textarea"
							rows="5"
							labelInd="Deskripsi"
							labelEng="Description"
							columnName="deskripsi"
							:errorInd="errors.deskripsi_ind"
							:errorEng="errors.deskripsi_eng"
							:isRequired="false"
						/>

						<div class="mt-10">
							<button type="submit" class="btn btn-primary">Simpan</button>
							&nbsp;
							<button @click="redirectToDataList" class="btn btn-secondary">Kembali</button>
						</div>
					</Form>
				</div>
			</div>
		</div>
	</div>
</template>


<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { Form, configure } from 'vee-validate'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'
import { sendRequest } from '@/api/common'

import { Field } from 'vee-validate'
import FormInput from '@/components/FormInput.vue'

const schema = {
	jenis_kompetensi_id_fk: 'required',
	kompetensi_kd: 'required',
	nama_ind: 'required',
	nama_eng: 'required',
}

const loading = ref(false)
const router = useRouter()

const redirectToDataList = () => router.push({ name: 'Kompetensi' })

const onSubmit = async values => {
	values.jenis_kompetensi_id_fk = parseInt(values.jenis_kompetensi_id_fk)
	values.is_aktif = true

	const param = {
		url: '/kompetensi',
		method: 'POST',
		body: values,
		loading: loading,
		context: 'Kompetensi'
	}

	return await sendRequest(param)
}

// custom message error
import { localize } from '@vee-validate/i18n'
const validationConfig = {
	id: {
		names: {
			jenis_kompetensi_id_fk: 'Jenis Kompetensi',
			kompetensi_kd: 'Kode Kompetensi',
			nama_ind: 'Nama Kompetensi',
			nama_eng: 'Name of Competency',
		}
	}
}
configure({ generateMessage: localize(validationConfig) })

const jenis_kompetensi_list = ref([])
const jenis_kompetensi_nama_eng = ref('')
const selectedJenisKompetensi = ref('')
onMounted(async () => {
	try {
		// eslint-disable-next-line no-undef
		$("select").select2();

		const response = await request(`/jenis-kompetensi`)
		jenis_kompetensi_list.value = response.data.data

		// eslint-disable-next-line no-undef
		$('body').on('change', 'select.jenis_kompetensi', async (event) => {
			const id = event.target.value;
			const { data } = await request(`/jenis-kompetensi/${id}`);

			selectedJenisKompetensi.value = id
			jenis_kompetensi_nama_eng.value = data.nama_eng
		})

		// eslint-disable-next-line no-undef
		$('button[type="submit"]').click(function() {
            // eslint-disable-next-line no-undef
            if ($(`select.jenis_kompetensi`).val() == '') {
                // eslint-disable-next-line no-undef
                $(`select.jenis_kompetensi.select2-hidden-accessible`).addClass('is-invalid')
                // eslint-disable-next-line no-undef
                $('span').find(`.select2-selection.jenis_kompetensi`).addClass('is-invalid')
            } else {
                // eslint-disable-next-line no-undef
                $(`select.jenis_kompetensi.select2-hidden-accessible`).removeClass('is-invalid')
                // eslint-disable-next-line no-undef
                $('span').find(`.select2-selection.jenis_kompetensi`).removeClass('is-invalid')
            }
        });
	} catch (error) {
		console.error('Error fetching data:', error)
	} 
});
</script>