<template>
   <breadcrumb-content menu="Profil" subMenu="Perguruan Tinggi" />
   <div class="card mb-5 mb-xxl-8">
      <div class="pb-10">
         <div v-if="dynamicComponent">
            <component :is="dynamicComponent" @form="recieveForm" />
         </div>
      </div>
   </div>
</template>

<script setup>
   import { onMounted, shallowRef, ref } from 'vue';

   const loading = ref(true);
   let dynamicComponent = shallowRef(null);
   const recieveForm = ((data) => {
      import(`./${ data }.vue`).then(module => {
         dynamicComponent.value = module.default;
      });
   });

   onMounted(async () => {
      try {
         import(`./ShowView.vue`).then(module => {
            dynamicComponent.value = module.default;
         });
      } catch (error) {
         console.error('Error fetching perguruan tinggi data: ', error);
      } finally {
         loading.value = false;
      }
   });
</script>