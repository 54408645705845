import Keycloak from 'keycloak-js'
import { useAuthStore } from '@/stores/authStore'
import { useAccessStore } from '@/stores/accessStore'

const options = {
	url: process.env.VUE_APP_UI_SSO_URL,
	realm: process.env.VUE_APP_UI_SSO_REALM,
	clientId: process.env.VUE_APP_UI_SSO_CLIENT_ID
}
const keycloak = new Keycloak(options)
const MAX_RETRY_ATTEMPTS = 3
let retryAttempts = 0

/**
 * Initializes Keycloak, thn run callback. This will prompt you to login.
 *
 * @param onAuthenticatedCallback
 */
async function init() {
	await keycloak
		.init({
			onLoad: 'check-sso',
			pkceMethod: 'S256',
			checkLoginIframe: false
		})
		.then(async authenticated => {
			if (authenticated) {
				await initStore()
			} else {
				await keycloak.login()
			}
		})
		.catch(err => {
			console.error('Keycloak init failed')
			console.error(err)
		})
}

/**
 * Initializes store with Keycloak user data
 *
 */
async function initStore() {
	try {
		const accessStore = useAccessStore()
		const authStore = useAuthStore()
		await authStore.initOauth(keycloak)
		await accessStore.initAccess()

		setInterval(() => {
			refreshToken()
		}, 60000) // refresh token each 60 sec
	} catch (error) {
		console.error('Keycloak init failed')
		console.error(error)
	}
}

/**
 * Logout user
 */
function logout(url) {
	keycloak.logout({ redirectUri: url })
}

/**
 * Refreshes token
 */
async function refreshToken() {
	const authStore = useAuthStore()

	// Akses token menggunakan getter
	try {
		keycloak
			.updateToken(60) // Refresh if token expires within 60 seconds
			.then(refreshed => {
				if (refreshed) {
					console.log('Token refreshed successfully')
					authStore.updateToken(keycloak.token)
					retryAttempts = 0 // Reset retry attempts
				} else {
					console.warn('Token was not refreshed, but still valid')
				}
			})
			.catch(error => {
				console.error('Error refreshing token:', error)
				retryAttempts += 1
				if (retryAttempts >= MAX_RETRY_ATTEMPTS) {
					console.error('Max retry attempts reached. Logging out or taking other action.')
					keycloak.logout() // Or other action, like redirecting to login
				}
			})
	} catch (error) {
		console.error('Failed to refresh token')
		console.error(error)
	}
}

const KeycloakService = {
	CallInit: init,
	CallInitStore: initStore,
	CallLogout: logout,
	CallTokenRefresh: refreshToken
}

export default KeycloakService
