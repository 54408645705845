<template>
   <breadcrumb-content menu="Profil" subMenu="Jenjang" />
   <div class="card mb-5 mb-xxl-8">
      <div class="pb-10">
         <div class="card-body pt-9 pb-0">
            <h1 class="anchor fw-bold mb-5">Detail Jenjang</h1>
            <div class="p-5">
               <div class="row mb-5">
                  <label class="form-label">Nama Jenjang</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input class="form-control form-control-solid-bg" v-model="grade.nama_ind" disabled="disabled" />
                  </div>
                  <div class="col-6">
                     <flag-image bahasa="Inggris" />
                     <input class="form-control form-control-solid-bg" v-model="grade.nama_eng" disabled="disabled" />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Dibuat pada</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input class="form-control form-control-solid-bg" :value="formatReadableDate(grade.created_at)" disabled="disabled" />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Diubah pada</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input class="form-control form-control-solid-bg" :value="formatReadableDate(grade.updated_at)" disabled="disabled" />
                  </div>
               </div>
               <div class="mt-10">
                  <div class="col-lg-6">
                     <button @click="redirectToJenjangList" class="btn btn-secondary">Kembali</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup>
   import { onMounted, ref } from 'vue';
   import { useRouter } from 'vue-router';
   import { formatReadableDate } from '@/utils/date';
   import request from '@/utils/request';

	const router = useRouter();
	const loading = ref(true);
	const id = router.currentRoute.value.params.id;
	const grade = ref({});
	const redirectToJenjangList = () => router.push({ name: 'Jenjang' });

	onMounted(async () => {
		try {
			grade.value = (await request(`/jenjang/${ id }`)).data;
		} catch (error) {
			console.error('Error fetching data: ', error);
		} finally {
			loading.value = false;
		}
	});
</script>