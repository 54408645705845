<template>
	<breadcrumb-content menu="Master Data" subMenu="Dosen" />

	<div class="card mb-5 mb-xxl-8" style="user-select: none;">
		<div class="pb-10">
			<div class="card-body pt-9 pb-0">
				<h1 class="anchor fw-bold mb-5">Detail Dosen</h1>
				<div class="p-5">
					<div class="row">
						<div class="col-lg-4 mb-5 text-center">
							<div class="row" >
								<div class="mb-5 col-xl-12">
									<img
										:src="dosenDeteil?.employee_photo || '/assets/images/no-profile.png'"
										class="img-thumbnail"
										:alt="dosenDeteil?.employee_display_name || 'Foto Dosen'"
										style="width: 100%; max-width: 200px; padding: 3px; border-radius: 5px"
										loading="lazy"
										draggable="false"
									/>
								</div>
								<div class="mb-5 col-xl-12 d-flex justify-content-center">
									<div
										v-if="dosenDeteil?.employment_status"
										:class="`bg-${
											dosenDeteil?.status === 'ACTIVE' ? 'success' : 'secondary'
										} badge-sm py-1 px-2 badge-centered rounded text-white font-weight-bold font-size-sm`"
										style="width: 100%; max-width: 200px"
									>
										{{ dosenDeteil?.employment_status + ' - ' + dosenDeteil?.status }}
									</div>
								</div>
							</div>
						</div>
						<div class="mb-5 col-lg-8">
							<div class="row">
								<div class="mb-5 col-xl-12">
									<label class="form-label"> Nama Dosen </label>
									<input
										type="text"
										name="nama_dosen"
										class="form-control form-control-solid"
										:value="dosenDeteil?.employee_display_name"
										disabled
									/>
								</div>
								<div class="mb-5 col-xl-6 col-md-12">
									<label class="form-label">Email</label>
									<div>
										<template v-if="dosenDeteil?.email">
											<div v-for="(email, index) in dosenDeteil?.email.split(',')" :key="index" class="mb-1">
												<div class="form-control form-control-solid">
													{{ email.trim() }}
												</div>
											</div>
										</template>
										<template v-else>
											<p>-</p>
										</template>
									</div>
								</div>
								<div class="mb-5 col-xl-6 col-md-12">
									<label class="form-label"> NIP </label>
									<input
										type="text"
										name="nip"
										class="form-control form-control-solid"
										:value="dosenDeteil?.nip"
										disabled
									/>
								</div>
								<div class="mb-5 col-xl-12">
									<label class="form-label"> Unit Organisasi </label>
									<input
										type="text"
										name="organization_unit"
										class="form-control form-control-solid"
										:value="dosenDeteil?.organization_unit"
										disabled
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="mt-5">
						<div class="kt-form__actions">
							<div class="col-lg-6">
								<router-link :to="{ name: 'Dosen' }" class="btn btn-secondary btn-kembali">Kembali</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref } from 'vue'
import { getDosenDetail } from '../../../api/dosen'
import { useRouter } from 'vue-router'

const router = useRouter()

const dosenDeteil = ref({})

const getDataDosenDeteil = async () => {
	try {
		const data = await getDosenDetail(router.currentRoute.value.params?.id)
		dosenDeteil.value = data
	} catch (error) {
		console.log(error)
	}
}

getDataDosenDeteil()
</script>
