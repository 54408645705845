import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import BreadcrumbContent from "./components/BreadcrumbContent.vue";
import FlagImage from "./components/FlagImage.vue";
import ImageInput from "./components/ImageInput.vue";
import CKEditor from '@ckeditor/ckeditor5-vue';
import TableDataPlaceholder from "./components/Skeleton/TableDataPlaceholder.vue";
import { initRule } from "@/utils/rule";
import keycloakService from '@/utils/keycloak';
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

const app = createApp(App);
const pinia = createPinia()

initRule();

pinia.use(piniaPluginPersistedstate)

app
	.component("breadcrumb-content", BreadcrumbContent)
	.component("flag-image", FlagImage)
	.component("image-input", ImageInput)
	.component("table-placeholder", TableDataPlaceholder)
	.use(CKEditor)
	.use(router)
	.use(pinia);
	
app.mount("#app");

await keycloakService.CallInit();
  

