import { defineStore } from 'pinia'
import { getUserProfile } from '@/api/profile'


export const useAuthStore = defineStore({
	id: 'authStore',
	state: () => ({
		authenticated: false,
		user: {},
    profile : null,
	}),
	persist: true,
	getters: {},
	actions: {
		// Initialize Keycloak OAuth
		async initOauth(keycloak, clearData = true) {
			if (clearData) {
				this.clearUserData()
			}

			if (keycloak.authenticated) {
				this.authenticated = keycloak.authenticated
				this.user.email = keycloak.tokenParsed.email
				this.user.name = keycloak.tokenParsed.name
				this.user.token = keycloak.token
				this.user.tokenParsed = keycloak.tokenParsed
				this.user.refreshToken = keycloak.refreshToken
				this.user.refreshTokenParsed = keycloak.refreshTokenParsed
			}
		},

		async updateToken(token) {
			this.user.token = token
		},

		// Clear user's store data
		clearUserData() {
			this.authenticated = false
			this.user = {}
      this.profile = null
		},
    async getProfile() {
      if (this.profile) return this.profile

      try {
        const data = await getUserProfile()
        this.profile = data
      } catch (error) {
        console.log(error?.message)
      }
    },
	}
})
