<template>
    <breadcrumb-content menu="Master Data" subMenu="Jenis Kerangka Kerja"/>

    <div class="card mb-5 mb-xxl-8">
        <div class="card-body pt-9 pb-0">
            <LoadingOverlay :loading="loading" />
            <router-link :to="{ name: 'CreateViewJenisKerangkaKerja'}" class="btn btn-primary mb-5">
                <span class="fa fa-plus"></span>
                Tambah Jenis Kerangka Kerja
            </router-link>
            
            <SSDatatable
                ref="datatableRef"
                url="/jenis-kerangka-kerja-datatable"
                primaryKey="jenis_kk_id"
                :columns="columns"
                :show="handleShow"
                :edit="handleEdit"
                :delete="handleDelete"
            />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import LoadingOverlay from '@/components/LoadingOverlay'
import SSDatatable from '@/components/SSDatatable.vue'
import { sendRequest } from '@/api/common'

const datatableRef = ref(null)
const loading = ref(false)
const router = useRouter()

const columns = [
	{ title: 'No', data: 'rownum', searchable: false, width: '8%', className: 'px-1' },
	{ title: 'Nama Jenis Kerangka Kerja', data: 'nama_ind' },
	{ title: 'Sumber', data: 'sumber_ind' },
	{ title: 'Deskripsi', data: 'deskripsi_ind' },
	{ title: 'Aksi', data: 'actions', searchable: false }
]

const handleShow = async jenis_kk_id => router.push({ name: 'ShowViewJenisKerangkaKerja', params: { id: jenis_kk_id } })
const handleEdit = async jenis_kk_id => router.push({ name: 'EditViewJenisKerangkaKerja', params: { id: jenis_kk_id } })
const handleDelete = async jenis_kk_id => {
	const param = {
		url: `/jenis-kerangka-kerja/${jenis_kk_id}`,
		method: 'DELETE',
		loading: loading,
		context: 'JenisKerangkaKerja'
	}

	return await sendRequest(param)
}
</script>