<template>
    <breadcrumb-content menu="Master Data" subMenu="Tinjauan Pustaka"/>

    <div class="card mb-5 mb-xxl-8">
        <div class="card-body pt-9 pb-0">
            <LoadingOverlay :loading="loading" />
            <router-link :to="{ name: 'CreateViewTinjauanPustaka'}" class="btn btn-primary mb-5">
                <span class="fa fa-plus"></span>
                Tambah Tinjauan Pustaka
            </router-link>
            
            <SSDatatable
                ref="datatableRef"
                url="/tinjauan-pustaka-datatable"
                primaryKey="tinjauan_pustaka_id"
                :columns="columns"
                :show="handleShow"
                :edit="handleEdit"
                :delete="handleDelete"
            />
        </div>
    </div>
</template>

<script setup>
import { sendRequest } from '@/api/common'
import LoadingOverlay from '@/components/LoadingOverlay'
import SSDatatable from '@/components/SSDatatable.vue'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const datatableRef = ref(null)
const loading = ref(false)
const router = useRouter()

const columns = [
	{ title: 'No', data: 'rownum', searchable: false, width: '8%', className: 'px-1' },
	{ title: 'Kategori', data: 'kat_tinjauan_pustaka_nama_ind' },
	{ title: 'Judul', data: 'judul_ind' },
	{ title: 'Penerbit', data: 'penerbit_ind' },
	{ title: 'Penulis', data: 'penulis_ind' },
	{ title: 'Tahun Terbit', data: 'tahun_terbit', searchable: false },
	{ title: 'Deskripsi', data: 'deskripsi_ind' },
	{ title: 'Aksi', data: 'actions', searchable: false }
]

const handleShow = async tinjauan_pustaka_id => router.push({ name: 'ShowViewTinjauanPustaka', params: { id: tinjauan_pustaka_id } })
const handleEdit = async tinjauan_pustaka_id => router.push({ name: 'EditViewTinjauanPustaka', params: { id: tinjauan_pustaka_id } })
const handleDelete = async tinjauan_pustaka_id => {
	const param = {
		url: `/tinjauan-pustaka/${tinjauan_pustaka_id}`,
		method: 'DELETE',
		loading: loading,
		context: 'TinjauanPustaka'
	}

	return await sendRequest(param)
}
</script>