export const formatReadableDate = timestamp => {
	if (!timestamp) {
		return ''
	}

	const date = new Date(timestamp)
	const months = [
		'Januari',
		'Februari',
		'Maret',
		'April',
		'Mei',
		'Juni',
		'Juli',
		'Agustus',
		'September',
		'Oktober',
		'November',
		'Desember'
	]
	const monthIndex = date.getMonth()
	const monthName = months[monthIndex]
	const day = date.getDate()
	const year = date.getFullYear()
	const hours = String(date.getHours()).padStart(2, '0') // Ensure two-digit hours
	const minutes = String(date.getMinutes()).padStart(2, '0') // Ensure two-digit minutes
	const seconds = String(date.getSeconds()).padStart(2, '0') // Ensure two-digit seconds
	return `${day} ${monthName} ${year} ${hours}:${minutes}:${seconds}`
}
