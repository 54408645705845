<template>
	<breadcrumb-content menu="Pembuatan Kurikulum" subMenu="Matrik MKK dan CPL"/>
	<div class="card mb-5 mb-xxl-8">
		<div class="card-body pt-9 pb-0">
			<div class="p-5">
				<div class="row" style="display:flex; justify-content:center; margin-bottom:10px;">
					<div class="col-6">
						<div class="row mb-5">
							<label class="form-label">Rumpun Ilmu</label>
							<div class="col-12">
								<select class="form-control form-control-solid-bg group" data-control="select2" data-placeholder="-- Pilih Rumpun Ilmu --" @change="handleGroup">
									<option></option>
									<option v-for="(row, index) in group" :key="index" :value="row.ri_id">{{ row.nama_ind }}</option>
								</select>
							</div>
						</div>
						<div class="row mb-5">
							<label class="form-label">Fakultas</label>
							<div class="col-12">
								<select class="form-control form-control-solid-bg faculty" data-control="select2" data-placeholder="-- Pilih Fakultas --" @change="handleFaculty">
									<option></option>
									<option v-for="(row, index) in faculty" :key="index" :value="row.fakultas_id">{{ row.nama_ind }}</option>
								</select>
							</div>
						</div>
						<div class="row mb-5">
							<label class="form-label">Departemen</label>
							<div class="col-12">
								<select class="form-control form-control-solid-bg department" data-control="select2" data-placeholder="-- Pilih Departemen --" @change="handleDepartment">
									<option></option>
									<option v-for="(row, index) in department" :key="index" :value="row.departemen_id">{{ row.nama_ind }}</option>
								</select>
							</div>
						</div>
						<div class="row mb-5">
							<label class="form-label">Program</label>
							<div class="col-12">
								<select class="form-control form-control-solid-bg program" data-control="select2" data-placeholder="-- Pilih Program --" @change="handleProgram">
									<option></option>
									<option v-for="(row, index) in program" :key="index" :value="row.prodi_id">{{ row.nama_ind }}</option>
								</select>
							</div>
						</div>
						<div class="row mb-5">
							<label class="form-label">Kurikulum</label>
							<div class="col-12">
								<select :class="`form-control form-control-solid-bg curriculum`" data-control="select2" data-placeholder="-- Pilih Kurikulum --" @change="handleCurriculum">
									<option></option>
									<option v-for="(row, index) in curriculum" :key="index" :value="row.kurikulum_id" :data-code="row.kurikulum_kd" :data-name="row.nama_ind">{{ row.kurikulum_kd }} {{ row.nama_ind }}</option>
								</select>
							</div>
						</div>
					</div>
				</div>
				
				<hr class="mt-2 mb-7">

				<div v-if="curriculumDetail.kurikulum_kd">
					<component :is="ListMKKTable" :curriculumCode="curriculumDetail.kurikulum_kd" />
				</div>
				<div v-else>
					<div class="alert alert-primary alert-dismissible fade show fs-4 text-center" role="alert">
						<i class="fas fa-info-circle fs-3 text-primary mx-1"></i>
						<strong>Pilih kurikulum</strong> terlebih dahulu untuk menampilkan data <strong>Matriks MKK & Bahan Kajian</strong>.
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { onMounted, shallowRef, ref } from 'vue';
	import request from '@/utils/request';

	const loading = ref(false);
	const group = ref({});
	const groupDetail = ref({});
	const faculty = ref({});
	const facultyDetail = ref({});
	const department = ref({});
	const departmentDetail = ref({});
	const program = ref({});
	const programDetail = ref({});
	const curriculum = ref({});
	const curriculumDetail = ref({});

	const handleGroup = (async (event) => {
		handleSelect({ facultySelect: false, departmentSelect: false, programSelect: false, curriculumSelect: false });
		groupDetail.value = (await request({ url:`/ri/${ event.target.value }` })).data;
		faculty.value = groupDetail.value.fakultas;
	});

	const handleFaculty = (async (event) => {
		handleSelect({ facultySelect: true, departmentSelect: false, programSelect: false, curriculumSelect: false });
		facultyDetail.value = (await request({ url:`/fakultas/${ event.target.value }` })).data;
		department.value = facultyDetail.value.departemen;
	});

	const handleDepartment = (async (event) => {
		handleSelect({ facultySelect: true, departmentSelect: true, programSelect: false, curriculumSelect: false });
		departmentDetail.value = (await request({ url:`/departemen/${ event.target.value }` })).data;
		program.value = departmentDetail.value.prodi;
	});

	const handleProgram = (async (event) => {
		handleSelect({ facultySelect: true, departmentSelect: true, programSelect: true, curriculumSelect: false });
		programDetail.value = (await request({ url:`/prodi/${ event.target.value }` })).data;
		curriculum.value = programDetail.value.kurikulum;
	});
	
	const handleCurriculum = (async (event) => {
		handleSelect({ facultySelect: true, departmentSelect: true, programSelect: true, curriculumSelect: true });
		curriculumDetail.value = (await request({ url:`/kurikulum/${ event.target.value }` })).data;

		if (Object.keys(curriculumDetail.value).length > 0) {
			ListMKKTable.value = null;
			import(`./ListMKKTable.vue`).then(module => {
				ListMKKTable.value = module.default;
			}).then(() => {
				// eslint-disable-next-line no-undef
				$(`#table`).slideDown(1500);
			});
		}
	});

	const handleSelect = (({ facultySelect, departmentSelect, programSelect, curriculumSelect }) => {
		if (!facultySelect) { faculty.value = {}; facultyDetail.value = {}; }
		if (!departmentSelect) { department.value = {}; departmentDetail.value = {}; }
		if (!programSelect) { program.value = {}; programDetail.value = {}; }
		if (!curriculumSelect) { curriculum.value = {}; curriculumDetail.value = {}; }
	});

	const templateSelect = (ress) => {
		if (!ress.id) return ress.text;

		// eslint-disable-next-line no-undef
		if ($(ress.element).data('ext')) {
			// eslint-disable-next-line no-undef
			return $(`<label style="display:flex; flex-direction:column; cursor:pointer;"><span style="font-size:10px;">${ $(ress.element).data('code') }</span><span style="font-size:14px;">${ $(ress.element).data('name') } - ${ $(ress.element).data('ext') }</span></label>`);
		}
		
		// eslint-disable-next-line no-undef
		return $(`<label style="display:flex; flex-direction:column; cursor:pointer;"><span style="font-size:10px;">${ $(ress.element).data('code') }</span><span style="font-size:14px;">${ $(ress.element).data('name') }</span></label>`);
	};
	let ListMKKTable = shallowRef(null);

	onMounted(async () => {
		try {
			group.value = (await request({ url:'/ri' })).data.data;

			// eslint-disable-next-line no-undef
			$('select.group').select2().on('change', handleGroup);
			// eslint-disable-next-line no-undef
			$('select.faculty').select2().on('change', handleFaculty);
			// eslint-disable-next-line no-undef
			$('select.department').select2().on('change', handleDepartment);
			// eslint-disable-next-line no-undef
			$('select.program').select2().on('change', handleProgram);
			// eslint-disable-next-line no-undef
			$('select.curriculum').select2({ templateResult:templateSelect, templateSelection:templateSelect }).on('change', handleCurriculum);
			// eslint-disable-next-line no-undef
			$('.select2-selection.select2-selection--single.curriculum').css({ 'height':'60px' });
		} catch (error) {
			console.error('Error fetching data: ', error);
		} finally {
			loading.value = false;
		}
	});
</script>