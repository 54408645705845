<template>
	<breadcrumb-content menu="Pembuatan Kurikulum" subMenu="Tujuan Program Pendidikan" />

	<div class="card mb-5 mb-xxl-8">
		<div class="pb-10">
			<div class="card-body pt-9 pb-0">
				<h1 class="anchor fw-bold mb-5">Tambah Tujuan Program Pendidikan</h1>
				<div class="p-5">
					<Form
						id="form-tpp-create"
						class="kt-form"
						method="post"
						@submit="onSubmit"
                        ref="formRef"
					>
						<LoadingOverlay :loading="loading" />

                        <div class="row mb-5">
                            <label class="form-label">
                                Rumpun Ilmu
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <select class="form-control form-control-solid-bg ri" data-control="select2" data-placeholder="-- Pilih Rumpun Ilmu --">
                                    <option></option>
                                    <option v-for="data in ri_list" :key="data.ri_id" :value="data.ri_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind"> {{ data.nama_ind }} </option>
                                </select>
                                <Field type="hidden" id="ri_id" name="ri_id" v-model="selectedRI" readonly/>
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <input type="text" class="form-control form-control-solid-bg bg-light-dark rumpun_ilmu" readonly placeholder="Select Group of knowledge" v-model="ri_nama_eng">
                            </div>
                        </div>
                        
                        <div class="row mb-5">
                            <label class="form-label">
                                Fakultas
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <select class="form-control form-control-solid-bg fakultas" data-control="select2" data-placeholder="-- Pilih Fakultas --">
                                    <option></option>
                                    <option v-for="data in fakultas_list" :key="data.fakultas_id" :value="data.fakultas_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind"> {{ data.nama_ind }} </option>
                                </select>
                                <Field type="hidden" id="fakultas_id" name="fakultas_id" v-model="selectedFakultas" readonly/>
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <input type="text" class="form-control form-control-solid-bg bg-light-dark fakultas" readonly placeholder="Select Faculty" v-model="fakultas_nama_eng">
                            </div>
                        </div>

                        <div class="row mb-5">
                            <label class="form-label">
                                Departemen
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <select class="form-control form-control-solid-bg departemen" data-control="select2" data-placeholder="-- Pilih Departemen --">
                                    <option></option>
                                    <option v-for="data in departemen_list" :key="data.departemen_id" :value="data.departemen_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind"> {{ data.nama_ind }} </option>
                                </select>
                                <Field type="hidden" id="departemen_id" name="departemen_id" v-model="selectedDepartemen" readonly/>
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <input type="text" class="form-control form-control-solid-bg bg-light-dark departemen" readonly placeholder="Select Department" v-model="departemen_nama_eng">
                            </div>
                        </div>

                        <div class="row mb-5">
                            <label class="form-label">
                                Program Studi
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <select class="form-control form-control-solid-bg prodi" data-control="select2" data-placeholder="-- Pilih Program Studi --">
                                    <option></option>
                                    <option v-for="data in prodi_list" :key="data.prodi_id" :value="data.prodi_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind"> {{ data.nama_ind }} </option>
                                </select>
                                <Field type="hidden" id="prodi_id" name="prodi_id" v-model="selectedProdi" readonly/>
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <input type="text" class="form-control form-control-solid-bg bg-light-dark prodi" readonly placeholder="Select Study Program" v-model="prodi_nama_eng">
                            </div>
                        </div>

                        <div class="row mb-5">
                            <label class="form-label">
                                Jenjang
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <input type="text" class="form-control form-control-solid-bg bg-light-dark" readonly placeholder="-- Pilih Level --" v-model="jenjang_nama_ind">
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <input type="text" class="form-control form-control-solid-bg bg-light-dark" readonly placeholder="Select Level" v-model="jenjang_nama_eng">
                            </div>
                        </div>

                        <div class="row mb-5">
                            <label class="form-label">
                                <span style="color: red">*</span>
                                Kurikulum
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <select class="form-control form-control-solid-bg kurikulum" data-control="select2" data-placeholder="-- Pilih Kurikulum --">
                                    <option></option>
                                    <option v-for="data in kurikulum_list" :key="data.kurikulum_id" :value="data.kurikulum_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind" :data-kurikulum_kd="data.kurikulum_kd" :data-is_aktif="data.is_aktif === 1 ? 'Aktif' : 'Tidak Aktif'"> {{ data.nama_ind }} </option>
                                </select>

                                <Field type="hidden" id="kurikulum_id" name="kurikulum_id" v-model="selectedKurikulum" readonly/>
                                <Field type="hidden" id="kurikulum_kd" name="kurikulum_kd" v-model="kurikulumKD" readonly/>
                                <div id="kurikulum_id-error" class="invalid-feedback">Kurikulum harus diisi</div>
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <div class="form-control form-control-solid-bg bg-light-dark" v-if="getCurriculumEng" v-html="getCurriculumEng"></div>
                                <div class="form-control form-control-solid-bg bg-light-dark text-gray-500" v-else>Select Curriculum</div>
                                <input type="hidden" class="form-control form-control-solid-bg bg-light-dark kurikulum" readonly :value="kurikulumKD" placeholder="Select Curriculum">
                            </div>
                        </div>

                        <div class="row mb-5">
                            <div class="col">
                                <button type="button" :class="kurikulumKD ? 'btn btn-success' : 'btn btn-secondary'" :disabled="!kurikulumKD" @click="onShowDataSelect">Tampilkan</button>
                            </div>

                            <div v-if="showDataSelect && kurikulumKD" class="slide-down">
                                <hr>
                                <div class="col-8">
                                    <div class="row mb-1" v-for="(data, index) in listDataToShow" :key="index">
                                        <label class="col-form-label col-sm-3">{{ data.label }}</label>
                                        <div class="col-sm-9">
                                            <input type="text" :id="index" class="form-control form-control-solid-bg form-control-sm" :value="data.value" readonly :placeholder="`Pilih ${data.label}`">
                                        </div>
                                    </div>
                                </div>

                                <div class="tps" v-if="tujuan_program_studi">
                                    <hr>
                                    <h3>Tujuan Program Studi</h3>
                                    <p v-html="tujuan_program_studi" style="text-align: justify; font-weight: 500;"></p>
                                </div>

                                <div class="tpp">
                                    <hr>
                                    <div class="alert alert-primary alert-dismissible fade show" role="alert">
                                        <div class="d-flex align-items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                                            </svg>
                                            <h3 class="alert-heading mt-2">INFORMASI!</h3>
                                            <i class="fas fa-close text-primary fs-1 btn-close" data-bs-dismiss="alert"></i>
                                        </div>
                                        <hr>
                                        <p class="fs-5 mb-0">
                                            Perlu diperhatikan ketika menambah <b>Data TPP</b>, data yang dimasukkan <b>tidak langsung masuk</b> ke dalam penyimpanan(basis data). Jadi ketika memuat ulang halaman / sesi berakhir, <b>data akan terhapus</b>.
                                        </p>
                                    </div>
                                    
                                    <div class="d-flex justify-content-between align-items-center">
                                        <h3>Data TPP</h3>
                                        <div class="text-end">
                                            <div class="btn btn-light-primary btn-sm border border-primary border-1" @click="btnShowFormTPP">
                                                <span class="fa fa-plus"></span>
                                                Tambah TPP
                                            </div>
                                        </div>
                                    </div>

                                    <div class="table-responsive mt-3">
                                        <table id="table" class="table table-row-bordered align-middle gy-3 display" style="border:1px solid #c5c6cc !important;">
                                            <thead>
                                                <tr style="border:1px solid #c5c6cc !important;">
                                                    <th class="text-center" style="width: 5% !important; font-weight:600;">No</th>
                                                    <th class="text-center" style="width: 20% !important; font-weight:600;">Nama TPP</th>
                                                    <th class="text-center" style="font-weight:600;">Deskripsi</th>
                                                </tr>
                                            </thead>
                                            <tbody style="border:1px solid #c5c6cc !important;">
                                                <tr v-for="(data, index) in listTempTPP" :key="index">
                                                    <td class="text-center">{{ index + 1 }}</td>
                                                    <td>{{ data.nama_ind }}</td>
                                                    <td class="px-3" style="text-align: justify;">{{ data.deskripsi_ind }}</td>
                                                </tr>
                                                <tr v-if="listTempTPP.length === 0">
                                                    <td class="text-center fw-bold" colspan="4">Belum ada data</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <transition name="slide" @enter="enter" @leave="leave">
                                        <div class="border border-primary border-2" v-if="showFormTPP">
                                            <div class="p-5 bg-light-primary d-flex justify-content-between align-items-center">
                                                <h2 class="flex-grow-1 text-primary text-center mt-1">Tambah Tujuan Program Pendidikan</h2>
                                                <h2 class="text-primary text-end" style="cursor: pointer;" @click="btnHideFormTPP"><i class="fas fa-close text-primary fs-1"></i></h2>
                                            </div>
                                            <hr class="mt-n1 mb-0 opacity-100 border border-primary border-1">
                                            
                                            <div class="p-5">
                                                <Form ref="formTPP" @submit="onSubmitTempTPP">
                                                    <FormInput
                                                        labelInd="Nama TPP"
                                                        labelEng="Name of TPP"
                                                        columnName="nama"
                                                    />
                                                    <FormInput
                                                        inputType="textarea"
                                                        rows="5"
                                                        labelInd="Deskripsi"
                                                        labelEng="Description"
                                                        columnName="deskripsi"
                                                        :isRequired="false"
                                                    />
    
                                                    <hr>
    
                                                    <button type="submit" class="btn btn-primary btn-submit w-100">
                                                        <span class="indicator-label"> Simpan TPP </span>
                                                        <span class="indicator-progress">Mohon Tunggu... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        </span>
                                                    </button>
                                                </Form>
                                            </div>
                                        </div>
                                    </transition>
                                </div>
                            </div>
                        </div>

						<div class="mt-10">
							<button type="submit" class="btn btn-primary">Simpan</button>
							&nbsp;
							<button @click="redirectToDataList" class="btn btn-secondary">Kembali</button>
						</div>
					</Form>
				</div>
			</div>
		</div>
	</div>
</template>


<script setup>
import Swal from 'sweetalert2';

import { onMounted, ref, computed, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import { Form, configure } from 'vee-validate'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'
import { sendRequest } from '@/api/common'

import { Field } from 'vee-validate'
import FormInput from '@/components/FormInput.vue'

const formRef = ref(null)
const formTPP = ref(null)
const loading = ref(false)
const router = useRouter()

const redirectToDataList = () => router.push({ name: 'TPP' })

const onSubmit = async values => {
    const kurikulum_id_fk = parseInt(values.kurikulum_id)
    const tpps = [...listTempTPP.value];

    if (!kurikulum_id_fk) {
        Swal.fire({ title: 'Harap memilih Kurikulum terlebih dahulu!', icon: 'error', confirmButtonText: 'OK' });
        return;
    } else if (tpps.length == 0) {
        Swal.fire({ title: 'Harap mengisi Data TPP terlebih dahulu!', icon: 'error', confirmButtonText: 'OK' });
        return;
    } else {
        const param = {
            url: '/tpp-kurikulum',
            method: 'POST',
            body: {
                kurikulum_id_fk,
                tpps
            },
            loading: loading,
            context: 'TPP'
        }
    
        return await sendRequest(param)
    }
}

// custom message error
import { localize } from '@vee-validate/i18n'
const validationConfig = {
	id: {
		names: {
            kurikulum_id: 'Kurikulum',
            nama_ind: 'Nama',
            nama_eng: 'Name',
		}
	}
}
configure({ generateMessage: localize(validationConfig) })

const ri_list = ref([])
const fakultas_list = ref([])
const departemen_list = ref([])
const prodi_list = ref([])
const kurikulum_list = ref([])

const ri_nama_eng = ref('')
const fakultas_nama_eng = ref('')
const departemen_nama_eng = ref('')
const prodi_nama_eng = ref('')
const jenjang_nama_ind = ref('')
const jenjang_nama_eng = ref('')
const tujuan_program_studi = ref('')

const selectedRI = ref('')
const selectedFakultas = ref('')
const selectedDepartemen = ref('')
const selectedProdi = ref('')
const selectedKurikulum = ref('')

const kurikulumKD = ref('')
const renderCustomOption = () => {
    const customSelect = (ress) => {
        if (!ress.id) return ress.text;

        // eslint-disable-next-line no-undef
        return $(`<label style="display:flex; flex-direction:column; cursor:pointer;"><span style="font-size:10px;"><b>${ $(ress.element).data('kurikulum_kd') } (${ $(ress.element).data('is_aktif') })</b></span><span style="font-size:14px;">${ $(ress.element).data('nama_ind') }</span></label>`);
    };
    // eslint-disable-next-line no-undef
    $('select.kurikulum').select2({ templateResult: customSelect, templateSelection: customSelect });
}
const getCurriculumEng = computed(() => {
	if (!kurikulumKD.value) return '';

    const selectedData = kurikulum_list.value.find(data => data.kurikulum_kd === kurikulumKD.value);
    if (selectedData) {
        const { kurikulum_kd, is_aktif, nama_eng } = selectedData;
        return `<label style="display:flex; flex-direction:column; cursor:pointer;">
                <span style="font-size:10px;"><b>${kurikulum_kd} (${is_aktif === 1 ? 'Active' : 'Not Active'})</b></span>
                <span style="font-size:14px;">${nama_eng}</span>
                </label>`;
    }
    return '';
})

const isSetTimeOut = ref(false)
async function filteredSelect() {
    // eslint-disable-next-line no-undef
    $('body').on('change', 'select.ri', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/ri/${id}`);
        selectedRI.value = id
		ri_nama_eng.value = data.nama_eng
        
        loading.value = true
		
		selectedFakultas.value = null
		selectedDepartemen.value = null
		selectedProdi.value = null
		selectedKurikulum.value = null

        fakultas_list.value = data.fakultas
        fakultas_nama_eng.value = null
        departemen_list.value = null
        departemen_nama_eng.value = null
        prodi_list.value = null
        prodi_nama_eng.value = null
        jenjang_nama_ind.value = null
        jenjang_nama_eng.value = null
        tujuan_program_studi.value = null
        kurikulum_list.value = null
        kurikulumKD.value = null

        loading.value = false
        isSetTimeOut.value = false
    }).on('change', 'select.fakultas', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/fakultas/${id}`);
		selectedFakultas.value = id
        fakultas_nama_eng.value = data.nama_eng
        
        loading.value = true
		
        selectedDepartemen.value = null
		selectedProdi.value = null
		selectedKurikulum.value = null

        departemen_list.value = data.departemen
        departemen_nama_eng.value = null
        prodi_list.value = null
        prodi_nama_eng.value = null
        jenjang_nama_ind.value = null
        jenjang_nama_eng.value = null
        tujuan_program_studi.value = null
        kurikulum_list.value = null
        kurikulumKD.value = null

        loading.value = false
        isSetTimeOut.value = false
    }).on('change', 'select.departemen', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/departemen/${id}`);
		selectedDepartemen.value = id
        departemen_nama_eng.value = data.nama_eng
        
        loading.value = true
		
        selectedProdi.value = null
		selectedKurikulum.value = null

        prodi_list.value = data.prodi
        prodi_nama_eng.value = null
        jenjang_nama_ind.value = null
        jenjang_nama_eng.value = null
        tujuan_program_studi.value = null
        kurikulum_list.value = null
        kurikulumKD.value = null

        loading.value = false
        isSetTimeOut.value = false
    }).on('change', 'select.prodi', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/prodi/${id}`);
		selectedProdi.value = id
        prodi_nama_eng.value = data.nama_eng
        
        loading.value = true
		selectedKurikulum.value = null

        jenjang_nama_ind.value = data.jenjang_nama_ind
        jenjang_nama_eng.value = data.jenjang_nama_eng
        tujuan_program_studi.value = data.tujuan_ind
        kurikulum_list.value = data.kurikulum
        kurikulumKD.value = null

        loading.value = false
        isSetTimeOut.value = false
    }).on('change', 'select.kurikulum', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/kurikulum/${id}`);
		selectedKurikulum.value = id

        kurikulumKD.value = data.kurikulum_kd
        
        if (showDataSelect.value) {
            showDataSelect.value = false; showFormTPP.value = false; listTempTPP.value = [];
            if (isSetTimeOut.value) setTimeout(() => { showDataSelect.value = true; }, 150);
            
            isSetTimeOut.value = true
        }
    });

	renderCustomOption();
}

const showDataSelect = ref(false);
const listDataToShow = ref([]);
const onShowDataSelect = () => { showDataSelect.value = true; }
function generateDataToShow(namaIndValues) {
    return [
        { label: 'Rumpun Ilmu', value: namaIndValues ? namaIndValues[0]: '' },
        { label: 'Fakultas', value: namaIndValues ? namaIndValues[1]: '' },
        { label: 'Departemen', value: namaIndValues ? namaIndValues[2]: '' },
        { label: 'Program Studi', value: namaIndValues ? namaIndValues[3]: '' },
        { label: 'Jenjang', value: jenjang_nama_ind.value },
        { label: 'Kurikulum', value: namaIndValues ? namaIndValues[4]: '' },
    ];
}
listDataToShow.value = generateDataToShow()

function confirmChange(text) {
    return Swal.fire({ 
        title: "Apakah anda yakin?", 
        text: text,
        icon: "question",
        showCancelButton: true, 
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ya, tutup", 
        cancelButtonText: "Batal", 
    })
}
// === Start Tabel TPP ===
const showFormTPP = ref(false)
const btnShowFormTPP = (() => { showFormTPP.value = true; });
const btnHideFormTPP = (() => {
    confirmChange("Jika anda menutup form ini, data yang telah terisi akan dihapus!")
    .then((result) => { 
        if (result.isConfirmed) showFormTPP.value = false;
    });
});

// Transition
const enter = (el) => {
    el.style.height = '0';
    setTimeout(() => { el.style.height = '480px'; });
    // setTimeout(() => { el.style.height = el.scrollHeight + 'px'; });
};
const leave = (el) => {
    el.style.height = el.scrollHeight + 'px';
    setTimeout(() => { el.style.height = '0'; });
};

const listTempTPP = ref([]);
const onSubmitTempTPP = async values => {
    const nama_ind = values.nama_ind
    const nama_eng = values.nama_eng
    const deskripsi_ind = values.deskripsi_ind
    const deskripsi_eng = values.deskripsi_eng

    if (nama_ind || nama_eng) {
        const { data } = await request({ url:`/tpp-check?kurikulum_kd=${kurikulumKD.value}&nama_ind=${nama_ind}&nama_eng=${nama_eng}` });
        if (data.nama_ind && data.nama_eng) { 
            if (data.nama_ind.is_exist) { Swal.fire({ title: `Nama ind '${nama_ind}' sudah ada!`, icon: 'error', confirmButtonText: 'OK' }); return; }
            if (data.nama_eng.is_exist) { Swal.fire({ title: `Nama eng '${nama_eng}' sudah ada!`, icon: 'error', confirmButtonText: 'OK' }); return; }
            
            const isDuplicate = listTempTPP.value.some(data => data.nama_ind === nama_ind || data.nama_eng === nama_eng);
            if (isDuplicate) { Swal.fire({ title: 'Nama TPP sudah ada dalam list sementara!', icon: 'warning', confirmButtonText: 'OK' }); return; }
            
            Swal.fire({ title: 'Berhasil menambahkan data ke dalam list sementara', icon: 'success', confirmButtonText: 'OK' }).then((result) => {
                if (result.isConfirmed) { listTempTPP.value.push({ nama_ind, nama_eng, deskripsi_ind, deskripsi_eng }); showFormTPP.value = false; }
            });
        } else {
            Swal.fire({ title: `${data.nama_ind ? '' : 'Nama ind'} ${data.nama_eng ? '' : 'Nama eng'} tidak boleh kosong!`, icon: 'error', confirmButtonText: 'OK' }); 
        }
    } else {
        Swal.fire({ title: `Harap lengkapi data terlebih dahulu!`, icon: 'error', confirmButtonText: 'OK' });
    }
};
// === End Tabel TPP ===

onMounted(async () => {
    try {
        // eslint-disable-next-line no-undef
        $('body').on('change', 'select.ri, select.fakultas, select.departemen, select.prodi, select.kurikulum', () => {
            // eslint-disable-next-line no-undef
            const namaIndValues = $('select.ri, select.fakultas, select.departemen, select.prodi, select.kurikulum').map(function() {
                // eslint-disable-next-line no-undef
                return $(this).find('option:selected').data('nama_ind');
            }).get();
            listDataToShow.value = generateDataToShow(namaIndValues);
        })

        const response = await request(`/ri`)
		ri_list.value = response.data.data

        await nextTick(() => { initializeSelect2(); });
		filteredSelect();

		// eslint-disable-next-line no-undef
		$('button[type="submit"]').click(() => {
			const validateSelect = (selector) => {
				// eslint-disable-next-line no-undef
				if ($(`select.${selector}`).val() == '') {
					// eslint-disable-next-line no-undef
					$(`select.${selector}.select2-hidden-accessible`).addClass('is-invalid');
					// eslint-disable-next-line no-undef
					$('span').find(`.select2-selection.${selector}`).addClass('is-invalid');
				} else {
					// eslint-disable-next-line no-undef
					$(`select.${selector}.select2-hidden-accessible`).removeClass('is-invalid');
					// eslint-disable-next-line no-undef
					$('span').find(`.select2-selection.${selector}`).removeClass('is-invalid');
				}
			};

			validateSelect('kurikulum');
		});
    } catch (error) {
		console.error('Error fetching data:', error)
	} 
})

// eslint-disable-next-line no-undef
const initializeSelect2 = () => { $("select").select2(); };
</script>

<style scoped>
.slide-down { animation: slideDown 5s ease; }
@keyframes slideDown {
    0% { transform: translateY(-5%); opacity: 0; overflow: hidden; }
    5% { transform: translateY(0); opacity: 1; overflow: hidden; }
}

.slide-enter-active, .slide-leave-active { transition: height 0.7s ease; overflow: hidden; }
.slide-enter-from, .slide-leave-to { height: 0; overflow: hidden; }

.btn-close { background: none; width:auto; cursor: pointer; }
</style>