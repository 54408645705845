<template>
	<div class="menu-item">
		<router-link class="menu-link" :class="{ active: isActive() }" :to="{ name: link }">
			<span class="menu-icon">
				<span :class="icon" v-if="icon"></span>
			</span>
			<span class="menu-title"><slot /></span>
		</router-link>
	</div>
</template>

<script>
export default {
	props: {
		icon: {
			type: String,
			default: null
		},
		link: {
			type: [String, Object],
			default: null
		}
	},
	methods: {
		isActive() {
			return this.$route.name === this.link
		}
	}
}
</script>
