<template>
   <breadcrumb-content menu="Profil" subMenu="Rumpun Ilmu" />
   <div class="card mb-5 mb-xxl-8">
      <div class="pb-10">
         <div class="card-body pt-9 pb-0">
            <h1 class="anchor fw-bold mb-5">Detail Rumpun Ilmu</h1>
            <div class="p-5">
               <div class="row mb-5">
                  <label class="form-label"> Perguruan Tinggi </label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input class="form-control form-control-solid-bg" v-model="university.nama_ind" disabled="disabled" />
                  </div>
                  <div class="col-6">
                     <flag-image bahasa="Inggris" />
                     <input class="form-control form-control-solid-bg" v-model="university.nama_eng" disabled="disabled" />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Kode Rumpun Ilmu</label>
                  <div class="col-6">
                     <div style="display:flex; flex-wrap:nowrap;">
                        <div style="width:80px;">
                           <input class="form-control form-control-solid-bg" v-model="group.pt_kd_fk" disabled="disabled" />
                        </div>
                        <div style="width:100%;">
                           <input class="form-control form-control-solid-bg" v-model="group.ri_kd" disabled="disabled" />
                        </div>
                     </div>
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Nama Rumpun Ilmu</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input class="form-control form-control-solid-bg" v-model="group.nama_ind" disabled="disabled" />
                  </div>
                  <div class="col-6">
                     <flag-image bahasa="Inggris" />
                     <input class="form-control form-control-solid-bg" v-model="group.nama_eng" disabled="disabled" />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Deskripsi Rumpun Ilmu</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <textarea class="form-control form-control-solid-bg" v-model="group.deskripsi_ind" rows="3" disabled="disabled"></textarea>
                  </div>
                  <div class="col-6">
                     <flag-image bahasa="Inggris" />
                     <textarea class="form-control form-control-solid-bg" v-model="group.deskripsi_eng" rows="3" disabled="disabled"></textarea>
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Visi Rumpun Ilmu</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <textarea class="form-control form-control-solid-bg" v-model="group.visi_ind" rows="3" disabled="disabled"></textarea>
                  </div>
                  <div class="col-6">
                     <flag-image bahasa="Inggris" />
                     <textarea class="form-control form-control-solid-bg" v-model="group.visi_eng" rows="3" disabled="disabled"></textarea>
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Misi Rumpun Ilmu</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <textarea class="form-control form-control-solid-bg" v-model="group.misi_ind" rows="3" disabled="disabled"></textarea>
                  </div>
                  <div class="col-6">
                     <flag-image bahasa="Inggris" />
                     <textarea class="form-control form-control-solid-bg" v-model="group.misi_eng" rows="3" disabled="disabled"></textarea>
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Dibuat pada</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input class="form-control form-control-solid-bg" :value="formatReadableDate(group.created_at)" disabled="disabled" />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Diubah pada</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input class="form-control form-control-solid-bg" :value="formatReadableDate(group.updated_at)" disabled="disabled" />
                  </div>
               </div>
               <div class="mt-10">
                  <div class="col-lg-6">
                     <button @click="redirectToRumpunIlmuList" class="btn btn-secondary">Kembali</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup>
	import { onMounted, ref } from 'vue';
	import { useRouter } from 'vue-router';
	import { formatReadableDate } from '@/utils/date';
	import request from '@/utils/request';

	const router = useRouter();
	const loading = ref(true);
   const id = router.currentRoute.value.params.id;
	const group = ref({});
	const university = ref({});
	const redirectToRumpunIlmuList = () => router.push({ name: 'RumpunIlmu' });

	onMounted(async () => {
		try {
         group.value = (await request(`/ri/${ id }`)).data;
         university.value = group.value.universitas;
		} catch (error) {
			console.error('Error fetching data: ', error);
		} finally {
			loading.value = false;
		}
	});
</script>