<template>
	<div>
		<breadcrumb-content menu="Pembuatan Kurikulum" subMenu="Evaluasi Kurikulum" />
		<div class="card mb-5 mb-xxl-8 placeholder-glow">
			<div class="pb-10">
				<div class="card-body pt-9 pb-0">
					<h1 class="anchor fw-bold mb-5">Ubah Evaluasi Kurikulum</h1>
					<div class="p-5">
						<Form
							id="form-kurikulum-update"
							class="kt-form"
							method="post"
							@submit="onSubmit"
							v-slot="{ errors }"
							ref="formRef"
						>
							<LoadingOverlay :loading="loading" />

							<div class="row mb-5">
								<label class="form-label">
									Rumpun Ilmu
								</label>
								<div class="col-6">
									<flag-image bahasa="Indonesia"/>

									<select class="form-control form-control-solid-bg ri" data-control="select2" data-placeholder="-- Pilih Rumpun Ilmu --" v-model="dataFakultas.ri_id_fk" disabled>
										<option></option>
										<option v-for="data in ri_list" :key="data.ri_id" :value="data.ri_id" :data-nama_eng="data.nama_eng"> {{ data.nama_ind }} </option>
									</select>
									<Field type="hidden" id="ri_id" name="ri_id" v-model="selectedRI" readonly/>
								</div>
								<div class="col-6">
									<flag-image bahasa="Inggris"/>

									<input type="text" class="form-control form-control-solid-bg bg-light-dark rumpun_ilmu" readonly placeholder="Select Group of knowledge" v-model="ri_nama_eng">
								</div>
							</div>
							
							<div class="row mb-5">
								<label class="form-label">
									Fakultas
								</label>
								<div class="col-6">
									<flag-image bahasa="Indonesia"/>

									<select class="form-control form-control-solid-bg fakultas" data-control="select2" data-placeholder="-- Pilih Fakultas --" v-model="dataDepartemen.fakultas_id_fk" disabled>
										<option></option>
										<option v-for="data in fakultas_list" :key="data.fakultas_id" :value="data.fakultas_id" :data-nama_eng="data.nama_eng"> {{ data.nama_ind }} </option>
									</select>
									<Field type="hidden" id="fakultas_id" name="fakultas_id" v-model="selectedFakultas" readonly/>
								</div>
								<div class="col-6">
									<flag-image bahasa="Inggris"/>

									<input type="text" class="form-control form-control-solid-bg bg-light-dark fakultas" readonly placeholder="Select Faculty" v-model="fakultas_nama_eng">
								</div>
							</div>

							<div class="row mb-5">
								<label class="form-label">
									Departemen
								</label>
								<div class="col-6">
									<flag-image bahasa="Indonesia"/>

									<select class="form-control form-control-solid-bg departemen" data-control="select2" data-placeholder="-- Pilih Departemen --" v-model="dataProdi.departemen_id_fk" disabled>
										<option></option>
										<option v-for="data in departemen_list" :key="data.departemen_id" :value="data.departemen_id" :data-nama_eng="data.nama_eng"> {{ data.nama_ind }} </option>
									</select>
									<Field type="hidden" id="departemen_id" name="departemen_id" v-model="selectedDepartemen" readonly/>
								</div>
								<div class="col-6">
									<flag-image bahasa="Inggris"/>

									<input type="text" class="form-control form-control-solid-bg bg-light-dark departemen" readonly placeholder="Select Department" v-model="departemen_nama_eng">
								</div>
							</div>

							<div class="row mb-5">
								<label class="form-label">
									Program Studi
								</label>
								<div class="col-6">
									<flag-image bahasa="Indonesia"/>

									<select class="form-control form-control-solid-bg prodi" data-control="select2" data-placeholder="-- Pilih Program Studi --" v-model="dataEK.prodi_id_fk" disabled>
										<option></option>
										<option v-for="data in prodi_list" :key="data.prodi_id" :value="data.prodi_id" :data-nama_eng="data.nama_eng"> {{ data.nama_ind }} </option>
									</select>
									<Field type="hidden" id="prodi_id" name="prodi_id" v-model="selectedProdi" readonly/>
								</div>
								<div class="col-6">
									<flag-image bahasa="Inggris"/>

									<input type="text" class="form-control form-control-solid-bg bg-light-dark prodi" readonly placeholder="Select Study Program" v-model="prodi_nama_eng">
								</div>
							</div>

							<div class="row mb-5">
								<label class="form-label">
									Jenjang
								</label>
								<div class="col-6">
									<flag-image bahasa="Indonesia"/>

									<input type="text" class="form-control form-control-solid-bg bg-light-dark" readonly placeholder="-- Pilih Level --" v-model="jenjang_nama_ind">
								</div>
								<div class="col-6">
									<flag-image bahasa="Inggris"/>

									<input type="text" class="form-control form-control-solid-bg bg-light-dark" readonly placeholder="Select Level" v-model="jenjang_nama_eng">
								</div>
							</div>

							<div class="row mb-5">
								<label class="form-label">
									<span style="color: red">*</span>
									Kurikulum
								</label>
								<div class="col-6">
									<flag-image bahasa="Indonesia"/>

									<select class="form-control form-control-solid-bg kurikulum" data-control="select2" data-placeholder="-- Pilih Kurikulum --" v-model="dataEK.kurikulum_id" disabled>
										<option></option>
										<option v-for="data in kurikulum_list" :key="data.kurikulum_id" :value="data.kurikulum_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind" :data-kurikulum_kd="data.kurikulum_kd" :data-is_aktif="data.is_aktif === 1 ? 'Aktif' : 'Tidak Aktif'"> {{ data.nama_ind }} </option>
									</select>

									<Field type="hidden" id="kurikulum_id" name="kurikulum_id" v-model="selectedKurikulum" readonly/>
									<Field type="hidden" id="kurikulum_kd" name="kurikulum_kd" v-model="kurikulumKD" readonly/>
									<div id="kurikulum_id-error" class="invalid-feedback">{{ errors.kurikulum_id }}</div>
								</div>
								<div class="col-6">
									<flag-image bahasa="Inggris"/>

									<div class="form-control form-control-solid-bg bg-light-dark" v-if="getCurriculumEng" v-html="getCurriculumEng"></div>
									<div class="form-control form-control-solid-bg bg-light-dark text-gray-500" v-else>Select Curriculum</div>
									<input type="hidden" class="form-control form-control-solid-bg bg-light-dark kurikulum" readonly :value="kurikulumKD" placeholder="Select Curriculum">
								</div>
							</div>

							<hr>

							<FormInput
								labelInd="Evaluasi Kurikulum"
								labelEng="Curriculum Evaluation"
								columnName="evaluasi_kurikulum"
								:valueInd="dataEK.evaluasi_kurikulum_ind"
								:valueEng="dataEK.evaluasi_kurikulum_eng"
								:errorInd="errors.evaluasi_kurikulum_ind"
								:errorEng="errors.evaluasi_kurikulum_eng"
								:ckeditor="true"
								customErrorInd="Evaluasi Kurikulum"
								customErrorEng="Curriculum Evaluation"
							/>
							<FormInput
								labelInd="Evaluasi Sumatif"
								labelEng="Summative Evaluation"
								columnName="evaluasi_sumatif"
								:valueInd="dataEK.evaluasi_sumatif_ind"
								:valueEng="dataEK.evaluasi_sumatif_eng"
								:errorInd="errors.evaluasi_sumatif_ind"
								:errorEng="errors.evaluasi_sumatif_eng"
								:ckeditor="true"
								customErrorInd="Evaluasi Sumatif"
								customErrorEng="Summative Evaluation"
							/>
							<FormInput
								labelInd="Evaluasi Formatif"
								labelEng="Formative Evaluation"
								columnName="evaluasi_formatif"
								:valueInd="dataEK.evaluasi_formatif_ind"
								:valueEng="dataEK.evaluasi_formatif_eng"
								:errorInd="errors.evaluasi_formatif_ind"
								:errorEng="errors.evaluasi_formatif_eng"
								:ckeditor="true"
								customErrorInd="Evaluasi Formatif"
								customErrorEng="Formative Evaluation"
							/>
							<FormInput
								labelInd="Studi Penelusuran"
								labelEng="Search Study"
								columnName="tracer_study"
								:valueInd="dataEK.tracer_study_ind"
								:valueEng="dataEK.tracer_study_eng"
								:errorInd="errors.tracer_study_ind"
								:errorEng="errors.tracer_study_eng"
								:ckeditor="true"
								customErrorInd="Studi Penelusuran"
								customErrorEng="Search Study"
							/>

							<div class="mt-10">
								<button type="submit" class="btn btn-primary">Simpan</button>
								&nbsp;
								<button @click="redirectToDataList" class="btn btn-secondary">Kembali</button>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script setup>
import Swal from 'sweetalert2';

import { onMounted, ref, computed, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import { Form } from 'vee-validate'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'
import { sendRequest } from '@/api/common'

import { Field } from 'vee-validate'
import FormInput from '@/components/FormInput.vue'

const formRef = ref(null)
const loading = ref(true)
const router = useRouter()
const kurikulum_id = router.currentRoute.value.params.id

const redirectToDataList = () => router.push({ name: 'EvaluasiKurikulum' })

function validateFields(values, fields) {
	let isValid = true;

	fields.forEach(field => {
		const value = values[field];
		// eslint-disable-next-line no-undef
		const fieldElement = $(`input[name=${field}]`);

		if (!value) { fieldElement.addClass('is-invalid'); isValid = false; } 
		else { fieldElement.removeClass('is-invalid'); }
	});

	return isValid;
}

const onSubmit = async values => {
	const dataToSend = {
		...dataEK.value,
		evaluasi_kurikulum_ind: values.evaluasi_kurikulum_ind, evaluasi_kurikulum_eng: values.evaluasi_kurikulum_eng,
		evaluasi_sumatif_ind: values.evaluasi_sumatif_ind, evaluasi_sumatif_eng: values.evaluasi_sumatif_eng,
		evaluasi_formatif_ind: values.evaluasi_formatif_ind, evaluasi_formatif_eng: values.evaluasi_formatif_eng,
		tracer_study_ind: values.tracer_study_ind, tracer_study_eng: values.tracer_study_eng,
	}

	const fieldsToValidate = [
		'evaluasi_kurikulum_ind', 'evaluasi_kurikulum_eng', 
		'evaluasi_sumatif_ind', 'evaluasi_sumatif_eng', 
		'evaluasi_formatif_ind', 'evaluasi_formatif_eng', 
		'tracer_study_ind', 'tracer_study_eng', 
	];
	if (!validateFields(values, fieldsToValidate)) { return Swal.fire({ title: `Harap memasukkan semua data yang ada terlebih dahulu!`, icon: 'error', confirmButtonText: 'OK' }); }

	try {
		const param = {
			url: `/kurikulum/${kurikulum_id}`,
			method: 'PUT',
			body: dataToSend,
			loading: loading,
			context: 'EvaluasiKurikulum'
		}
	
		return await sendRequest(param);
	} catch (error) {
		Swal.fire({ title: 'Terjadi kesalahan, silahkan coba lagi.', icon: 'error', confirmButtonText: 'OK' });
	}
};

const ri_list = ref([])
const fakultas_list = ref([])
const departemen_list = ref([])
const prodi_list = ref([])
const kurikulum_list = ref([])

const ri_nama_eng = ref('')
const fakultas_nama_eng = ref('')
const departemen_nama_eng = ref('')
const prodi_nama_eng = ref('')
const jenjang_nama_ind = ref('')
const jenjang_nama_eng = ref('')

const selectedRI = ref('')
const selectedFakultas = ref('')
const selectedDepartemen = ref('')
const selectedProdi = ref('')
const selectedKurikulum = ref('')

const dataEK = ref({})
const dataProdi = ref({})
const dataDepartemen = ref({})
const dataFakultas = ref({})
const dataRI = ref({})

const kurikulumKD = ref('')
const renderCustomOption = () => {
    const customSelect = (ress) => {
        if (!ress.id) return ress.text;

        // eslint-disable-next-line no-undef
        return $(`<label style="display:flex; flex-direction:column; cursor:pointer;"><span style="font-size:10px;"><b>${ $(ress.element).data('kurikulum_kd') } (${ $(ress.element).data('is_aktif') })</b></span><span style="font-size:14px;">${ $(ress.element).data('nama_ind') }</span></label>`);
    };
    // eslint-disable-next-line no-undef
    $('select.kurikulum').select2({ templateResult: customSelect, templateSelection: customSelect });
}
const getCurriculumEng = computed(() => {
	if (!kurikulumKD.value) return '';

    const selectedData = kurikulum_list.value.find(data => data.kurikulum_kd === kurikulumKD.value);
    if (selectedData) {
        const { kurikulum_kd, is_aktif, nama_eng } = selectedData;
        return `<label style="display:flex; flex-direction:column; cursor:pointer;">
                <span style="font-size:10px;"><b>${kurikulum_kd} (${is_aktif === 1 ? 'Active' : 'Not Active'})</b></span>
                <span style="font-size:14px;">${nama_eng}</span>
                </label>`;
    }
    return '';
})

onMounted(async () => {
	try {
		const { data } = await request(`/kurikulum/${kurikulum_id}`)
		dataEK.value = data
		kurikulumKD.value = data.kurikulum_kd

		const getDataProdi = await request(`/prodi/${dataEK.value.prodi_id_fk}`)
		dataProdi.value = getDataProdi.data
		
		const getDataDepartemen = await request(`/departemen/${dataProdi.value.departemen_id_fk}`)
		dataDepartemen.value = getDataDepartemen.data
		
		const getDataFakultas = await request(`/fakultas/${dataDepartemen.value.fakultas_id_fk}`)
		dataFakultas.value = getDataFakultas.data
		
		const getDataRI = await request(`/ri/${dataFakultas.value.ri_id_fk}`)
		dataRI.value = getDataRI.data

		// === Data ===
		const response = await request(`/ri`)
		ri_list.value = response.data.data
		ri_nama_eng.value = dataRI.value.nama_eng
		selectedRI.value = dataRI.value.ri_id

		fakultas_list.value = getDataRI.data.fakultas
		fakultas_nama_eng.value = dataFakultas.value.nama_eng
		selectedFakultas.value = dataFakultas.value.fakultas_id

		departemen_list.value = getDataFakultas.data.departemen
		departemen_nama_eng.value = dataDepartemen.value.nama_eng
		selectedDepartemen.value = dataDepartemen.value.departemen_id

		prodi_list.value = getDataDepartemen.data.prodi
		prodi_nama_eng.value = dataProdi.value.nama_eng
		selectedProdi.value = dataProdi.value.prodi_id

		jenjang_nama_ind.value = dataProdi.value.jenjang_nama_ind
		jenjang_nama_eng.value = dataProdi.value.jenjang_nama_eng

		kurikulum_list.value = getDataProdi.data.kurikulum
		selectedKurikulum.value = dataEK.value.kurikulum_id
		// === End Edit Data ===

		await nextTick(() => { initializeSelect2(); });
		renderCustomOption();

		// eslint-disable-next-line no-undef
		$('button[type="submit"]').click(() => {
			const validateSelect = (selector) => {
				// eslint-disable-next-line no-undef
				if ($(`select.${selector}`).val() == '') {
					// eslint-disable-next-line no-undef
					$(`select.${selector}.select2-hidden-accessible`).addClass('is-invalid');
					// eslint-disable-next-line no-undef
					$('span').find(`.select2-selection.${selector}`).addClass('is-invalid');
				} else {
					// eslint-disable-next-line no-undef
					$(`select.${selector}.select2-hidden-accessible`).removeClass('is-invalid');
					// eslint-disable-next-line no-undef
					$('span').find(`.select2-selection.${selector}`).removeClass('is-invalid');
				}
			};

			validateSelect('kurikulum');
		});
	} catch (error) {
		console.error('Error fetching data:', error)
	} finally {
		loading.value = false
	}
})

// eslint-disable-next-line no-undef
const initializeSelect2 = () => { $("select").select2(); };
</script>