<template>
	<breadcrumb-content menu="Pembuatan Kurikulum" subMenu="Bahan Kajian" />

	<div class="card mb-5 mb-xxl-8">
		<div class="pb-10">
			<div class="card-body pt-9 pb-0">
				<h1 class="anchor fw-bold mb-5">Ubah Bahan Kajian</h1>
				<div class="p-5">
					<Form
						id="form-bahan-kajian-update"
						class="kt-form"
						method="post"
						@submit="onSubmit"
                        ref="formRef"
					>
						<LoadingOverlay :loading="loading" />

                        <div class="row mb-5">
                            <label class="form-label">
                                Rumpun Ilmu
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <select class="form-control form-control-solid-bg ri" data-control="select2" data-placeholder="-- Pilih Rumpun Ilmu --" v-model="dataFakultas.ri_id_fk" disabled>
                                    <option></option>
                                    <option v-for="data in ri_list" :key="data.ri_id" :value="data.ri_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind"> {{ data.nama_ind }} </option>
                                </select>
                                <Field type="hidden" id="ri_id" name="ri_id" v-model="selectedRI" readonly/>
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <input type="text" class="form-control form-control-solid-bg bg-light-dark rumpun_ilmu" readonly placeholder="Select Group of knowledge" v-model="ri_nama_eng">
                            </div>
                        </div>
                        
                        <div class="row mb-5">
                            <label class="form-label">
                                Fakultas
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <select class="form-control form-control-solid-bg fakultas" data-control="select2" data-placeholder="-- Pilih Fakultas --" v-model="dataDepartemen.fakultas_id_fk" disabled>
                                    <option></option>
                                    <option v-for="data in fakultas_list" :key="data.fakultas_id" :value="data.fakultas_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind"> {{ data.nama_ind }} </option>
                                </select>
                                <Field type="hidden" id="fakultas_id" name="fakultas_id" v-model="selectedFakultas" readonly/>
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <input type="text" class="form-control form-control-solid-bg bg-light-dark fakultas" readonly placeholder="Select Faculty" v-model="fakultas_nama_eng">
                            </div>
                        </div>

                        <div class="row mb-5">
                            <label class="form-label">
                                Departemen
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <select class="form-control form-control-solid-bg departemen" data-control="select2" data-placeholder="-- Pilih Departemen --" v-model="dataProdi.departemen_id_fk" disabled>
                                    <option></option>
                                    <option v-for="data in departemen_list" :key="data.departemen_id" :value="data.departemen_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind"> {{ data.nama_ind }} </option>
                                </select>
                                <Field type="hidden" id="departemen_id" name="departemen_id" v-model="selectedDepartemen" readonly/>
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <input type="text" class="form-control form-control-solid-bg bg-light-dark departemen" readonly placeholder="Select Department" v-model="departemen_nama_eng">
                            </div>
                        </div>

                        <div class="row mb-5">
                            <label class="form-label">
                                Program Studi
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <select class="form-control form-control-solid-bg prodi" data-control="select2" data-placeholder="-- Pilih Program Studi --" v-model="dataKurikulum.prodi_id_fk" disabled>
                                    <option></option>
                                    <option v-for="data in prodi_list" :key="data.prodi_id" :value="data.prodi_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind"> {{ data.nama_ind }} </option>
                                </select>
                                <Field type="hidden" id="prodi_id" name="prodi_id" v-model="selectedProdi" readonly/>
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <input type="text" class="form-control form-control-solid-bg bg-light-dark prodi" readonly placeholder="Select Study Program" v-model="prodi_nama_eng">
                            </div>
                        </div>

                        <div class="row mb-5">
                            <label class="form-label">
                                Jenjang
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <input type="text" class="form-control form-control-solid-bg bg-light-dark" readonly placeholder="-- Pilih Level --" v-model="jenjang_nama_ind">
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <input type="text" class="form-control form-control-solid-bg bg-light-dark" readonly placeholder="Select Level" v-model="jenjang_nama_eng">
                            </div>
                        </div>

                        <div class="row mb-5">
                            <label class="form-label">
                                Kurikulum
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <select class="form-control form-control-solid-bg kurikulum" data-control="select2" data-placeholder="-- Pilih Kurikulum --" v-model="dataKurikulum.kurikulum_id" disabled>
                                    <option></option>
                                    <option v-for="data in kurikulum_list" :key="data.kurikulum_id" :value="data.kurikulum_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind" :data-kurikulum_kd="data.kurikulum_kd" :data-is_aktif="data.is_aktif === 1 ? 'Aktif' : 'Tidak Aktif'"> {{ data.nama_ind }} </option>
                                </select>

                                <Field type="hidden" id="kurikulum_id" name="kurikulum_id" v-model="selectedKurikulum" readonly/>
                                <Field type="hidden" id="kurikulum_kd" name="kurikulum_kd" v-model="kurikulumKD" readonly/>
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <div class="form-control form-control-solid-bg bg-light-dark" v-if="getCurriculumEng" v-html="getCurriculumEng"></div>
                                <div class="form-control form-control-solid-bg bg-light-dark text-gray-500" v-else>Select Curriculum</div>
                                <input type="hidden" class="form-control form-control-solid-bg bg-light-dark kurikulum" readonly :value="kurikulumKD" placeholder="Select Curriculum">
                            </div>
                        </div>

                        <div class="row mb-5">
                            <label class="form-label">
                                <span style="color: red">*</span>
                                Capaian Pembelajaran Lulusan
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <select class="form-control form-control-solid-bg cpl" data-control="select2" data-placeholder="-- Pilih Capaian Pembelajaran Lulusan --" v-model="dataCPL.cpl_id" disabled>
                                    <option></option>
                                    <option v-for="data in cpl_list" :key="data.cpl_id" :value="data.cpl_id" :data-nama_eng="data.name_eng" :data-nama_ind="data.name_ind"> {{ data.cpl_kd + ' - ' + data.name_ind  }} </option>
                                </select>
                                <Field type="hidden" id="cpl_id_fk" name="cpl_id_fk" v-model="selectedCPL" readonly/>
                                <Field type="hidden" id="cpl_id_kd" name="cpl_id_kd" v-model="selectedCPLKD" readonly/>
                                <div id="cpl_id_fk-error" class="invalid-feedback">CPL harus diisi</div>
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <input type="text" class="form-control form-control-solid-bg bg-light-dark cpl" readonly placeholder="Select Learning Outcomes" v-model="cpl_nama_eng">
                            </div>
                        </div>

                        <div class="row mb-5">
                            <label class="form-label">
                                Deskripsi Capaian Pembelajaran Lulusan
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>
                                <textarea class="form-control form-control-solid-bg bg-light-dark" rows="5" readonly placeholder="Deskripsi CPL..." v-model="cpl_deskripsi_ind"></textarea>
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>
                                <textarea class="form-control form-control-solid-bg bg-light-dark" rows="5" readonly placeholder="Description CPL..." v-model="cpl_deskripsi_eng"></textarea>
                            </div>
                        </div>

                        <hr class="my-7" />
                        <div class="bahan-kajian">
                            <div class="alert alert-primary alert-dismissible fade show" role="alert">
                                <div class="d-flex align-items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                                    </svg>
                                    <h3 class="alert-heading mt-2">INFORMASI!</h3>
                                    <i class="fas fa-close text-primary fs-1 btn-close" data-bs-dismiss="alert"></i>
                                </div>
                                <hr>
                                <p class="fs-5 mb-0">
                                    Pada halaman ini, data yang dimasukkan akan langsung <b>masuk ke dalam database</b>. Jadi tidak perlu khawatir ketika memuat ulang halaman / sesi berakhir, karena <b>data sudah pasti tersimpan</b>.
                                </p>
                            </div>                                    
                            <div class="d-flex justify-content-between align-items-center">
                                <h3>Data Bahan Kajian</h3>
                                <div class="text-end">
                                    <div class="btn btn-light-primary btn-sm border border-primary border-1" @click="btnShowFormBK">
                                        <span class="fa fa-plus"></span>
                                        Tambah Bahan Kajian
                                    </div>
                                </div>
                            </div>

                            <div class="table-responsive mt-3">
                                <table id="table" class="table table-row-bordered align-middle gy-3 display" style="border:1px solid #c5c6cc !important;">
                                    <thead>
                                        <tr style="border:1px solid #c5c6cc !important; font-weight:600;">
                                            <th class="text-center" style="width: 5% !important;">No</th>
                                            <th class="text-center" style="width: 20% !important;">Nama Bahan Kajian</th>
                                            <th class="text-center">Deskripsi Bahan Kajian</th>
                                            <th class="text-center px-1" style="width:10% !important;">Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody style="border:1px solid #c5c6cc !important;">
                                        <tr v-for="(data, index) in bk_list_by_cpl_kd" :key="index">
                                            <td class="text-center">{{ index + 1 }}</td>
                                            <td>{{ data.nama_ind }}</td>
                                            <td class="px-3" style="text-align: justify;">{{ data.deskripsi_ind }}</td>
                                            <td class="text-center px-1">
                                                <span :data-bahan_kajian_id="data.bahan_kajian_id" class="fa fa-edit text-info" title="Edit Bahan Kajian" style="cursor:pointer; font-size:17px;" @click="updateBahanKajian"></span>
                                                &nbsp;
                                                <span :data-bahan_kajian_id="data.bahan_kajian_id" class="fa fa-trash text-danger" title="Hapus Bahan Kajian" style="cursor:pointer; font-size:17px;" @click="deleteBahanKajian"></span>
                                            </td>
                                        </tr>
                                        <tr v-if="bk_list_by_cpl_kd.length === 0">
                                            <td class="text-center fw-bold" colspan="4">Belum ada data</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <transition name="slide" @enter="enter" @leave="leave">
                                <div class="border border-primary border-2" v-if="showFormBK">
                                    <div class="p-5 bg-light-primary d-flex justify-content-between align-items-center">
                                        <h2 class="flex-grow-1 text-primary text-center mt-1">{{ isEdit ? 'Ubah Bahan Kajian' : 'Tambah Bahan Kajian' }}</h2>
                                        <h2 class="text-primary text-end mt-1" style="cursor: pointer;" @click="btnHideFormBK"><i class="fas fa-close text-primary fs-1"></i></h2>
                                    </div>
                                    <hr class="mt-n1 mb-0 opacity-100 border border-primary border-1">
                                    
                                    <div class="p-5">
                                        <Form ref="formBK" @submit="onSubmitBahanKajian">
                                            <FormInput
                                                labelInd="Nama Bahan Kajian"
                                                labelEng="Name of Study Materials"
                                                columnName="nama"
                                            />
                                            <FormInput
                                                inputType="textarea"
                                                rows="5"
                                                labelInd="Deskripsi"
                                                labelEng="Description"
                                                columnName="deskripsi"
                                            />

                                            <hr>

                                            <button type="submit" class="btn btn-primary btn-submit w-100">
                                                <span class="indicator-label"> Simpan Bahan Kajian </span>
                                                <span class="indicator-progress">Mohon Tunggu... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            </button>
                                        </Form>
                                    </div>
                                </div>
                            </transition>
                        </div>

						<div class="mt-10">
							<button type="submit" class="btn btn-primary">Simpan</button>
							&nbsp;
							<button @click="redirectToDataList" class="btn btn-secondary">Kembali</button>
						</div>
					</Form>
				</div>
			</div>
		</div>
	</div>
</template>


<script setup>
import Swal from 'sweetalert2';

import { onMounted, ref, computed, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import { Form, configure } from 'vee-validate'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'
import { sendRequest } from '@/api/common'

import { Field } from 'vee-validate'
import FormInput from '@/components/FormInput.vue'

const formRef = ref(null)
const formBK = ref(null)
const loading = ref(false)
const router = useRouter()
const cpl_id_fk = router.currentRoute.value.params.id

const redirectToDataList = () => router.push({ name: 'BahanKajian' })

const onSubmit = async values => {
    const cpl_id_fk = parseInt(values.cpl_id_fk)
    const bahan_kajians = [...bk_list_by_cpl_kd.value];

    const param = {
        url: `/cpl-bahan-kajian`,
        method: 'PUT',
        body: {
            cpl_id_fk,
            bahan_kajians,
        },
        loading: loading,
        context: 'BahanKajian'
    }
    
    return await sendRequest(param);
}

// custom message error
import { localize } from '@vee-validate/i18n'
const validationConfig = {
	id: {
		names: {
            cpl_id_fk: 'Capaian Pembelajaran Lulusan',
            bahan_kajian_id_fk: 'Bahan Kajian',
		}
	}
}
configure({ generateMessage: localize(validationConfig) })

const ri_list = ref([])
const fakultas_list = ref([])
const departemen_list = ref([])
const prodi_list = ref([])
const kurikulum_list = ref([])
const cpl_list = ref([])

const ri_nama_eng = ref('')
const fakultas_nama_eng = ref('')
const departemen_nama_eng = ref('')
const prodi_nama_eng = ref('')
const jenjang_nama_ind = ref('')
const jenjang_nama_eng = ref('')
const cpl_nama_eng = ref('')
const cpl_deskripsi_ind = ref('')
const cpl_deskripsi_eng = ref('')

const selectedRI = ref('')
const selectedFakultas = ref('')
const selectedDepartemen = ref('')
const selectedProdi = ref('')
const selectedKurikulum = ref('')
const selectedCPL = ref('')
const selectedCPLKD = ref('')

const dataCPL = ref({})
const dataKurikulum = ref({})
const dataProdi = ref({})
const dataDepartemen = ref({})
const dataFakultas = ref({})
const dataRI = ref({})

const kurikulumKD = ref('')
const renderCustomOption = () => {
    const customSelect = (ress) => {
        if (!ress.id) return ress.text;

        // eslint-disable-next-line no-undef
        return $(`<label style="display:flex; flex-direction:column; cursor:pointer;"><span style="font-size:10px;"><b>${ $(ress.element).data('kurikulum_kd') } (${ $(ress.element).data('is_aktif') })</b></span><span style="font-size:14px;">${ $(ress.element).data('nama_ind') }</span></label>`);
    };
    // eslint-disable-next-line no-undef
    $('select.kurikulum').select2({ templateResult: customSelect, templateSelection: customSelect });
}
const getCurriculumEng = computed(() => {
	if (!kurikulumKD.value) return '';

    const selectedData = kurikulum_list.value.find(data => data.kurikulum_kd === kurikulumKD.value);
    if (selectedData) {
        const { kurikulum_kd, is_aktif, nama_eng } = selectedData;
        return `<label style="display:flex; flex-direction:column; cursor:pointer;">
                <span style="font-size:10px;"><b>${kurikulum_kd} (${is_aktif === 1 ? 'Active' : 'Not Active'})</b></span>
                <span style="font-size:14px;">${nama_eng}</span>
                </label>`;
    }
    return '';
})

async function filteredSelect() {
    // eslint-disable-next-line no-undef
    $('body').on('change', 'select.ri', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/ri/${id}`);
        selectedRI.value = id
		ri_nama_eng.value = data.nama_eng
        
        loading.value = true
		
		selectedFakultas.value = null
		selectedDepartemen.value = null
		selectedProdi.value = null
		selectedKurikulum.value = null
		selectedCPL.value = null
		selectedCPLKD.value = null

        fakultas_list.value = data.fakultas
        fakultas_nama_eng.value = null
        departemen_list.value = null
        departemen_nama_eng.value = null
        prodi_list.value = null
        prodi_nama_eng.value = null
        jenjang_nama_ind.value = null
        jenjang_nama_eng.value = null
        kurikulum_list.value = null
        kurikulumKD.value = null
        cpl_list.value = null
        cpl_nama_eng.value = null
        cpl_deskripsi_ind.value = null
        cpl_deskripsi_eng.value = null

        loading.value = false
    }).on('change', 'select.fakultas', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/fakultas/${id}`);
		selectedFakultas.value = id
        fakultas_nama_eng.value = data.nama_eng
        
        loading.value = true
		
        selectedDepartemen.value = null
		selectedProdi.value = null
		selectedKurikulum.value = null
		selectedCPL.value = null
		selectedCPLKD.value = null

        departemen_list.value = data.departemen
        departemen_nama_eng.value = null
        prodi_list.value = null
        prodi_nama_eng.value = null
        jenjang_nama_ind.value = null
        jenjang_nama_eng.value = null
        kurikulum_list.value = null
        kurikulumKD.value = null
        cpl_list.value = null
        cpl_nama_eng.value = null
        cpl_deskripsi_ind.value = null
        cpl_deskripsi_eng.value = null

        loading.value = false
    }).on('change', 'select.departemen', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/departemen/${id}`);
		selectedDepartemen.value = id
        departemen_nama_eng.value = data.nama_eng
        
        loading.value = true
		
        selectedProdi.value = null
		selectedKurikulum.value = null
		selectedCPL.value = null
		selectedCPLKD.value = null

        prodi_list.value = data.prodi
        prodi_nama_eng.value = null
        jenjang_nama_ind.value = null
        jenjang_nama_eng.value = null
        kurikulum_list.value = null
        kurikulumKD.value = null
        cpl_list.value = null
        cpl_nama_eng.value = null
        cpl_deskripsi_ind.value = null
        cpl_deskripsi_eng.value = null

        loading.value = false
    }).on('change', 'select.prodi', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/prodi/${id}`);
		selectedProdi.value = id
        prodi_nama_eng.value = data.nama_eng
        
        loading.value = true
		selectedKurikulum.value = null
		selectedCPL.value = null
		selectedCPLKD.value = null

        jenjang_nama_ind.value = data.jenjang_nama_ind
        jenjang_nama_eng.value = data.jenjang_nama_eng
        kurikulum_list.value = data.kurikulum
        kurikulumKD.value = null
        cpl_list.value = null
        cpl_nama_eng.value = null
        cpl_deskripsi_ind.value = null
        cpl_deskripsi_eng.value = null

        loading.value = false
    }).on('change', 'select.kurikulum', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/kurikulum/${id}`);
		selectedKurikulum.value = id
        kurikulumKD.value = data.kurikulum_kd
        
        loading.value = true

        selectedCPL.value = null
        selectedCPLKD.value = null

        cpl_list.value = data.cpl
        cpl_nama_eng.value = null
        cpl_deskripsi_ind.value = null
        cpl_deskripsi_eng.value = null

        loading.value = false
    }).on('change', 'select.cpl', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/cpl/${id}?join=cpl_taksonomi`);
        
        const taksonomiHkdString = [...new Set(data.cpl_taksonomi.map(item => item.taksonomi_h_kd_fk))].join(', ');

        selectedCPL.value = id
        selectedCPLKD.value = data.cpl_kd
        cpl_nama_eng.value = data.cpl_kd + ' - ' + data.name_eng
        cpl_deskripsi_ind.value = data.deskripsi_ind + '['+ taksonomiHkdString +']'
        cpl_deskripsi_eng.value = data.deskripsi_eng + '['+ taksonomiHkdString +']'
    });

	renderCustomOption();
}

// === Start Tabel Bahan Kajian ===
const showFormBK = ref(false)
const isEdit = ref(false);
const formIsShow = ref(false);
const btnShowFormBK = ( async() => { 
    if (formIsShow.value) { showFormBK.value = false; await nextTick(); }
    isEdit.value = false; showFormBK.value = true; formIsShow.value = true; 
});
const btnHideFormBK = (() => {
    Swal.fire({ 
        title: "Apakah anda yakin?", 
        text: "Jika anda menutup form ini, data yang telah terisi akan dihapus!",
        icon: "question",
        showCancelButton: true, 
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ya, tutup", 
        cancelButtonText: "Batal", 
    }).then((result) => { 
        if (result.isConfirmed) showFormBK.value = false;
    });
});

// Transition
const enter = (el) => {
    el.style.height = '0';
    setTimeout(() => { el.style.height = '480px'; });
    // setTimeout(() => { el.style.height = el.scrollHeight + 'px'; });
};
const leave = (el) => {
    el.style.height = el.scrollHeight + 'px';
    setTimeout(() => { el.style.height = '0'; });
};

const tempBKCreate = ref([]);
const bk_list_by_cpl_kd = ref([]);
const onSubmitBahanKajian = async values => {
    const nama_ind = values.nama_ind
    const nama_eng = values.nama_eng
    const deskripsi_ind = values.deskripsi_ind
    const deskripsi_eng = values.deskripsi_eng

    const bahan_kajian_id = formBK.value.values.bahan_kajian_id;

    if (nama_ind || nama_eng || deskripsi_ind || deskripsi_eng) {
        const { data } = await request({ url:`/cpl-bahan-kajian-check?kurikulum_kd=${kurikulumKD.value}&nama_ind=${nama_ind}&nama_eng=${nama_eng}` });
        
        if (data.nama_ind && data.nama_eng) {    
            const isDuplicate = bk_list_by_cpl_kd.value.some(dataR => (dataR.nama_ind === nama_ind || dataR.nama_eng === nama_eng) && dataR.bahan_kajian_id !== bahan_kajian_id);
            if (isDuplicate) { Swal.fire({ title: 'Nama Bahan Kajian sudah ada', icon: 'warning', confirmButtonText: 'OK' }); return; }
            
            if (isEdit.value) {
                loading.value = true
                const res = await request({
                    url: `/bahan-kajian/${bahan_kajian_id}`,
                    method: 'PUT',
                    data: { ...formBK.value.values }
                });

                if (res.status === 'SUCCESS') Swal.fire({ icon: 'success', title: 'Success', text: `Bahan Kajian berhasil diubah` }).then(async (result) => {
                    if (result.isConfirmed) { showFormBK.value = false; loading.value = false }
                });
                else Swal.fire({ title: `Bahan Kajian gagal diubah`, icon: 'error', confirmButtonText: 'OK' });
            } else {
                if (data.nama_ind.is_exist) { Swal.fire({ title: `Nama ind '${nama_ind}' sudah ada!`, icon: 'error', confirmButtonText: 'OK' }); return; }
                if (data.nama_eng.is_exist) { Swal.fire({ title: `Nama eng '${nama_eng}' sudah ada!`, icon: 'error', confirmButtonText: 'OK' }); return; }

                const newBahanKajian = { nama_ind, nama_eng, deskripsi_ind, deskripsi_eng, is_aktif: true };
                tempBKCreate.value.push(newBahanKajian);
                
                const cpl_id_fk = parseInt(selectedCPL.value)
                const bahan_kajians = [...tempBKCreate.value];
                
                loading.value = true
                const res = await request({
                    url: `/cpl-bahan-kajian`,
                    method: 'POST',
                    data: { cpl_id_fk, bahan_kajians }
                });

                if (res.status === 'SUCCESS') Swal.fire({ icon: 'success', title: 'Success', text: `Bahan Kajian berhasil ditambah` }).then(async (result) => {
                    if (result.isConfirmed) { tempBKCreate.value = []; showFormBK.value = false; loading.value = false }
                });
                else Swal.fire({ title: `Bahan Kajian gagal ditambah`, icon: 'error', confirmButtonText: 'OK' });
            }
        } else {
            Swal.fire({ title: `${data.nama_ind ? '' : 'Nama ind'} ${data.nama_eng ? '' : 'Nama eng'} tidak boleh kosong!`, icon: 'error', confirmButtonText: 'OK' }); 
        }
    } else {
        Swal.fire({ title: `Harap lengkapi data terlebih dahulu!`, icon: 'error', confirmButtonText: 'OK' }); 
    }

    await fetchDataBK();
};

const updateBahanKajian = async (event) => {
    isEdit.value = true;

    const bahan_kajian_id = parseInt(event.target.dataset.bahan_kajian_id);
    if (formIsShow.value) { showFormBK.value = false; await nextTick(); }

    showFormBK.value = true;
    formIsShow.value = true;

    loading.value = true;

    const { data } = await request(`/bahan-kajian/${bahan_kajian_id}`)
    formBK.value.setFieldValue('bahan_kajian_id', bahan_kajian_id)
    formBK.value.setFieldValue('nama_ind', data.nama_ind)
    formBK.value.setFieldValue('nama_eng', data.nama_eng)
    formBK.value.setFieldValue('deskripsi_ind', data.deskripsi_ind)
    formBK.value.setFieldValue('deskripsi_eng', data.deskripsi_eng)
    formBK.value.setFieldValue('is_aktif', data.is_aktif)

    loading.value = false;
};

const deleteBahanKajian = async (event) => {
    const bahan_kajian_id = parseInt(event.target.dataset.bahan_kajian_id);
    const response = await Swal.fire({
        title: 'Apakah anda yakin?',
        text: "Data yang telah dihapus tidak dapat dikembalikan!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Batal'
    });

    if (response.isConfirmed) {
        bk_list_by_cpl_kd.value = bk_list_by_cpl_kd.value.filter(bahan_kajian => bahan_kajian.bahan_kajian_id !== bahan_kajian_id);

        const res = await request({ url: `/bahan-kajian/${bahan_kajian_id}`, method: 'DELETE' });
        if (res.status == 'SUCCESS') Swal.fire('Dihapus!', 'Bahan Kajian berhasil dihapus', 'success');
    }
};
// === End Tabel Bahan Kajian ===

onMounted(async () => {
    try {        
        const getDataCPL = await request(`/cpl/${cpl_id_fk}?join=cpl_taksonomi`)
		dataCPL.value = getDataCPL.data
        const taksonomiHkdString = [...new Set(dataCPL.value.cpl_taksonomi.map(item => item.taksonomi_h_kd_fk))].join(', ');
        
        const getDataKurikulum = await request(`/kurikulum/${dataCPL.value.kurikulum_id_fk}`)
		dataKurikulum.value = getDataKurikulum.data
		kurikulumKD.value = getDataKurikulum.data.kurikulum_kd

		const getDataProdi = await request(`/prodi/${dataKurikulum.value.prodi_id_fk}`)
		dataProdi.value = getDataProdi.data
		
		const getDataDepartemen = await request(`/departemen/${dataProdi.value.departemen_id_fk}`)
		dataDepartemen.value = getDataDepartemen.data
		
		const getDataFakultas = await request(`/fakultas/${dataDepartemen.value.fakultas_id_fk}`)
		dataFakultas.value = getDataFakultas.data
		
		const getDataRI = await request(`/ri/${dataFakultas.value.ri_id_fk}`)
		dataRI.value = getDataRI.data
        
        
		// === Data ===
		const response = await request(`/ri`)
		ri_list.value = response.data.data
		ri_nama_eng.value = dataRI.value.nama_eng
		selectedRI.value = dataRI.value.ri_id

		fakultas_list.value = getDataRI.data.fakultas
		fakultas_nama_eng.value = dataFakultas.value.nama_eng
		selectedFakultas.value = dataFakultas.value.fakultas_id

		departemen_list.value = getDataFakultas.data.departemen
		departemen_nama_eng.value = dataDepartemen.value.nama_eng
		selectedDepartemen.value = dataDepartemen.value.departemen_id

		prodi_list.value = getDataDepartemen.data.prodi
		prodi_nama_eng.value = dataProdi.value.nama_eng
		selectedProdi.value = dataProdi.value.prodi_id

		jenjang_nama_ind.value = dataProdi.value.jenjang_nama_ind
		jenjang_nama_eng.value = dataProdi.value.jenjang_nama_eng

		kurikulum_list.value = getDataProdi.data.kurikulum
		selectedKurikulum.value = dataKurikulum.value.kurikulum_id

        cpl_list.value = getDataKurikulum.data.cpl
		cpl_nama_eng.value = dataCPL.value.cpl_kd + ' - ' + dataCPL.value.name_eng
        cpl_deskripsi_ind.value = dataCPL.value.deskripsi_ind + '['+ taksonomiHkdString +']'
        cpl_deskripsi_eng.value = dataCPL.value.deskripsi_eng + '['+ taksonomiHkdString +']'
		selectedCPL.value = dataCPL.value.cpl_id
		selectedCPLKD.value = dataCPL.value.cpl_kd
		// === End Data ===

        // eslint-disable-next-line no-undef
        $('button[type="submit"]').click(function() {
            // eslint-disable-next-line no-undef
            if ($(`select.cpl`).val() == '') {
                // eslint-disable-next-line no-undef
                $(`select.cpl.select2-hidden-accessible`).addClass('is-invalid')
                // eslint-disable-next-line no-undef
                $('span').find(`.select2-selection.cpl`).addClass('is-invalid')
            } else {
                // eslint-disable-next-line no-undef
                $(`select.cpl.select2-hidden-accessible`).removeClass('is-invalid')
                // eslint-disable-next-line no-undef
                $('span').find(`.select2-selection.cpl`).removeClass('is-invalid')
            }
        });

        await fetchDataBK();

        await nextTick(() => { initializeSelect2(); });
        filteredSelect();
    } catch (error) {
		console.error('Error fetching data:', error)
	} 
})

async function fetchDataBK() {
    loading.value = true;

    try {        
        const resBKbyCPL = await request(`/cpl-bahan-kajian?cpl_kd=${selectedCPLKD.value}`)
		bk_list_by_cpl_kd.value = resBKbyCPL.data.data[0].bahan_kajians
    } catch (error) {
        console.error('Error:', error);
    } finally {
		loading.value = false
	}
}

// eslint-disable-next-line no-undef
const initializeSelect2 = () => { $("select").select2(); };
</script>

<style scoped>
.slide-enter-active, .slide-leave-active { transition: height 0.7s ease; overflow: hidden; }
.slide-enter-from, .slide-leave-to { height: 0; overflow: hidden; }

.btn-close { background: none; width:auto; cursor: pointer; }
</style>