<template>
    <breadcrumb-content menu="Pembuatan Kurikulum" subMenu="Evaluasi Kurikulum"/>

    <div class="card mb-5 mb-xxl-8">
        <div class="card-body pt-9 pb-0">
            <LoadingOverlay :loading="loading" />
            
            <SSDatatable
                ref="datatableRef"
                url="/kurikulum-datatable"
                primaryKey="kurikulum_id"
                :columns="columns"
                :show="handleShow"
                :edit="handleEdit"
            />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import LoadingOverlay from '@/components/LoadingOverlay'
import SSDatatable from '@/components/SSDatatable.vue'

const datatableRef = ref(null)
const loading = ref(false)
const router = useRouter()

const columns = [
	{ title: 'No', data: 'rownum', searchable: false, width: '8%', className: 'px-1' },
	{ title: 'Kurikulum', data: 'kode_nama_ind' },
	{ title: 'Evaluasi', data: 'evaluasi_kurikulum_ind' },
	{ title: 'Studi Penelusuran', data: 'tracer_study_ind' },
	{ title: 'Aksi', data: 'actions', searchable: false }
]

const handleShow = async kurikulum_id => router.push({ name: 'ShowViewEvaluasiKurikulum', params: { id: kurikulum_id } })
const handleEdit = async kurikulum_id => router.push({ name: 'EditViewEvaluasiKurikulum', params: { id: kurikulum_id } })
</script>