<template>
	<breadcrumb-content menu="Master Data" subMenu="Kerangka Kerja" />

	<div class="card mb-5 mb-xxl-8">
		<div class="pb-10">
			<div class="card-body pt-9 pb-0">
				<h1 class="anchor fw-bold mb-5">Tambah Kerangka Kerja</h1>
				<div class="p-5">
					<Form
						id="form-kerangka-kerja-create"
						class="kt-form"
						method="post"
						@submit="onSubmit"
						:validation-schema="schema"
						v-slot="{ errors }"
					>
						<LoadingOverlay :loading="loading" />

                        <div class="row mb-5">
                            <label class="form-label">
								<span style="color: red">*</span>
                                Jenis Kerangka Kerja
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <select class="form-control form-control-solid-bg jenis_kk" data-control="select2" data-placeholder="-- Pilih Jenis Kerangka Kerja --">
                                    <option></option>
                                    <option v-for="data in jenis_kk_list" :key="data.jenis_kk_id" :value="data.jenis_kk_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind"> {{ data.nama_ind }} </option>
                                </select>
                                <Field type="hidden" id="jenis_kk_id_fk" name="jenis_kk_id_fk" v-model="selectedJenisKK" readonly/>
								<div id="jenis_kk_id_fk-error" class="invalid-feedback">Jenis Kerangka Kerja harus diisi</div>
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <input type="text" class="form-control form-control-solid-bg bg-light-dark jenis_kk" readonly placeholder="Select Framework Type" v-model="jenis_kk_nama_eng">
                            </div>
                        </div>
						
						<FormInput
							labelInd="Kode Kerangka Kerja"
							customName="kk_kd"
							:errorInd="errors.kk_kd"
							:isEnglish="false"
						/>
						<FormInput
							labelInd="Nama Kerangka Kerja"
							labelEng="Name of Framework"
							columnName="nama"
							:errorInd="errors.nama_ind"
							:errorEng="errors.nama_eng"
						/>
						<FormInput
							inputType="textarea"
							rows="5"
							labelInd="Deskripsi"
							labelEng="Description"
							columnName="deskripsi"
							:errorInd="errors.deskripsi_ind"
							:errorEng="errors.deskripsi_eng"
                            :isRequired="false"
						/>

						<div class="mt-10">
							<button type="submit" class="btn btn-primary">Simpan</button>
							&nbsp;
							<button @click="redirectToDataList" class="btn btn-secondary">Kembali</button>
						</div>
					</Form>
				</div>
			</div>
		</div>
	</div>
</template>


<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { Form, configure } from 'vee-validate'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'
import { sendRequest } from '@/api/common'

import { Field } from 'vee-validate'
import FormInput from '@/components/FormInput.vue'

const schema = {
	jenis_kk_id_fk: 'required',
	kk_kd: 'required',
	nama_ind: 'required',
	nama_eng: 'required',
}

const loading = ref(false)
const router = useRouter()

const redirectToDataList = () => router.push({ name: 'KerangkaKerja' })

const onSubmit = async values => {
	values.jenis_kk_id_fk = parseInt(values.jenis_kk_id_fk)

	const param = {
		url: '/kerangka-kerja',
		method: 'POST',
		body: values,
		loading: loading,
		context: 'KerangkaKerja'
	}

	return await sendRequest(param)
}

// custom message error
import { localize } from '@vee-validate/i18n'
const validationConfig = {
	id: {
		names: {
			jenis_kk_id_fk: 'Jenis Kerangka Kerja',
			kk_kd: 'Kode Kerangka Kerja',
			nama_ind: 'Nama Kerangka Kerja',
			nama_eng: 'Name of Framework',
		}
	}
}
configure({ generateMessage: localize(validationConfig) })

const jenis_kk_list = ref([])
const jenis_kk_nama_eng = ref('')
const selectedJenisKK = ref('')

onMounted(async () => {
	try {
		// eslint-disable-next-line no-undef
		$("select").select2();

		const response = await request(`/jenis-kerangka-kerja`)
		jenis_kk_list.value = response.data.data

		// eslint-disable-next-line no-undef
		$('body').on('change', 'select.jenis_kk', async (event) => {
			const id = event.target.value;
			const { data } = await request(`/jenis-kerangka-kerja/${id}`);

			selectedJenisKK.value = id
			jenis_kk_nama_eng.value = data.nama_eng
		})

		// eslint-disable-next-line no-undef
		$('button[type="submit"]').click(function() {
            // eslint-disable-next-line no-undef
            if ($(`select.jenis_kk`).val() == '') {
                // eslint-disable-next-line no-undef
                $(`select.jenis_kk.select2-hidden-accessible`).addClass('is-invalid')
                // eslint-disable-next-line no-undef
                $('span').find(`.select2-selection.jenis_kk`).addClass('is-invalid')
            } else {
                // eslint-disable-next-line no-undef
                $(`select.jenis_kk.select2-hidden-accessible`).removeClass('is-invalid')
                // eslint-disable-next-line no-undef
                $('span').find(`.select2-selection.jenis_kk`).removeClass('is-invalid')
            }
        });
	} catch (error) {
		console.error('Error fetching data:', error)
	} 
});
</script>