<template>
   <breadcrumb-content menu="Pembuatan Kurikulum" subMenu="Matakuliah Kurikulum"/>
	<div class="card mb-5 mb-xxl-8">
      <div class="card-body pt-9 pb-0">
         <LoadingOverlay :loading="loading" />
         <router-link :to="{ name: 'CreateViewMatakuliahKurikulum' }" :class="`btn btn-primary mb-5`">
            <span class="fa fa-plus"></span> Tambah Matakuliah Kurikulum
         </router-link>
         <SSDatatable ref="datatableRef" url="/kurikulum-datatable" primaryKey="kurikulum_id" :columns="columns" :show="handleShow" />
      </div>
   </div>
</template>

<script setup>
	import { ref } from 'vue';
	import { useRouter } from 'vue-router';
	import LoadingOverlay from '@/components/LoadingOverlay';
	import SSDatatable from '@/components/SSDatatable.vue';

	const datatableRef = ref(null);
	const loading = ref(false);
	const router = useRouter();

	const columns = [
		{ title: 'No', data: 'rownum', searchable: false, width: '8%', className: 'px-1' },
		{ title: 'Fakultas', data: 'fakultas_nama_ind' },
		{ title: 'Departemen', data: 'departemen_nama_ind' },
		{ title: 'Program Studi', data: 'prodi_nama_ind' },
		{ title: 'Jenjang', data: 'jenjang_nama_ind' },
		{ title: 'Kode Kurikulum', data: 'kurikulum_kd' },
		{ title: 'Nama Kurikulum', data: 'nama_ind' },
		{ title: 'Aksi', data: 'actions', searchable: false }
	];

	const handleShow = async id => router.push({ name: 'ShowViewMatakuliahKurikulum', params: { id } });
</script>