<template>
	<breadcrumb-content menu="Pembuatan Kurikulum" subMenu="Kirim Ke Reviewer"/>
	<div class="card mb-5 mb-xxl-8">
		<div class="card-body pt-9 pb-0">
			<LoadingOverlay :loading="loading" />
			<SSDatatable 
				ref="datatableRef" 
				url="/kurikulum-datatable" 
				primaryKey="kurikulum_id" 
				:columns="columns" 
				:customBtnAction="generateCustomBtnAction"
				dataName="nama_ind" />
		</div>
	</div>
</template>

<script setup>
	import Swal from 'sweetalert2';
	
	import { ref, onMounted, onBeforeUnmount } from 'vue';
	import LoadingOverlay from '@/components/LoadingOverlay';
	import SSDatatable from '@/components/SSDatatable.vue';
	import request from '@/utils/request'
	import router from '@/router'

	const datatableRef = ref(null);
	const loading = ref(false);

	const columns = [
		{ title: 'No', data: 'rownum', searchable: false, width: '8%', className: 'px-1' },
		{ title: 'Fakultas', data: 'fakultas_nama_ind' },
		{ title: 'Program Studi', data: 'prodi_nama_ind' },
		{ title: 'Kode Kurikulum', data: 'kurikulum_kd' },
		{ title: 'Nama Kurikulum', data: 'nama_ind' },
		{ title: 'Tahun Ajar', data: 'tahun_akhir' },
		{ title: 'Aksi', data: 'actions', searchable: false }
	];

	const generateCustomBtnAction = (kurikulumId, namaKurikulum) => {
		return `
			<div class="actions text-center">
				<button class="btn btn-light-primary btn-sm btn-send m-1" data-toggle="tooltip" data-placement="top" title="Kirim Ke Reviewer" data-kurikulum_id="${kurikulumId}" data-nama="${namaKurikulum}">
					<i class="fa fa-paper-plane"></i>
				</button>
			</div>
		`;
	};

	const handleSend = async (id, nama) => {
		const result = await Swal.fire({
			title: 'Apakah Anda yakin?',
			text: `Anda akan mengirim "${nama}" ke reviewer kurikulum.`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Ya, kirim!',
			cancelButtonText: 'Tidak, batalkan',
			allowOutsideClick: false
		})
		
		if (result.isConfirmed) {
			await onSubmit(id, nama);
		}
	};

	const onSubmit = async (id, nama) => {
		loading.value = true

		try {
			const res = await request({
				url: '/kurikulum-status',
				method: 'PUT',
				data: { 
					kurikulum_id: parseInt(id),
					status_id: 2 //Status Penyusunan menjadi UPMA
				}
			})

			if (res.status === 'SUCCESS') {
				Swal.fire( 'Terkirim!', `"${nama}" telah dikirim ke reviewer.`, 'success' );
				router.push({ name: 'UPMA' });
			} else {
				Swal.fire( 'Tidak terkirim!', `"${nama}" gagal dikirim ke reviewer.`, 'error' );
			}
		} catch (error) {
			const errData = error?.response?.data
			if (errData && errData.code == 400) Swal.fire( 'Tidak terkirim!', `"${nama}" gagal dikirim ke reviewer.`, 'error' );
		} finally {
			loading.value = false
		}
	}

	const handleButtonClick = async (event) => {
		const target = event.target;
		const button = target.closest('.btn-send');

		if (button) {
			event.stopPropagation();
			event.preventDefault();

			const kurikulum_id = button.dataset.kurikulum_id;
			const namaKurikulum = button.dataset.nama;
			await handleSend(kurikulum_id, namaKurikulum);
		}
	};
	onMounted(() => { document.addEventListener('click', handleButtonClick); });
	onBeforeUnmount(() => { document.removeEventListener('click', handleButtonClick); });
</script>