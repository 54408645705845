<template>
	<div>
		<breadcrumb-content menu="Role Management" subMenu="Role" />
		<div class="card mb-5 mb-xxl-8 placeholder-glow">
			<div class="pb-10">
				<div class="card-body pt-9 pb-0">
					<h1 class="anchor fw-bold mb-5">Detail Role Menu</h1>
					<div class="p-5">
						<form @submit.prevent="submitForm">
							<LoadingOverlay :loading="loading" />
							<div class="mb-5 col-lg-8">
								<label class="form-label">Nama Role</label>
								<input
									type="text"
									class="form-control form-control-solid"
									v-model="role.role_name"
									disabled="disabled"
								/>
							</div>
							<div class="mb-5 col-lg-8">
								<label class="form-label">Deskripsi Role</label>
								<textarea
									class="form-control form-control-solid"
									v-model="role.description"
									rows="3"
									disabled="disabled"
								></textarea>
							</div>

							<div class="container">
								<div class="row">
									<div v-for="menu in role.menu" :key="menu.menu_id" class="col-lg-3 col-md-6 col-sm-12">
										<div class="border rounded mb-3">
											<div class="text-center pb-4 border-bottom py-4 d-flex align-items-center">
												<i :class="[menu.icon, 'me-2', 'ms-2', 'text-primary']"></i>
												<span class="text-capitalize text-primary">{{ menu.name }}</span>
												<input
													class="form-check-input me-2 cursor-pointer ms-auto"
													type="checkbox"
													:checked="isChecked(menu.menu_id)"
													@change="toggleCheck(menu)"
												/>
											</div>
											<div v-if="menu.child_menu.length > 0">
												<div
													v-for="childMenu in menu.child_menu"
													:key="childMenu.menu_id"
													class="p-3 d-flex justify-content-between"
												>
													<div v-if="childMenu.icon">
														<i :class="childMenu.icon + ' me-2 ms-2 text-primary'"></i>
													</div>
													<div class="fw-semibold align-items-center">
														<div
															class="d-flex align-items-center"
															:class="{
																'text-primary': isChecked(childMenu.menu_id),
																'text-gray-500': !isChecked(childMenu.menu_id)
															}"
														>
															<div
																class="bullet w-8px h-3px rounded-2 me-3"
																:class="{ 'bg-primary': isChecked(childMenu.menu_id) }"
																:style="{ 'background-color': isChecked(childMenu.menu_id) ? '' : '#E4E6EF' }"
															></div>
															<div class="flex-grow-1 me-4">{{ childMenu.name }}</div>
														</div>
													</div>
													<div class="form-check form-check-sm d-flex justify-content-center align-items-center">
														<!-- Added class here -->
														<input
															class="form-check-input me-2 cursor-pointer"
															type="checkbox"
															:checked="isChecked(childMenu.menu_id)"
															@change="toggleCheck(childMenu)"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="mt-10">
								<div class="col-lg-6">
									<button type="submit" class="btn btn-primary">Simpan</button>
									&nbsp;
									<button @click="redirectToRoleMenuList" class="btn btn-secondary">Kembali</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'
import { sendRequest } from '@/api/common'
import { useAuthStore } from "@/stores/authStore"

const router = useRouter()
const roleId = router.currentRoute.value.params.id
const role = ref({})
const checkedMenus = ref({})
const loading = ref(true)
const redirectToRoleMenuList = () => router.push({ name: 'RoleMenu' })
const {user} = useAuthStore()
const formData = ref({
	menu_id: null,
	role_id: parseInt(roleId),
	updated_by: user.sub
})
const submitForm = async () => {
	const menuIds = Object.keys(checkedMenus.value).filter(menuId => checkedMenus.value[menuId])
	const menuIdsInt = menuIds.map(menuId => parseInt(menuId))
	formData.value.menu_id = menuIdsInt

	const param = {
		url: `/role-menu/${roleId}`,
		method: 'PUT',
		body: formData.value,
		loading: loading,
		context: 'Role Menu',
		indexRoute: 'RoleMenu'
	}

	return await sendRequest(param)
}

watch(role, newValue => {
	checkedMenus.value = {}
	const { menu, granted_menu: grantedMenu } = newValue

	function isMenuGranted(menuId) {
		for (let i = 0; i < grantedMenu.length; i++) {
			if (grantedMenu[i].menu_id === menuId) {
				return true
			}
			if (grantedMenu[i].child_menu && grantedMenu[i].child_menu.length > 0) {
				if (isMenuGrantedRecursive(grantedMenu[i].child_menu, menuId)) {
					return true
				}
			}
		}

		return false
	}

	function isMenuGrantedRecursive(childMenu, menuId) {
		for (let i = 0; i < childMenu.length; i++) {
			if (childMenu[i].menu_id === menuId) {
				return true
			}
			if (childMenu[i].child_menu && childMenu[i].child_menu.length > 0) {
				if (isMenuGrantedRecursive(childMenu[i].child_menu, menuId)) {
					return true
				}
			}
		}
		return false
	}

	menu.forEach(item => {
		checkedMenus.value[item.menu_id] = isMenuGranted(item.menu_id)

		if (item.child_menu.length > 0) {
			item.child_menu.forEach(childItem => {
				checkedMenus.value[childItem.menu_id] = isMenuGranted(childItem.menu_id)
			})
		}
	})
})

const isChecked = menuId => {
	return checkedMenus.value[menuId] || false
}

const toggleCheck = menu => {
	if (menu.child_menu.length > 0) {
		checkedMenus.value[menu.menu_id] = !checkedMenus.value[menu.menu_id]

		// check all child menu if this menu was parent
		menu.child_menu.forEach(child => {
			checkedMenus.value[child.menu_id] = checkedMenus.value[menu.menu_id]
		})
	} else {
		// self check
		const checked = !checkedMenus.value[menu.menu_id]
		checkedMenus.value[menu.menu_id] = checked

		// parent check
		if (checked && menu.id_parent != 0) {
			checkedMenus.value[menu.id_parent] = checked
		}
	}
}

onMounted(async () => {
	const roleId = router.currentRoute.value.params.id
	try {
		const { data } = await request(`/role-menu/${roleId}`)
		role.value = data
	} catch (error) {
		console.error('Error fetching role menu data:', error)
	} finally {
		loading.value = false
	}
})
</script>
