<template>
	<div>
		<breadcrumb-content menu="Role Management" subMenu="Role" />
		<div class="card mb-5 mb-xxl-8 placeholder-glow">
			<div class="pb-10">
				<div class="card-body pt-9 pb-0">
					<h1 class="anchor fw-bold mb-5">Detail Role</h1>
					<div class="p-5">
						<LoadingOverlay :loading="loading" />
						<div class="mb-5 col-lg-8">
							<label class="form-label">Nama Role</label>
							<input type="text" class="form-control form-control-solid" v-model="role.role_name" disabled="disabled" />
						</div>
						<div class="mb-5 col-lg-8">
							<label class="form-label">Deskripsi Role</label>
							<textarea
								class="form-control form-control-solid"
								v-model="role.description"
								rows="3"
								disabled="disabled"
							></textarea>
						</div>
						<div class="mb-5 col-lg-8">
							<label class="form-label">Dibuat pada</label>
							<input
								type="text"
								class="form-control form-control-solid"
								v-model="formattedCreatedAt"
								disabled="disabled"
							/>
						</div>
						<div class="mb-5 col-lg-8">
							<label class="form-label">Diubah pada</label>
							<input
								type="text"
								class="form-control form-control-solid"
								v-model="formattedUpdatedAt"
								disabled="disabled"
							/>
						</div>
						<div class="mt-10">
							<div class="col-lg-6">
								<button @click="redirectToRoleList" class="btn btn-secondary">Kembali</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { formatReadableDate } from '@/utils/date'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'

const router = useRouter()
const role = ref({})
const loading = ref(true)

const formattedCreatedAt = computed(() => formatReadableDate(role.value.created_at))
const formattedUpdatedAt = computed(() => formatReadableDate(role.value.updated_at))

const redirectToRoleList = () => router.push({ name: 'Role' })

onMounted(async () => {
	const roleId = router.currentRoute.value.params.id
	try {
		const { data } = await request(`/role/${roleId}`)
		role.value = data
	} catch (error) {
		console.error('Error fetching role data:', error)
	} finally {
		loading.value = false
	}
})
</script>
