<template>
	<breadcrumb-content menu="Profil" subMenu="Program Studi" />
	<div class="card mb-5 mb-xxl-8">
      <div class="pb-10">
         <div class="card-body pt-9 pb-0">
            <h1 class="anchor fw-bold mb-5">Tambah Program Studi</h1>
            <div class="p-5">
               <Form id="form-prodi-add" class="kt-form" method="post" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
                  <LoadingOverlay :loading="loading" />
                  <div class="row mb-5">
                     <label class="form-label">Perguruan Tinggi</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <select class="form-control form-control-solid-bg university" data-control="select2" data-placeholder="-- Pilih Perguruan Tinggi --">
                           <option></option>
                           <option :key="university.pt_id" :value="university.pt_id">{{ university.nama_ind }}</option>
                        </select>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input type="text" class="form-control form-control-solid-bg bg-light-dark" v-model="universityDetail.nama_eng" placeholder="Input University" readonly />
                     </div>
                  </div>
                  <div class="row mb-5">
                     <label class="form-label">Rumpun Ilmu</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <select class="form-control form-control-solid-bg group" data-control="select2" data-placeholder="-- Pilih Rumpun Ilmu --">
                           <option></option>
                           <option v-for="(row, index) in group" :key="index" :value="row.ri_id">{{ row.nama_ind }}</option>
                        </select>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input type="text" class="form-control form-control-solid-bg bg-light-dark" v-model="groupDetail.nama_eng" placeholder="Input Group of Knowledge" readonly />
                     </div>
                  </div>
                  <div class="row mb-5">
                     <label class="form-label">Fakultas</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <select class="form-control form-control-solid-bg faculty" data-control="select2" data-placeholder="-- Pilih Fakultas --">
                           <option></option>
                           <option v-for="(row, index) in faculty" :key="index" :value="row.fakultas_id">{{ row.nama_ind }}</option>
                        </select>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input type="text" class="form-control form-control-solid-bg bg-light-dark" v-model="facultyDetail.nama_eng" placeholder="Input Faculty" readonly />
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label"><span style="color: red">*</span> Departemen</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <select class="form-control form-control-solid-bg department" data-control="select2" data-placeholder="-- Pilih Departemen --">
                           <option></option>
                           <option v-for="(row, index) in department" :key="index" :value="row.departemen_id">{{ row.nama_ind }}</option>
                        </select>
                        <Field type="hidden" name="departemen_id_fk" class="form-control form-control-solid-bg" v-model="departmentDetail.departemen_id" readonly />
                        <div v-if="errors.departemen_id_fk" id="departemen_id_fk-error" class="invalid-feedback" style="display:block;">Departemen harus diisi</div>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input type="text" class="form-control form-control-solid-bg bg-light-dark" v-model="departmentDetail.nama_eng" placeholder="Input Department" readonly />
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label"><span style="color: red">*</span> Jenjang</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <select class="form-control form-control-solid-bg grade" data-control="select2" data-placeholder="-- Pilih Jenjang --">
									<option></option>
									<option v-for="(row, index) in grade" :key="index" :value="row.jenjang_id">{{ row.nama_ind }}</option>
								</select>
                        <Field type="hidden" name="jenjang_id_fk" class="form-control form-control-solid-bg" v-model="gradeDetail.jenjang_id" readonly />
                        <div v-if="errors.jenjang_id_fk" id="jenjang_id_fk-error" class="invalid-feedback" style="display:block;">Jenjang harus diisi</div>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input type="text" class="form-control form-control-solid-bg bg-light-dark" v-model="gradeDetail.nama_eng" placeholder="Input Grade" readonly />
                     </div>
                  </div>
                  <div class="row mb-5">
                     <label class="form-label"><span style="color:red;">*</span> Kode Program Studi</label>
                     <div class="col-6">
								<div style="display:flex; flex-wrap:nowrap;">
									<div style="width:80px;">
										<input class="form-control form-control-solid-bg" v-model="universityDetail.pt_kd" readonly style="background-color:var(--kt-input-solid-bg); text-align:center;" />
									</div>
                           <div style="width:80px;">
										<input class="form-control form-control-solid-bg" v-model="groupDetail.ri_kd" readonly style="background-color:var(--kt-input-solid-bg); text-align:center;" />
									</div>
                           <div style="width:80px;">
										<input class="form-control form-control-solid-bg" v-model="facultyDetail.fakultas_kd" readonly style="background-color:var(--kt-input-solid-bg); text-align:center;" />
									</div>
									<div style="width:100%;">
										<Field type="text" name="prodi_kd" class="form-control form-control-solid-bg" placeholder="Masukkan Kode Program Studi" />
									</div>
								</div>
                        <div v-if="errors.prodi_kd" class="invalid-feedback" style="display:block;">Kode Program Studi harus diisi</div>
                     </div>
                  </div>
                  <div class="row mb-5">
                     <label class="form-label"><span style="color: red">*</span> Nama Program Studi </label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <Field type="text" name="nama_ind" class="form-control form-control-solid-bg" :class="{ 'is-invalid': errors.nama_ind }" placeholder="Masukkan Nama Program Studi" />
                        <div v-if="errors.nama_ind" class="invalid-feedback" style="display:block;">Nama Program Studi harus diisi</div>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <Field type="text" name="nama_eng" class="form-control form-control-solid-bg" :class="{ 'is-invalid': errors.nama_eng }" placeholder="Input Study Program Name" />
                        <div v-if="errors.nama_eng" class="invalid-feedback" style="display:block;">Program Study Name cannot be empty</div>
                     </div>
                  </div>
                  <div class="row mb-5">
                     <label class="form-label">Deskripsi Program Studi</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <Field as="textarea" name="deskripsi_ind" class="form-control form-control-solid-bg" rows="3" placeholder="Masukkan Deskripsi Program Studi" />
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <Field as="textarea" name="deskripsi_eng" class="form-control form-control-solid-bg" rows="3" placeholder="Input Study Program Description" />
                     </div>
                  </div>
                  <div class="row mb-5">
                     <label class="form-label"><span style="color: red">*</span> Visi Program Studi </label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <input type="text" ref="editor1" />
                        <Field type="hidden" name="visi_ind" v-model="programVission.indStatus" readonly />
                        <div v-if="errors.visi_ind" class="invalid-feedback" style="display:block;">Visi Program Studi harus diisi</div>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input type="text" ref="editor2" />
                        <Field type="hidden" name="visi_eng" v-model="programVission.engStatus" readonly />
                        <div v-if="errors.visi_eng" class="invalid-feedback" style="display:block;">Program Studi Visson cannot be empty</div>
                     </div>
                  </div>
                  <div class="row mb-5">
                     <label class="form-label"><span style="color: red">*</span> Misi Program Studi </label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <input type="text" ref="editor3" />
                        <Field type="hidden" name="misi_ind" v-model="programMission.indStatus" readonly />
                        <div v-if="errors.misi_ind" class="invalid-feedback" style="display:block;">Misi Program Studi harus diisi</div>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input type="text" ref="editor4" />
                        <Field type="hidden" name="misi_eng" v-model="programMission.engStatus" readonly />
                        <div v-if="errors.misi_eng" class="invalid-feedback" style="display:block;">Program Studi Misson cannot be empty</div>
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label"><span style="color: red">*</span> Tujuan Program Studi</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <input type="text" ref="editor5" />
                        <Field type="hidden" name="tujuan_ind" v-model="programTarget.indStatus" readonly />
                        <div v-if="errors.tujuan_ind" class="invalid-feedback" style="display:block;">Tujuan Program Studi harus diisi</div>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input type="text" ref="editor6" />
                        <Field type="hidden" name="tujuan_eng" v-model="programTarget.engStatus" readonly />
                        <div v-if="errors.tujuan_eng" class="invalid-feedback" style="display:block;">Program Studi Target cannot be empty</div>
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label"><span style="color: red">*</span> Strategi Program Studi</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <input type="text" ref="editor7" />
                        <Field type="hidden" name="strategi_ind" v-model="programStrategy.indStatus" readonly />
                        <div v-if="errors.strategi_ind" class="invalid-feedback" style="display:block;">Strategi Program Studi harus diisi</div>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input type="text" ref="editor8" />
                        <Field type="hidden" name="strategi_eng" v-model="programStrategy.engStatus" readonly />
                        <div v-if="errors.strategi_eng" class="invalid-feedback" style="display:block;">Program Studi Strategy cannot be empty</div>
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label"><span style="color: red">*</span>Nilai Universitas </label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <input type="text" ref="editor9" />
                        <Field type="hidden" name="university_value_ind" v-model="universityValue.indStatus" readonly />
                        <div v-if="errors.university_value_ind" class="invalid-feedback" style="display:block;">Nilai Universitas harus diisi</div>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input type="text" ref="editor10" />
                        <Field type="hidden" name="university_value_eng" v-model="universityValue.engStatus" readonly />
                        <div v-if="errors.university_value_eng" class="invalid-feedback" style="display:block;">University Value cannot be empty</div>
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label"><span style="color: red">*</span> Jumlah Mahasiswa</label>
                     <div class="col-6">
                        <Field type="text" name="jml_mahasiswa" class="form-control form-control-solid-bg" placeholder="Masukkan Jumlah Mahasiswa" />
                        <div v-if="errors.jml_mahasiswa" class="invalid-feedback" style="display:block;">Jumlah Mahasiswa harus diisi</div>
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label"><span style="color: red">*</span> Jumlah Dosen</label>
                     <div class="col-6">
                        <Field type="text" name="jml_dosen" class="form-control form-control-solid-bg" placeholder="Masukkan Jumlah Dosen" />
                        <div v-if="errors.jml_dosen" class="invalid-feedback" style="display:block;">Jumlah Dosen harus diisi</div>
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label"><span style="color: red">*</span> Alamat Program Studi</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <Field as="textarea" name="alamat" class="form-control form-control-solid-bg" rows="3" placeholder="Masukkan Alamat Program Studi" />
                        <div v-if="errors.alamat" class="invalid-feedback" style="display:block;">Alamat Program Studi harus diisi</div>
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label"><span style="color: red">*</span> Telepon</label>
                     <div class="col-6">
                        <Field type="text" name="telp" class="form-control form-control-solid-bg" placeholder="Masukkan Nomor Telepon" />
                        <div v-if="errors.telp" class="invalid-feedback" style="display:block;">Telepon harus diisi</div>
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label"><span style="color: red">*</span> Website Program Studi</label>
                     <div class="col-6">
                        <Field type="text" name="website" class="form-control form-control-solid-bg" placeholder="Masukkan URL Website" />
                        <div v-if="errors.website" class="invalid-feedback" style="display:block;">Website Program Studi harus diisi</div>
                     </div>
                  </div>
						<div class="akreditasi-nas">
							<hr />
							<div class="d-flex justify-content-between align-items-center">
								<h3>Data Akreditasi Nasional</h3>
								<div class="text-end">
									<div class="btn btn-light-primary btn-sm border border-primary border-1" @click="showAccreditation('National', 'dynamicComponentNational')">
										<span class="fa fa-plus"></span> Tambah Akreditasi
									</div>
								</div>
							</div>
							<div class="table-responsive mt-3">
								<TableAccreditation :nationalAccreditationData="nationalAccreditation.datatable" text="nationalAccreditationData" />
							</div>
                     <div v-if="dynamicComponentNational">
                        <component :is="dynamicComponentNational" label="Nasional" target="lmbg-nas" field="lmbg_nas_id" @accreditationData="recieveData" @removeDynamicComponent="recieveRemoveDynamicComponent" />
                     </div>
						</div>
						<div class="akreditasi-int">
							<hr />
							<div class="d-flex justify-content-between align-items-center">
								<h3>Data Akreditasi Internasional</h3>
								<div class="text-end">
									<div class="btn btn-light-primary btn-sm border border-primary border-1" @click="showAccreditation('International', 'dynamicComponentInternational')">
										<span class="fa fa-plus"></span> Tambah Akreditasi
									</div>
								</div>
							</div>
							<div class="table-responsive mt-3">
								<TableAccreditation :internationalAccreditationData="internationalAccreditation.datatable" text="internationalAccreditationData" />
							</div>
                     <div v-if="dynamicComponentInternational">
                        <component :is="dynamicComponentInternational" label="Internasional" target="lmbg-int" field="lmbg_int_id" @accreditationData="recieveData" @removeDynamicComponent="recieveRemoveDynamicComponent" />
                     </div>
						</div>
                  <div class="mt-10">
                     <div class="col-lg-6">
                        <button type="submit" class="btn btn-primary">Simpan</button> &nbsp; <button @click="redirectToProgramStudiList" class="btn btn-secondary">Kembali</button>
                     </div>
                  </div>
               </Form>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup>
	import { onMounted, shallowRef, ref } from 'vue';
   import { useRouter } from 'vue-router';
   import { Form, Field } from 'vee-validate';
   import LoadingOverlay from '@/components/LoadingOverlay';
   import { sendRequest } from '@/api/common';
   import request from '@/utils/request';
	import TableAccreditation from './TableAccreditation.vue';
   import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

	const schema = { departemen_id_fk: 'required', jenjang_id_fk: 'required', prodi_kd: 'required', nama_ind: 'required|max:100', nama_eng: 'required|max:100', visi_ind: 'required', visi_eng: 'required', misi_ind: 'required', misi_eng: 'required', tujuan_ind: 'required', tujuan_eng: 'required', strategi_ind: 'required', strategi_eng: 'required', university_value_ind: 'required', university_value_eng: 'required', jml_mahasiswa: 'required' , jml_dosen: 'required' , alamat: 'required' , telp: 'required' , website: 'required' };
	const loading = ref(false);
	const router = useRouter();
	const university = ref({});
	const universityDetail = ref({});
   const group = ref({});
	const groupDetail = ref({});
   const faculty = ref({});
   const facultyDetail = ref({});
   const department = ref({});
   const departmentDetail = ref({});
	const grade = ref({});
	const gradeDetail = ref({});
	const nationalAccreditation = ref({'datatable': [], 'store': []});
	const internationalAccreditation = ref({'datatable': [], 'store': []});
   const editor1 = ref(null);
	const editor2 = ref(null);
	const editor3 = ref(null);
	const editor4 = ref(null);
	const editor5 = ref(null);
	const editor6 = ref(null);
	const editor7 = ref(null);
	const editor8 = ref(null);
	const editor9 = ref(null);
	const editor10 = ref(null);
	let programVission = { ind: null, indStatus: null, eng: null, engStatus: null };
	let programMission = { ind: null, indStatus: null, eng: null, engStatus: null };
	let programTarget = { ind: null, indStatus: null, eng: null, engStatus: null };
	let programStrategy = { ind: null, indStatus: null, eng: null, engStatus: null };
	let universityValue = { ind: null, indStatus: null, eng: null, engStatus: null };
   let dynamicComponentNational = shallowRef(null);
	let dynamicComponentInternational = shallowRef(null);
   const recieveData = (({ type, datatable, store }) => { eval(type).value.datatable.push(datatable); eval(type).value.store.push(store); });
   const recieveRemoveDynamicComponent = ((dynamic) => eval(dynamic).value = null );
	const showAccreditation = ((type, dynamic) => {
      eval(dynamic).value = null;
		import(`./AccreditationFormAdd.vue`).then(module => {
			((type === 'National') ? (dynamicComponentNational.value = module.default) : (dynamicComponentInternational.value = module.default));
		}).then(() => {
			// eslint-disable-next-line no-undef
			$(`.${ ((type === 'National') ? ('lmbg-nas') : ('lmbg-int')) }-accreditation`).slideDown(1000);
		});
	});
	const redirectToProgramStudiList = () => router.push({ name: 'ProgramStudi' });
   const handleElSelect = (async ({ target, self, child, point, handleSelectObject }, event) => {
		const el = event.target;
		if (handleSelectObject) handleSelect(handleSelectObject);
		handleSelectedOption({ url: ((target == '/pt') ? (target) : (`${ target }${ el.value }`)), self, child, point });
	});
	const handleSelectedOption = (async ({ url, self, child, point }) => {
		eval(`${ self }Detail`).value = (await request({ url })).data;
      if (child && point) eval(child).value = eval(`${ self }Detail`).value[point];
	});
	const handleSelect = (({ universitySelect = false, groupSelect = false, facultySelect = false, departmentSelect = false }) => {
		if (!universitySelect) { university.value = {}; universityDetail.value = {}; }
		if (!groupSelect) { group.value = {}; groupDetail.value = {}; }
		if (!facultySelect) { faculty.value = {}; facultyDetail.value = {}; }
		if (!departmentSelect) { department.value = {}; departmentDetail.value = {}; }
	});
	const onSubmit = (async (values) => {
      values.jenjang_id_fk = parseInt(values.jenjang_id_fk);
      values.departemen_id_fk = parseInt(values.departemen_id_fk);
      values.visi_ind = programVission.ind.getData();
      values.visi_eng = programVission.eng.getData();
      values.misi_ind = programMission.ind.getData();
      values.misi_eng = programMission.eng.getData();
      values.tujuan_ind = programTarget.ind.getData();
      values.tujuan_eng = programTarget.eng.getData();
      values.strategi_ind = programStrategy.ind.getData();
      values.strategi_eng = programStrategy.eng.getData();
      values.university_value_ind = universityValue.ind.getData();
      values.university_value_eng = universityValue.eng.getData();
      values.jml_mahasiswa = parseInt(values.jml_mahasiswa);
      values.jml_dosen = parseInt(values.jml_dosen);
      values.akreditasi = [...nationalAccreditation.value.store, ...internationalAccreditation.value.store];

      return await sendRequest({ url: '/prodi', method: 'POST', body: values, loading: loading, context: 'ProgramStudi' });
	});

	onMounted(async () => {
		try {
         ClassicEditor.create(editor1.value).then(editor => { programVission.ind = editor; editor.model.document.on('change:data', () => { programVission.indStatus = ((editor.getData() !== '') ? (true) : (false)); }); });
         ClassicEditor.create(editor2.value).then(editor => { programVission.eng = editor; editor.model.document.on('change:data', () => { programVission.engStatus = ((editor.getData() !== '') ? (true) : (false)); }); });
         ClassicEditor.create(editor3.value).then(editor => { programMission.ind = editor; editor.model.document.on('change:data', () => { programMission.indStatus = ((editor.getData() !== '') ? (true) : (false)); }); });
         ClassicEditor.create(editor4.value).then(editor => { programMission.eng = editor; editor.model.document.on('change:data', () => { programMission.engStatus = ((editor.getData() !== '') ? (true) : (false)); }); });
         ClassicEditor.create(editor5.value).then(editor => { programTarget.ind = editor; editor.model.document.on('change:data', () => { programTarget.indStatus = ((editor.getData() !== '') ? (true) : (false)); }); });
         ClassicEditor.create(editor6.value).then(editor => { programTarget.eng = editor; editor.model.document.on('change:data', () => { programTarget.engStatus = ((editor.getData() !== '') ? (true) : (false)); }); });
         ClassicEditor.create(editor7.value).then(editor => { programStrategy.ind = editor; editor.model.document.on('change:data', () => { programStrategy.indStatus = ((editor.getData() !== '') ? (true) : (false)); }); });
         ClassicEditor.create(editor8.value).then(editor => { programStrategy.eng = editor; editor.model.document.on('change:data', () => { programStrategy.engStatus = ((editor.getData() !== '') ? (true) : (false)); }); });
         ClassicEditor.create(editor9.value).then(editor => { universityValue.ind = editor; editor.model.document.on('change:data', () => { universityValue.indStatus = ((editor.getData() !== '') ? (true) : (false)); }); });
         ClassicEditor.create(editor10.value).then(editor => { universityValue.eng = editor; editor.model.document.on('change:data', () => { universityValue.engStatus = ((editor.getData() !== '') ? (true) : (false)); }); });

			university.value = (await request({ url: '/pt' })).data;
			grade.value = (await request({ url: '/jenjang' })).data.data;

         // eslint-disable-next-line no-undef
         $('input[name=jml_mahasiswa], input[name=jml_dosen], input[name=telp]').on('keypress', function(event) {
            const charCode = ((event.which) ? (event.which) : (event.keyCode));
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
               return false;
            }
         });

         // eslint-disable-next-line no-undef
         $('select.university').select2().on('change', (event) => { handleElSelect({ target: '/pt', self: 'university', child: 'group', point: 'rumpun_ilmu', handleSelectObject: { universitySelect: true } }, event); });

         // eslint-disable-next-line no-undef
         $('select.group').select2().on('change', (event) => { handleElSelect({ target: '/ri/', self: 'group', child: 'faculty', point: 'fakultas', handleSelectObject: { universitySelect: true, groupSelect: true } }, event); });

         // eslint-disable-next-line no-undef
         $('select.faculty').select2().on('change', (event) => { handleElSelect({ target: '/fakultas/', self: 'faculty', child: 'department', point: 'departemen', handleSelectObject: { universitySelect: true, groupSelect: true, facultySelect: true } }, event); });
         
         // eslint-disable-next-line no-undef
         $('select.department').select2().on('change', (event) => { handleElSelect({ target: '/departemen/', self: 'department', handleSelectObject: { universitySelect: true, groupSelect: true, facultySelect: true, departmentSelect: true } }, event); });

         // eslint-disable-next-line no-undef
         $('select.grade').select2().on('change', (event) => { handleElSelect({ target: '/jenjang/', self: 'grade' }, event); });
		} catch (error) {
			console.error('Error fetching data: ', error);
		} finally {
			loading.value = false;
		}
	});
</script>