<template>
	<div>
		<breadcrumb-content menu="Master Data" subMenu="Kerangka Kerja" />
		<div class="card mb-5 mb-xxl-8 placeholder-glow">
			<div class="pb-10">
				<div class="card-body pt-9 pb-0">
					<h1 class="anchor fw-bold mb-5">Detail Kerangka Kerja</h1>
					<div class="p-5">
						<LoadingOverlay :loading="loading" />

						<FormInput
							labelInd="Jenis Kerangka Kerja"
							columnName="nama"
							:valueInd="getDataJenisKK ? getDataJenisKK.nama_ind : '-'"
							:valueEng="getDataJenisKK ? getDataJenisKK.nama_eng : '-'"
							mode="show"
						/>
						<FormInput
							labelInd="Kode Kerangka Kerja"
							customName="kk_kd"
							:valueInd="dataKK.kk_kd"
							:isEnglish="false"
							mode="show"
						/>
						<FormInput
							labelInd="Nama Kerangka Kerja"
							columnName="nama"
							:valueInd="dataKK.nama_ind"
							:valueEng="dataKK.nama_eng"
							mode="show"
						/>
						<FormInput
							inputType="textarea"
							rows="5"
							labelInd="Deskripsi"
							columnName="deskripsi"
							:valueInd="dataKK.deskripsi_ind ? dataKK.deskripsi_ind : '-'"
							:valueEng="dataKK.deskripsi_eng ? dataKK.deskripsi_eng : '-'"
							:isRequired="false"
							mode="show"
						/>

						<FormInput
							labelInd="Dibuat pada"
							columnName="created"
							:isEnglish="false"
							:valueInd="formattedCreatedAt ? formattedCreatedAt : '-'"
							:isRequired="false"
							mode="show"
						/>
						<FormInput
							labelInd="Diubah pada"
							columnName="updated"
							:isEnglish="false"
							:valueInd="formattedUpdatedAt ? formattedUpdatedAt : '-'"
							:isRequired="false"
							mode="show"
						/>

						<div class="mt-10">
							<button @click="redirectToDataList" class="btn btn-secondary">Kembali</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script setup>
import { onMounted, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'

import FormInput from '@/components/FormInput.vue'

const loading = ref(true)
const router = useRouter()
const dataKK = ref({})
const getDataJenisKK = ref({})
const kk_id = router.currentRoute.value.params.id

import { formatReadableDate } from '@/utils/date'
const formattedCreatedAt = computed(() => formatReadableDate(dataKK.value.created_at))
const formattedUpdatedAt = computed(() => formatReadableDate(dataKK.value.updated_at))

const redirectToDataList = () => router.push({ name: 'KerangkaKerja' })

onMounted(async () => {
	try {
		const { data } = await request(`/kerangka-kerja/${kk_id}`)
		dataKK.value = data
		
		const dataJKK = await request(`/jenis-kerangka-kerja/${data.jenis_kk_id_fk}`)
		getDataJenisKK.value = dataJKK.data
	} catch (error) {
		console.error('Error fetching data:', error)
	} finally {
		loading.value = false
	}
})
</script>