<template>
	<div id="overlay" class="overlay">
      <img :src="loadingGIF" alt="Loading..." class="loading-spinner">
   </div>
	<breadcrumb-content menu="Perubahan BRP" subMenu="Lampiran" />
	<div id="lampiran">
		<div v-if="Object.keys(data.SearchInfo).length > 0">
			<SearchInformation :SearchInfo="data.SearchInfo" />
		</div>
		<div class="alert alert-primary mt-10 loading" role="alert">
			<h3 class="alert-heading">INFORMASI!</h3>
			<hr>
			<p class="fs-5 mb-0">Pada halaman ini, data yang dimasukkan akan langsung masuk ke dalam database. Jadi tidak perlu khawatir ketika halaman ter-refresh / sesi berakhir, karena <b>data sudah pasti tersimpan</b>. </p>
		</div>
      <div class="card mb-5 mb-xxl-8 border-top border-primary border-2">
			<div class="card-header" style="min-height: 65px;">
				<h3 class="card-title align-items-start flex-column">
					<span class="card-label fw-bold fs-3 mb-1">Lampiran</span>
					<span class="text-muted mt-1 fw-semibold fs-7">Data Lampiran <b>[Matakuliah: {{ data.CourseInCurriculum.matakuliah_nama_ind }}]</b></span>
				</h3>
				<div class="text-end pt-5">
					<span class="border border-primary border-1 btn btn-light-primary btn-sm p-2 fw-bold" @click="onOpenModal({ title: 'Tambah Lampiran', body: 'Attachment', id, style: { width: '800px', height: '80%' } })"><i class="fas fa-plus p-0 mt-n1"></i> Tambah Lampiran </span>
				</div>
			</div>
         <div class="card-body pt-10 pb-0">
            <div class="row">
					<div class="col-sm">
						<DataTable :columns="columns" :data="data.Attachment.length > 0 ? data.Attachment : []" class="table table-row-bordered gy-5 align-middle dataTable no-footer" />
					</div>
            </div>
         </div>
			<div class="card-footer text-end" style="padding: 1.5rem 2.25rem;">
				<a class="border border-primary border-1 btn btn-light-primary btn-sm" @click="handleNext()"> Lanjut Ke Halaman Kirim ke Reviewer <i class="fa fa-angle-double-right"></i></a>
			</div>
      </div>
		<div v-if="Modal">
			<component :is="Modal" :data="ModalData" @removeModal="onRemoveModal" />
		</div>
   </div>
</template>

<script setup>
	import { ref, onMounted, onBeforeUnmount, shallowRef } from 'vue';
   import { useRouter } from 'vue-router';
	import SearchInformation from './SearchInformation.vue';
	import DataTable from 'datatables.net-vue3';
	import loadingGIF from '@/assets/images/loading.gif';
   import request from '@/utils/request';
	import swal from 'sweetalert2';

   const loading = ref(false);
   const router = useRouter();
   const id = router.currentRoute.value.params.id;
	const data = ref({ BRP: {}, CourseInCurriculum: {}, SearchInfo: {}, Attachment: {} });
	const ModalData = ref({});
	let Modal = shallowRef(null);
	const onOpenModal = ((data) => {
		Modal.value = null;
		ModalData.value = data;
		import(`./Modal.vue`).then(module => {
			Modal.value = module.default;
		}).then(() => {
			// eslint-disable-next-line no-undef
			$(`#Modal-${ data.body }`).fadeIn(1000);
		});
	});
	const handleClick = ((event) => {
      const target = event.target;
      if (target.classList.contains('preview')) onOpenModal({ title: 'Pratinjau Gambar Lampiran', body: 'PreviewImage', src: target.getAttribute('data-src'), style: { width: '360px', height: '550px' } });
      else if (target.classList.contains('edit')) onOpenModal({ title: 'Ubah Lampiran', body: 'Attachment', oldData: data.value.Attachment.find((v) => v.id == target.getAttribute('data-id') ), style: { width: '800px', height: '80%' } });
      else if (target.classList.contains('delete')) handleDelete(target.getAttribute('data-id'));
   });
	const handleDelete = (async (id) => {
		const res = await request({ url: `/lampiran/${ id }`, method: 'Delete' });
		if (res.status === 'SUCCESS') swal.fire({ icon: 'success', title: 'Success', text: `Lampiran Berhasil Dihapus`, showConfirmButton: true }).then(() => data.value.Attachment = data.value.Attachment.filter((v) => v.id != id ) );
      else swal.fire({ title: `Lampiran Gagal Dihapus`, icon: 'error', confirmButtonText: 'OK' });
	});
	const handleNext = (() => router.push({ name: 'KirimKeReviewerBRP' }) );
	const onRemoveModal = (({ dismiss, newdata, type }) => {
		eval(dismiss).value = null;
		if (newdata) {
			if (type) data.value.Attachment = data.value.Attachment.map((v) => ((v.id === newdata.lampiran_id) ? ({ ...v, no: newdata.no_urut, name: newdata.nama, file: newdata.file_name, src: newdata.file_src }) : (v)) );
			else data.value.Attachment.push({ id: newdata.lampiran_id, brp: newdata.brp_id_fk, no: newdata.no_urut, name: newdata.nama, file: newdata.file_name, src: newdata.file_src });

			(data.value.Attachment).sort((a, b) => a.no - b.no );
		}
	});
	const columns = [
      { title: 'Lampiran', data: 'name', width: '250px', orderable: false },
      { title: 'File', data: null, orderable: false, render: (data, type, row) => (`<span class="preview" data-toggle="tooltip" data-placement="top" title="Pratinjau Gambar" data-src="${ row.src }" style="color:#89a6cf; cursor:pointer;">${ row.file }</span>`) },
		{ title: 'Aksi', data: null, width: '120px', searchable: false, className: 'text-center action', orderable: false, render: (data, type, row) => (`<i class="fa fa-edit edit" data-id="${ row.id }" style="margin:2px; font-size:14px; color:#009ef7; cursor:pointer;"></i> <i class="fa fa-trash delete" data-id="${ row.id }" style="margin:2px; font-size:14px; color:red; cursor:pointer;"></i>`) }
   ];

	onMounted(async () => {
		try {
			data.value.BRP = (await request({ url: `/brp/${ id }?loads=mk_kurikulum,status_brp,dosen_pengembang,metode_pembelajaran_brp,tinjauan_pustaka_brp,aktivitas_pembelajaran_brp,rencana_belajar,rancangan_tugas,kriteria_penilaian,korelasi,cpmk,sub_cpmk` })).data;
			data.value.CourseInCurriculum = data.value.BRP.mk_kurikulum;
			data.value.SearchInfo = { group: data.value.CourseInCurriculum.rumpun_ilmu_nama_ind, faculty: data.value.CourseInCurriculum.fakultas_nama_ind, department: data.value.CourseInCurriculum.departemen_nama_ind, program: data.value.CourseInCurriculum.prodi_nama_ind, grade: data.value.CourseInCurriculum.prodi_jenjang_ind, curriculum: { code: data.value.CourseInCurriculum.kurikulum_kd_fk, name: data.value.CourseInCurriculum.kurikulum_nama_ind } };
			data.value.Attachment = ((await request({ url: `/lampiran?per_page=-1` })).data.data).map((v) => ({ id: v.lampiran_id, brp: v.brp_id_fk, no: v.no_urut, name: v.nama, file: v.file_name, src: v.file_src }) ).sort((a, b) => a.no - b.no );

			document.addEventListener('click', handleClick);
		} catch (error) {
			console.error('Error fetching data: ', error);
		} finally {
			loading.value = false;
		}
	});

	onBeforeUnmount(() => { document.removeEventListener('click', handleClick); });
</script>