<template>
	<breadcrumb-content menu="Pembuatan BRP" subMenu="Kriteria Penilaian"/>
	<template v-if="ListDataTableFilter">
		<div class="card mb-5 mb-xxl-8 border-top border-primary border-2">
			<div class="card-body pt-10 pb-5">
				<component :is="ListDataTableFilter" form="ListDataTableFilter" target="ListDataTable" @ListDataTable="recieveNewForm" @getKurikulumKD="updateKurikulumKD"/>
			</div>
		</div>
	</template>
	<template v-if="ListDataTable">
		<component :is="ListDataTable" form="ListDataTable" target="ListDataTableFilter" :kurikulumKD="kurikulumKD" @ListDataTableFilter="recieveNewForm"/>
	</template>
</template>

<script setup>
	import { onMounted, shallowRef, ref } from 'vue';

	const loading = ref(false);
	let ListDataTableFilter = shallowRef(null);
	let ListDataTable = shallowRef(null);

	const kurikulumKD = shallowRef(null);
	const updateKurikulumKD = (value) => { kurikulumKD.value = value; };

	const recieveNewForm = (async ({ open, dismiss }) => {
		eval(dismiss).value = null;
		import(`./${ open }.vue`).then(module => {
			eval(open).value = module.default;
		}).then(() => {
			// eslint-disable-next-line no-undef
			$(`#${ open }`).slideDown(1000);
		});
	});

	onMounted(async () => {
		try {
			import(`./ListDataTableFilter.vue`).then(module => {
				ListDataTableFilter.value = module.default;
			}).then(() => {
				// eslint-disable-next-line no-undef
				$(`#ListDataTableFilter`).slideDown(1000);
			});
		} catch (error) {
			console.error('Error fetching data: ', error);
		} finally {
			loading.value = false;
		}
	});
</script>