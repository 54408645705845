<template>
   <breadcrumb-content menu="Master Data" subMenu="Bentuk Evaluasi Detail"/>
	<div class="card mb-5 mb-xxl-8">
		<div class="card-body pt-9 pb-0">
			<LoadingOverlay :loading="loading" />
			<router-link :to="{ name: 'CreateViewBentukEvaluasiDetail' }" class="btn btn-primary mb-5">
				<span class="fa fa-plus"></span>
				Tambah Bentuk Evaluasi Detail
			</router-link>

			<SSDatatable ref="datatableRef" url="/bentuk-evaluasi-detail-datatable" primaryKey="bentuk_evaluasi_detail_id" :columns="columns" :show="handleShow" :edit="handleEdit" :delete="handleDelete" />
		</div>
	</div>
</template>

<script setup>
	import { ref } from 'vue';
	import { useRouter } from 'vue-router';
	import LoadingOverlay from '@/components/LoadingOverlay';
	import SSDatatable from '@/components/SSDatatable.vue';
	import { sendRequest } from '@/api/common';

	const datatableRef = ref(null);
	const loading = ref(false);
	const router = useRouter();

	const columns = [
		{ title: 'No', data: 'rownum', searchable: false, width: '8%', className: 'px-1' },
		{ title: 'Bentuk Evaluasi', data: 'bentuk_evaluasi_nama_ind' },
		{ title: 'Detail', data: 'deskripsi_ind' },
		{ title: 'Aksi', data: 'actions', searchable: false }
	];

	const handleShow = async id => router.push({ name: 'ShowViewBentukEvaluasiDetail', params: { id } });
	const handleEdit = async id => router.push({ name: 'EditViewBentukEvaluasiDetail', params: { id } });
	const handleDelete = async id => {
		const param = {
			url: `/bentuk-evaluasi-detail/${id}`,
			method: 'DELETE',
			loading: loading,
			context: 'BentukEvaluasiDetail'
		};

		return await sendRequest(param);
	};
</script>