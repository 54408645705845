<template>
   <breadcrumb-content menu="Profil" subMenu="Kelas" />
   <div class="card mb-5 mb-xxl-8">
      <div class="pb-10">
         <div class="card-body pt-9 pb-0">
            <h1 class="anchor fw-bold mb-5">Ubah Kelas</h1>
            <div class="p-5">
               <Form id="form-kelas-update" class="kt-form" method="post" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
                  <LoadingOverlay :loading="loading" />
                  <div class="row mb-5">
                     <label class="form-label">
                        <span style="color: red">*</span> Nama Kelas</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <Field type="text" name="nama_ind" class="form-control form-control-solid-bg" :class="{ 'is-invalid': errors.nama_ind }" v-model="classes.nama_ind" placeholder="Masukkan Nama Kelas" />
                        <div id="nama_ind-error" class="invalid-feedback">{{ errors.nama_ind }}</div>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <Field type="text" name="nama_eng" class="form-control form-control-solid-bg" :class="{ 'is-invalid': errors.nama_eng }" v-model="classes.nama_eng" placeholder="Input Class Name" />
                        <div id="nama_eng-error" class="invalid-feedback">{{ errors.nama_eng }}</div>
                     </div>
                  </div>
                  <div class="mt-10">
                     <div class="col-lg-6">
                        <button type="submit" class="btn btn-primary">Simpan</button> &nbsp; <button @click="redirectToKelasList" class="btn btn-secondary">Kembali</button>
                     </div>
                  </div>
               </Form>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup>
   import { onMounted, ref } from 'vue';
	import { useRouter } from 'vue-router';
	import { Form, Field } from 'vee-validate';
	import LoadingOverlay from '@/components/LoadingOverlay';
	import { sendRequest } from '@/api/common';
	import request from '@/utils/request';

	const schema = { nama_ind: 'required|max:200', nama_eng: 'required|max:200'};
	const loading = ref(true);
	const router = useRouter();
	const id = router.currentRoute.value.params.id;
	const classes = ref({});
	const redirectToKelasList = () => router.push({ name: 'Kelas' });
	const onSubmit = (async (values) => {
		values.is_aktif = true;
		await sendRequest({ url: `/kelas/${ id }`, method: 'PUT', body: values, loading: loading, context: 'Kelas' })
	});

	onMounted(async () => {
		try {            
			classes.value = (await request({ url: `/kelas/${ id }` })).data;
		} catch (error) {
			console.error('Error fetching data: ', error);
		} finally {
			loading.value = false;
		}
	});
</script>