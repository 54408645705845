<template>
	<div>
		<breadcrumb-content menu="Master Data" subMenu="Semester" />
		<div class="card mb-5 mb-xxl-8 placeholder-glow">
			<div class="pb-10">
				<div class="card-body pt-9 pb-0">
					<h1 class="anchor fw-bold mb-5">Ubah Semester</h1>
					<div class="p-5">
						<Form
							id="form-semester-update"
							class="kt-form"
							method="post"
							@submit="onSubmit"
							:validation-schema="schema"
							v-slot="{ errors }"
							ref="formRef"
						>
							<LoadingOverlay :loading="loading" />

							<FormInput
								labelInd="Semester"
								customName="no"
								customClass="semester"
								:errorInd="errors.no"
								:isEnglish="false"
							/>
							<FormInput
								inputType="textarea"
								rows="3"
								labelInd="Keterangan"
								labelEng="Description"
								columnName="keterangan"
								:errorInd="errors.keterangan_ind"
								:errorEng="errors.keterangan_eng"
							/>

							<div class="mt-10">
								<button type="submit" class="btn btn-primary">Simpan</button>
								&nbsp;
								<button @click="redirectToDataList" class="btn btn-secondary">Kembali</button>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { Form, configure } from 'vee-validate'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'
import { sendRequest } from '@/api/common'

import FormInput from '@/components/FormInput.vue'

const schema = {
	no: 'required|max:5',
	keterangan_ind: 'required',
	keterangan_eng: 'required'
}

const formRef = ref(null)
const loading = ref(true)
const router = useRouter()
const semester_id = router.currentRoute.value.params.id

const redirectToDataList = () => router.push({ name: 'Semester' })

const onSubmit = async values => {
	const param = {
		url: `/semester/${semester_id}`,
		method: 'PUT',
		body: values,
		loading: loading,
		context: 'Semester'
	}

	return await sendRequest(param)
}

onMounted(async () => {
	// eslint-disable-next-line no-undef
	$('input.semester').on('input', function () { this.value = this.value.replace(/\D/g,'') });

	try {
		const { data } = await request(`/semester/${semester_id}`)
		if (formRef.value) {
			formRef.value.setFieldValue('no', data.no)
			formRef.value.setFieldValue('keterangan_ind', data.keterangan_ind)
			formRef.value.setFieldValue('keterangan_eng', data.keterangan_eng)
		}
	} catch (error) {
		console.error('Error fetching data:', error)
	} finally {
		loading.value = false
	}
})

// custom message error
import { localize } from '@vee-validate/i18n'
const validationConfig = {
	id: {
		names: {
			no: 'Semester',
			keterangan_ind: 'Keterangan',
			keterangan_eng: 'Description'
		}
	}
}
configure({ generateMessage: localize(validationConfig) })
</script>