<template>
	<div>
		<breadcrumb-content menu="Review Kurikulum" subMenu="UPMA" />
		<div class="card mb-5 mb-xxl-8 placeholder-glow">
			<div class="pb-10">
				<div class="card-body pt-9 pb-0">
					<div class="p-5">
						<LoadingOverlay :loading="loading" />

						<div class="row mb-1">
							<label class="col-form-label col-2 fs-6">Rumpun Ilmu</label>
							<div class="col-form-label col-1 text-end">:</div>
							<div class="col-6">
								<input type="text" class="form-control form-control-solid-bg form-control-sm" v-model="dataKurikulum.ri_nama_ind" readonly>
							</div>
						</div>
						<div class="row mb-1">
							<label class="col-form-label col-2 fs-6">Fakultas</label>
							<div class="col-form-label col-1 text-end">:</div>
							<div class="col-6">
								<input type="text" class="form-control form-control-solid-bg form-control-sm" v-model="dataKurikulum.fakultas_nama_ind" readonly>
							</div>
						</div>
						<div class="row mb-1">
							<label class="col-form-label col-2 fs-6">Departemen</label>
							<div class="col-form-label col-1 text-end">:</div>
							<div class="col-6">
								<input type="text" class="form-control form-control-solid-bg form-control-sm" v-model="dataKurikulum.departemen_nama_ind" readonly>
							</div>
						</div>
						<div class="row mb-1">
							<label class="col-form-label col-2 fs-6">Program Studi</label>
							<div class="col-form-label col-1 text-end">:</div>
							<div class="col-6">
								<input type="text" class="form-control form-control-solid-bg form-control-sm" v-model="dataKurikulum.prodi_nama_ind" readonly>
							</div>
						</div>
						<div class="row mb-1">
							<label class="col-form-label col-2 fs-6">Jenjang</label>
							<div class="col-form-label col-1 text-end">:</div>
							<div class="col-6">
								<input type="text" class="form-control form-control-solid-bg form-control-sm" v-model="dataKurikulum.jenjang_nama_ind" readonly>
							</div>
						</div>
						<div class="row mb-1">
							<label class="col-form-label col-2 fs-6">Kode Kurikulum</label>
							<div class="col-form-label col-1 text-end">:</div>
							<div class="col-6">
								<input type="text" class="form-control form-control-solid-bg form-control-sm" v-model="dataKurikulum.kurikulum_kd" readonly>
							</div>
						</div>
						<div class="row mb-1">
							<label class="col-form-label col-2 fs-6">Nama Kurikulum</label>
							<div class="col-form-label col-1 text-end">:</div>
							<div class="col-6">
								<input type="text" class="form-control form-control-solid-bg form-control-sm" v-model="dataKurikulum.nama_ind" readonly>
							</div>
						</div>

						<hr>

						<Form
							id="form-review1"
							class="kt-form"
							method="post"
							@submit="onSubmit"
							:validation-schema="schema"
							v-slot="{ errors }"
							ref="formRef"
						>
							<div class="row mb-5">
								<label class="col-form-label col-2">Tanggal</label>
								<div class="col-form-label col-1 text-end">:</div>
								<div class="col-6">
									<Field name="tgl_review" type="text" class="form-control bg bg-light-dark" :class="{ 'is-invalid': errors.tgl_review }" v-model="tgl_review" readonly/>
									<div id="tgl_review-error" class="invalid-feedback">{{ errors.tgl_review }}</div>
								</div>
							</div>
							<div class="row mb-5">
								<label class="col-form-label col-2">Status Persetujuan</label>
								<div class="col-form-label col-1 text-end">:</div>
								<div class="col-form-label col-2">
									<label class="form-check-label" for="p-true" style="cursor: pointer;">
										<div class="form-check">
											<input class="form-check-input" type="radio" name="is_disetujui" :v-model="true" id="p-true">
											Disetujui
										</div>
									</label>
								</div>
								<div class="col-form-label col-3">
									<label class="form-check-label" for="p-false" style="cursor: pointer;">
										<div class="form-check">
											<input class="form-check-input" type="radio" name="is_disetujui" :v-model="false" id="p-false" checked>
											Tidak Disetujui
										</div>
									</label>
								</div>
							</div>
							<div class="row mb-5">
								<label class="col-form-label col-2">Catatan</label>
								<div class="col-form-label col-1 text-end">:</div>
								<div class="col-6">
									<Field as="textarea" rows="3" name="catatan" type="text" class="form-control form-control-solid-bg" placeholder="Masukkan catatan"/>
								</div>
							</div>
							<div class="row mb-5">
								<label class="col-form-label col-2">Upload Dokumen</label>
								<div class="col-form-label col-1 text-end">:</div>
								<div class="col-6">
									<Field name="doc_upload" type="file" class="form-control bg bg-light-dark"/>
								</div>
							</div>
	
							<div class="mt-10">
								<button type="submit" class="btn btn-primary">Simpan</button>
								&nbsp;
								<button @click="redirectToDataList" class="btn btn-secondary">Kembali</button>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script setup>
import { onMounted, ref, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import LoadingOverlay from '@/components/LoadingOverlay'
import { sendRequest } from '@/api/common'
import request from '@/utils/request'
import { Form, Field, configure } from 'vee-validate'

const schema = {
	tgl_review: 'required',
}

const formRef = ref(null)
const loading = ref(true)
const router = useRouter()
const kurikulum_id = router.currentRoute.value.params.id

const dataKurikulum = ref({})

const redirectToDataList = () => router.push({ name: 'UPMA' })
const onSubmit = async values => {
	values.kurikulum_id_fk = parseInt(dataKurikulum.value.kurikulum_id)
	values.organisasi = "UPMA"
	// Catatan
	// Upload Doc

	const param = {
		url: '/review-1',
		method: 'POST',
		body: values,
		loading: loading,
		context: 'UPMA'
	}

	return await sendRequest(param)
}

const tgl_review = ref('');

onMounted(async () => {
	fetchData();

	const currentDate = new Date();
	const day = String(currentDate.getDate()).padStart(2, '0');
	const month = String(currentDate.getMonth() + 1).padStart(2, '0');
	const year = currentDate.getFullYear();
	tgl_review.value = `${day}-${month}-${year}`;
})

const fetchData = async () => {
	try {				
		const { data } = await request(`/kurikulum/${kurikulum_id}`)
		dataKurikulum.value = data

		await nextTick(() => { initializeSelect2(); });
	} catch (error) {
		console.error('Error fetching data:', error)
	} finally {
		loading.value = false
	}
}

// custom message error
import { localize } from '@vee-validate/i18n'
const validationConfig = {
	id: {
		names: {
			tgl_review: 'Tanggal Review',
		}
	}
}
configure({ generateMessage: localize(validationConfig) })

// eslint-disable-next-line no-undef
const initializeSelect2 = () => { $("select").select2(); };
</script>