<template>
	<breadcrumb-content menu="Pembuatan Kurikulum" subMenu="Identitas Kurikulum" />
	<div class="card mb-5 mb-xxl-8">
		<div class="pb-10">
			<div class="card-body pt-9 pb-0">
				<h1 class="anchor fw-bold mb-5">Ubah Identitas Kurikulum</h1>
				<div class="p-5">
					<Form id="form-identitas-kurikulum-update" class="kt-form" method="post" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
						<LoadingOverlay :loading="loading" />
						<div class="row mb-5">
							<label class="form-label">Institusi Pemberi Gelar</label>
							<div class="col-6">
								<flag-image bahasa="Indonesia" />
								<Field type="input" name="inst_pemberi_gelar_ind" class="form-control form-control-solid-bg" placeholder="Masukkan Institusi Pemberi Gelar" v-model="curriculum.inst_pemberi_gelar_ind" readonly />
								<div class="form-check mt-2">
									<input type="checkbox" id="doubleTitleCheck" class="form-check-input" v-model="doubleTitleCheck" @change="handleChangeDouble" style="cursor:pointer;" />
									<label class="form-label" for="doubleTitleCheck" style="cursor:pointer;"><span>Gelar Ganda</span></label>
								</div>
							</div>
							<div class="col-6">
								<flag-image bahasa="Inggris" />
								<Field type="input" name="inst_pemberi_gelar_eng" class="form-control form-control-solid-bg" placeholder="Input Degree Granting Institution" v-model="curriculum.inst_pemberi_gelar_eng" readonly />
							</div>
							<div class="col-12" v-if="doubleTitleCheck">
								<div class="row">
									<div class="col-6">
										<Field type="input" name="inst_pemberi_gelar_dobel_ind" class="form-control form-control-solid-bg" placeholder="Masukkan Institusi Pemberi Gelar" v-model="curriculum.inst_pemberi_gelar_dobel_ind" />
									</div>
									<div class="col-6">
										<Field type="input" name="inst_pemberi_gelar_dobel_eng" class="form-control form-control-solid-bg" placeholder="Input Degree Granting Institution" v-model="curriculum.inst_pemberi_gelar_dobel_eng" />
									</div>
								</div>
							</div>
						</div>
						<div class="row mb-5">
							<label class="form-label">Institusi Penyelenggara</label>
							<div class="col-6">
								<flag-image bahasa="Indonesia" />
								<Field type="input" name="inst_penyelenggara_ind" class="form-control form-control-solid-bg" placeholder="Masukkan Institusi Penyelenggara" v-model="curriculum.inst_penyelenggara_ind" readonly />
								<div class="form-check mt-2">
									<input type="checkbox" id="doubleInstitutionCheck" class="form-check-input" v-model="doubleInstitutionCheck" @change="handleChangeDouble" style="cursor:pointer;" />
									<label class="form-label" for="doubleInstitutionCheck" style="cursor:pointer;"><span>Gelar Ganda</span></label>
								</div>
							</div>
							<div class="col-6">
								<flag-image bahasa="Inggris" />
								<Field type="input" name="inst_penyelenggara_eng" class="form-control form-control-solid-bg" placeholder="Input Organizing Institution" v-model="curriculum.inst_penyelenggara_eng" readonly />
							</div>
							<div class="col-12" v-if="doubleInstitutionCheck">
								<div class="row">
									<div class="col-6">
										<Field type="input" name="inst_penyelenggara_dobel_ind" class="form-control form-control-solid-bg" placeholder="Masukkan Institusi Penyelenggara" v-model="curriculum.inst_penyelenggara_dobel_ind" />
									</div>
									<div class="col-6">
										<Field type="input" name="inst_penyelenggara_dobel_eng" class="form-control form-control-solid-bg" placeholder="Input Organizing Institution" v-model="curriculum.inst_penyelenggara_dobel_eng" />
									</div>
								</div>
							</div>
						</div>
						<div class="row mb-5">
                     <label class="form-label">Rumpun Ilmu</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <select class="form-control form-control-solid-bg group" data-control="select2" data-placeholder="-- Pilih Rumpun Ilmu --" v-model="groupDetail.ri_id">
									<option></option>
									<option v-for="(row, index) in group" :key="index" :value="row.ri_id">{{ row.nama_ind }}</option>
								</select>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input class="form-control form-control-solid-bg bg-light-dark" v-model="groupDetail.nama_eng" placeholder="Input Group of Knowledge" readonly />
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label">Fakultas</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <select class="form-control form-control-solid-bg faculty" data-control="select2" data-placeholder="-- Pilih Fakultas --" v-model="facultyDetail.fakultas_id">
									<option></option>
									<option v-for="(row, index) in faculty" :key="index" :value="row.fakultas_id">{{ row.nama_ind }}</option>
								</select>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input class="form-control form-control-solid-bg bg-light-dark" v-model="facultyDetail.nama_eng" placeholder="Input Faculty" readonly />
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label">Departemen</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <select class="form-control form-control-solid-bg department" data-control="select2" data-placeholder="-- Pilih Departemen --" v-model="departmentDetail.departemen_id">
									<option></option>
									<option v-for="(row, index) in department" :key="index" :value="row.departemen_id">{{ row.nama_ind }}</option>
								</select>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input class="form-control form-control-solid-bg bg-light-dark" v-model="departmentDetail.nama_eng" placeholder="Input Department" readonly />
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label">Program Studi</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <select class="form-control form-control-solid-bg program" data-control="select2" data-placeholder="-- Pilih Program Studi --" v-model="programDetail.prodi_id">
									<option></option>
									<option v-for="(row, index) in program" :key="index" :value="row.prodi_id">{{ row.nama_ind }}</option>
								</select>
								<Field type="hidden" name="prodi_id_fk" v-model="programDetail.prodi_id" readonly :class="{ 'is-invalid': errors.prodi_id_fk }" />
								<div id="prodi_id_fk-error" class="invalid-feedback">{{ errors.prodi_id_fk }}</div>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input class="form-control form-control-solid-bg bg-light-dark" v-model="programDetail.nama_eng" placeholder="Input Program Study" readonly />
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label">Jenjang</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
								<input class="form-control form-control-solid-bg bg-light-dark" v-model="programDetail.jenjang_nama_ind" placeholder="Masukkan Jenjang" readonly />
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input class="form-control form-control-solid-bg bg-light-dark" v-model="programDetail.jenjang_nama_eng" placeholder="Input Grade" readonly />
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label">Visi Program Studi</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <textarea class="form-control form-control-solid-bg" rows="3" placeholder="Masukkan Visi Program Studi" readonly v-model="programDetail.visi_ind"></textarea>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <textarea class="form-control form-control-solid-bg" rows="3" placeholder="Input Study Program Vision" readonly v-model="programDetail.visi_eng"></textarea>
                     </div>
                  </div>
                  <div class="row mb-5">
                     <label class="form-label">Misi Program Studi</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <textarea class="form-control form-control-solid-bg" rows="3" placeholder="Masukkan Misi Program Studi" readonly v-model="programDetail.misi_ind"></textarea>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <textarea class="form-control form-control-solid-bg" rows="3" placeholder="Input Study Program Mission" readonly v-model="programDetail.misi_eng"></textarea>
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label">Tujuan Program Studi </label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <textarea class="form-control form-control-solid-bg" rows="3" placeholder="Masukkan Misi Program Studi" readonly v-model="programDetail.tujuan_ind"></textarea>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <textarea class="form-control form-control-solid-bg" rows="3" placeholder="Input Study Program Objective" readonly v-model="programDetail.tujuan_eng"></textarea>
                     </div>
                  </div>
						<div class="row mb-5">
							<label class="form-label">Gelar Yang Diberikan</label>
							<div class="col-6">
								<flag-image bahasa="Indonesia" />
								<select class="form-control form-control-solid-bg title" data-control="select2" data-placeholder="-- Pilih Gelar yang Diberikan --" v-model="titleDetail.gelar_id">
                           <option></option>
									<option v-for="(row, index) in title" :key="index" :value="row.gelar_id">{{ row.sebutan_ind }} - {{ row.singkatan_ind }}</option>
                        </select>
								<Field type="hidden" name="gelar_id_fk" v-model="titleDetail.gelar_id" readonly :class="{ 'is-invalid': errors.gelar_id_fk }" />
								<div id="gelar_id_fk-error" class="invalid-feedback">{{ errors.gelar_id_fk }}</div>
								<div class="form-check mt-2">
									<input type="checkbox" id="doubleDegreeAwardedCheck" class="form-check-input" v-model="doubleDegreeAwardedCheck" @change="handleChangeDouble" style="cursor:pointer;" />
									<label class="form-label" for="doubleDegreeAwardedCheck" style="cursor:pointer;"><span>Gelar Ganda</span></label>
								</div>
							</div>
							<div class="col-6">
								<flag-image bahasa="Inggris" />
								<input class="form-control form-control-solid-bg bg-light-dark" v-model="concatTitleEng" placeholder="Input Degrees Awarded" readonly />
							</div>
							<div class="col-12" v-if="doubleDegreeAwardedCheck">
								<div class="row">
									<div class="col-6">
										<Field type="input" name="gelar_diberikan_dobel_ind" class="form-control form-control-solid-bg" placeholder="Masukkan Gelar yang Diberikan" v-model="curriculum.gelar_diberikan_dobel_ind" />
									</div>
									<div class="col-6">
										<Field type="input" name="gelar_diberikan_dobel_eng" class="form-control form-control-solid-bg" placeholder="Input Degrees Awarded" v-model="curriculum.gelar_diberikan_dobel_eng" />
									</div>
								</div>
							</div>
						</div>
						<div class="row mb-5">
                     <label class="form-label">Tahun Ajar (Awal - Akhir) </label>
                     <div class="col-6">
								<div class="row">
									<div class="col-6">
										<Field type="text" name="tahun_awal" class="form-control form-control-solid-bg" :class="{ 'is-invalid': errors.tahun_awal }" v-model="curriculum.tahun_awal" placeholder="Tahun Ajar Awal" @input="parseDateToYear" />
										<div id="tahun_awal-error" class="invalid-feedback">{{ errors.tahun_awal }}</div>
									</div>
									<div class="col-6">
										<Field type="text" name="tahun_akhir" class="form-control form-control-solid-bg" :class="{ 'is-invalid': errors.tahun_akhir }" v-model="curriculum.tahun_akhir" placeholder="Tahun Ajar Akhir" />
										<div id="tahun_akhir-error" class="invalid-feedback">{{ errors.tahun_akhir }}</div>
									</div>
								</div>
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label"><span style="color:red;">*</span> Kode Kurikulum</label>
                     <div class="col-6">
								<div style="display:flex; flex-wrap:nowrap;">
									<div style="width:80px;">
										<Field type="text" name="pt_kd" class="form-control form-control-solid-bg" v-model="university.pt_kd" readonly style="background-color:var(--kt-input-solid-bg); text-align:center;" />
									</div>
                           <div style="width:80px;">
										<Field type="text" name="ri_kd" class="form-control form-control-solid-bg" v-model="groupDetail.ri_kd" readonly style="background-color:var(--kt-input-solid-bg); text-align:center;" />
									</div>
                           <div style="width:80px;">
										<Field type="text" name="fakultas_kd" class="form-control form-control-solid-bg" v-model="facultyDetail.fakultas_kd" readonly style="background-color:var(--kt-input-solid-bg); text-align:center;" />
									</div>
									<div style="width:80px;">
										<Field type="text" name="prodi_kd" class="form-control form-control-solid-bg" v-model="programDetail.prodi_kd" readonly style="background-color:var(--kt-input-solid-bg); text-align:center;" />
									</div>
									<div style="width:100%;">
										<Field type="text" name="kurikulum_kd" class="form-control form-control-solid-bg" v-model="curriculumCode" readonly />
									</div>
								</div>
                        <div v-if="errors.kurikulum_kd" class="invalid-feedback" style="display:block;">Kode Kurikulum harus diisi</div>
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label">Nama Kurikulum </label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <Field type="text" name="nama_ind" class="form-control form-control-solid-bg" :class="{ 'is-invalid': errors.nama_ind }" placeholder="Masukkan Nama Kurikulum" v-model="curriculum.nama_ind" />
                        <div id="nama_ind-error" class="invalid-feedback">{{ errors.nama_ind }}</div>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <Field type="text" name="nama_eng" class="form-control form-control-solid-bg" :class="{ 'is-invalid': errors.nama_eng }" placeholder="Input Curriculum Name" v-model="curriculum.nama_eng" />
                        <div id="nama_eng-error" class="invalid-feedback">{{ errors.nama_eng }}</div>
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label">Deskripsi Kurikulum</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <Field as="textarea" name="deskripsi_ind" class="form-control form-control-solid-bg" rows="3" placeholder="Masukkan Deskripsi Kurikulum" v-model="curriculum.deskripsi_ind" />
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <Field as="textarea" name="deskripsi_eng" class="form-control form-control-solid-bg" rows="3" placeholder="Input Curriculum Description" v-model="curriculum.deskripsi_eng" />
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label">Bahasa Pengantar</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <select class="form-control form-control-solid-bg instructionLanguage" data-control="select2" data-placeholder="-- Pilih Bahasa Pengantar --" v-model="instructionLanguageDetail.bhs_pengantar_id">
									<option></option>
									<option v-for="(row, index) in instructionLanguage" :key="index" :value="row.bhs_pengantar_id">{{ row.nama_ind }}</option>
								</select>
								<Field type="hidden" name="bhs_pengantar_id_fk" v-model="instructionLanguageDetail.bhs_pengantar_id" readonly :class="{ 'is-invalid': errors.bhs_pengantar_id_fk }" />
                        <div id="bhs_pengantar_id_fk-error" class="invalid-feedback">{{ errors.bhs_pengantar_id_fk }}</div>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input class="form-control form-control-solid-bg bg-light-dark" v-model="instructionLanguageDetail.nama_eng" placeholder="Input Instruction Language" readonly />
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label">Skema Belajar</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <select class="form-control form-control-solid-bg learningScheme" data-control="select2" data-placeholder="-- Pilih Skema Belajar --" v-model="learningSchemeDetail.skema_bljr_id">
									<option></option>
									<option v-for="(row, index) in learningScheme" :key="index" :value="row.skema_bljr_id">{{ row.nama_ind }}</option>
								</select>
								<Field type="hidden" name="skema_bljr_id_fk" v-model="learningSchemeDetail.skema_bljr_id" readonly :class="{ 'is-invalid': errors.skema_bljr_id_fk }" />
								<div id="skema_bljr_id_fk-error" class="invalid-feedback">{{ errors.skema_bljr_id_fk }}</div>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input type="text" class="form-control form-control-solid-bg bg-light-dark" v-model="learningSchemeDetail.nama_eng" placeholder="Input Learning Scheme" readonly />
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label">Persyaratan Masuk</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <Field as="textarea" name="syarat_masuk_ind" class="form-control form-control-solid-bg" rows="3" placeholder="Masukkan Persyaratan Masuk" v-model="curriculum.syarat_masuk_ind" />
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <Field as="textarea" name="syarat_masuk_eng" class="form-control form-control-solid-bg" rows="3" placeholder="Input Entry Requirements" v-model="curriculum.syarat_masuk_eng" />
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label">Masa Tempuh Kurikulum </label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <Field type="text" name="lama_bljr_min" class="form-control form-control-solid-bg" placeholder="Masukkan Masa Tempuh Kurikulum" v-model="curriculum.lama_bljr_min" />
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label">Masa Studi </label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <Field type="text" name="lama_bljr_max" class="form-control form-control-solid-bg" placeholder="Masukkan Masa Studi" v-model="curriculum.lama_bljr_max" />
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label">Status Profesi</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <Field type="text" name="status_profesi_ind" class="form-control form-control-solid-bg" placeholder="Masukkan Status Profesi" v-model="curriculum.status_profesi_ind" />
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <Field type="text" name="status_profesi_eng" class="form-control form-control-solid-bg" placeholder="Input Professional Status" v-model="curriculum.status_profesi_eng" />
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label">Tanggal Pengajuan </label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <Field type="text" name="tgl_pengajuan" class="form-control form-control-solid-bg tgl-pengajuan" :class="{ 'is-invalid': errors.tgl_pengajuan }" placeholder="Masukkan Tanggal Pengajuan" v-model="curriculum.tgl_pengajuan" />
                        <div id="tgl_pengajuan-error" class="invalid-feedback">{{ errors.tgl_pengajuan }}</div>
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label">Pengguna Lulusan </label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
								<input type="text" ref="editor1" />
                     </div>
							<div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input type="text" ref="editor2" />
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label">Landasan Filosofis </label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
								<input type="text" ref="editor3" />
                     </div>
							<div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input type="text" ref="editor4" />
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label">Landasan Sosiologis </label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
								<input type="text" ref="editor5" />
                     </div>
							<div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input type="text" ref="editor6" />
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label">Landasan Psikologis </label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
								<input type="text" ref="editor7" />
                     </div>
							<div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input type="text" ref="editor8" />
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label">Landasan Historis </label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
								<input type="text" ref="editor9" />
                     </div>
							<div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input type="text" ref="editor10" />
                     </div>
                  </div>
						<hr />
						<div class="d-flex justify-content-between align-items-center">
							<h3>Data Semester Lainnya</h3>
							<div class="text-end">
								<div class="btn btn-light-primary btn-sm border border-primary border-1" @click="showSemester('semester-form', 'dynamicComponent')">
									<span class="fa fa-plus"></span> Tahapan Proses Pembelajaran
								</div>
							</div>
						</div>
						<div class="table-responsive mt-3">
							<TableSemester :curriculumSemesterData="curriculumSemesterData.datatable" :curriculumID="curriculum.kurikulum_id" @editCurriculumSemester="recieveEditCurriculumSemester" @removeCurriculumSemester="recieveRemoveCurriculumSemester" />
						</div>
						<div v-if="dynamicComponent">
							<component :is="dynamicComponent" target="semester-form" :curriculumID="curriculum.kurikulum_id" :curriculumSemesterDataByID="curriculumSemesterDataByID" @semesterData="recieveSemesterData" @removeDynamicComponent="recieveRemoveDynamicComponent" />
						</div>
						<div class="mt-10">
                     <div class="col-lg-6">
                        <button type="submit" class="btn btn-primary">Simpan</button> &nbsp; <button @click="redirectToIdentitasKurikulumList" class="btn btn-secondary">Kembali</button>
                     </div>
                  </div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { onMounted, shallowRef, ref } from 'vue';
   import { useRouter } from 'vue-router';
   import { Form, Field } from 'vee-validate';
   import LoadingOverlay from '@/components/LoadingOverlay';
   import { sendRequest } from '@/api/common';
   import request from '@/utils/request';
	import TableSemester from './TableSemester.vue';
	import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
	import { useAuthStore } from "@/stores/authStore";

	const userStore = useAuthStore();
	const schema = { prodi_id_fk: 'required', bhs_pengantar_id_fk: 'required', skema_bljr_id_fk: 'required', kurikulum_kd: 'required', nama_ind: 'required', nama_eng: 'required', tgl_pengajuan: 'required', tahun_awal: 'required', tahun_akhir: 'required' };
	const loading = ref(false);
	const router = useRouter();
	const id = router.currentRoute.value.params.id;
	const university = ref({});
	const curriculum = ref({});
	const group = ref({});
	const groupDetail = ref({});
	const faculty = ref({});
	const facultyDetail = ref({});
	const department = ref({});
	const departmentDetail = ref({});
	const program = ref({});
	const programDetail = ref({});
	const gradeDetail = ref({});
	const title = ref({});
	const titleDetail = ref({});
	const concatTitleEng = ref(null);
	const instructionLanguage = ref({});
	const instructionLanguageDetail = ref({});
	const learningScheme = ref({});
	const learningSchemeDetail = ref({});
	const doubleTitleCheck = ref(null);
	const doubleInstitutionCheck = ref(null);
	const doubleDegreeAwardedCheck = ref(null);
	const curriculumSemesterData = ref({ 'datatable': [], 'store': [] });
	const curriculumCode = ref(null);
	const editor1 = ref(null);
	const editor2 = ref(null);
	const editor3 = ref(null);
	const editor4 = ref(null);
	const editor5 = ref(null);
	const editor6 = ref(null);
	const editor7 = ref(null);
	const editor8 = ref(null);
	const editor9 = ref(null);
	const editor10 = ref(null);
	let graduateUsers = {ind: null, eng: null};
	let philosophicalFoundations = {ind: null, eng: null};
	let sociologicalFoundations = {ind: null, eng: null};
	let psychologicalFoundations = {ind: null, eng: null};
	let historicalFoundations = {ind: null, eng: null};
	let dynamicComponent = shallowRef(null);
	const curriculumSemesterDataByID = shallowRef({});
   const recieveSemesterData = (({ datatable, store, edit }) => {
		if (!edit) {
			curriculumSemesterData.value.datatable.push(datatable);
			curriculumSemesterData.value.store.push(store);
		}
		else {
			curriculumSemesterData.value.datatable = (curriculumSemesterData.value.datatable).map(v => {
				if (datatable.id === v.id) return datatable;
				else return v;
			});
		}
	});
   const recieveRemoveDynamicComponent = ((dynamic) => eval(dynamic).value = null );
	const recieveEditCurriculumSemester = (({ data, target, dynamic }) => {
		eval(dynamic).value = null;
		curriculumSemesterDataByID.value = data;
		import(`./SemesterForm.vue`).then(module => {
			eval(dynamic).value = module.default;
		}).then(() => {
			// eslint-disable-next-line no-undef
			$(`.${ target }`).slideDown(1000);
		});
	});
	const handleChangeDouble = ((event) => {
		const id = event.target.id;
		if (event.target.checked) eval(id).value = true;
		else {
			if (id == 'doubleTitleCheck') {
				curriculum.value.inst_pemberi_gelar_dobel_ind = '';
				curriculum.value.inst_pemberi_gelar_dobel_eng = '';
			}
			else if (id == 'doubleInstitutionCheck') {
				curriculum.value.inst_penyelenggara_dobel_ind = '';
				curriculum.value.inst_penyelenggara_dobel_eng = '';
			}
			else {
				curriculum.value.gelar_diberikan_dobel_ind = '';
				curriculum.value.gelar_diberikan_dobel_eng = '';
			}
			eval(id).value = false;
		}
	});
	const showSemester = ((target, dynamic) => {
      eval(dynamic).value = null;
		curriculumSemesterDataByID.value = {};
		import(`./SemesterForm.vue`).then(module => {
			eval(dynamic).value = module.default;
		}).then(() => {
			// eslint-disable-next-line no-undef
			$(`.${ target }`).slideDown(1000);
		});
	});
	const recieveRemoveCurriculumSemester = (({ id, type }) => { eval(type).value.datatable = eval(type).value.datatable.filter(v => v.id !== parseInt(id) ); });
	const redirectToIdentitasKurikulumList = () => router.push({ name: 'IdentitasKurikulum' });
	const parseDateToYear = ((event) => curriculumCode.value = ((event.target.value).split('-'))[2] );
	const parseDateToDB = ((date) => {
		if (date) {
			const [day, month, year] = date.split('-');
			return new Date(year, month - 1, day);
		}
		return null;
	});
	const parseDateToView = ((date) => {
		const formatted = new Date(date);
		return `${ String(formatted.getDate()).padStart(2, '0') }-${ String(formatted.getMonth() + 1).padStart(2, '0') }-${ formatted.getFullYear() }`;
	});
	const stripHtml = ((html) => {
      const doc = new DOMParser().parseFromString(html, 'text/html');
      return doc.body.textContent || '';
   });
	const onSubmit = async (values) => {
		values.status_monitoring_id_fk = 1;
		values.kurikulum_kd = `${ values.pt_kd }.${ values.ri_kd }.${ values.fakultas_kd }.${ values.prodi_kd }.${ curriculumCode.value }`;
		values.is_dobel_pemberi_gelar = doubleTitleCheck.value;
		values.is_dobel_penyelenggara = doubleInstitutionCheck.value;
		values.is_dobel_gelar = doubleTitleCheck.value;
		values.tahun_awal = parseDateToDB(values.tahun_awal);
		values.tahun_akhir = parseDateToDB(values.tahun_akhir);
		values.tgl_pengajuan = parseDateToDB(values.tgl_pengajuan);
		values.lama_bljr_min = parseInt(values.lama_bljr_min);
		values.lama_bljr_max = parseInt(values.lama_bljr_max);
		values.pengguna_lulusan_ind = graduateUsers.ind.getData();
		values.pengguna_lulusan_eng = graduateUsers.eng.getData();
		values.landasan_filosofis_ind = philosophicalFoundations.ind.getData();
		values.landasan_filosofis_eng = philosophicalFoundations.eng.getData();
		values.landasan_sosiologis_ind = sociologicalFoundations.ind.getData();
		values.landasan_sosiologis_eng = sociologicalFoundations.eng.getData();
		values.landasan_psikologis_ind = psychologicalFoundations.ind.getData();
		values.landasan_psikologis_eng = psychologicalFoundations.eng.getData();
		values.landasan_historis_ind = historicalFoundations.ind.getData();
		values.landasan_historis_eng = historicalFoundations.eng.getData();
		values.user_pengajuan = userStore.user.name;

		return await sendRequest({ url: `/kurikulum/${ id }`, method: 'PUT', body: values, loading: loading, context: 'IdentitasKurikulum' });
	};
	async function initializeEditor(container, value, target, sub) {
		try {
			const editor = await ClassicEditor.create(container);
			editor.setData(value);
			eval(target)[sub] = editor;
		}
		catch (error) {
			console.error('Error initializing the editor: ', error);
		}
	}
	const handleElSelect = (async ({ target, self, child, point, handleSelectObject, concat }, event) => {
		const el = event.target;
		if (handleSelectObject !== undefined) handleSelect(handleSelectObject);
		handleSelectedOption({ url: `${ target }${ el.value }`, self, child, point, concat });
	});
	const handleSelect = (({ groupSelect = false, facultySelect = false, departmentSelect = false, programSelect = false }) => {
		if (!groupSelect) { group.value = {}; groupDetail.value = {}; }
		if (!facultySelect) { faculty.value = {}; facultyDetail.value = {}; }
		if (!departmentSelect) { department.value = {}; departmentDetail.value = {}; }
		if (!programSelect) { program.value = {}; programDetail.value = {}; }
	});
	const handleSelectedOption = (async ({ url, self, child, point, concat }) => {
		eval(`${ self }Detail`).value = (await request({ url })).data;

		if ((!child) && (!point)) {
			if (concat) eval(concat).value = `${ eval(`${ self }Detail`).value.sebutan_eng } - ${ eval(`${ self }Detail`).value.singkatan_eng }`;
			else {
				eval(`${ self }Detail`).value.visi_ind = stripHtml(eval(`${ self }Detail`).value.visi_ind);
				eval(`${ self }Detail`).value.visi_eng = stripHtml(eval(`${ self }Detail`).value.visi_eng);
				eval(`${ self }Detail`).value.misi_ind = stripHtml(eval(`${ self }Detail`).value.misi_ind);
				eval(`${ self }Detail`).value.misi_eng = stripHtml(eval(`${ self }Detail`).value.misi_eng);
				eval(`${ self }Detail`).value.tujuan_ind = stripHtml(eval(`${ self }Detail`).value.tujuan_ind);
				eval(`${ self }Detail`).value.tujuan_eng = stripHtml(eval(`${ self }Detail`).value.tujuan_eng);
			}
		}
		else eval(child).value = eval(`${ self }Detail`).value[point];
	});

	onMounted(async () => {
		try {
			university.value = (await request({ url: `/pt` })).data;
			curriculum.value = (await request({ url: `/kurikulum/${ id }` })).data;
			curriculum.value.tahun_awal = parseDateToView(curriculum.value.tahun_awal);
			curriculum.value.tahun_akhir = parseDateToView(curriculum.value.tahun_akhir);
			curriculum.value.tgl_pengajuan = parseDateToView(curriculum.value.tgl_pengajuan);
			curriculumCode.value = ((curriculum.value.tahun_awal).split('-'))[2];
			doubleTitleCheck.value = curriculum.value.is_dobel_pemberi_gelar;
			doubleInstitutionCheck.value = curriculum.value.is_dobel_penyelenggara;
			doubleDegreeAwardedCheck.value = curriculum.value.is_dobel_gelar;
			titleDetail.value = (await request({ url: `/gelar/${ curriculum.value.gelar_id_fk }` })).data;
			instructionLanguageDetail.value = (await request({ url: `/bhs-pengantar/${ curriculum.value.bhs_pengantar_id_fk }` })).data;
			learningSchemeDetail.value = (await request({ url: `/skemabljr/${ curriculum.value.skema_bljr_id_fk }` })).data;
			programDetail.value = (await request({ url: `/prodi/${ curriculum.value.prodi_id_fk }` })).data;
			programDetail.value.visi_ind = stripHtml(programDetail.value.visi_ind);
			programDetail.value.visi_eng = stripHtml(programDetail.value.visi_eng);
			programDetail.value.misi_ind = stripHtml(programDetail.value.misi_ind);
			programDetail.value.misi_eng = stripHtml(programDetail.value.misi_eng);
			programDetail.value.tujuan_ind = stripHtml(programDetail.value.tujuan_ind);
			programDetail.value.tujuan_eng = stripHtml(programDetail.value.tujuan_eng);
			gradeDetail.value = (await request({ url: `/jenjang/${ programDetail.value.jenjang_id_fk }` })).data;
			departmentDetail.value = (await request({ url: `/departemen/${ programDetail.value.departemen_id_fk }` })).data;
			facultyDetail.value = (await request({ url: `/fakultas/${ departmentDetail.value.fakultas_id_fk }` })).data;
			groupDetail.value = (await request({ url: `/ri/${ facultyDetail.value.ri_id_fk }` })).data;
			group.value = (await request({ url: `/ri` })).data.data;
			faculty.value = groupDetail.value.fakultas;
			department.value = facultyDetail.value.departemen;
			program.value = departmentDetail.value.prodi;
			title.value = (await request({ url: `/gelar` })).data.data;
			instructionLanguage.value = (await request({ url: `/bhs-pengantar` })).data.data;
			learningScheme.value = (await request({ url: `/skemabljr` })).data.data;
			concatTitleEng.value = `${ titleDetail.value.sebutan_eng } - ${ titleDetail.value.singkatan_eng }`;

			initializeEditor(editor1.value, curriculum.value.pengguna_lulusan_ind, 'graduateUsers', 'ind');
			initializeEditor(editor2.value, curriculum.value.pengguna_lulusan_eng, 'graduateUsers', 'eng');
			initializeEditor(editor3.value, curriculum.value.landasan_filosofis_ind, 'philosophicalFoundations', 'ind');
			initializeEditor(editor4.value, curriculum.value.landasan_filosofis_eng, 'philosophicalFoundations', 'eng');
			initializeEditor(editor5.value, curriculum.value.landasan_sosiologis_ind, 'sociologicalFoundations', 'ind');
			initializeEditor(editor6.value, curriculum.value.landasan_sosiologis_eng, 'sociologicalFoundations', 'eng');
			initializeEditor(editor7.value, curriculum.value.landasan_psikologis_ind, 'psychologicalFoundations', 'ind');
			initializeEditor(editor8.value, curriculum.value.landasan_psikologis_eng, 'psychologicalFoundations', 'eng');
			initializeEditor(editor9.value, curriculum.value.landasan_historis_ind, 'historicalFoundations', 'ind');
			initializeEditor(editor10.value, curriculum.value.landasan_historis_eng, 'historicalFoundations', 'eng');

			// eslint-disable-next-line no-undef
			$('input[name=lama_bljr_min], input[name=lama_bljr_max]').on('keypress', function(event) {
				const charCode = ((event.which) ? (event.which) : (event.keyCode));
				if (charCode > 31 && (charCode < 48 || charCode > 57)) {
					return false;
				}
			});

			// eslint-disable-next-line no-undef
			$('input.tgl-pengajuan, input[name=tahun_awal], input[name=tahun_akhir]').flatpickr({ dateFormat: 'd-m-Y' });

			// eslint-disable-next-line no-undef
         $('select.group').select2().on('change', async (event) => { handleElSelect({ target: '/ri/', self: 'group', child: 'faculty', point: 'fakultas', handleSelectObject: { groupSelect: true } }, event); university.value = (await request({ url: `/pt` })).data; });

			// eslint-disable-next-line no-undef
         $('select.faculty').select2().on('change', (event) => { handleElSelect({ target: '/fakultas/', self: 'faculty', child: 'department', point: 'departemen', handleSelectObject: { groupSelect: true, facultySelect: true } }, event); });

			// eslint-disable-next-line no-undef
			$('select.department').select2().on('change', (event) => { handleElSelect({ target: '/departemen/', self: 'department', child: 'program', point: 'prodi', handleSelectObject: { groupSelect: true, facultySelect: true, departmentSelect: true } }, event); });

			// eslint-disable-next-line no-undef
			$('select.program').select2().on('change', (event) => { handleElSelect({ target: '/prodi/', self: 'program', handleSelectObject: { groupSelect: true, facultySelect: true, departmentSelect: true, programSelect: true } }, event); });

			// eslint-disable-next-line no-undef
			$('select.title').select2().on('change', (event) => { handleElSelect({ target: '/gelar/', self: 'title', concat: 'concatTitleEng' }, event); });

			// eslint-disable-next-line no-undef
			$('select.instructionLanguage').select2().on('change', (event) => { handleElSelect({ target: '/bhs-pengantar/', self: 'instructionLanguage' }, event); });
			
			// eslint-disable-next-line no-undef
			$('select.learningScheme').select2().on('change', (event) => { handleElSelect({ target: '/skemabljr/', self: 'learningScheme' }, event); });

			(curriculum.value.kurikulum_semester).map((v) => {
				curriculumSemesterData.value.datatable.push({ id: v.kurikulum_semester_id, semesterTypeName: v.nama_ind, numberOfSemester: v.jml_semester, numberOfWeek: v.jml_minggu });
			});
		} catch (error) {
			console.error('Error fetching data: ', error);
		} finally {
			loading.value = false;
		}
	});
</script>