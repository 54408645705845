<template>
	<div id="overlay" class="overlay">
      <img :src="loadingGIF" alt="Loading..." class="loading-spinner">
   </div>
   <breadcrumb-content menu="Perubahan BRP" subMenu="Informasi Umum" />
	<div class="card mb-5 mb-xxl-8">
		<div class="pb-10">
			<div class="card-body pt-9 pb-0">
				<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist" style="padding-bottom:11px; border-bottom:3px solid #a1a5c3;">
               <li v-for="(row, index) in NavPills" :key="index" class="nav-item" role="presentation">
                  <button :class="`nav-link ${ row.class } ${ ((urlParams.target == row.class) ? ('active') : ('')) }`" type="button" role="tab" :aria-selected="urlParams.target == row.class">{{ row.name }}</button>
               </li>
            </ul>
				<div class="p-5">
               <div v-if="GeneralInfo">
						<component :is="GeneralInfo" form="index" next="CPL-program-study" :BRP="data.BRP" :CourseInCurriculum="data.CourseInCurriculum" @form="recieveForm" />
					</div>
               <div v-else-if="CPLProgramStudy">
						<component :is="CPLProgramStudy" form="CPL-program-study" next="CPMK" prev="index" :BRP="data.BRP" :CPL="data.CPL" @form="recieveForm" />
					</div>
					<div v-else-if="CPMK">
						<component :is="CPMK" form="CPMK" next="sub-CPMK" prev="CPL-program-study" :BRP="data.BRP" :CPMK="data.CPMK" @form="recieveForm" />
					</div>
					<div v-else-if="SubCPMK">
						<component :is="SubCPMK" form="sub-CPMK" next="correlation" prev="CPMK" :BRP="data.BRP" :SubCPMK="data.SubCPMK" @form="recieveForm" />
					</div>
					<div v-else-if="Correlation">
						<component :is="Correlation" form="correlation" prev="sub-CPMK" :BRP="data.BRP" :CPMK="data.CPMK" :SubCPMK="data.SubCPMK" :CPL="data.CPL" :Correlation="data.Correlation" :Literature="data.Literature" @form="recieveForm" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { onMounted, shallowRef, ref } from 'vue';
   import { useRouter } from 'vue-router';
   import request from '@/utils/request';
	import loadingGIF from '@/assets/images/loading.gif';

   const loading = ref(false);
   const router = useRouter();
   const urlParams = router.currentRoute.value.params;
   const NavPills = ref([{ class: 'index', name: 'Informasi Umum' }, { class: 'CPL-program-study', name: 'CPL Prodi' }, { class: 'CPMK', name: 'CPMK' }, { class: 'sub-CPMK', name: 'Sub CPMK' }, { class: 'correlation', name: 'Korelasi' }]);
	const data = ref({ BRP: {}, CourseInCurriculum: {}, CPL: [], CPMK: [], SubCPMK: [], Correlation: [], Literature: [] });
   let GeneralInfo = shallowRef(null);
   let CPLProgramStudy = shallowRef(null);
   let CPMK = shallowRef(null);
   let SubCPMK = shallowRef(null);
   let Correlation = shallowRef(null);
   const urlPage = ((target) => ((target == 'index') ? ('GeneralInfo') : ((target.split('-')).map((word) => word.charAt(0).toUpperCase() + word.slice(1) ).join(''))) );
   const recieveForm = (({ open, dismiss, pathTo, newdata }) => {
		eval(urlPage(dismiss)).value = null;
		import(`./${ urlPage(open) }.vue`).then(module => {
			eval(urlPage(open)).value = module.default;
		}).then(() => {
			router.push(pathTo);
			urlParams.target = open;
			// eslint-disable-next-line no-undef
			$(`#${ urlParams.target }`).slideDown(1000);
			if (newdata) data.value[newdata.target] = newdata.data;
		});
   });

	onMounted(async () => {
		try {
			data.value.BRP = (await request({ url: `/brp/${ urlParams.id }?loads=mk_kurikulum,status_brp,dosen_pengembang,metode_pembelajaran_brp,tinjauan_pustaka_brp,aktivitas_pembelajaran_brp,rencana_belajar,rancangan_tugas,kriteria_penilaian,korelasi,cpmk,sub_cpmk` })).data;
			data.value.CourseInCurriculum = data.value.BRP.mk_kurikulum;
			data.value.CPL = data.value.CourseInCurriculum.mk_cpl;
			data.value.CPMK = data.value.BRP.cpmk;
			data.value.SubCPMK = data.value.BRP.sub_cpmk;
			data.value.Correlation = data.value.BRP.korelasi;
			data.value.Literature = data.value.BRP.tinjauan_pustaka_brp;

         import(`./${ urlPage(urlParams.target) }.vue`).then(module => {
				eval(urlPage(urlParams.target)).value = module.default;
			}).then(() => {
				// eslint-disable-next-line no-undef
				$(`#${ urlParams.target }`).slideDown(1000);
			});
		} catch (error) {
			console.error('Error fetching data: ', error);
		} finally {
			loading.value = false;
		}
	});
</script>

<style>
   .btn-submit, .btn-submit:hover, .btn-submit:active, .btn-submit:focus, .btn-next, .btn-next:hover, .btn-next:active, .btn-next:focus, .btn-prev, .btn-prev:hover, .btn-prev:active, .btn-prev:focus { background-color:#ffd400; color:#585858; border:1px solid #585858 !important; }
   .btn-submit i, .btn-next i, .btn-prev i { color:#585858; }

	.dataTables_wrapper .dataTables_filter, .dataTables_wrapper .dataTables_paginate { display:flex; justify-content:flex-end; }
	table tbody tr td.dataTables_empty { text-align:center; }

	.select2-search.select2-search--inline { width:100% }

	table#CPL-program-study-table thead tr th.no, table#CPL-program-study-table thead tr th.action, table#CPMK-table thead tr th.no, table#CPMK-table thead tr th.action, table#sub-CPMK-table thead tr th.no, table#sub-CPMK-table thead tr th.action { width:100px !important; }

	table#correlation thead, table#correlation tbody, table#study-material thead, table#study-material tbody { border:1px solid #bcc1c5; }
   table#correlation tbody tr:last-child, table#correlation tfoot tr:last-child, table#study-material tbody tr:last-child, table#study-material tfoot tr:last-child { border-bottom:1px solid #bcc1c5; }

	.overlay { position:fixed; top:0; left:0; width:100%; height:100%; background-color:rgba(0, 0, 0, 0.5); display:none; align-items:center; justify-content:center; z-index:1000; }
	.loading-spinner { width:50px; height:50px; }
</style>