import axios from 'axios'
import { useAuthStore } from '@/stores/authStore'
import { useAccessStore } from '@/stores/accessStore' 

const request = axios.create({
	baseURL: process.env.VUE_APP_UI_SSO_URL,
	withCredentials: true,
	timeout: 60000,
    headers: {
        'Content-Type': 'application/json'
    }
})

const handleUnauthorizedError = () => {
	const authStore = useAuthStore();
	const accessStore = useAccessStore();
	authStore.clearUserData()
	accessStore.clearUserAccess()
	window.location.href = "/";
};

const setAuthorizationHeader = (config) => {
    const authStore = useAuthStore();
    const token = authStore.user?.token;
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
};


request.interceptors.request.use(
    (config) => {
        return setAuthorizationHeader(config);
    },
    (error) => {
        return Promise.reject(error);
    }
);

request.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401) {
            handleUnauthorizedError();
        }
        return Promise.reject(error);
    }
);


export default request;