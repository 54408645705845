<template>
  <!--begin::App-->
  <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
    <!--begin::Page-->
    <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
      <!--begin::Header-->
      <header-layout />
      <!--end::Header-->

      <!--begin::Wrapper-->
      <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
        <!--begin::sidebar-->
        <sidebar-layout />
        <!--end::sidebar-->

        <!--begin::Main-->
        <div
          class="app-main flex-column flex-row-fluid justify-content-between"
          id="kt_app_main"
          style="min-height: calc(100vh - 60px)"
        >
          <!--begin::Content wrapper-->
          <div class="d-flex flex-column flex-column-fluid main-layout">
            <!--begin::Content-->
            <div id="kt_app_content" class="app-content flex-column-fluid">
              <!--begin::Content container-->
              <div
                id="kt_app_content_container"
                class="app-container container-fluid"
              >
                <router-view />
              </div>
              <!--end::Content container-->
            </div>
            <!--end::Content-->
          </div>
          <!--end::Content wrapper-->

          <!--begin::Footer-->
          <footer-layout />
          <!--end::Footer-->
        </div>
        <!--end:::Main-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Page-->
  </div>
  <!--end::App-->

  <!--begin::Scrolltop-->
  <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
    <!--begin::Svg Icon | path: icons/duotune/arrows/arr066.svg-->
    <span class="svg-icon">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          opacity="0.5"
          x="13"
          y="6"
          width="13"
          height="2"
          rx="1"
          transform="rotate(90 13 6)"
          fill="currentColor"
        />
        <path
          d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
          fill="currentColor"
        />
      </svg>
    </span>
    <!--end::Svg Icon-->
  </div>
  <!--end::Scrolltop-->
</template>

<script>
import HeaderLayout from "./include/HeaderLayout.vue";
import SidebarLayout from "./include/SidebarLayout.vue";
import FooterLayout from "./include/FooterLayout.vue";

export default {
  components: {
    HeaderLayout,
    SidebarLayout,
    FooterLayout,
  },
  beforeUnmount() {
    // eslint-disable-next-line no-undef
    $("select").select2("destroy");
  },
  mounted() {
    // eslint-disable-next-line no-undef
    $("div.app-root")
      .closest("#kt_app_body")
      .addClass("app-default")
      .removeClass("app-blank bgi-size-cover bgi-position-center bgi-no-repeat")
      .css("background-image", "none")
      .find("#app")
      .addClass("d-flex flex-column flex-root app-root")
      .end();
  },
};
</script>
