<template>
	<div>
		<breadcrumb-content menu="Role Management" subMenu="Menu Permission" />
		<div class="card mb-5 mb-xxl-8 placeholder-glow">
			<div class="pb-10">
				<div class="card-body pt-9 pb-0">
					<h1 class="anchor fw-bold mb-5">Detail Menu Permission</h1>
					<div class="p-5">
						<form>
							<LoadingOverlay :loading="loading" />
							<div class="mb-5 col-lg-8">
								<label class="form-label">Nama Role</label>
								<input type="text" class="form-control form-control-solid" v-model="roleName" disabled="disabled" />
							</div>

							<div class="col-md-12">
								<div class="permission-menu-content">
									<table class="table table-permission table-bordered table-striped table-hovered">
										<thead class="thead-light">
											<tr>
												<th class="permission-menu-name">
													<b>Menu Name</b>
												</th>
												<th v-for="permission in permissions" :key="permission.permission_id" class="permission-name">
													<b>{{ permission.permission_name }}</b>
													<br />
													<input
														type="checkbox"
														data-type="parent"
														:data-route="permission?.permission_action"
														:key="permission.id"
														:data-permission-id="permission.permission_id"
														disabled="disabled"
													/>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr v-if="menuLength === 0">
												<td class="text-center" :colspan="permissionCount + 1">
													<b>Role ini tidak mendapatkan akses menu</b>
												</td>
											</tr>
											<template v-else v-for="(menu, menuIndex) in menus" :key="menuIndex">
												<tr>
													<td>
														<div class="text-primary">
															<strong>
																<i v-if="menu.child_menu.length > 0" class="bi bi-folder2-open"></i>
																<i v-else class="bi bi-folder-fill"></i>
																{{ menu.name }}
															</strong>
														</div>
													</td>

													<template
														v-for="(permission, parentPermissionIndex) in permissions"
														:key="parentPermissionIndex"
													>
														<td v-if="menu.route" class="permission-checkbox">
															<!-- checked checkbox permission -->
															<label>
																<input
																	name="permissions"
																	type="checkbox"
																	:checked="isChecked(menu.id, permission.permission_id)"
																	disabled="disabled"
																/>
															</label>
														</td>
														<td v-else></td>
													</template>
												</tr>

												<template v-for="(childMenu, childMenuIndex) in menu.child_menu" :key="childMenuIndex">
													<tr>
														<td>
															<span class="role-menu-child">
																<span class="menu-bullet"><span class="bullet bullet-dot"></span></span>
																{{ childMenu.name }}
															</span>
														</td>
														<template
															v-for="(permission, childPermissionIndex) in permissions"
															:key="childPermissionIndex"
														>
															<td v-if="childMenu.route" class="permission-checkbox">
																<!-- checked checkbox permission -->
																<label>
																	<input
																		name="permissions"
																		type="checkbox"
																		:checked="isChecked(childMenu.id, permission.permission_id)"
																		disabled="disabled"
																	/>
																</label>
															</td>
															<td v-else></td>
														</template>
													</tr>

													<template
														v-for="(grandchildMenu, grandchildMenuIndex) in childMenu.child_menu"
														:key="grandchildMenuIndex"
													>
														<tr>
															<td>
																<span class="role-menu-grandchild">
																	{{ grandchildMenu.name }}
																</span>
															</td>
															<template
																v-for="(permission, grandchildPermissionIndex) in permissions"
																:key="grandchildPermissionIndex"
															>
																<td v-if="grandchildMenu.route" class="permission-checkbox">
																	<!-- checked checkbox permission -->
																	<label>
																		<input
																			name="permissions"
																			type="checkbox"
																			:checked="isChecked(grandchildMenu.id, permission.permission_id)"
																			disabled="disabled"
																		/>
																	</label>
																</td>
																<td v-else></td>
															</template>
														</tr>
													</template>
												</template>
											</template>
										</tbody>
									</table>
								</div>
							</div>
							<div class="mt-10">
								<div class="col-lg-6">
									<button @click="redirectToMenuPermissionList" class="btn btn-secondary">Kembali</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'
import { useAccessStore } from '@/stores/accessStore' 

const router = useRouter()
const menuPermission = ref({})
const checkedPermissions = ref({})
const loading = ref(true)
const redirectToMenuPermissionList = () => router.push({ name: 'MenuPermission' })
const {permissions} = useAccessStore();

const isChecked = (menuId, permissionId) => {
	return checkedPermissions.value[menuId][permissionId]
}

const roleName = computed(() => menuPermission.value?.role_name)
const menus = computed(() => menuPermission.value?.menu)
const menuLength = computed(() => menuPermission.value?.menu?.length)
const permissionCount = computed(() => permissions.length)

onMounted(async () => {
	const roleId = router.currentRoute.value.params.id
	try {
		const { data } = await request(`/menu-permission/${roleId}`)
		menuPermission.value = data
	} catch (error) {
		console.error('Error fetching role menu data:', error)
	} finally {
		loading.value = false
	}
})

watch(menuPermission, newValue => {
	checkedPermissions.value = {}
	const { menu } = newValue

	menu.forEach(item => {
		checkedPermissions.value[item.id] = {} // Initialize as empty object

		permissions.forEach(permission => {
			checkedPermissions.value[item.id][permission.permission_id] = item.permissions.some(
				itemPermission => itemPermission.permission_id === permission.permission_id
			)
		})

		if (item.child_menu.length > 0) {
			item.child_menu.forEach(childItem => {
				checkedPermissions.value[childItem.id] = {} // Initialize as empty object

				permissions.forEach(permission => {
					checkedPermissions.value[childItem.id][permission.permission_id] = childItem.permissions.some(
						childItemPermission => childItemPermission.permission_id === permission.permission_id
					)
				})
			})
		}
	})
})
</script>
<style scoped>
@import '@/assets/css/pages/menu-permission.css';
</style>

