<template>
	<div>
		<breadcrumb-content menu="Role Management" subMenu="Role" />
		<div class="card mb-5 mb-xxl-8 placeholder-glow">
			<div class="pb-10">
				<div class="card-body pt-9 pb-0">
					<h1 class="anchor fw-bold mb-5">Detail Role Menu</h1>
					<div class="p-5">
						<LoadingOverlay :loading="loading" />
						<div class="mb-5 col-lg-8">
							<label class="form-label">Nama Role</label>
							<input type="text" class="form-control form-control-solid" v-model="role.role_name" disabled="disabled" />
						</div>
						<div class="mb-5 col-lg-8">
							<label class="form-label">Deskripsi Role</label>
							<textarea
								class="form-control form-control-solid"
								v-model="role.description"
								rows="3"
								disabled="disabled"
							></textarea>
						</div>

						<div class="container">
							<div class="row">
								<div v-for="menu in role.menu" :key="menu.menu_id" class="col-lg-3 col-md-6 col-sm-12">
									<div class="border rounded mb-3">
										<div class="text-center pb-4 border-bottom py-4">
											<i :class="[menu.icon, 'me-2', 'ms-2', 'text-primary']"></i>
											<span class="text-capitalize text-primary">{{ menu.name }}</span>
										</div>
										<div>
											<div v-if="menu.child_menu.length > 0">
												<div
													v-for="childMenu in menu.child_menu"
													:key="childMenu.menu_id"
													class="p-3 d-flex justify-content-between"
												>
													<div v-if="childMenu.icon">
														<i :class="childMenu.icon + ' me-2 ms-2 text-primary'"></i>
													</div>
													<div class="d-flex fw-semibold align-items-center">
														<div class="bullet w-8px h-3px rounded-2 me-3" style="background-color: #e4e6ef"></div>
														<div class="text-gray-500 flex-grow-1 me-4">{{ childMenu.name }}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="mt-10">
							<div class="col-lg-6">
								<button @click="redirectToRoleMenuList" class="btn btn-secondary">Kembali</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'

const router = useRouter()
const role = ref({})
const loading = ref(true)
const redirectToRoleMenuList = () => router.push({ name: 'RoleMenu' })

onMounted(async () => {
	const roleId = router.currentRoute.value.params.id
	try {
		const { data } = await request(`/role-menu/${roleId}`)
		role.value = data
	} catch (error) {
		console.error('Error fetching role menu data:', error)
	} finally {
		loading.value = false
	}
})
</script>
