<template>
   <breadcrumb-content menu="Profil" subMenu="Jenjang" />
   <div class="card mb-5 mb-xxl-8">
      <div class="pb-10">
         <div class="card-body pt-9 pb-0">
            <h1 class="anchor fw-bold mb-5">Tambah Jenjang</h1>
            <div class="p-5">
               <Form id="form-jenjang-add" class="kt-form" method="post" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
                  <LoadingOverlay :loading="loading" />
                  <div class="row mb-5">
                     <label class="form-label"><span style="color: red">*</span> Nama Jenjang</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <Field type="text" name="nama_ind" class="form-control form-control-solid-bg" :class="{ 'is-invalid': errors.nama_ind }" placeholder="Masukkan Nama Jenjang" />
                        <div id="nama_ind-error" class="invalid-feedback">{{ errors.nama_ind }}</div>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <Field type="text" name="nama_eng" class="form-control form-control-solid-bg" :class="{ 'is-invalid': errors.nama_eng }" placeholder="Input Grade Name" />
                        <div id="nama_eng-error" class="invalid-feedback">{{ errors.nama_eng }}</div>
                     </div>
                  </div>
                  <div class="mt-10">
                     <div class="col-lg-6">
                        <button type="submit" class="btn btn-primary">Simpan</button> &nbsp; <button @click="redirectToJenjangList" class="btn btn-secondary">Kembali</button>
                     </div>
                  </div>
               </Form>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup>
   import { ref } from 'vue';
   import { useRouter } from 'vue-router';
   import { Form, Field } from 'vee-validate';
   import LoadingOverlay from '@/components/LoadingOverlay';
   import { sendRequest } from '@/api/common';

	const schema = { nama_ind: 'required|max:200', nama_eng: 'required|max:200' };
	const loading = ref(false);
	const router = useRouter();
	const redirectToJenjangList = () => router.push({ name: 'Jenjang' });
	const onSubmit = (async (values) => await sendRequest({ url: '/jenjang', method: 'POST', body: values, loading: loading, context: 'Jenjang' }) );
</script>