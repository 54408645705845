import { defineRule, configure } from 'vee-validate'
import { required, email, min, max } from '@vee-validate/rules'
import { localize, setLocale } from '@vee-validate/i18n'
import id from '@vee-validate/i18n/dist/locale/id.json'
import en from '@vee-validate/i18n/dist/locale/en.json'

/**  add rules if needed https://vee-validate.logaretm.com/v4/guide/global-validators#vee-validaterules **/
export function initRule() {
	defineRule('required', required)
	defineRule('email', email)
	defineRule('min', min)
	defineRule('max', max)

	// Set locale
	localize({ id })
	setLocale('id')

	configure({
		// Generates an indonesian message locale generator
		generateMessage: localize({
			id: {
				messages: id.messages
			},
			en: {
				messages: en.messages
			}
		})
	})
}
