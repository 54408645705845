<template>
	<div>
		<breadcrumb-content menu="Master Data" subMenu="Tinjauan Pustaka" />
		<div class="card mb-5 mb-xxl-8 placeholder-glow">
			<div class="pb-10">
				<div class="card-body pt-9 pb-0">
					<h1 class="anchor fw-bold mb-5">Detail Tinjauan Pustaka</h1>
					<div class="p-5">
						<LoadingOverlay :loading="loading" />

						<FormInput
							labelInd="Kategori Tinjauan Pustaka"
							customName="kat_tinjauan_pustaka_id_fk"
							:valueInd="dataTP.kat_tinjauan_pustaka ? dataTP.kat_tinjauan_pustaka.nama_ind : ''"
							:valueEng="dataTP.kat_tinjauan_pustaka ? dataTP.kat_tinjauan_pustaka.nama_eng : ''"
							mode="show"
						/>
						<FormInput
							labelInd="Judul"
							columnName="judul"
							:valueInd="dataTP.judul_ind"
							:valueEng="dataTP.judul_eng"
							mode="show"
						/>
						<FormInput
							labelInd="Penerbit"
							columnName="penerbit"
							:valueInd="dataTP.penerbit_ind"
							:valueEng="dataTP.penerbit_eng"
							mode="show"
						/>
						<FormInput
							labelInd="Penulis"
							columnName="penulis"
							:valueInd="dataTP.penulis_ind"
							:valueEng="dataTP.penulis_eng"
							mode="show"
						/>
						<FormInput
							inputType="number"
							labelInd="Tahun Terbit"
							customName="tahun_terbit"
							:valueInd="dataTP.tahun_terbit"
							:isEnglish="false"
							:isRequired="false"
							mode="show"
						/>
						<FormInput
							inputType="textarea"
							rows="3"
							labelInd="Deskripsi"
							columnName="deskripsi"
							:valueInd="dataTP.deskripsi_ind ? dataTP.deskripsi_ind : '-'"
							:valueEng="dataTP.deskripsi_eng ? dataTP.deskripsi_eng : '-'"
                            :isRequired="false"
							mode="show"
						/>

						<FormInput
							labelInd="Dibuat pada"
							columnName="created"
							:isEnglish="false"
							:valueInd="formattedCreatedAt ? formattedCreatedAt : '-'"
							:isRequired="false"
							mode="show"
						/>
						<FormInput
							labelInd="Diubah pada"
							columnName="updated"
							:isEnglish="false"
							:valueInd="formattedUpdatedAt ? formattedUpdatedAt : '-'"
							:isRequired="false"
							mode="show"
						/>

						<div class="mt-10">
							<button @click="redirectToDataList" class="btn btn-secondary">Kembali</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script setup>
import { onMounted, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'

import FormInput from '@/components/FormInput.vue'

const loading = ref(true)
const router = useRouter()
const dataTP = ref({})
const tinjauan_pustaka_id = router.currentRoute.value.params.id

import { formatReadableDate } from '@/utils/date'
const formattedCreatedAt = computed(() => formatReadableDate(dataTP.value.created_at))
const formattedUpdatedAt = computed(() => formatReadableDate(dataTP.value.updated_at))

const redirectToDataList = () => router.push({ name: 'TinjauanPustaka' })

onMounted(async () => {
	try {
		const { data } = await request(`/tinjauan-pustaka/${tinjauan_pustaka_id}`)
		dataTP.value = data
	} catch (error) {
		console.error('Error fetching data:', error)
	} finally {
		loading.value = false
	}
})
</script>