<template>
	<div class="menu-item">
		<!--begin:Menu link-->
		<router-link v-if="hasRouteName" class="menu-link" :class="{ active: isActive() }" :to="{ name: link }">
			<span class="menu-bullet">
				<span class="bullet bullet-dot"></span>
			</span>
			<span class="menu-title"><slot /></span>
		</router-link>
		<a v-else class="menu-link" :class="{ active: isActive() }" href="javascript:void(0);">
			<span class="menu-bullet">
				<span class="bullet bullet-dot"></span>
			</span>
			<span class="menu-title"><slot /></span>
		</a>
		<!--end:Menu link-->
	</div>
</template>

<script>
export default {
	props: {
		link: {
			type: [String, Object],
			default: null
		},
		permissions: {
			type: Array,
			required: true
		}
	},
	computed: {
		hasRouteName() {
			return typeof this.link === 'string' && this.$router.hasRoute(this.link)
		}
	},
	methods: {
		isActive() {
			const permissionActions = this.permissions.map(permission => permission.permission_action)
			let routeName = this.$route.name

			for (const permission of permissionActions) {
				if (routeName.includes(permission)) {
					routeName = routeName.replace(permission, '')
				}
			}
			return routeName === this.link
		}
	}
}
</script>
