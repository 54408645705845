<template>
	<div>
		<breadcrumb-content menu="Pembuatan Kurikulum" subMenu="Tujuan Program Pendidikan" />
		<div class="card mb-5 mb-xxl-8 placeholder-glow">
			<div class="pb-10">
				<div class="card-body pt-9 pb-0">
					<h1 class="anchor fw-bold mb-5">Detail Tujuan Program Pendidikan</h1>
					<div class="p-5">
						<LoadingOverlay :loading="loading" />

                        <FormInput
							labelInd="Rumpun Ilmu"
							columnName="ri_nama_ind"
							:valueInd="dataKurikulum.ri_nama_ind"
							:valueEng="dataKurikulum.ri_nama_eng"
							:isRequired="false"
							mode="show"
						/>
						<FormInput
							labelInd="Fakultas"
							columnName="fakultas_nama_ind"
							:valueInd="dataKurikulum.fakultas_nama_ind"
							:valueEng="dataKurikulum.fakultas_nama_eng"
							:isRequired="false"
							mode="show"
						/>
						<FormInput
							labelInd="Departemen"
							columnName="departemen_nama_ind"
							:valueInd="dataKurikulum.departemen_nama_ind"
							:valueEng="dataKurikulum.departemen_nama_eng"
							:isRequired="false"
							mode="show"
						/>
						<FormInput
							labelInd="Program Studi"
							columnName="prodi_nama_ind"
							:valueInd="dataKurikulum.prodi_nama_ind"
							:valueEng="dataKurikulum.prodi_nama_eng"
							:isRequired="false"
							mode="show"
						/>
						<FormInput
							labelInd="Jenjang"
							columnName="jenjang_nama_ind"
							:valueInd="dataKurikulum.jenjang_nama_ind"
							:valueEng="dataKurikulum.jenjang_nama_eng"
							:isRequired="false"
							mode="show"
						/>
                        <div class="row mb-5">
                            <label class="form-label">
								<span style="color: red">*</span>
                                Kurikulum
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <select id="kurikulum_id" name="kurikulum_id" class="form-control form-control-solid-bg kurikulum" data-control="select2" data-placeholder="-- Pilih Kurikulum --" v-model="dataKurikulum.kurikulum_id" disabled>
                                    <option></option>
                                    <option v-for="data in kurikulum_list" :key="data.kurikulum_id" :value="data.kurikulum_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind" :data-kurikulum_kd="data.kurikulum_kd" :data-is_aktif="data.is_aktif === 1 ? 'Aktif' : 'Tidak Aktif'" :selected="kurikulumKD === data.kurikulum_kd"> {{ data.nama_ind }} </option>
                                </select>
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <div class="form-control form-control-solid" v-if="getCurriculumEng" v-html="getCurriculumEng"></div>
                            </div>
                        </div>

                        <hr>

                        <h3>Tujuan Program Studi</h3>
                        <p v-html="dataProdi.tujuan_ind" style="text-align: justify; font-weight: 500;"></p>

						<hr>

                        <FormInput
							labelInd="Nama TPP"
							columnName="nama_ind"
							:valueInd="dataTPP.nama_ind"
							:valueEng="dataTPP.nama_eng"
							:isRequired="false"
							mode="show"
						/>
                        <FormInput
							inputType="textarea"
							rows="5"
							labelInd="Deskripsi"
							columnName="deskripsi"
							:valueInd="dataTPP.deskripsi_ind ? dataTPP.deskripsi_ind : '-'"
							:valueEng="dataTPP.deskripsi_eng ? dataTPP.deskripsi_eng : '-'"
							:isRequired="false"
							mode="show"
						/>

						<FormInput
							labelInd="Dibuat pada"
							columnName="created"
							:isEnglish="false"
							:valueInd="formattedCreatedAt ? formattedCreatedAt : '-'"
							:isRequired="false"
							mode="show"
						/>
						<FormInput
							labelInd="Diubah pada"
							columnName="updated"
							:isEnglish="false"
							:valueInd="formattedUpdatedAt ? formattedUpdatedAt : '-'"
							:isRequired="false"
							mode="show"
						/>

						<div class="mt-10">
							<button @click="redirectToDataList" class="btn btn-secondary">Kembali</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script setup>
import { onMounted, ref, computed, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'

import FormInput from '@/components/FormInput.vue'

const loading = ref(true)
const router = useRouter()
const tpp_id = router.currentRoute.value.params.id

import { formatReadableDate } from '@/utils/date'
const formattedCreatedAt = computed(() => formatReadableDate(dataTPP.value.created_at))
const formattedUpdatedAt = computed(() => formatReadableDate(dataTPP.value.updated_at))

const redirectToDataList = () => router.push({ name: 'TPP' })

const dataTPP = ref({})
const dataKurikulum = ref({})
const dataProdi = ref({})

const kurikulum_list = ref([])
const kurikulumKD = ref('')
const renderCustomOption = () => {
    const customSelect = (ress) => {
        if (!ress.id) return ress.text;

        // eslint-disable-next-line no-undef
        return $(`<label style="display:flex; flex-direction:column; cursor:pointer;"><span style="font-size:10px;"><b>${ $(ress.element).data('kurikulum_kd') } (${ $(ress.element).data('is_aktif') })</b></span><span style="font-size:14px;">${ $(ress.element).data('nama_ind') }</span></label>`);
    };
    // eslint-disable-next-line no-undef
    $('select.kurikulum').select2({ templateResult: customSelect, templateSelection: customSelect });
}
const getCurriculumEng = computed(() => {
    const selectedData = kurikulum_list.value.find(data => data.kurikulum_kd === kurikulumKD.value);
    if (selectedData) {
        const { kurikulum_kd, is_aktif, nama_eng } = selectedData;
        return `<label style="display:flex; flex-direction:column; cursor:pointer;">
                <span style="font-size:10px;"><b>${kurikulum_kd} (${is_aktif === 1 ? 'Active' : 'Not Active'})</b></span>
                <span style="font-size:14px;">${nama_eng}</span>
                </label>`;
    }
    return '';
})

onMounted(async () => {
	try {
        const response = await request('/kurikulum')
        kurikulum_list.value = response.data.data
        
		const { data } = await request(`/tpp/${tpp_id}`)
		dataTPP.value = data
        
        const getDataKurikulum = await request(`/kurikulum/${data.kurikulum_id_fk}`)
        dataKurikulum.value = getDataKurikulum.data
        kurikulumKD.value = getDataKurikulum.data.kurikulum_kd

        const getDataProdi = await request(`/prodi/${dataKurikulum.value.prodi_id_fk}`)
		dataProdi.value = getDataProdi.data

        await nextTick(() => { initializeSelect2(); });
        renderCustomOption();
	} catch (error) {
		console.error('Error fetching data:', error)
	} finally {
		loading.value = false
	}
})

// eslint-disable-next-line no-undef
const initializeSelect2 = () => { $("select").select2(); };
</script>