<template>
   <breadcrumb-content menu="Pembuatan Kurikulum" subMenu="Matakuliah Kurikulum" />
	<div class="card mb-5 mb-xxl-8">
		<div class="pb-10">
			<div class="card-body pt-9 pb-0">
				<h1 class="anchor fw-bold mb-5">Detail Matakuliah Kurikulum</h1>
				<div class="p-5">
					<div class="row mb-5">
						<label class="form-label">Perguruan Tinggi</label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<input type="text" class="form-control form-control-solid-bg bg-light-dark" value="Universitas Indonesia" readonly />
						</div>
						<div class="col-6">
							<flag-image bahasa="Inggris" />
							<input type="text" class="form-control form-control-solid-bg bg-light-dark" value="University of Indonesia" readonly />
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Rumpun Ilmu</label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<input type="text" class="form-control form-control-solid-bg bg-light-dark" v-model="curriculumDetail.ri_nama_ind" readonly />
						</div>
						<div class="col-6">
							<flag-image bahasa="Inggris" />
							<input type="text" class="form-control form-control-solid-bg bg-light-dark" v-model="curriculumDetail.ri_nama_ind" readonly />
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Fakultas</label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<input type="text" class="form-control form-control-solid-bg bg-light-dark" v-model="curriculumDetail.fakultas_nama_ind" readonly />
						</div>
						<div class="col-6">
							<flag-image bahasa="Inggris" />
							<input type="text" class="form-control form-control-solid-bg bg-light-dark" v-model="curriculumDetail.fakultas_nama_ind" readonly />
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Departemen</label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<input type="text" class="form-control form-control-solid-bg bg-light-dark" v-model="curriculumDetail.departemen_nama_ind" readonly />
						</div>
						<div class="col-6">
							<flag-image bahasa="Inggris" />
							<input type="text" class="form-control form-control-solid-bg bg-light-dark" v-model="curriculumDetail.departemen_nama_ind" readonly />
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Program Studi</label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<input type="text" class="form-control form-control-solid-bg bg-light-dark" v-model="curriculumDetail.prodi_nama_ind" readonly />
						</div>
						<div class="col-6">
							<flag-image bahasa="Inggris" />
							<input type="text" class="form-control form-control-solid-bg bg-light-dark" v-model="curriculumDetail.prodi_nama_eng" readonly />
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Jenjang</label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<input type="text" class="form-control form-control-solid-bg bg-light-dark" v-model="curriculumDetail.jenjang_nama_ind" readonly />
						</div>
						<div class="col-6">
							<flag-image bahasa="Inggris" />
							<input type="text" class="form-control form-control-solid-bg bg-light-dark" v-model="curriculumDetail.jenjang_nama_eng" readonly />
						</div>
					</div>
					<div class="row mb-5">
						<label class="form-label">Kurikulum</label>
						<div class="col-6">
							<flag-image bahasa="Indonesia" />
							<div style="display:flex; flex-direction:column; justify-content:center; background-color:#f5f8fa; border:1px solid #e4e6ef; border-radius:.475rem; height:60px; padding:.775rem 1rem;">
								<span style="font-size:10px; font-weight:500;">{{ curriculumDetail.kurikulum_kd }}</span>
								<span style="font-size:14px; font-weight:500;">{{ curriculumDetail.nama_ind }}</span>
							</div>
						</div>
						<div class="col-6">
							<flag-image bahasa="Inggris" />
							<div style="display:flex; flex-direction:column; justify-content:center; background-color:#f5f8fa; border:1px solid #e4e6ef; border-radius:.475rem; height:60px; padding:.775rem 1rem;">
								<span style="font-size:10px; font-weight:500;">{{ curriculumDetail.kurikulum_kd }}</span>
								<span style="font-size:14px; font-weight:500;">{{ curriculumDetail.nama_eng }}</span>
							</div>
						</div>
					</div>
					<hr />
					<h2 style="margin-bottom:1rem;">Daftar Matakuliah:</h2>
					<div v-if="ListMKKTable">
						<component :is="ListMKKTable" :curriculumCode="curriculumDetail.kurikulum_kd" />
					</div>
					<div class="mt-10">
						<div class="col-lg-6">
							<button @click="redirectToIdentitasKurikulumList" class="btn btn-secondary">Kembali</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { onMounted, shallowRef, ref } from 'vue';
   import { useRouter } from 'vue-router';
	import request from '@/utils/request';

	const loading = ref(false);
   const router = useRouter();
   const id = router.currentRoute.value.params.id;
	const curriculumDetail = ref({});
	let ListMKKTable = shallowRef(null);
	const redirectToIdentitasKurikulumList = () => router.push({ name: 'MatakuliahKurikulum' });

	onMounted(async () => {
		try {
         curriculumDetail.value = (await request({ url:`/kurikulum/${ id }` })).data;
			if (Object.keys(curriculumDetail.value).length > 0) {
				ListMKKTable.value = null;
				import(`./ListMKKTable.vue`).then(module => {
					ListMKKTable.value = module.default;
				}).then(() => {
					// eslint-disable-next-line no-undef
					$(`#table`).slideDown(1000);
				});
			}
		} catch (error) {
			console.error('Error fetching data: ', error);
		} finally {
			loading.value = false;
		}
	});
</script>