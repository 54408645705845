<template>
	<breadcrumb-content menu="Role Management" subMenu="Role Menu" />
	<div class="card mb-5 mb-xxl-8">
		<div class="card-body pt-9 pb-0">
			<LoadingOverlay :loading="loading" />

			<SSDatatable
				ref="datatableRef"
				url="/role-menu-datatable"
				primaryKey="role_id"
				:columns="columns"
				:show="handleShow"
				:edit="handleEdit"
			/>
		</div>
	</div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import LoadingOverlay from '@/components/LoadingOverlay'
import SSDatatable from '@/components/SSDatatable.vue'

const datatableRef = ref(null)
const loading = ref(false)
const router = useRouter()
const columns = [
	{
		title: 'No',
		data: 'rownum',
		searchable: false,
		width: '8%',
		className: 'px-1'
	},
	{ title: 'Nama', data: 'role_name' },
	{ title: 'Menu', data: 'menu_access', render: (data, type, row) => renderMenu(data, type, row) },
	{ title: 'Aksi', data: 'actions', searchable: false }
]

function renderMenu(data, type, row) {
	if (type === 'display' && row) {
		const parentMenu = data.filter(item => !item.startsWith('#')) // Filter out items starting with '#'
		const displayedItems = parentMenu.slice(0, 5) // Get only the first 5 valid items
		let menuHTML = displayedItems
			.map(item => {
				const [icon, label] = item.split(' - ')
				return `
                <span class="badge badge-light-primary m-1 text-capitalize">
                    <i class="${icon} me-1 text-primary"></i> ${label}
                </span>
            `
			})
			.join('')

		let additionalItemsCount = parentMenu.length - displayedItems.length
		if (additionalItemsCount > 0) {
			menuHTML += `<span class="badge badge-light-dark m-1 text-capitalize"> dan ${additionalItemsCount} lainnya... </span>`
		}

		return menuHTML
	}
	return ''
}

const handleShow = async id => router.push({ name: 'ShowViewRoleMenu', params: { id: id } })
const handleEdit = async id => router.push({ name: 'EditViewRoleMenu', params: { id: id } })
</script>
