<template>
   <breadcrumb-content menu="Profil" subMenu="Departemen" />
   <div class="card mb-5 mb-xxl-8">
      <div class="pb-10">
         <div class="card-body pt-9 pb-0">
            <h1 class="anchor fw-bold mb-5">Detail Departemen</h1>
            <div class="p-5">
               <div class="row mb-5">
                  <label class="form-label">Perguruan Tinggi</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input class="form-control form-control-solid-bg" v-model="university.nama_ind" disabled="disabled" />
                  </div>
                  <div class="col-6">
                     <flag-image bahasa="Inggris" />
                     <input class="form-control form-control-solid-bg" v-model="university.nama_eng" disabled="disabled" />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Rumpun Ilmu</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input class="form-control form-control-solid-bg" v-model="group.nama_ind" disabled="disabled" />
                  </div>
                  <div class="col-6">
                     <flag-image bahasa="Inggris" />
                     <input class="form-control form-control-solid-bg" v-model="group.nama_eng" disabled="disabled" />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Fakultas</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input class="form-control form-control-solid-bg" v-model="faculty.nama_ind" disabled="disabled" />
                  </div>
                  <div class="col-6">
                     <flag-image bahasa="Inggris" />
                     <input class="form-control form-control-solid-bg" v-model="faculty.nama_eng" disabled="disabled" />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Kode Departemen</label>
                  <div class="col-6">
                     <input class="form-control form-control-solid-bg" v-model="department.departemen_kd" disabled="disabled" />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Nama Departemen</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input class="form-control form-control-solid-bg" v-model="department.nama_ind" disabled="disabled" />
                  </div>
                  <div class="col-6">
                     <flag-image bahasa="Inggris" />
                     <input class="form-control form-control-solid-bg" v-model="department.nama_ind" disabled="disabled" />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Deskripsi Departemen</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <textarea class="form-control form-control-solid-bg" rows="3" v-model="department.deskripsi_ind" disabled="disabled"></textarea>
                  </div>
                  <div class="col-6">
                     <flag-image bahasa="Inggris" />
                     <textarea class="form-control form-control-solid-bg" rows="3" v-model="department.deskripsi_eng" disabled="disabled"></textarea>
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Visi Departemen</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <textarea class="form-control form-control-solid-bg" rows="3" v-model="department.visi_ind" disabled="disabled"></textarea>
                  </div>
                  <div class="col-6">
                     <flag-image bahasa="Inggris" />
                     <textarea class="form-control form-control-solid-bg" rows="3" v-model="department.visi_eng" disabled="disabled"></textarea>
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Misi Departemen</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <textarea class="form-control form-control-solid-bg" rows="3" v-model="department.misi_ind" disabled="disabled"></textarea>
                  </div>
                  <div class="col-6">
                     <flag-image bahasa="Inggris" />
                     <textarea class="form-control form-control-solid-bg" rows="3" v-model="department.misi_eng" disabled="disabled"></textarea>
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Dibuat pada</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input class="form-control form-control-solid-bg" :value="formatReadableDate(faculty.created_at)" disabled="disabled" />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Diubah pada</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input class="form-control form-control-solid-bg" :value="formatReadableDate(faculty.updated_at)" disabled="disabled" />
                  </div>
               </div>
               <div class="mt-10">
                  <div class="col-lg-6">
                     <button @click="redirectToDepartemenList" class="btn btn-secondary">Kembali</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup>
   import { onMounted, ref } from 'vue';
   import { useRouter } from 'vue-router';
   import { formatReadableDate } from '@/utils/date';
   import request from '@/utils/request';

   const router = useRouter();
   const loading = ref(true);
   const id = router.currentRoute.value.params.id;
   const department = ref({});
   const faculty = ref({});
   const group = ref({});
   const university = ref({});
   const redirectToDepartemenList = () => router.push({ name: 'Departemen' });

   onMounted(async () => {
      try {
         university.value = (await request(`/pt`)).data;
         department.value = (await request(`/departemen/${ id }`)).data;
         faculty.value = (await request(`/fakultas/${ department.value.fakultas_id_fk }`)).data;
         group.value = (await request(`/ri/${ faculty.value.ri_id_fk }`)).data;
      } catch (error) {
         console.error('Error fetching data: ', error);
      } finally {
         loading.value = false;
      }
   });
</script>