<template>
   <breadcrumb-content menu="Profil" subMenu="Program Studi"/>
      <div class="card mb-5 mb-xxl-8">
      <div class="pb-10">
         <div class="card-body pt-9 pb-0">
            <h1 class="anchor fw-bold mb-5">Detail Program Studi</h1>
            <div class="p-5">
               <LoadingOverlay :loading="loading" />
               <div class="row mb-5">
                  <label class="form-label">Perguruan Tinggi</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input class="form-control form-control-solid-bg bg-light-dark" v-model="university.nama_ind" readonly />
                  </div>
                  <div class="col-6">
                     <flag-image bahasa="Inggris" />
                     <input class="form-control form-control-solid-bg bg-light-dark" v-model="university.nama_eng" readonly />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Rumpun Ilmu</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input class="form-control form-control-solid-bg bg-light-dark" v-model="group.nama_ind" readonly />
                  </div>
                  <div class="col-6">
                     <flag-image bahasa="Inggris" />
                     <input class="form-control form-control-solid-bg bg-light-dark" v-model="group.nama_eng" readonly />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Fakultas</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input class="form-control form-control-solid-bg bg-light-dark" v-model="faculty.nama_ind" readonly />
                  </div>
                  <div class="col-6">
                     <flag-image bahasa="Inggris" />
                     <input class="form-control form-control-solid-bg bg-light-dark" v-model="faculty.nama_eng" readonly />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Departemen</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input class="form-control form-control-solid-bg bg-light-dark" v-model="department.nama_ind" readonly />
                  </div>
                  <div class="col-6">
                     <flag-image bahasa="Inggris" />
                     <input class="form-control form-control-solid-bg bg-light-dark" v-model="department.nama_eng" readonly />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Jenjang</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input class="form-control form-control-solid-bg bg-light-dark" v-model="grade.nama_ind" readonly />
                  </div>
                  <div class="col-6">
                     <flag-image bahasa="Inggris" />
                     <input class="form-control form-control-solid-bg bg-light-dark" v-model="grade.nama_eng" readonly />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Kode Program Studi</label>
                  <div class="col-6">
                     <input class="form-control form-control-solid-bg bg-light-dark" v-model="program.prodi_kd" readonly />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Nama Program Studi</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input class="form-control form-control-solid-bg bg-light-dark" v-model="program.nama_ind" readonly />
                  </div>
                  <div class="col-6">
                     <flag-image bahasa="Inggris" />
                     <input class="form-control form-control-solid-bg bg-light-dark" v-model="program.nama_eng" readonly />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Deskripsi Program Studi</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <textarea class="form-control form-control-solid-bg bg-light-dark" rows="3" v-model="program.deskripsi_ind" readonly></textarea>
                  </div>
                  <div class="col-6">
                     <flag-image bahasa="Inggris" />
                     <textarea class="form-control form-control-solid-bg bg-light-dark" rows="3" v-model="program.deskripsi_eng" readonly></textarea>
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Visi Program Studi</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input type="text" ref="editor1" disabled />
                  </div>
                  <div class="col-6">
                     <flag-image bahasa="Inggris" />
                     <input type="text" ref="editor2" disabled />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Misi Program Studi</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input type="text" ref="editor3" disabled />
                  </div>
                  <div class="col-6">
                     <flag-image bahasa="Inggris" />
                     <input type="text" ref="editor4" disabled />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Tujuan Program Studi</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input type="text" ref="editor5" disabled />
                  </div>
                  <div class="col-6">
                     <flag-image bahasa="Inggris" />
                     <input type="text" ref="editor6" disabled />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Strategi Program Studi</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input type="text" ref="editor7" disabled />
                  </div>
                  <div class="col-6">
                     <flag-image bahasa="Inggris" />
                     <input type="text" ref="editor8" disabled />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Nilai Universitas</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input type="text" ref="editor9" disabled />
                  </div>
                  <div class="col-6">
                     <flag-image bahasa="Inggris" />
                     <input type="text" ref="editor10" disabled />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Jumlah Mahasiswa</label>
                  <div class="col-6">
                     <input class="form-control form-control-solid-bg bg-light-dark" v-model="program.jml_mahasiswa" readonly />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Jumlah Dosen</label>
                  <div class="col-6">
                     <input class="form-control form-control-solid-bg bg-light-dark" v-model="program.jml_dosen" readonly />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Alamat Program Studi</label>
                  <div class="col-6">
                     <input class="form-control form-control-solid-bg bg-light-dark" v-model="program.alamat" readonly />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Telepon</label>
                  <div class="col-6">
                     <input class="form-control form-control-solid-bg bg-light-dark" v-model="program.telp" readonly />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Website Prodi</label>
                  <div class="col-6">
                     <input class="form-control form-control-solid-bg bg-light-dark" v-model="program.website" readonly />
                  </div>
               </div>
               <div class="akreditasi-nas">
                  <hr />
                  <div class="d-flex justify-content-between align-items-center">
                     <h3>Data Akreditasi Nasional</h3>
                  </div>
                  <div class="table-responsive mt-3">
                     <TableAccreditation :nationalAccreditationData="nationalAccreditation.datatable" text="nationalAccreditationData" :prodiID="program.prodi_id" :disabled="true" />
                  </div>
               </div>
               <div class="akreditasi-int">
                  <hr />
                  <div class="d-flex justify-content-between align-items-center">
                     <h3>Data Akreditasi Internasional</h3>
                  </div>
                  <div class="table-responsive mt-3">
                     <TableAccreditation :internationalAccreditationData="internationalAccreditation.datatable" text="internationalAccreditationData" :prodiID="program.prodi_id" :disabled="true" />
                  </div>
               </div>
               <div class="mt-10">
                  <div class="col-lg-6">
                     <button @click="redirectToProgramStudiList" class="btn btn-secondary">Kembali</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup>
   import { onMounted, ref } from 'vue';
   import { useRouter } from 'vue-router';
   import LoadingOverlay from '@/components/LoadingOverlay';
   import request from '@/utils/request';
   import TableAccreditation from './TableAccreditation.vue';
   import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

   const loading = ref(false);
   const router = useRouter();
   const id = router.currentRoute.value.params.id;
   const program = ref({});
   const department = ref({});
   const faculty = ref({});
   const group = ref({});
   const university = ref({});
   const grade = ref({});
   const editor1 = ref(null);
   const editor2 = ref(null);
   const editor3 = ref(null);
   const editor4 = ref(null);
   const editor5 = ref(null);
   const editor6 = ref(null);
   const editor7 = ref(null);
   const editor8 = ref(null);
   const editor9 = ref(null);
   const editor10 = ref(null);
   const nationalAccreditation = ref({ 'datatable': [] });
   const internationalAccreditation = ref({ 'datatable': [] });
   const redirectToProgramStudiList = () => router.push({ name: 'ProgramStudi' });

   onMounted(async () => {
      try {
         program.value = (await request({ url: `/prodi/${ id }` })).data;
         university.value = (await request({ url: `/pt` })).data;
         grade.value = (await request({ url: `/jenjang/${ program.value.jenjang_id_fk }` })).data;
         department.value = (await request({ url: `/departemen/${ program.value.departemen_id_fk }` })).data;
         faculty.value = (await request({ url: `/fakultas/${ department.value.fakultas_id_fk }` })).data;
         group.value = (await request({ url: `/ri/${ faculty.value.ri_id_fk }`})).data;

         (await ClassicEditor.create(editor1.value)).setData(program.value.visi_ind);
         (await ClassicEditor.create(editor2.value)).setData(program.value.visi_eng);
         (await ClassicEditor.create(editor3.value)).setData(program.value.misi_ind);
         (await ClassicEditor.create(editor4.value)).setData(program.value.misi_eng);
         (await ClassicEditor.create(editor5.value)).setData(program.value.tujuan_ind);
         (await ClassicEditor.create(editor6.value)).setData(program.value.tujuan_eng);
         (await ClassicEditor.create(editor7.value)).setData(program.value.strategi_ind);
         (await ClassicEditor.create(editor8.value)).setData(program.value.strategi_eng);
         (await ClassicEditor.create(editor9.value)).setData(program.value.university_value_ind);
         (await ClassicEditor.create(editor10.value)).setData(program.value.university_value_eng);

         (program.value.akreditasi).map((v) => {
            if ((v.lmbg_nas_id_fk > 0) && (v.lmbg_int_id_fk == 0)) {
               nationalAccreditation.value.datatable.push({ id: v.akreditasi_id, newLevelName: v.peringkat_nama_ind, newCode: v.no_akreditasi, newInstitutionName: v.lmbg_nas_nama_ind, newStatus: v.is_aktif, newStatusName: ((v.is_aktif) ? ('Aktif') : ('Tidak Aktif')) });
            }
            else {
               internationalAccreditation.value.datatable.push({ id: v.akreditasi_id, newLevelName: v.peringkat_nama_ind, newCode: v.no_akreditasi, newInstitutionName: v.lmbg_int_nama_ind, newStatus: v.is_aktif, newStatusName: ((v.is_aktif) ? ('Aktif') : ('Tidak Aktif')) });
            }
         });
      } catch (error) {
         console.error('Error fetching data: ', error);
      } finally {
         loading.value = false;
      }
   });
</script>