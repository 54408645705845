import axios from "axios";
import { useAuthStore } from '@/stores/authStore' 
import { useAccessStore } from '@/stores/accessStore' 

// create an axios instance
const request = axios.create({
	baseURL: process.env.VUE_APP_BASE_API,
	withCredentials: true, // send cookies when cross-domain requests
	timeout: 60000, // 1 minuterequest timeout
});

const UNAUTHORIZED = 401;
const handleUnauthorizedError = () => {
	const authStore = useAuthStore();
	const accessStore = useAccessStore();
	authStore.clearUserData()
	accessStore.clearUserAccess()
	window.location.href = "/";
};

// request interceptor
request.interceptors.request.use(
	(config) => {
		// check url request contain api version other than v1
		let apiVersion = "v1";
		let hasApiVersion = config.url.match(/\/v([0-9]+)\//); // url request has api version
		if (hasApiVersion) {
			apiVersion = "v" + hasApiVersion[1];
		}
		const URL = config.baseURL + "/api/" + apiVersion + config.url;
		config.url = URL;

		// check if token exist
		const authStore = useAuthStore();
		const token = authStore.user?.token;
		if (token) {
			// let each request carry token
			config.headers.Authorization = `Bearer ${token}`;
		}

		return config;
	},
	(error) => {
		// do something with request error
		console.log(error); // for debug
		return Promise.reject(error);
	}
);

// response interceptor
request.interceptors.response.use(
	(response) => {
		const res = response.data;

		if (response.status === UNAUTHORIZED) handleUnauthorizedError();

		// if the custom code is not 200, it is judged as an error.
		if (res.code !== 200) {
			// default error response

			return Promise.reject(new Error(res.message || "Error"));
		} else {
			return res;
		}
	},
	(error) => {
		if (error.response.status === UNAUTHORIZED) handleUnauthorizedError();

		return Promise.reject(error);
	}
);

request.defaults.headers.post["Content-Type"] = "application/json";

export default request;
