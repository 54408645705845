<template>
    <breadcrumb-content menu="Pembuatan Kurikulum" subMenu="Matriks CPL dan KK"/>

    <div class="card mb-5 mb-xxl-8">
        <div class="card-body pt-9 pb-0">
            <div class="row" style="display:flex; justify-content:center;">
                <div class="col-6">
                    <div class="row mb-5">
                        <label class="form-label" for="ri_id">
                            Rumpun Ilmu
                        </label>
                        <div class="col-12">
                            <select id="ri_id" name="ri_id" class="form-control form-control-solid-bg ri" data-control="select2" data-placeholder="-- Pilih Rumpun Ilmu --">
                                <option></option>
                                <option v-for="data in ri_list" :key="data.ri_id" :value="data.ri_id" :data-nama_eng="data.nama_eng"> {{ data.nama_ind }} </option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <label class="form-label" for="fakultas_id">
                            Fakultas
                        </label>
                        <div class="col-12">
                            <select id="fakultas_id" name="fakultas_id" class="form-control form-control-solid-bg fakultas" data-control="select2" data-placeholder="-- Pilih Fakultas --">
                                <option></option>
                                <option v-for="data in fakultas_list" :key="data.fakultas_id" :value="data.fakultas_id" :data-nama_eng="data.nama_eng"> {{ data.nama_ind }} </option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <label class="form-label" for="departemen_id">
                            Departemen
                        </label>
                        <div class="col-12">
                            <select id="departemen_id" name="departemen_id" class="form-control form-control-solid-bg departemen" data-control="select2" data-placeholder="-- Pilih Departemen --">
                                <option></option>
                                <option v-for="data in departemen_list" :key="data.departemen_id" :value="data.departemen_id" :data-nama_eng="data.nama_eng"> {{ data.nama_ind }} </option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <label class="form-label" for="prodi_id">
                            Program Studi
                        </label>
                        <div class="col-12">
                            <select id="prodi_id" name="prodi_id" class="form-control form-control-solid-bg prodi" data-control="select2" data-placeholder="-- Pilih Program Studi --">
                                <option></option>
                                <option v-for="data in prodi_list" :key="data.prodi_id" :value="data.prodi_id" :data-nama_eng="data.nama_eng"> {{ data.nama_ind }} </option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <label class="form-label" for="kurikulum_id">
                            Kurikulum
                        </label>
                        <div class="col-12">
                            <select id="kurikulum_id" name="kurikulum_id" class="form-control form-control-solid-bg kurikulum" data-control="select2" data-placeholder="-- Pilih Kurikulum --">
                                <option></option>
                                <option v-for="data in kurikulum_list" :key="data.kurikulum_id" :value="data.kurikulum_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind" :data-kurikulum_kd="data.kurikulum_kd" :data-is_aktif="data.is_aktif === 1 ? 'Aktif' : 'Tidak Aktif'"> {{ data.nama_ind }} </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <LoadingOverlay :loading="loading" />
            
            <hr class="mt-2 mb-7">
            
            <div class="table-container border border-1 border-primary mb-10 slide-down" v-if="kurikulumKD">
                <div class="table-fixed-header">
                    <table class="table table-row-bordered align-middle gy-3" style="width: 100%;">
                        <thead>
                            <tr :class="{'bg-light-primary border-bottom border-primary': cpl_kk_list.length === 0, 'bg-primary text-white': cpl_kk_list.length > 0}">
                                <th class="text-center fs-3 fw-bold" :colspan="filtered_header_jenis_kk.length + 2">Matriks CPL Terhadap Kerangka Kerja</th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div class="table-scrollable scrolling">
                    <table id="table" :class="{'table table-row-bordered align-middle gy-3': true, 'm-0': cpl_kk_list.length === 0}">
                        <thead>
                            <tr class="border-bottom border-end border-primary" style="font-weight:bold;" v-if="cpl_kk_list.length > 0">
                                <th class="text-center">No</th>
                                <th class="text-center w-70">CPL</th>
                                <th v-for="(header, index) in filtered_header_jenis_kk" :key="header" :class="{'text-center w-300': true, 'px-5': index === filtered_header_jenis_kk.length - 1}">{{ header }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(cpl, index) in cpl_kk_list" :key="index">
                                <td class="text-center align-top border-end border-1 border-primary py-2">{{ index + 1 }}</td>
                                <td class="text-center align-top border-end border-1 border-primary py-2">{{ cpl.cpl_kd }}</td>
                                <td v-for="(header, idxHeader) in filtered_header_jenis_kk" :key="header" :class="{'text-center py-2 border-1 border-primary': true, 'border-end': idxHeader !== filtered_header_jenis_kk.length - 1 || (idxHeader === filtered_header_jenis_kk.length - 1 && cpl_kk_list.length > 1), 'align-top': cpl[header].length > 0, 'border-bottom': index !== cpl_kk_list.length - 1, 'px-3': idxHeader === filtered_header_jenis_kk.length - 1}">
                                    <template v-if="cpl[header] && cpl[header].length > 0">
                                        <label class="form-check form-check-custom form-check-solid py-1" v-for="cplkk in cpl[header]" :key="cplkk.kk_id">
                                            <span class="check-label px-2" style="text-align: justify; cursor: pointer;">
                                                <b>{{ cplkk.kk_kd }}</b> | {{ cplkk.nama_ind }}<hr class="m-0"><i>{{ cplkk.nama_eng }}</i>
                                            </span>
                                        </label>
                                    </template>
                                    <template v-else>
                                        <span>-</span>
                                    </template>
                                </td>
                            </tr>
                            <tr v-if="cpl_kk_list.length === 0">
                                <td class="text-center fw-bold" :colspan="filtered_header_jenis_kk.length + 2">Belum ada data</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-else>
                <div class="alert alert-primary alert-dismissible fade show fs-4 text-center mb-7" role="alert">
                    <i class="fas fa-info-circle fs-3 text-primary mx-1"></i>
                    <strong>Pilih kurikulum</strong> terlebih dahulu untuk menampilkan data <strong>Matriks CPL dan KK</strong>.
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'
const loading = ref(false)

const ri_list = ref([])
const fakultas_list = ref([])
const departemen_list = ref([])
const prodi_list = ref([])
const kurikulum_list = ref([])

const kurikulumKD = ref('')
const renderCustomOption = () => {
    const customSelect = (ress) => {
        if (!ress.id) return ress.text;

        // eslint-disable-next-line no-undef
        return $(`<label style="display:flex; flex-direction:column; cursor:pointer;"><span style="font-size:10px;"><b>${ $(ress.element).data('kurikulum_kd') } (${ $(ress.element).data('is_aktif') })</b></span><span style="font-size:14px;">${ $(ress.element).data('nama_ind') }</span></label>`);
    };
    // eslint-disable-next-line no-undef
    $('select.kurikulum').select2({ templateResult: customSelect, templateSelection: customSelect });
}

const header_jenis_kk = ref([])
const cpl_kk_list = ref([])
const filtered_header_jenis_kk = ref([]);
async function fetchDataCPLKK() {
    loading.value = true;

    try {
        const response = await request(`/cpl-kk-pemetaan?kurikulum_kd=${kurikulumKD.value}`)
        cpl_kk_list.value = response.data.data.cpl_kk.sort((a, b) => a.cpl_kd.localeCompare(b.cpl_kd))
        header_jenis_kk.value = response.data.data.header_jenis_kk.sort((a, b) => a.localeCompare(b))

        // Filter Header in CPL KK
        for (const header of header_jenis_kk.value) {
            if (cpl_kk_list.value.some(cpl => cpl[header] && cpl[header].length > 0)) {
                filtered_header_jenis_kk.value.push(header);
            }
        }
    } catch (error) {
        console.error('Error:', error);
    } finally {
		loading.value = false
	}
}

async function filteredSelect() {
    // eslint-disable-next-line no-undef
    $('body').on('change', 'select.ri', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/ri/${id}`);

        loading.value = true
        fakultas_list.value = data.fakultas
        departemen_list.value = null
        prodi_list.value = null
        kurikulum_list.value = null
        kurikulumKD.value = null
        loading.value = false
    }).on('change', 'select.fakultas', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/fakultas/${id}`);

        loading.value = true
        departemen_list.value = data.departemen
        prodi_list.value = null
        kurikulum_list.value = null
        kurikulumKD.value = null
        loading.value = false
    }).on('change', 'select.departemen', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/departemen/${id}`);

        loading.value = true
        prodi_list.value = data.prodi
        kurikulum_list.value = null
        kurikulumKD.value = null
        loading.value = false
    }).on('change', 'select.prodi', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/prodi/${id}`);

        loading.value = true
        kurikulum_list.value = data.kurikulum
        kurikulumKD.value = null
        loading.value = false

        renderCustomOption();
    }).on('change', 'select.kurikulum', async (event) => {
        kurikulumKD.value = null
        
        const id = event.target.value;
        const { data } = await request(`/kurikulum/${id}`);

        kurikulumKD.value = data.kurikulum_kd
        if (kurikulumKD.value) await fetchDataCPLKK()
    });
}

onMounted( async () => {
    // eslint-disable-next-line no-undef
    $("select").select2();

    const response = await request('/ri')
    ri_list.value = response.data.data
    
    filteredSelect();
})
</script>

<style scoped>
.slide-down { animation: slideDown 5s ease; }
@keyframes slideDown {
    0% { transform: translateY(-5%); opacity: 0; overflow: hidden; }
    5% { transform: translateY(0); opacity: 1; overflow: hidden; }
}

.table-container { position: relative; max-width: 100%; overflow-x: auto; }
.table-fixed-header { position: relative; width: 100%; overflow: hidden; }
.table-scrollable { display: block; overflow-x: auto; max-height: 500px; overflow-y: auto; margin: 0 0 25px 0; }
.table-scrollable table { min-width: 100%; }
.table-scrollable th.w-70, .table-scrollable td.w-70 { min-width: 70px; }
.table-scrollable th.w-300, .table-scrollable td.w-300 { min-width: 300px; }
.table-scrollable thead th { position: sticky; top: 0; background: var(--kt-secondary)!important; z-index: 1; }
.scrolling { max-height: 500px; overflow-y: auto; margin: -14px 0 0 0; }
</style>