<template>
   <breadcrumb-content menu="Pembuatan Kurikulum" subMenu="Matakuliah Kurikulum" />
	<div class="card mb-5 mb-xxl-8">
		<div class="pb-10">
			<div class="card-body pt-9 pb-0">
				<h1 class="anchor fw-bold mb-5">Tambah Matakuliah Kurikulum</h1>
				<div class="p-5">
					<Form id="form-matakuliah-kurikulum-add" class="kt-form" method="post">
						<LoadingOverlay :loading="loading" />
						<div class="row mb-5">
							<label class="form-label">Perguruan Tinggi</label>
							<div class="col-6">
								<flag-image bahasa="Indonesia" />
								<input type="text" class="form-control form-control-solid-bg bg-light-dark" value="Universitas Indonesia" readonly />
							</div>
							<div class="col-6">
								<flag-image bahasa="Inggris" />
								<input type="text" class="form-control form-control-solid-bg bg-light-dark" value="University of Indonesia" readonly />
							</div>
						</div>
						<div class="row mb-5">
                     <label class="form-label">Rumpun Ilmu</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <select class="form-control form-control-solid-bg group" data-control="select2" data-placeholder="-- Pilih Rumpun Ilmu --">
									<option></option>
									<option v-for="(row, index) in group" :key="index" :value="row.ri_id">{{ row.nama_ind }}</option>
								</select>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input type="text" class="form-control form-control-solid-bg bg-light-dark" v-model="groupDetail.nama_eng" placeholder="Input Group of Knowledge" readonly>
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label">Fakultas</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <select class="form-control form-control-solid-bg faculty" data-control="select2" data-placeholder="-- Pilih Fakultas --">
									<option></option>
									<option v-for="(row, index) in faculty" :key="index" :value="row.fakultas_id">{{ row.nama_ind }}</option>
								</select>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input type="text" class="form-control form-control-solid-bg bg-light-dark" v-model="facultyDetail.nama_eng" placeholder="Input Faculty" readonly>
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label">Departemen</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <select :class="`form-control form-control-solid-bg department`" data-control="select2" data-placeholder="-- Pilih Departemen --">
									<option></option>
									<option v-for="(row, index) in department" :key="index" :value="row.departemen_id">{{ row.nama_ind }}</option>
								</select>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input type="text" class="form-control form-control-solid-bg bg-light-dark" v-model="departmentDetail.nama_eng" placeholder="Input Department" readonly>
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label">Program Studi</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <select :class="`form-control form-control-solid-bg program`" data-control="select2" data-placeholder="-- Pilih Program Studi --">
									<option></option>
									<option v-for="(row, index) in program" :key="index" :value="row.prodi_id">{{ row.nama_ind }}</option>
								</select>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input type="text" class="form-control form-control-solid-bg bg-light-dark" v-model="programDetail.nama_eng" placeholder="Input Program Study" readonly>
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label">Jenjang</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
								<input type="text" class="form-control form-control-solid-bg bg-light-dark" v-model="programDetail.jenjang_nama_ind" placeholder="Masukkan Jenjang" readonly>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input type="text" class="form-control form-control-solid-bg bg-light-dark" v-model="programDetail.jenjang_nama_eng" placeholder="Input Grade" readonly>
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label">Kurikulum</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <select :class="`form-control form-control-solid-bg curriculum`" data-control="select2" data-placeholder="-- Pilih Kurikulum --">
									<option></option>
									<option v-for="(row, index) in curriculum" :key="index" :value="row.kurikulum_id" :data-code="row.kurikulum_kd" :data-name="row.nama_ind">{{ row.kurikulum_kd }} {{ row.nama_ind }}</option>
								</select>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
								<div style="display:flex; flex-direction:column; justify-content:center; background-color:#f5f8fa; border:1px solid #e4e6ef; border-radius:.475rem; height:60px; padding:.775rem 1rem;">
									<span v-if="Object.keys(curriculumDetail).length === 0" style="font-size:1.1rem; font-weight:500; color:#a1a5b7;">Input Curriculum</span>
									<template v-else>
										<span style="font-size:10px;">{{ curriculumDetail.kurikulum_kd }}</span>
										<span style="font-size:14px;">{{ curriculumDetail.nama_eng }}</span>
									</template>
								</div>
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label">Kode Kurikulum</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
								<input type="text" class="form-control form-control-solid-bg bg-light-dark" v-model="curriculumDetail.kurikulum_kd" placeholder="Masukkan Kode Kurikulum" readonly />
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label">Deskripsi Kurikulum</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <textarea class="form-control form-control-solid-bg bg-light-dark" rows="3" placeholder="Masukkan Deskripsi Kurikulum" readonly v-model="curriculumDetail.deskripsi_ind"></textarea>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <textarea class="form-control form-control-solid-bg bg-light-dark" rows="3" placeholder="Input Curriculum Description" readonly v-model="curriculumDetail.deskripsi_eng"></textarea>
                     </div>
                  </div>
						<div class="row mb-5">
                     <label class="form-label">Tahun Ajar (Awal - Akhir) </label>
                     <div class="col-6">
								<div class="row">
									<div class="col-6">
										<input type="text" class="form-control form-control-solid-bg bg-light-dark" placeholder="Tahun Ajar Awal" :value="parseDateToView(curriculumDetail.tahun_awal)" readonly />
									</div>
									<div class="col-6">
										<input type="text" class="form-control form-control-solid-bg bg-light-dark" placeholder="Tahun Ajar Akhir" :value="parseDateToView(curriculumDetail.tahun_akhir)" readonly />
									</div>
								</div>
                     </div>
                  </div>
						<hr />
						<div class="note">
							<svg xmlns="http://www.w3.org/2000/svg" style="display:none;">
								<symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
									<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
								</symbol>
								<symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
									<path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
								</symbol>
								<symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
									<path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
								</symbol>
							</svg>
							<div class="alert alert-warning alert-dismissible fade show" role="alert">
								<svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Warning:">
									<use xlink:href="#exclamation-triangle-fill" />
								</svg>
								<span class="fs-5">
									<strong>Perhatian!</strong>, Sebelum melakukan penyelarasan anda perlu memilih kurikulum terlebih dahulu </span>
								<button type="button" class="btn-close mt-1" data-bs-dismiss="alert" aria-label="Close"></button>
							</div>
						</div>
						<div class="d-flex align-items-center justify-content-between">
							<h3>Penyelarasan CPL dan BK terhadap Matakuliah</h3>
							<div class="text-end">
								<div id="addCPLBK" class="btn btn-secondary btn-sm border p-3 mx-1" data-form="CPLBKForm" @click="showForm"> Pilih CPL dan Bahan Kajian </div>
								<div id="addMK" class="btn btn-secondary btn-sm border p-3 mx-1" data-form="MKForm" @click="showForm"> Pilih Matakuliah </div>
								<div id="showMKK" class="btn btn-secondary btn-sm border p-3 mx-1" data-form="MKKModal" @click="showForm"> MK yang dihasilkan </div>
							</div>
						</div>
						<div class="mt-5">
							<div v-if="CPLBKForm">
								<component :is="CPLBKForm" form="CPLBKForm" :curriculumCode="curriculumDetail.kurikulum_kd" :selectedCPLBK="selectedCPLBK" @selectedCPLBK="recieveSelectedCPLBK" @removeCPLBKForm="recieveRemoveForm" @selectDisabled="recieveSelectDisabled" />
							</div>
							<CPLBKTable :selectedCPLBK="selectedCPLBK" />
						</div>
						<div v-if="MKForm">
							<component :is="MKForm" form="MKForm" :curriculum="curriculumDetail" :selectedCPLBK="selectedCPLBK" @selectedCPLBK="recieveSelectedCPLBK" @removeMKForm="recieveRemoveForm" />
						</div>
						<div v-if="MKKModal">
							<component :is="MKKModal" form="MKKModal" :curriculumCode="curriculumDetail.kurikulum_kd" @removeMKKModal="recieveRemoveForm" />
						</div>
						<div class="mt-10">
                     <div class="col-lg-6">
                        <button @click="redirectToIdentitasKurikulumList" class="btn btn-secondary">Kembali</button>
                     </div>
                  </div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { onMounted, shallowRef, ref } from 'vue';
   import { useRouter } from 'vue-router';
   import { Form } from 'vee-validate';
   import LoadingOverlay from '@/components/LoadingOverlay';
   import request from '@/utils/request';
	import CPLBKTable from './CPLBKTable.vue';

	const loading = ref(false);
	const router = useRouter();
	const group = ref({});
	const groupDetail = ref({});
	const faculty = ref({});
	const facultyDetail = ref({});
	const department = ref({});
	const departmentDetail = ref({});
	const program = ref({});
	const programDetail = ref({});
	const curriculum = ref({});
	const curriculumDetail = ref({});
	const selectedCPLBK = ref({'CPL': [], 'CPLBK': []});
	let CPLBKForm = shallowRef(null);
	let MKForm = shallowRef(null);
	let MKKModal = shallowRef(null);
	const redirectToIdentitasKurikulumList = () => router.push({ name: 'MatakuliahKurikulum' });
	const parseDateToView = ((date) => {
		if (date) {
			const formatted = new Date(date);
			return `${ String(formatted.getDate()).padStart(2, '0') }-${ String(formatted.getMonth() + 1).padStart(2, '0') }-${ formatted.getFullYear() }`;
		}
		return null;
	});
	const showForm = ((event) => {
		if (event.target.classList.contains('active')) {
			eval(event.target.dataset.form).value = null;
			import(`./${ event.target.dataset.form }.vue`).then(module => {
				eval(event.target.dataset.form).value = module.default;
			}).then(() => {
				// eslint-disable-next-line no-undef
				$(`#${ event.target.dataset.form }`).slideDown(1000);
			});
		}
	});
	const recieveSelectedCPLBK = (({ CPL, CPLBK }) => {
		if ((Object.keys(CPL).length > 0) && (CPLBK.length > 0)) {
			const check = selectedCPLBK.value.CPL.some((v) => v.cpl_id === CPL.cpl_id);
			if (!check) selectedCPLBK.value.CPL.push(CPL);
			
			selectedCPLBK.value.CPLBK = [...selectedCPLBK.value.CPLBK.filter((v) => v.cpl_id_fk !== CPL.cpl_id), ...CPLBK];
		}
		else {
			selectedCPLBK.value.CPL = [];
			selectedCPLBK.value.CPLBK = [];
		}

		changeBtn('active', selectedCPLBK.value.CPL.length);
	});
	const recieveRemoveForm = ((dynamic) => eval(dynamic).value = null );
	// eslint-disable-next-line no-undef
	const recieveSelectDisabled = ((attr) => $('select').select2(attr));
	const templateSelect = (ress) => {
		if (!ress.id) return ress.text;

		// eslint-disable-next-line no-undef
		if ($(ress.element).data('ext')) {
			// eslint-disable-next-line no-undef
			return $(`<label style="display:flex; flex-direction:column; cursor:pointer;"><span style="font-size:10px;">${ $(ress.element).data('code') }</span><span style="font-size:14px;">${ $(ress.element).data('name') } - ${ $(ress.element).data('ext') }</span></label>`);
		}
		
		// eslint-disable-next-line no-undef
		return $(`<label style="display:flex; flex-direction:column; cursor:pointer;"><span style="font-size:10px;">${ $(ress.element).data('code') }</span><span style="font-size:14px;">${ $(ress.element).data('name') }</span></label>`);
	};
	const handleElSelect = (async ({ target, self, child, point, handleSelectObject, activeButton }, event) => {
		const el = event.target;
		handleSelect(handleSelectObject);
		handleSelectedOption({ url: `${ target }${ el.value }`, self, child, point, activeButton });
	});
	const handleSelectedOption = (async ({ url, self, child, point, activeButton }) => {
		eval(`${ self }Detail`).value = (await request({ url })).data;

		if (activeButton) {
			CPLBKForm.value = null;
			if (Object.keys(eval(`${ self }Detail`).value).length > 0) changeBtn('active', selectedCPLBK.value.CPL.length);
			else changeBtn();
		}
		else eval(child).value = eval(`${ self }Detail`).value[point];
	});
	const handleSelect = (({ facultySelect, departmentSelect, programSelect, curriculumSelect }) => {
		if (!facultySelect) { faculty.value = {}; facultyDetail.value = {}; }
		if (!departmentSelect) { department.value = {}; departmentDetail.value = {}; }
		if (!programSelect) { program.value = {}; programDetail.value = {}; }
		if (!curriculumSelect) { curriculum.value = {}; curriculumDetail.value = {}; }
	});
	const changeBtn = ((type = '', hasCPL = 0) => {
		const addCPLBK = document.querySelector(`#addCPLBK`);
		const addMK = document.querySelector(`#addMK`);
		const showMKK = document.querySelector(`#showMKK`);

		if (type == 'active') {
			addCPLBK.classList.remove('btn-secondary');
			addCPLBK.classList.add('btn-info', 'active');
			addCPLBK.removeAttribute('style');
			addCPLBK.style.cursor = 'pointer';
			
			if (hasCPL > 0) {
				addMK.classList.remove('btn-secondary');
				addMK.classList.add('btn-primary', 'active');
				addMK.removeAttribute('style');
				addMK.style.cursor = 'pointer';
			}
			else {
				MKForm.value = null;
				addMK.classList.remove('btn-primary', 'active');
				addMK.classList.add('btn-secondary');
				addMK.style.cursor = 'default';
			}

			showMKK.classList.remove('btn-secondary');
			showMKK.classList.add('btn-dark', 'active');
			showMKK.removeAttribute('style');
			showMKK.style.cursor = 'pointer';
		}
		else {
			CPLBKForm.value = null;
			addCPLBK.classList.remove('btn-info', 'active');
			addCPLBK.classList.add('btn-secondary');
			addCPLBK.style.cursor = 'default';
			
			MKForm.value = null;
			addMK.classList.remove('btn-primary', 'active');
			addMK.classList.add('btn-secondary');
			addMK.style.cursor = 'default';

			MKKModal.value = null;
			showMKK.classList.remove('btn-dark', 'active');
			showMKK.classList.add('btn-secondary');
			showMKK.style.cursor = 'default';
		}
	});

	onMounted(async () => {
		try {
			changeBtn();
			group.value = (await request({ url:'/ri' })).data.data;

			// eslint-disable-next-line no-undef
         $('select.group').select2().on('change', (event) => { handleElSelect({ target: '/ri/', self: 'group', child: 'faculty', point: 'fakultas', handleSelectObject: { facultySelect: false, departmentSelect: false, programSelect: false, curriculumSelect: false } }, event); });

			// eslint-disable-next-line no-undef
         $('select.faculty').select2().on('change', (event) => { handleElSelect({ target: '/fakultas/', self: 'faculty', child: 'department', point: 'departemen', handleSelectObject: { facultySelect: true, departmentSelect: false, programSelect: false, curriculumSelect: false } }, event); });

			// eslint-disable-next-line no-undef
			$('select.department').select2().on('change', (event) => { handleElSelect({ target: '/departemen/', self: 'department', child: 'program', point: 'prodi', handleSelectObject: { facultySelect: true, departmentSelect: true, programSelect: false, curriculumSelect: false } }, event); });

			// eslint-disable-next-line no-undef
			$('select.program').select2().on('change', (event) => { handleElSelect({ target: '/prodi/', self: 'program', child: 'curriculum', point: 'kurikulum', handleSelectObject: { facultySelect: true, departmentSelect: true, programSelect: true, curriculumSelect: false } }, event); });

			// eslint-disable-next-line no-undef
			$('select.curriculum').select2({ templateResult:templateSelect, templateSelection:templateSelect }).on('change', (event) => { handleElSelect({ target: '/kurikulum/', self: 'curriculum', handleSelectObject: { facultySelect: true, departmentSelect: true, programSelect: true, curriculumSelect: true }, activeButton: true }, event); });

			// eslint-disable-next-line no-undef
			$('.select2-selection.select2-selection--single.curriculum').css({ 'height':'60px' });
		} catch (error) {
			console.error('Error fetching data: ', error);
		} finally {
			loading.value = false;
		}
	});
</script>