import request from '@/utils/requestUiServer'

const getDosenList = async () => {
	try {
		const data = await request.get('/list/dosen')
		return data.data
	} catch (error) {
		console.log(error.response?.message)
	}
}

const getDosenDetail = async (nip) => {
	try {
		const { data } = await request.get(`/staf/${nip}`)
		return data
	} catch (error) {
		console.log(error.response?.message)
	}
}

export { getDosenList, getDosenDetail }
