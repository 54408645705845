<template>
   <breadcrumb-content menu="Profil" subMenu="Departemen" />
   <div class="card mb-5 mb-xxl-8">
      <div class="card-body pt-9 pb-0">
         <LoadingOverlay :loading="loading" />
         <router-link :to="{ name: 'CreateViewDepartemen' }" class="btn btn-primary mb-5">
            <span class="fa fa-plus"></span> Tambah Departemen
         </router-link>
         <SSDatatable ref="datatableRef" url="/departemen-datatable" primaryKey="departemen_id" :columns="columns" :show="handleShow" :edit="handleEdit" :delete="handleDelete" />
      </div>
   </div>
</template>

<script setup>
	import { ref } from 'vue';
	import { useRouter } from 'vue-router';
	import LoadingOverlay from '@/components/LoadingOverlay';
	import SSDatatable from '@/components/SSDatatable.vue';
	import { sendRequest } from '@/api/common';

	const datatableRef = ref(null);
	const loading = ref(false);
	const router = useRouter();
	const columns = [
		{ title: 'No', data: 'rownum', searchable: false, width: '8%', className: 'px-1' },
		{ title: 'Nama', data: 'nama_ind' },
		{ title: 'Deskripsi', data: 'deskripsi_ind' },
		{ title: 'Aksi', data: 'actions', searchable: false }
	];

	const handleShow = (async (id) => router.push({ name: 'ShowViewDepartemen', params: { id } }) );
	const handleEdit = (async (id) => router.push({ name: 'EditViewDepartemen', params: { id } }) );
	const handleDelete = (async (id) => await sendRequest({ url: `/departemen/${ id }`, method: 'DELETE', loading: loading, context: 'Departemen' }) );
</script>