<template>
	<div>
		<breadcrumb-content menu="Master Data" subMenu="Tinjauan Pustaka" />
		<div class="card mb-5 mb-xxl-8 placeholder-glow">
			<div class="pb-10">
				<div class="card-body pt-9 pb-0">
					<h1 class="anchor fw-bold mb-5">Ubah Tinjauan Pustaka</h1>
					<div class="p-5">
						<Form
							id="form-tinjauan-pustaka-update"
							class="kt-form"
							method="post"
							@submit="onSubmit"
							:validation-schema="schema"
							v-slot="{ errors }"
							ref="formRef"
						>
							<LoadingOverlay :loading="loading" />

							<div class="row mb-5">
								<label class="form-label">
									<span style="color: red">*</span>
									Kategori Tinjauan Pustaka
								</label>
								<div class="col-6">
									<flag-image bahasa="Indonesia"/>

									<select class="form-control form-control-solid-bg kat_tinjauan_pustaka" data-control="select2" data-placeholder="-- Pilih Kategori Tinjauan Pustaka --" v-model="selectedKatTinjauanPustaka">
										<option></option>
										<option v-for="data in kat_tinjauan_pustaka_list" :key="data.kat_tinjauan_pustaka_id" :value="data.kat_tinjauan_pustaka_id" :data-nama_eng="data.nama_eng"> {{ data.nama_ind }} </option>
									</select>
									<Field type="hidden" id="kat_tinjauan_pustaka_id_fk" name="kat_tinjauan_pustaka_id_fk" v-model="selectedKatTinjauanPustaka" readonly/>
								</div>
								<div class="col-6">
									<flag-image bahasa="Inggris"/>

									<input type="text" class="form-control form-control-solid-bg bg-light-dark kat_tinjauan_pustaka" readonly placeholder="Select Literatur Review Category" v-model="kat_tinjauan_pustaka_nama_eng">
								</div>
							</div>
							
							<FormInput
								labelInd="Judul"
								labelEng="Title"
								columnName="judul"
								:errorInd="errors.judul_ind"
								:errorEng="errors.judul_eng"
							/>
							<FormInput
								labelInd="Penerbit"
								labelEng="Publisher"
								columnName="penerbit"
								:errorInd="errors.penerbit_ind"
								:errorEng="errors.penerbit_eng"
							/>
							<FormInput
								labelInd="Penulis"
								labelEng="Writer"
								columnName="penulis"
								:errorInd="errors.penulis_ind"
								:errorEng="errors.penulis_eng"
							/>
							<FormInput
								labelInd="Tahun Terbit"
								customName="tahun_terbit"
								customClass="tahun_terbit"
								:errorInd="errors.tahun_terbit"
								:isEnglish="false"
							/>
							<FormInput
								inputType="textarea"
								rows="3"
								labelInd="Deskripsi"
								labelEng="Description"
								columnName="deskripsi"
								:errorInd="errors.deskripsi_ind"
								:errorEng="errors.deskripsi_eng"
								:isRequired="false"
							/>

							<div class="mt-10">
								<button type="submit" class="btn btn-primary">Simpan</button>
								&nbsp;
								<button @click="redirectToDataList" class="btn btn-secondary">Kembali</button>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { Form, configure } from 'vee-validate'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'
import { sendRequest } from '@/api/common'

import { Field } from 'vee-validate'
import FormInput from '@/components/FormInput.vue'

const schema = {
	kat_tinjauan_pustaka_id_fk: 'required',
	judul_ind: 'required',
	judul_eng: 'required',
	penerbit_ind: 'required',
	penerbit_eng: 'required',
	penulis_ind: 'required',
	penulis_eng: 'required',
	tahun_terbit: 'required',
}

const formRef = ref(null)
const loading = ref(true)
const router = useRouter()
const tinjauan_pustaka_id = router.currentRoute.value.params.id

const redirectToDataList = () => router.push({ name: 'TinjauanPustaka' })

const onSubmit = async values => {
	values.kat_tinjauan_pustaka_id_fk = parseInt(values.kat_tinjauan_pustaka_id_fk)
	values.tahun_terbit = Number(values.tahun_terbit);

	const param = {
		url: `/tinjauan-pustaka/${tinjauan_pustaka_id}`,
		method: 'PUT',
		body: values,
		loading: loading,
		context: 'TinjauanPustaka'
	}

	return await sendRequest(param)
}

// custom message error
import { localize } from '@vee-validate/i18n'
const validationConfig = {
	id: {
		names: {
			kat_tinjauan_pustaka_id_fk: 'Kategori Tinjauan Pustaka',
			judul_ind: 'Judul',
			judul_eng: 'Title',
			penerbit_ind: 'Penerbit',
			penerbit_eng: 'Publisher',
			penulis_ind: 'Penulis',
			penulis_eng: 'Writer',
			tahun_terbit: 'Tahun Terbit',
		}
	}
}
configure({ generateMessage: localize(validationConfig) })

const kat_tinjauan_pustaka_list = ref([])
const kat_tinjauan_pustaka_nama_eng = ref('')
const selectedKatTinjauanPustaka = ref('')

onMounted(async () => {
	try {
		// eslint-disable-next-line no-undef
		$("select").select2();

		const { data } = await request(`/tinjauan-pustaka/${tinjauan_pustaka_id}`)
		if (formRef.value) {
			formRef.value.setFieldValue('kat_tinjauan_pustaka_id_fk', data.kat_tinjauan_pustaka_id_fk)
			formRef.value.setFieldValue('judul_ind', data.judul_ind)
			formRef.value.setFieldValue('judul_eng', data.judul_eng)
			formRef.value.setFieldValue('penerbit_ind', data.penerbit_ind)
			formRef.value.setFieldValue('penerbit_eng', data.penerbit_eng)
			formRef.value.setFieldValue('penulis_ind', data.penulis_ind)
			formRef.value.setFieldValue('penulis_eng', data.penulis_eng)
			formRef.value.setFieldValue('tahun_terbit', data.tahun_terbit)
			formRef.value.setFieldValue('deskripsi_ind', data.deskripsi_ind)
			formRef.value.setFieldValue('deskripsi_eng', data.deskripsi_eng)
		}

		const response = await request(`/kat-tinjauan-pustaka`)
		kat_tinjauan_pustaka_list.value = response.data.data
		kat_tinjauan_pustaka_nama_eng.value = data.kat_tinjauan_pustaka.nama_eng

		// eslint-disable-next-line no-undef
		$('body').on('change', 'select.kat_tinjauan_pustaka', async (event) => {
			const id = event.target.value;
			const { data } = await request(`/kat-tinjauan-pustaka/${id}`);

			selectedKatTinjauanPustaka.value = id
			kat_tinjauan_pustaka_nama_eng.value = data.nama_eng
		})

		// eslint-disable-next-line no-undef
		$('button[type="submit"]').click(function() {
			// eslint-disable-next-line no-undef
			if ($(`select.kat_tinjauan_pustaka`).val() == '') {
				// eslint-disable-next-line no-undef
				$(`select.kat_tinjauan_pustaka.select2-hidden-accessible`).addClass('is-invalid')
				// eslint-disable-next-line no-undef
				$('span').find(`.select2-selection.kat_tinjauan_pustaka`).addClass('is-invalid')
			} else {
				// eslint-disable-next-line no-undef
				$(`select.kat_tinjauan_pustaka.select2-hidden-accessible`).removeClass('is-invalid')
				// eslint-disable-next-line no-undef
				$('span').find(`.select2-selection.kat_tinjauan_pustaka`).removeClass('is-invalid')
			}
		});

		// eslint-disable-next-line no-undef
		$('input.tahun_terbit').on('input', function () { this.value = this.value.replace(/\D/g,'') });
	} catch (error) {
		console.error('Error fetching data:', error)
	} finally {
		loading.value = false
	}
})
</script>