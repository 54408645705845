<template>
   <breadcrumb-content menu="Master Data" subMenu="Lembaga Internasional" />
   <div class="card mb-5 mb-xxl-8">
      <div class="pb-10">
         <div class="card-body pt-9 pb-0">
            <h1 class="anchor fw-bold mb-5">Ubah Lembaga Internasional</h1>
            <div class="p-5">
               <Form id="form-lmbg-int-update" class="kt-form" method="post" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
                  <LoadingOverlay :loading="loading" />
                  <div class="row mb-5">
                     <label class="form-label">
                        <span style="color: red">*</span>Nama Lembaga Internasional </label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <Field type="text" name="nama_ind" class="form-control form-control-solid-bg" :class="{ 'is-invalid': errors.nama_ind }" v-model="internationalInstitution.nama_ind" placeholder="Masukkan Nama Lembaga Internasional" />
                        <div id="nama_ind-error" class="invalid-feedback">{{ errors.nama_ind }}</div>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <Field type="text" name="nama_eng" class="form-control form-control-solid-bg" :class="{ 'is-invalid': errors.nama_eng }" v-model="internationalInstitution.nama_eng" placeholder="Input International Institution Name" />
                        <div id="nama_eng-error" class="invalid-feedback">{{ errors.nama_eng }}</div>
                     </div>
                  </div>
                  <div class="row mb-5">
                     <label class="form-label">Deskripsi Lembaga Internasional</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <Field as="textarea" name="deskripsi_ind" class="form-control form-control-solid-bg" rows="3" v-model="internationalInstitution.deskripsi_ind" placeholder="Masukkan Deskripsi Lembaga Internasional" />
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <Field as="textarea" name="deskripsi_eng" class="form-control form-control-solid-bg" rows="3" v-model="internationalInstitution.deskripsi_eng" placeholder="Input International Institution Description" />
                     </div>
                  </div>
                  <div class="mt-10">
                     <div class="col-lg-6">
                        <button type="submit" class="btn btn-primary">Simpan</button> &nbsp; <button @click="redirectToLembagaNasionalList" class="btn btn-secondary">Kembali</button>
                     </div>
                  </div>
               </Form>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup>
	import { onMounted, ref } from 'vue';
	import { useRouter } from 'vue-router';
	import { Form, Field } from 'vee-validate';
	import LoadingOverlay from '@/components/LoadingOverlay';
	import { sendRequest } from '@/api/common';
	import request from '@/utils/request';

	const schema = {
		nama_ind: 'required',
		nama_eng: 'required'
	};

	const loading = ref(true);
	const router = useRouter();
	const id = router.currentRoute.value.params.id;
	const internationalInstitution = ref({});

	const redirectToLembagaNasionalList = () => router.push({ name: 'LembagaInternasional' });

	const onSubmit = async values => {
		const param = {
			url: `/lmbg-int/${id}`,
			method: 'PUT',
			body: values,
			loading: loading,
			context: 'LembagaInternasional'
		};

		return await sendRequest(param);
	};

	onMounted(async () => {
		try {
			const { data } = await request(`/lmbg-int/${id}`);
			internationalInstitution.value = data;
		} catch (error) {
			console.error('Error fetching lembaga internasional data: ', error);
		} finally {
			loading.value = false;
		}
	});
</script>