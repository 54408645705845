<template>
	<div>
		<breadcrumb-content menu="Role Management" subMenu="Role" />
		<div class="card mb-5 mb-xxl-8 placeholder-glow">
			<div class="pb-10">
				<div class="card-body pt-9 pb-0">
					<h1 class="anchor fw-bold mb-5">Ubah Role</h1>
					<div class="p-5">
						<Form
							id="form-role-update"
							class="kt-form"
							method="post"
							@submit="onSubmit"
							:validation-schema="schema"
							v-slot="{ errors }"
							ref="formRef"
						>
							<LoadingOverlay :loading="loading" />
							<div class="mb-5 col-lg-8">
								<label class="form-label"><span style="color: red">*</span>Nama Role</label>
								<Field
									name="role_name"
									type="text"
									class="form-control form-control-solid-bg"
									:class="{ 'is-invalid': errors.role_name }"
								/>
								<div id="role_name-error" class="invalid-feedback">{{ errors.role_name }}</div>
							</div>
							<div class="mb-5 col-lg-8">
								<label class="form-label"><span style="color: red">*</span>Deskripsi Role</label>
								<Field
									as="textarea"
									name="description"
									class="form-control form-control-solid-bg"
									rows="3"
									:class="{ 'is-invalid': errors.description }"
								/>
								<div id="description-error" class="invalid-feedback">{{ errors.description }}</div>
							</div>
							<div class="mt-10">
								<div class="col-lg-6">
									<button type="submit" class="btn btn-primary">Simpan</button>
									&nbsp;
									<button @click="redirectToRoleList" class="btn btn-secondary">Kembali</button>
								</div>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { Form, Field } from 'vee-validate'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'
import { sendRequest } from '@/api/common'

const schema = {
	role_name: 'required|min:3|max:150',
	description: 'required|max:255'
}
const formRef = ref(null)
const loading = ref(true)
const router = useRouter()
const roleId = router.currentRoute.value.params.id

const redirectToRoleList = () => router.push({ name: 'Role' })

const onSubmit = async values => {
	const param = {
		url: `/role/${roleId}`,
		method: 'PUT',
		body: values,
		loading: loading,
		context: 'Role'
	}

	return await sendRequest(param)
}

onMounted(async () => {
	try {
		const { data } = await request(`/role/${roleId}`)
		if (formRef.value) {
			formRef.value.setFieldValue('role_name', data.role_name)
			formRef.value.setFieldValue('description', data.description)
		}
	} catch (error) {
		console.error('Error fetching role data:', error)
	} finally {
		loading.value = false
	}
})
</script>
