<template>
   <breadcrumb-content menu="Pembuatan BRP" subMenu="Rencana Pembelajaran" />

   <LoadingOverlay :loading="loading" />
   <div class="card mb-5 mb-xxl-8 border-top border-primary border-2">
      <div class="card-body">
         <div class="row">
            <div class="col-md-6 col-12">
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Rumpun Ilmu</label>
                  <div class="col-sm-9">
                     <input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataBRP.rumpun_matakuliah_nama_ind" readonly>
                  </div>
               </div>
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Fakultas</label>
                  <div class="col-sm-9">
                     <input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataBRP.fakultas_nama_ind" readonly>
                  </div>
               </div>
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Departemen</label>
                  <div class="col-sm-9">
                     <input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataBRP.departemen_nama_ind" readonly>
                  </div>
               </div>
            </div>
            <div class="col-md-6 col-12">
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Program Studi</label>
                  <div class="col-sm-9">
                     <input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataBRP.prodi_nama_ind" readonly>
                  </div>
               </div>
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Jenjang</label>
                  <div class="col-sm-9">
                     <input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataBRP.prodi_jenjang_ind" readonly>
                  </div>
               </div>
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Kurikulum</label>
                  <div class="col-sm-9">
                     <textarea type="text" class="form-control form-control-solid-bg form-control-sm" :value="`${dataBRP.kurikulum_kd_fk}\n${dataBRP.kurikulum_nama_ind}`" readonly></textarea>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="card mb-5 mb-xxl-8 border-top border-primary border-2">
      <div class="card-header" style="min-height: 65px;">
         <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bold fs-3 mb-1">Rencana Pembelajaran</span>
            <span class="text-muted mt-1 fw-semibold fs-7">Data Rencana Pembelajaran <b>[Matakuliah: {{ dataBRP.matakuliah_nama_ind }}]</b></span>
         </h3>
         <div class="text-end pt-5">
            <span class="border border-primary border-1 btn btn-light-primary btn-sm p-2 fw-bold" @click="showModal()">
               <i class="fas fa-plus p-0 mt-n1"></i>
               Tambah Rencana Pembelajaran
            </span>
         </div>
      </div>
      <div class="card-body pb-3">
         <div class="row">            
            <div class="table-responsive">
               <DataTable ref="tableRef" id="table" class="table gs-0 table-hover border border-secondary border-1" :options="options">
                  <thead class="text-center fw-bold text-muted bg-light px-3" style="vertical-align: middle;">
                        <tr>
                           <th class="text-center px-3 border border-secondary border-1" rowspan="3" style="padding-left: 10px;">Minggu Ke- / Topik</th>
                           <th class="text-center px-3 border border-secondary border-1" rowspan="3">Sub-CPMK</th>
                           <th class="text-center px-3 border border-secondary border-1" colspan="2" rowspan="2">Penilaian</th>
                           <th class="text-center px-3 border border-secondary border-1" colspan="2">Metode Pembelajaran *; <br /> Pengalaman Belajar dalam modal Asinkron dan Sinkron (O - L - U)**</th>
                           <th class="text-center px-3 border border-secondary border-1" rowspan="3">Materi Pembelajaran <br /> [Rujukan]</th>
                           <th class="text-center px-3 border border-secondary border-1" rowspan="3">Bobot Penerapan <br /> (%)</th>
                           <th class="text-center px-3 border border-secondary border-1" rowspan="3" style="padding-right: 10px;">Actions</th>
                        </tr>
                        <tr>
                           <th class="text-center px-3 border border-secondary border-1" colspan="2">[Estimasi Waktu]</th>
                        </tr>
                        <tr>
                           <th class="text-center px-3 border border-secondary border-1">Indikator</th>
                           <th class="text-center px-3 border border-secondary border-1">Teknik dan Kriteria</th>
                           <th class="text-center px-3 border border-secondary border-1">Daring (Online)</th>
                           <th class="text-center px-3 border border-secondary border-1">Luring (Offline)</th>
                        </tr>
                  </thead>
                  <tbody class="border border-secondary border-1" style="word-break: break-all; text-align: justify;"></tbody>
               </DataTable>
            </div>
         </div>
      </div>
      <div class="card-footer text-end" style="padding: 1.5rem 2.25rem;">
         <router-link :to="{ name: 'EditViewRancanganTugas', params: { brp_id: brp_id }}" class="border border-primary border-1 btn btn-light-primary btn-sm">
            Lanjut Ke Halaman Rancangan Tugas
            <i class="fa fa-angle-double-right"></i>
         </router-link>
      </div>
   </div>

   <ModalForm
      id="modal_form"
      :close_func="confirmCloseModal"
      :custom_classes="['modal-xl']"
      :modalHidden="state.modalHidden"
   >
      <template #title>
         <span v-if="state.is_edit && state.dataRncnBljr">Ubah Rencana Pembelajaran</span>
         <span v-else>Tambah Rencana Pembelajaran</span>
      </template>

      <template #body>
         <LoadingOverlay :loading="loadingModal" />
         
         <Form :id="`form-${state.is_edit && state.dataRncnBljr ? 'edit' : 'create'}-rencana_bljr`" class="kt-form" method="post" @submit="onSubmit" ref="formRef">
            <FormInput
               inputType="number"
               labelInd="Nomor Urut"
               customName="no_urut"
               :valueInd="state.is_edit && state.dataRncnBljr ? state.dataRncnBljr.no_urut : ''"
               :isEnglish="false"
               customErrorInd="Nomor Urut"
            />
            <div class="row mb-5">
               <label class="form-label">
                  <span style="color: red">*</span>
                  Sub-CPMK
               </label>
               <div class="col-6">
                  <flag-image bahasa="Indonesia"/>

                  <select class="form-control form-control-solid-bg sub_cpmks" data-self="sub_cpmks" data-control="select2" data-placeholder="-- Pilih Sub-CPMK --" multiple>
                     <option></option>
                     <option v-for="data in sub_cpmks" :key="data.sub_cpmk_id" :value="data.sub_cpmk_id" :data-eng="data.keterangan_eng" :selected="subcpmk_ids_merged.includes(data.sub_cpmk_id)"> {{ data.keterangan_ind }} </option>
                  </select>
                  <!-- <Field type="hidden" id="sub_cpmk_id_fk" name="sub_cpmk_id_fk" v-model="sub_cpmks_selected.sub_cpmk_id" readonly/> -->
                  <div v-if="!sub_cpmks_selected.name_eng" id="sub_cpmks-error" class="invalid-feedback">Sub-CPMK harus dipilih</div>
               </div>
               <div class="col-6">
                  <flag-image bahasa="Inggris"/>

                  <textarea id="sub_cpmks" class="form-control form-control-solid-bg bg-light-dark" rows="1" readonly placeholder="Pilih Sub-CPMK" v-model="sub_cpmks_selected.name_eng"></textarea>
               </div>
            </div>
            <FormInput
               labelInd="Minggu ke- / Topik"
               labelEng="Week / Topic"
               columnName="minggu_topik"
               :valueInd="state.is_edit && state.dataRncnBljr ? state.dataRncnBljr.minggu_topik_ind : ''"
               :valueEng="state.is_edit && state.dataRncnBljr ? state.dataRncnBljr.minggu_topik_eng : ''"
               customErrorInd="Minggu ke- / Topik"
               customErrorEng="Week / Topic"
            />
            <FormInput
               ref="editor1"
               labelInd="Penilaian indikator"
               labelEng="Indicator assessment"
               columnName="penilaian_indikator"
               :valueInd="state.is_edit && state.dataRncnBljr ? state.dataRncnBljr.penilaian_indikator_ind : ''"
               :valueEng="state.is_edit && state.dataRncnBljr ? state.dataRncnBljr.penilaian_indikator_eng : ''"
               :ckeditor="true"
               customErrorInd="Penilaian indikator"
               customErrorEng="Indicator assessment"
            />
            <FormInput
               ref="editor2"
               labelInd="Teknik dan Kriteria Penilaian"
               labelEng="Assessment Techniques and Criteria"
               columnName="penilaian_teknik_kriteria"
               :valueInd="state.is_edit && state.dataRncnBljr ? state.dataRncnBljr.penilaian_teknik_kriteria_ind : ''"
               :valueEng="state.is_edit && state.dataRncnBljr ? state.dataRncnBljr.penilaian_teknik_kriteria_eng : ''"
               :ckeditor="true"
               customErrorInd="Teknik dan Kriteria Penilaian"
               customErrorEng="Assessment Techniques and Criteria"
            />
            <FormInput
               ref="editor3"
               labelInd="Metode Pembelajaran Daring"
               labelEng="Online Learning Methods"
               columnName="daring"
               :valueInd="state.is_edit && state.dataRncnBljr ? state.dataRncnBljr.daring_ind : ''"
               :valueEng="state.is_edit && state.dataRncnBljr ? state.dataRncnBljr.daring_eng : ''"
               :ckeditor="true"
               customErrorInd="Metode Pembelajaran Daring"
               customErrorEng="Online Learning Methods"
            />
            <FormInput
               ref="editor4"
               labelInd="Metode Pembelajaran Luring"
               labelEng="Offline Learning Methods"
               columnName="luring"
               :valueInd="state.is_edit && state.dataRncnBljr ? state.dataRncnBljr.luring_ind : ''"
               :valueEng="state.is_edit && state.dataRncnBljr ? state.dataRncnBljr.luring_eng : ''"
               :ckeditor="true"
               customErrorInd="Metode Pembelajaran Luring"
               customErrorEng="Offline Learning Methods"
            />
            <FormInput
               ref="editor5"
               labelInd="Materi Pembelajaran"
               labelEng="Learning materials"
               columnName="materi_bljr"
               :valueInd="state.is_edit && state.dataRncnBljr ? state.dataRncnBljr.materi_bljr_ind : ''"
               :valueEng="state.is_edit && state.dataRncnBljr ? state.dataRncnBljr.materi_bljr_eng : ''"
               :ckeditor="true"
               customErrorInd="Materi Pembelajaran"
               customErrorEng="Learning materials"
            />
            <FormInput
               inputType="number"
               labelInd="Bobot Penerapan (%)"
               customName="bobot_penerapan"
               :valueInd="state.is_edit && state.dataRncnBljr ? state.dataRncnBljr.bobot_penerapan : ''"
               :isEnglish="false"
               customErrorInd="Bobot Penerapan"
            />

            <div class="modal-footer pt-5 p-0 text-end">
               <button type="button" class="btn btn-secondary" @click="confirmCloseModal">Batal</button>
               <button type="submit" class="btn btn-primary">
                  <span v-if="state.is_edit && state.dataRncnBljr">Simpan Perubahan</span>
                  <span v-else>Simpan Data</span>
               </button>
            </div>
         </Form>
      </template>
   </ModalForm>
</template>

<script setup>
   import { onMounted, onBeforeUnmount, ref, toRaw, reactive, watch, nextTick } from 'vue'
   import { useRouter } from 'vue-router'
   import { Form } from 'vee-validate'
   import LoadingOverlay from '@/components/LoadingOverlay'
   import request from '@/utils/request'
   import DataTable from 'datatables.net-vue3'
   import DataTablesLib from 'datatables.net'
   import swal from 'sweetalert2'
   import { Modal } from 'bootstrap';

   import FormInput from '@/components/FormInput.vue'
   import ModalForm from "@/components/ModalForm.vue";

   let dt
   const tableRef = ref()

   const formRef = ref(null)
   const loading = ref(true)
   const loadingModal = ref(false)
   const router = useRouter()
   const brp_id = router.currentRoute.value.params.brp_id

   const dataBRP = ref({})
   const editor1 = ref(null)
   const editor2 = ref(null)
   const editor3 = ref(null)
   const editor4 = ref(null)
   const editor5 = ref(null)

   const sub_cpmks = ref([])
   const sub_cpmks_selected = ref({ sub_cpmk_id: [], name_eng: '' });
   const subcpmk_ids_merged = ref([])

   function validateFields(values, fields, removeClass = false) {
      let isValid = true;

      fields.forEach(field => {
         const value = values[field];
         // eslint-disable-next-line no-undef
         const fieldElement = $(`input[name=${field}]`);

         if (!value) { fieldElement.addClass('is-invalid'); isValid = false; } 
         else { fieldElement.removeClass('is-invalid'); }

         if (removeClass) { fieldElement.removeClass('is-invalid'); }
      });

      // Validate Select2 multi-select
      // eslint-disable-next-line no-undef
      const subCpmksSelect = $('select.sub_cpmks');
      if (sub_cpmks_selected.value.sub_cpmk_id.length === 0) {
         subCpmksSelect.next('.select2').find('.select2-selection').addClass('is-invalid');
         subCpmksSelect.next('.select2').parent().find('select.sub_cpmks').addClass('is-invalid');
         isValid = false;
      } else {
         subCpmksSelect.next('.select2').find('.select2-selection').removeClass('is-invalid');
         subCpmksSelect.next('.select2').parent().find('select.sub_cpmks').removeClass('is-invalid');
      }
      if (removeClass) {
         subCpmksSelect.next('.select2').find('.select2-selection').removeClass('is-invalid');
         subCpmksSelect.next('.select2').parent().find('select.sub_cpmks').removeClass('is-invalid');
      }

      return isValid;
   }

   const onSubmit = async values => {
      const dataToSend = {
         brp_id_fk: parseInt(brp_id),
         minggu_topik_ind: values.minggu_topik_ind, minggu_topik_eng: values.minggu_topik_eng,
         penilaian_indikator_ind: values.penilaian_indikator_ind, penilaian_indikator_eng: values.penilaian_indikator_eng,
         penilaian_teknik_kriteria_ind: values.penilaian_teknik_kriteria_ind, penilaian_teknik_kriteria_eng: values.penilaian_teknik_kriteria_eng,
         daring_ind: values.daring_ind, daring_eng: values.daring_eng,
         luring_ind: values.luring_ind, luring_eng: values.luring_eng,
         materi_bljr_ind: values.materi_bljr_ind, materi_bljr_eng: values.materi_bljr_eng,
         bobot_penerapan: values.bobot_penerapan,
         no_urut: values.no_urut,
         sub_cpmk: (sub_cpmks_selected.value.sub_cpmk_id).map((v) => { return { sub_cpmk_id: parseInt(v) }; })
      };

      const fieldsToValidate = [
         'no_urut', 
         'bobot_penerapan', 
         'minggu_topik_ind', 'minggu_topik_eng', 
         'penilaian_indikator_ind', 'penilaian_indikator_eng',
         'penilaian_teknik_kriteria_ind', 'penilaian_teknik_kriteria_eng',
         'daring_ind', 'daring_eng',
         'luring_ind', 'luring_eng',
         'materi_bljr_ind', 'materi_bljr_eng',
      ];
      if (!validateFields(values, fieldsToValidate)) { return swal.fire({ title: `Harap memasukkan semua data yang ada terlebih dahulu!`, icon: 'error', confirmButtonText: 'OK' }); }
      
      try {
         loadingModal.value = true
         const { data } = await request({ url:`/rencana-belajar-check?brp_id=${dataToSend.brp_id_fk}&minggu_topik_ind=${dataToSend.minggu_topik_ind}&minggu_topik_eng=${dataToSend.minggu_topik_eng}` });
         
         if (state.is_edit && state.dataRncnBljr) { 
            const isIndChanged = data.minggu_topik_ind.value !== state.dataRncnBljr.minggu_topik_ind;
            const isEngChanged = data.minggu_topik_eng.value !== state.dataRncnBljr.minggu_topik_eng;

            if ((isIndChanged && data.minggu_topik_ind.is_exist) || (isEngChanged && data.minggu_topik_eng.is_exist)) {
               const existingTopic = isIndChanged && data.minggu_topik_ind.is_exist ? dataToSend.minggu_topik_ind : dataToSend.minggu_topik_eng;
               return swal.fire({ title: `Inputan '${existingTopic}' sudah ada!`, icon: 'error', confirmButtonText: 'OK' });
            }

            const param = await request({
               url: `/rencana-belajar/${state.dataRncnBljr.rencana_belajar_id}`,
               method: 'PUT',
               data: dataToSend,
            });

            handleResponse(param, 'diubah');
         } else {
            if (data.minggu_topik_ind.is_exist || data.minggu_topik_eng.is_exist) {
               const existingTopic = data.minggu_topik_ind.is_exist ? dataToSend.minggu_topik_ind : dataToSend.minggu_topik_eng;
               return swal.fire({ title: `Inputan '${existingTopic}' sudah ada!`, icon: 'error', confirmButtonText: 'OK' });
            }

            const param = await request({
               url: `/rencana-belajar`,
               method: 'POST',
               data: dataToSend,
            });

            handleResponse(param, 'ditambah');
         }
      } catch (error) {
         console.error('Error:', error);
      } finally {
         loadingModal.value = false;
      }
   }
   function handleResponse(param, action) {
      if (param.status === 'SUCCESS') {
         swal.fire({ icon: 'success', title: 'Success', text: `Rencana Pembelajaran berhasil ${action}` }).then(async (result) => {
            if (result.isConfirmed) { closeModal(); reloadDataTable(); }
         });
      } else {
         swal.fire({ title: `Rencana Pembelajaran gagal ${action}`, icon: 'error', confirmButtonText: 'OK' });
      }
   }

   // === Datatable ===
   DataTable.use(DataTablesLib)
   let dtRencanaBljr = ref([]);
   const fetchDataRencanaBljrWithPagination = async (params) => {
      if (dtRencanaBljr.value.length === 0) {
         try { dtRencanaBljr.value = (await request({ url:`/rencana-belajar-datatable?draw=1&start=0&length=-1&brp_id=${brp_id}&loads=sub_cpmk` })).data.data } 
         catch (error) { return error }
      }

      let resultDatas = dtRencanaBljr.value
      let countFilter = resultDatas.length

      if (params?.search.value) {
         resultDatas = dtRencanaBljr.value.filter(rencana_bljr => {
            let str = rencana_bljr?.minggu_topik_ind + rencana_bljr?.minggu_topik_eng 
                     + rencana_bljr?.penilaian_indikator_ind + rencana_bljr?.penilaian_indikator_eng 
                     + rencana_bljr?.penilaian_teknik_kriteria_ind + rencana_bljr?.penilaian_teknik_kriteria_eng 
                     + rencana_bljr?.daring_ind + rencana_bljr?.daring_eng 
                     + rencana_bljr?.luring_ind + rencana_bljr?.luring_eng 
                     + rencana_bljr?.materi_bljr_ind + rencana_bljr?.materi_bljr_eng 
                     + rencana_bljr?.no_urut 
                     + rencana_bljr?.bobot_penerapan

            if (rencana_bljr.sub_cpmk && Array.isArray(rencana_bljr.sub_cpmk)) { rencana_bljr.sub_cpmk.forEach(sub => { str += sub.keterangan_ind; }); }
            return str.toLowerCase().includes(params?.search.value.toLowerCase())
         })

         countFilter = resultDatas.length
      }

      if (params?.order.length > 0) {
         resultDatas = resultDatas.sort((a, b) => {
            return params?.order[0].dir === 'asc' ? (a['no_urut'] - b['no_urut']) : (b['no_urut'] - a['no_urut']);
         })
      }
      resultDatas = resultDatas.slice(params?.start, params?.start + params?.length)

      return { draw: params?.draw, recordsTotal: dtRencanaBljr.value.length, recordsFiltered: countFilter, data: resultDatas }
   };
   const fetchDataRencanaBljr = async (data, callback) => {
      try {
         const response = await fetchDataRencanaBljrWithPagination(data)
         const plainResponse = {
            ...toRaw(response),
            data: response.data.map(item => toRaw(item))
         }

         callback(plainResponse)
      } catch (error) {
         console.error('Error fetching data rencana pembelajaran:', error)
      } finally {
         loading.value = false
      }
   }
   const reloadDataTable = async () => {
      dtRencanaBljr.value = [];
      if (dt && dt.ajax) { dt.ajax.reload(null, false); } 
      else { console.error('DataTable reloaded unsuccessfully.'); }
   };
   const columns = ref([
      { data: 'minggu_topik_ind', title: 'Minggu Ke- / Topik', width: '7%', className: 'text-center px-3' },
      { data: 'sub_cpmk', title: 'Sub-CPMK', width: '10%',
         render: function (data, type, row) {
            if (type === 'display' && row && Array.isArray(data) && data.length > 0) {
               const subCpmks = [...new Set(data.map(item => item.keterangan_ind))].join(', ');
               return `<td> <span>${subCpmks}</span> </td>`;
            } else {
               return `<td> <span>-</span> </td>`;
            }
         }
      },
      { data: 'penilaian_indikator_ind', title: 'Indikator', width: '14%' },
      { data: 'penilaian_teknik_kriteria_ind', title: 'Teknik dan Kriteria', width: '14%' },
      { data: 'daring_ind', title: 'Daring (Online)', width: '14%' },
      { data: 'luring_ind', title: 'Luring (Offline)', width: '14%' },
      { data: 'materi_bljr_ind', title: 'Materi Pembelajaran [Rujukan]', width: '13%' },
      { data: 'bobot_penerapan', title: 'Bobot Penerapan (%)', width: '7%', className: 'text-center',
         render: function (data, type, row) {
            if (type === 'display' && row) return row.bobot_penerapan+`%`;
            else return '-';
         }
      },
      { title: 'Actions', orderable: false, searchable: false, width: '7%', className: 'text-center px-3',
         render: function (data, type, row) {
            let actionBtn = `
                              <div class="d-flex justify-content-center">
                                 <button class="border border-secondary border-1 btn btn-icon btn-sm btn-edit-datatable btn-active-color-primary w-30px h-30px mx-1" data-toggle="tooltip" data-placement="top" title="Edit Data" data-id="${row.rencana_belajar_id}">
                                    <i class="fa fa-edit p-0 fs-5"></i>
                                 </button>

                                 <button class="border border-secondary border-1 btn btn-icon btn-sm btn-delete-datatable btn-active-color-danger w-30px h-30px mx-1" data-toggle="tooltip" data-placement="top" title="Delete Data" data-id="${row.rencana_belajar_id}">
                                    <i class="fa fa-trash p-0 fs-5"></i>
                                 </button>
                              </div>
                           `;
            return actionBtn;
         }
      }
   ]);
   const options = ref({ serverSide: true, ajax: fetchDataRencanaBljr, columns: columns.value })

   const handleButtonClick = async event => {
      const target = event.target
      const button = target.closest('.btn-edit-datatable, .btn-delete-datatable')

      if (button) {
         event.stopPropagation()
         event.preventDefault()

         if (button.matches('.btn-edit-datatable')) {
            showModal(button.dataset.id)
         } else if (button.matches('.btn-delete-datatable')) {
            const result = await swal.fire({
               title: `Apakah Anda yakin untuk menghapus data Rencana Pembelajaran ini?`,
               icon: 'warning',
               showCancelButton: true,
               confirmButtonText: 'Ya, saya yakin',
               cancelButtonText: 'Batal'
            })
            if (!result.isConfirmed) { return }

            loading.value = true

            try {
               const res = await request({
                  url: `/rencana-belajar/${button.dataset.id}`,
                  method: 'DELETE',
               })

               if (res.status === 'SUCCESS') {
                  return await swal.fire({
                     icon: 'success',
                     title: 'Success',
                     text: `Rencana Pembelajaran berhasil dihapus`,
                  }).then(async (result) => { 
                     if (result.isConfirmed) { reloadDataTable() } 
                  });
               } 
               else { return await swal.fire({ title: 'Rencana Pembelajaran gagal dihapus', icon: 'error', confirmButtonText: 'OK' }) }
            } 
            catch (error) { return await swal.fire({ title: 'Rencana Pembelajaran gagal dihapus', icon: 'error', confirmButtonText: 'OK' }) } 
            finally { loading.value = false }
         }
      }
   }
   // === End Datatable ===


   // === Modal Form ===
   const fetchDataSubCPMKs = async () => {
      loadingModal.value = true

      try { sub_cpmks.value = (await request(`/sub-cpmk-datatable?length=-1&brp_id=${brp_id}`)).data.data[0].sub_cpmk } 
      catch (error) { console.error('Error fetching data Sub-CPMK:', error) } 
      finally { loadingModal.value = false }
   }
   const state = reactive({
      modal_form: null,
      is_edit: false,
      dataRncnBljr: null,
      modalHidden: false,
   })

   const showModal = async (rencana_belajar_id) => {
      state.modal_form.show()
      await fetchDataSubCPMKs()

      if (rencana_belajar_id) {
         loadingModal.value = true
         state.is_edit = true
         
         try { 
            // get All Data
            state.dataRncnBljr = (await request({ url:`/rencana-belajar/${rencana_belajar_id}` })).data 

            // get Data SubCPMK Selected
            const subcpmkDatas = (await request({ url: `/rencana-belajar-subcpmk-datatable?length=-1&brp_id=${brp_id}&rencana_belajar_id=${rencana_belajar_id}` })).data.data[0].subcpmk;
            sub_cpmks_selected.value.sub_cpmk_id = subcpmkDatas.map(item => item.sub_cpmk_id);
            sub_cpmks_selected.value.name_eng = subcpmkDatas.map(item => item.keterangan_eng).join(', ');

            subcpmk_ids_merged.value = [...new Set([...sub_cpmks_selected.value.sub_cpmk_id, ...subcpmk_ids_merged.value])];
         } 
         catch (error) { return error } 
         finally { loadingModal.value = false; }
      } else {
         document.getElementById('sub_cpmks').style.height = '0px';
      }

      state.modalHidden = false
   };
   function clearFormData() {
      if (state.is_edit) { state.is_edit = false; state.dataRncnBljr = null; } 

      formRef.value.setFieldValue('no_urut', '')
      formRef.value.setFieldValue('minggu_topik_ind', '')
      formRef.value.setFieldValue('minggu_topik_eng', '')
      formRef.value.setFieldValue('bobot_penerapan', '')

      // Reset CKEditor values
      editor1.value.resetValueInd(); editor1.value.resetValueEng();
      editor2.value.resetValueInd(); editor2.value.resetValueEng();
      editor3.value.resetValueInd(); editor3.value.resetValueEng();
      editor4.value.resetValueInd(); editor4.value.resetValueEng();
      editor5.value.resetValueInd(); editor5.value.resetValueEng();

      // Select2
      sub_cpmks.value = [];
      sub_cpmks_selected.value.sub_cpmk_id = []; sub_cpmks_selected.value.name_eng = '';
      subcpmk_ids_merged.value = [];
      
      const fieldsToValidate = [
         'no_urut', 
         'bobot_penerapan', 
         'minggu_topik_ind', 'minggu_topik_eng', 
         'penilaian_indikator_ind', 'penilaian_indikator_eng',
         'penilaian_teknik_kriteria_ind', 'penilaian_teknik_kriteria_eng',
         'daring_ind', 'daring_eng',
         'luring_ind', 'luring_eng',
         'materi_bljr_ind', 'materi_bljr_eng',
      ];

      validateFields(formRef.value, fieldsToValidate, true);
   }
   function closeModal() { state.modal_form.hide(); clearFormData(); state.modalHidden = true; }

   async function confirmCloseModal() {
      const result = await swal.fire({
			title: `Konfirmasi Batal Input`,
			text: `Apakah Anda yakin ingin keluar dari proses inputan ini?, karena data yang telah diinput akan terhapus`,
			icon: 'question',
			showCancelButton: true,
			confirmButtonText: 'Ya, saya yakin',
			cancelButtonText: 'Batal'
		})

		if (!result.isConfirmed) { return } 
      else { closeModal() }
   }
   // === End Modal Form ===

   const handleElMultiSelect = (async (event) => {
      const el = event.target;
      sub_cpmks_selected.value.sub_cpmk_id = Array.from(el.selectedOptions || []).map(option => option.value);
      sub_cpmks_selected.value.name_eng = (Array.from(el.selectedOptions || []).map(option => option.dataset.eng)).join(', ');
      subcpmk_ids_merged.value = [...new Set([...subcpmk_ids_merged.value, ...sub_cpmks_selected.value.sub_cpmk_id])];

      handleTextarea(el.dataset.self);
   });
   const handleTextarea = ((id) => {
      const textarea = document.getElementById(id);
      setTimeout(() => { textarea.style.height = 'auto'; textarea.style.height = textarea.scrollHeight + 'px'; }, 20);
   });

   // eslint-disable-next-line no-undef
   const initializeSelect2 = () => { $('select.sub_cpmks').select2().on('change', handleElMultiSelect); };
   async function fetchDataBRP() {
      try { dataBRP.value = (await request(`/brp/${brp_id}?loads=mk_kurikulum`)).data.mk_kurikulum } 
      catch (error) { console.error('Error:', error); } 
      finally { loading.value = false }
   }
   onMounted(async () => {
      dt = tableRef.value.dt
      document.addEventListener('click', handleButtonClick)
      
      // eslint-disable-next-line no-undef
      $('div.dataTables_length, div.dataTables_info').parent().removeClass('col-sm-12 col-md-6').closest('.row').removeClass('row').addClass('d-flex justify-content-between');
      // eslint-disable-next-line no-undef
      $('div.dataTables_filter, div.dataTables_paginate').parent().removeClass('col-sm-12 col-md-6');
      
      state.modal_form = new Modal('#modal_form', {})
      // state.modal_form._element.addEventListener('hide.bs.modal', () => { clearFormData(); })

      await nextTick(() => { initializeSelect2(); fetchDataBRP(); });
   })
   onBeforeUnmount(() => { document.removeEventListener('click', handleButtonClick) })
   watch(sub_cpmks_selected.value, async (newVal) => { if (newVal.sub_cpmk_id.length > 0) await nextTick(() => { initializeSelect2(); }) });
</script>