<template>
	<div>
		<breadcrumb-content menu="Master Data" subMenu="Kompetensi" />
		<div class="card mb-5 mb-xxl-8 placeholder-glow">
			<div class="pb-10">
				<div class="card-body pt-9 pb-0">
					<h1 class="anchor fw-bold mb-5">Ubah Kompetensi</h1>
					<div class="p-5">
						<Form
							id="form-kompetensi-update"
							class="kt-form"
							method="post"
							@submit="onSubmit"
							:validation-schema="schema"
							v-slot="{ errors }"
							ref="formRef"
						>
							<LoadingOverlay :loading="loading" />

							<div class="row mb-5">
								<label class="form-label">
									<span style="color: red">*</span>
									Jenis Kompetensi
								</label>
								<div class="col-6">
									<flag-image bahasa="Indonesia"/>

									<select class="form-control form-control-solid-bg jenis_kompetensi" data-control="select2" data-placeholder="-- Pilih Jenis Kompetensi --" v-model="selectedJenisKompetensi">
										<option></option>
										<option v-for="data in jenis_kompetensi_list" :key="data.jenis_kompetensi_id" :value="data.jenis_kompetensi_id" :data-nama_eng="data.nama_eng"> {{ data.nama_ind }} </option>
									</select>
									<Field type="hidden" id="jenis_kompetensi_id_fk" name="jenis_kompetensi_id_fk" v-model="selectedJenisKompetensi" readonly/>
								</div>
								<div class="col-6">
									<flag-image bahasa="Inggris"/>

									<input type="text" class="form-control form-control-solid-bg bg-light-dark jenis_kompetensi" readonly placeholder="Select Competency Type" v-model="jenis_kompetensi_nama_eng">
								</div>
							</div>

							<FormInput
								labelInd="Kode Kompetensi"
								customName="kompetensi_kd"
								:errorInd="errors.kompetensi_kd"
								:isEnglish="false"
							/>
							<FormInput
								labelInd="Nama Kompetensi"
								labelEng="Name of Competency Type"
								columnName="nama"
								:errorInd="errors.nama_ind"
								:errorEng="errors.nama_eng"
							/>
							<FormInput
								inputType="textarea"
								rows="5"
								labelInd="Deskripsi"
								labelEng="Description"
								columnName="deskripsi"
								:errorInd="errors.deskripsi_ind"
								:errorEng="errors.deskripsi_eng"
								:isRequired="false"
							/>

							<div class="row mb-5">
								<label class="form-label">
									<span style="color: red">*</span>
									Status
								</label>
								<div class="col-6">
									<flag-image bahasa="Indonesia"/>

									<select class="form-control form-control-solid-bg is_aktif" data-control="select2" data-placeholder="-- Pilih Status --" v-model="selectedStatus">
										<option></option>
										<option v-for="data in status_list" :key="data.id" :value="data.val" :data-nama_eng="data.nama_eng"> {{ data.nama_ind }} </option>
									</select>
									<Field type="hidden" name="is_aktif" v-model="selectedStatus" readonly/>
								</div>
								<div class="col-6">
									<flag-image bahasa="Inggris"/>

									<input type="text" class="form-control form-control-solid-bg bg-light-dark is_aktif" readonly placeholder="Select Status" v-model="status_nama_eng">
								</div>
							</div>

							<div class="mt-10">
								<button type="submit" class="btn btn-primary">Simpan</button>
								&nbsp;
								<button @click="redirectToDataList" class="btn btn-secondary">Kembali</button>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script setup>
import { onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { Form, configure } from 'vee-validate'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'
import { sendRequest } from '@/api/common'

import { Field } from 'vee-validate'
import FormInput from '@/components/FormInput.vue'

const schema = {
	jenis_kompetensi_id_fk: 'required',
	kompetensi_kd: 'required',
	nama_ind: 'required',
	nama_eng: 'required',
}

// custom message error
import { localize } from '@vee-validate/i18n'
const validationConfig = {
	id: {
		names: {
			jenis_kompetensi_id_fk: 'Jenis Kompetensi',
			kompetensi_kd: 'Kode Kompetensi',
			nama_ind: 'Nama Kompetensi',
			nama_eng: 'Name of Competency',
		}
	}
}
configure({ generateMessage: localize(validationConfig) })

const formRef = ref(null)
const loading = ref(true)
const router = useRouter()
const kompetensi_id = router.currentRoute.value.params.id

const redirectToDataList = () => router.push({ name: 'Kompetensi' })

const onSubmit = async values => {
	values.jenis_kompetensi_id_fk = parseInt(values.jenis_kompetensi_id_fk)

	const param = {
		url: `/kompetensi/${kompetensi_id}`,
		method: 'PUT',
		body: values,
		loading: loading,
		context: 'Kompetensi'
	}
	console.log(param);
	return await sendRequest(param)
}

const jenis_kompetensi_list = ref([])
const jenis_kompetensi_nama_eng = ref('')
const selectedJenisKompetensi = ref('')

const status_list = ref([
    { id: 1, val: true, nama_ind: 'Aktif', nama_eng: 'Active' },
    { id: 2, val: false, nama_ind: 'Tidak Aktif', nama_eng: 'Inactive' },
]);
const selectedStatus = ref(null)
const status_nama_eng = ref('')

onMounted(async () => {
	try {
		// eslint-disable-next-line no-undef
		$("select").select2();

		const { data } = await request(`/kompetensi/${kompetensi_id}`)
		selectedStatus.value = data.is_aktif
		if (formRef.value) {
			formRef.value.setFieldValue('jenis_kompetensi_id_fk', data.jenis_kompetensi_id_fk)
			formRef.value.setFieldValue('kompetensi_kd', data.kompetensi_kd)
			formRef.value.setFieldValue('nama_ind', data.nama_ind)
			formRef.value.setFieldValue('nama_eng', data.nama_eng)
			formRef.value.setFieldValue('deskripsi_ind', data.deskripsi_ind)
			formRef.value.setFieldValue('deskripsi_eng', data.deskripsi_eng)
		}

		const response = await request(`/jenis-kompetensi`)
		jenis_kompetensi_list.value = response.data.data

		const dataJK = await request(`/jenis-kompetensi/${data.jenis_kompetensi_id_fk}`)
		jenis_kompetensi_nama_eng.value = dataJK.data.nama_eng

		// eslint-disable-next-line no-undef
		$('body').on('change', 'select.jenis_kompetensi', async (event) => {
			const id = event.target.value;
			const { data } = await request(`/jenis-kompetensi/${id}`);

			selectedJenisKompetensi.value = id
			jenis_kompetensi_nama_eng.value = data.nama_eng
		}).on('change', 'select.is_aktif', async (event) => {
			selectedStatus.value = JSON.parse(event.target.value)
		})
		// eslint-disable-next-line no-undef
		$('select.is_aktif').val(JSON.stringify(selectedStatus.value)).trigger('change');

		// eslint-disable-next-line no-undef
		$('button[type="submit"]').click(function() {
			// eslint-disable-next-line no-undef
			if ($(`select.jenis_kompetensi`).val() == '') {
				// eslint-disable-next-line no-undef
				$(`select.jenis_kompetensi.select2-hidden-accessible`).addClass('is-invalid')
				// eslint-disable-next-line no-undef
				$('span').find(`.select2-selection.jenis_kompetensi`).addClass('is-invalid')
			} else {
				// eslint-disable-next-line no-undef
				$(`select.jenis_kompetensi.select2-hidden-accessible`).removeClass('is-invalid')
				// eslint-disable-next-line no-undef
				$('span').find(`.select2-selection.jenis_kompetensi`).removeClass('is-invalid')
			}
		});
	} catch (error) {
		console.error('Error fetching data:', error)
	} finally {
		loading.value = false
	}
})

watch(selectedStatus, (newVal) => {
    const selectedStatus = status_list.value.find(status => status.val === newVal);
    if (selectedStatus) status_nama_eng.value = selectedStatus.nama_eng;
});
</script>