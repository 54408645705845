<template>
  <div class="table-responsive placeholder-glow">
    <DataTable
      ref="dataTable"
      class="table table-row-bordered align-middle gy-3 display"
      v-bind:columns="columns"
    >
      <tbody>
        <tr v-for="i in rows" :key="i">
          <td v-for="(item, indexJ) in columns.length" :key="item">
            <span
              :class="
                indexJ !== 0 && indexJ !== columns.length - 1
                  ? `placeholder col-${
                      Math.floor(Math.random() * (12 - 6 + 1)) + 6
                    } bg-secondary rounded-3 h-20px`
                  : `placeholder col-10 bg-secondary rounded-3 h-20px m-2`
              "
            ></span>
          </td>
        </tr>
      </tbody>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "datatables.net-vue3";
import DataTableLib from "datatables.net-bs5";
import "datatables.net-responsive-bs5";
DataTable.use(DataTableLib);

export default {
  name: "TableDataPlaceholder",
  components: {
    DataTable,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    rows: {
      type: Number,
      required: true,
    },
  },
};
</script>
