<template>
   <breadcrumb-content menu="Master Data" subMenu="Lembaga Internasional"/>
	<div class="card mb-5 mb-xxl-8">
		<div class="card-body pt-9 pb-0">
			<LoadingOverlay :loading="loading" />
			<router-link :to="{ name: 'CreateViewLembagaInternasional' }" class="btn btn-primary mb-5">
				<span class="fa fa-plus"></span>
				Tambah Lembaga Internasional
			</router-link>

			<SSDatatable ref="datatableRef" url="/lmbg-int-datatable" primaryKey="lmbg_int_id" :columns="columns" :show="handleShow" :edit="handleEdit" :delete="handleDelete" />
		</div>
	</div>
</template>

<script setup>
	import { ref } from 'vue';
	import { useRouter } from 'vue-router';
	import LoadingOverlay from '@/components/LoadingOverlay';
	import SSDatatable from '@/components/SSDatatable.vue';
	import { sendRequest } from '@/api/common';

	const datatableRef = ref(null);
	const loading = ref(false);
	const router = useRouter();

	const columns = [
		{ title: 'No', data: 'rownum', searchable: false, width: '8%', className: 'px-1' },
		{ title: 'Nama', data: 'nama_ind' },
		{ title: 'Deskripsi', data: 'deskripsi_ind' },
		{ title: 'Aksi', data: 'actions', searchable: false }
	];

	const handleShow = async id => router.push({ name: 'ShowViewLembagaInternasional', params: { id } });
	const handleEdit = async id => router.push({ name: 'EditViewLembagaInternasional', params: { id } });
	const handleDelete = async id => {
		const param = {
			url: `/lmbg-int/${id}`,
			method: 'DELETE',
			loading: loading,
			context: 'LembagaInternasional'
		};

		return await sendRequest(param);
	};
</script>