<template>
   <breadcrumb-content menu="Pembuatan BRP" subMenu="Rencana Pembelajaran" />

   <LoadingOverlay :loading="loading" />
   <div class="card mb-5 mb-xxl-8 border-top border-primary border-2">
      <div class="card-body">
         <div class="row">
            <div class="col-md-6 col-12">
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Rumpun Ilmu</label>
                  <div class="col-sm-9">
                     <input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataBRP.rumpun_matakuliah_nama_ind" readonly>
                  </div>
               </div>
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Fakultas</label>
                  <div class="col-sm-9">
                     <input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataBRP.fakultas_nama_ind" readonly>
                  </div>
               </div>
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Departemen</label>
                  <div class="col-sm-9">
                     <input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataBRP.departemen_nama_ind" readonly>
                  </div>
               </div>
            </div>
            <div class="col-md-6 col-12">
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Program Studi</label>
                  <div class="col-sm-9">
                     <input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataBRP.prodi_nama_ind" readonly>
                  </div>
               </div>
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Jenjang</label>
                  <div class="col-sm-9">
                     <input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataBRP.prodi_jenjang_ind" readonly>
                  </div>
               </div>
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Kurikulum</label>
                  <div class="col-sm-9">
                     <textarea type="text" class="form-control form-control-solid-bg form-control-sm" :value="`${dataBRP.kurikulum_kd_fk}\n${dataBRP.kurikulum_nama_ind}`" readonly></textarea>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="card mb-5 mb-xxl-8 border-top border-primary border-2">
      <div class="card-header" style="min-height: 65px;">
         <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bold fs-3 mb-1">Rencana Pembelajaran</span>
            <span class="text-muted mt-1 fw-semibold fs-7">Data Rencana Pembelajaran <b>[Matakuliah: {{ dataBRP.matakuliah_nama_ind }}]</b></span>
         </h3>
      </div>
      <div class="card-body pb-3">
         <div class="row">            
            <div class="table-responsive">
               <DataTable ref="tableRef" id="table" class="table gs-0 table-hover border border-secondary border-1" :options="options">
                  <thead class="text-center fw-bold text-muted bg-light px-3" style="vertical-align: middle;">
                        <tr>
                           <th class="text-center px-3 border border-secondary border-1" rowspan="3" style="padding-left: 10px;">Minggu Ke- / Topik</th>
                           <th class="text-center px-3 border border-secondary border-1" rowspan="3">Sub-CPMK</th>
                           <th class="text-center px-3 border border-secondary border-1" colspan="2" rowspan="2">Penilaian</th>
                           <th class="text-center px-3 border border-secondary border-1" colspan="2">Metode Pembelajaran *; <br /> Pengalaman Belajar dalam modal Asinkron dan Sinkron (O - L - U)**</th>
                           <th class="text-center px-3 border border-secondary border-1" rowspan="3">Materi Pembelajaran <br /> [Rujukan]</th>
                           <th class="text-center px-3 border border-secondary border-1" rowspan="3">Bobot Penerapan <br /> (%)</th>
                        </tr>
                        <tr>
                           <th class="text-center px-3 border border-secondary border-1" colspan="2">[Estimasi Waktu]</th>
                        </tr>
                        <tr>
                           <th class="text-center px-3 border border-secondary border-1">Indikator</th>
                           <th class="text-center px-3 border border-secondary border-1">Teknik dan Kriteria</th>
                           <th class="text-center px-3 border border-secondary border-1">Daring (Online)</th>
                           <th class="text-center px-3 border border-secondary border-1">Luring (Offline)</th>
                        </tr>
                  </thead>
                  <tbody class="border border-secondary border-1" style="word-break: break-all; text-align: justify;"></tbody>
               </DataTable>
            </div>
         </div>
      </div>
      <div class="card-footer text-end" style="padding: 1.5rem 2.25rem;">
         <router-link :to="{ name: 'ShowViewRancanganTugas', params: { brp_id: brp_id }}" class="border border-primary border-1 btn btn-light-primary btn-sm">
            Lanjut Ke Halaman Rancangan Tugas
            <i class="fa fa-angle-double-right"></i>
         </router-link>
      </div>
   </div>
</template>

<script setup>
   import { onMounted, ref, toRaw, nextTick } from 'vue'
   import { useRouter } from 'vue-router'
   import LoadingOverlay from '@/components/LoadingOverlay'
   import request from '@/utils/request'
   import DataTable from 'datatables.net-vue3'
   import DataTablesLib from 'datatables.net'

   const tableRef = ref()
   const loading = ref(true)
   const router = useRouter()
   const brp_id = router.currentRoute.value.params.brp_id

   const dataBRP = ref({})

   // === Datatable ===
   DataTable.use(DataTablesLib)
   let dtRencanaBljr = ref([]);
   const fetchDataRencanaBljrWithPagination = async (params) => {
      if (dtRencanaBljr.value.length === 0) {
         try { dtRencanaBljr.value = (await request({ url:`/rencana-belajar-datatable?draw=1&start=0&length=-1&brp_id=${brp_id}&loads=sub_cpmk` })).data.data } 
         catch (error) { return error }
      }

      let resultDatas = dtRencanaBljr.value
      let countFilter = resultDatas.length

      if (params?.search.value) {
         resultDatas = dtRencanaBljr.value.filter(rencana_bljr => {
            let str = rencana_bljr?.minggu_topik_ind + rencana_bljr?.minggu_topik_eng 
                     + rencana_bljr?.penilaian_indikator_ind + rencana_bljr?.penilaian_indikator_eng 
                     + rencana_bljr?.penilaian_teknik_kriteria_ind + rencana_bljr?.penilaian_teknik_kriteria_eng 
                     + rencana_bljr?.daring_ind + rencana_bljr?.daring_eng 
                     + rencana_bljr?.luring_ind + rencana_bljr?.luring_eng 
                     + rencana_bljr?.materi_bljr_ind + rencana_bljr?.materi_bljr_eng 
                     + rencana_bljr?.no_urut 
                     + rencana_bljr?.bobot_penerapan

            if (rencana_bljr.sub_cpmk && Array.isArray(rencana_bljr.sub_cpmk)) { rencana_bljr.sub_cpmk.forEach(sub => { str += sub.keterangan_ind; }); }
            return str.toLowerCase().includes(params?.search.value.toLowerCase())
         })

         countFilter = resultDatas.length
      }

      if (params?.order.length > 0) {
         resultDatas = resultDatas.sort((a, b) => {
            return params?.order[0].dir === 'asc' ? (a['no_urut'] - b['no_urut']) : (b['no_urut'] - a['no_urut']);
         })
      }
      resultDatas = resultDatas.slice(params?.start, params?.start + params?.length)

      return { draw: params?.draw, recordsTotal: dtRencanaBljr.value.length, recordsFiltered: countFilter, data: resultDatas }
   };
   const fetchDataRencanaBljr = async (data, callback) => {
      try {
         const response = await fetchDataRencanaBljrWithPagination(data)
         const plainResponse = {
            ...toRaw(response),
            data: response.data.map(item => toRaw(item))
         }

         callback(plainResponse)
      } catch (error) {
         console.error('Error fetching data rencana pembelajaran:', error)
      } finally {
         loading.value = false
      }
   }
   const columns = ref([
      { data: 'minggu_topik_ind', title: 'Minggu Ke- / Topik', width: '8%', className: 'text-center px-3' },
      { data: 'sub_cpmk', title: 'Sub-CPMK', width: '10%', 
         render: function (data, type, row) {
            if (type === 'display' && row && Array.isArray(data) && data.length > 0) {
               const subCpmks = [...new Set(data.map(item => item.keterangan_ind))].join(', ');
               return `<td> <span>${subCpmks}</span> </td>`;
            } else {
               return `<td> <span>-</span> </td>`;
            }
         }
      },
      { data: 'penilaian_indikator_ind', title: 'Indikator', width: '15%' },
      { data: 'penilaian_teknik_kriteria_ind', title: 'Teknik dan Kriteria', width: '15%' },
      { data: 'daring_ind', title: 'Daring (Online)', width: '15%' },
      { data: 'luring_ind', title: 'Luring (Offline)', width: '15%' },
      { data: 'materi_bljr_ind', title: 'Materi Pembelajaran [Rujukan]', width: '14%' },
      { data: 'bobot_penerapan', title: 'Bobot Penerapan (%)', width: '8%', className: 'text-center px-3',
         render: function (data, type, row) {
            if (type === 'display' && row) return row.bobot_penerapan+`%`;
            else return '-';
         }
      },
   ]);
   const options = ref({ serverSide: true, ajax: fetchDataRencanaBljr, columns: columns.value })
   // === End Datatable ===

   async function fetchDataBRP() {
      try { dataBRP.value = (await request(`/brp/${brp_id}?loads=mk_kurikulum`)).data.mk_kurikulum } 
      catch (error) { console.error('Error:', error); } 
      finally { loading.value = false }
   }
   onMounted(async () => {
      // eslint-disable-next-line no-undef
      $('div.dataTables_length, div.dataTables_info').parent().removeClass('col-sm-12 col-md-6').closest('.row').removeClass('row').addClass('d-flex justify-content-between');
      // eslint-disable-next-line no-undef
      $('div.dataTables_filter, div.dataTables_paginate').parent().removeClass('col-sm-12 col-md-6');
      
      await nextTick(() => { fetchDataBRP(); });
   })
</script>