import { defineStore } from 'pinia'
import { getDosenList } from '@/api/dosen'
import { getOrgDetail } from '@/api/org'

export const useDosenStore = defineStore('dosen', {
	state: () => ({
		dosens: [],
		orgMap: new Map()
	}),
	actions: {
		async fetchDosen() {
			if (this.dosens.length > 0) {
				return this.dosens
			}
			try {
				const data = await getDosenList()
				this.dosens = data
				return this.dosens
			} catch (error) {
				return error
			}
		},
		async fetchDosenWithPaginasi(params) {
			if (this.dosens.length === 0) {
				try {
					const data = await getDosenList()
					this.dosens = data
				} catch (error) {
					return error
				}
			}

			let reslutDatas = this.dosens
			let countFilter = reslutDatas.length

			if (params?.search.value) {
				reslutDatas = this.dosens.filter(dosen => {
					const str = dosen?.nip + dosen?.nama
					return str.toLowerCase().includes(params?.search.value.toLowerCase())
				})

				countFilter = reslutDatas.length
			}

			if (params?.order.length > 0) {
				reslutDatas = reslutDatas.sort((a, b) => {
					if (params?.order[0].dir === 'asc') {
						return a['nama'] > b['nama'] ? 1 : -1
					} else {
						return a['nama'] < b['nama'] ? 1 : -1
					}
				})
			}

			reslutDatas = reslutDatas.slice(params?.start, params?.start + params?.length)

			await this.getOrgsForDosen(reslutDatas)

			reslutDatas = reslutDatas.map((dosen, i) => {
				const dt = {...dosen}
				dt.rownum = params?.start + i + 1
				dt.org = this.orgMap.get(dosen?.org) || dosen?.org
				return dt
			})

			return {
				draw: params?.draw,
				recordsTotal: this.dosens.length,
				recordsFiltered: countFilter,
				data: reslutDatas
			}
		},
		async getOrgsForDosen(dosenList) {
			const orgCodes = [...new Set(dosenList.map(dosen => dosen?.org))]
			const missingCodes = orgCodes.filter(code => !this.orgMap.has(code))

			if (missingCodes.length > 0) {
				try {
					const orgData = await Promise.all(
						missingCodes.map(async code => {
							const data = await getOrgDetail(code)
							return data?.org
						})
					)

					orgData.forEach(({ code, org }) => {
						this.orgMap.set(code, org)
					})
				} catch (error) {
					console.error(error)
				}
			}
		}
	}
})
