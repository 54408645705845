<template>
	<div class="py-3 py-lg-6">
		<div class="card card-flush h-md-100">
			<div
				class="card-body d-flex flex-column justify-content-between bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0"
				style="background-position: 100% 50%; background-image: url('/assets/media/stock/900x600/42.png')"
			>
				<div class="fs-2hx fw-bold text-gray-800 text-center mb-13">
					<span class="me-2">
						<span class="position-relative d-inline-block text-danger">
							<span class="text-danger opacity-75-hover">~ 401 Unauthorized ~</span>
							<span
								class="position-absolute opacity-15 bottom-0 start-0 border-4 border-danger border-bottom w-100"
							></span>
						</span>
						<br />
						Akun pengguna ini belum terdaftar dalam aplikasi Kurikulum
					</span>
				</div>

				<img
					class="mx-auto h-185px h-lg-250px theme-light-show"
					src="/assets/media/illustrations/dozzy-1/4.png"
					alt="Develop"
				/>
				<img
					class="mx-auto h-185px h-lg-250px theme-dark-show"
					src="/assets/media/illustrations/dozzy-1/4-dark.png"
					alt="Develop"
				/>

				<div class="text-center my-10">
					<a @click="redirectBack" class="btn btn-sm btn-dark fw-bold"> Kembali ke halaman sebelumnya </a>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { useRouter } from 'vue-router'

const router = useRouter()

const redirectBack = () => {
	router.go(-1)
}
</script>
