<template>
   <breadcrumb-content menu="Pembuatan BRP" subMenu="Kriteria Penilaian" />

   <LoadingOverlay :loading="loading" />
   <div class="card mb-5 mb-xxl-8 border-top border-primary border-2">
      <div class="card-body">
         <div class="row">
            <div class="col-md-6 col-12">
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Rumpun Ilmu</label>
                  <div class="col-sm-9">
                     <input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataBRP.rumpun_matakuliah_nama_ind" readonly>
                  </div>
               </div>
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Fakultas</label>
                  <div class="col-sm-9">
                     <input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataBRP.fakultas_nama_ind" readonly>
                  </div>
               </div>
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Departemen</label>
                  <div class="col-sm-9">
                     <input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataBRP.departemen_nama_ind" readonly>
                  </div>
               </div>
            </div>
            <div class="col-md-6 col-12">
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Program Studi</label>
                  <div class="col-sm-9">
                     <input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataBRP.prodi_nama_ind" readonly>
                  </div>
               </div>
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Jenjang</label>
                  <div class="col-sm-9">
                     <input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataBRP.prodi_jenjang_ind" readonly>
                  </div>
               </div>
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Kurikulum</label>
                  <div class="col-sm-9">
                     <textarea type="text" class="form-control form-control-solid-bg form-control-sm" :value="`${dataBRP.kurikulum_kd_fk}\n${dataBRP.kurikulum_nama_ind}`" readonly></textarea>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="card mb-5 mb-xxl-8 border-top border-primary border-2">
      <div class="card-header" style="min-height: 65px;">
         <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bold fs-3 mb-1">Kriteria Penilaian</span>
            <span class="text-muted mt-1 fw-semibold fs-7">Data Kriteria Penilaian <b>[Matakuliah: {{ dataBRP.matakuliah_nama_ind }}]</b></span>
         </h3>
      </div>
      <div class="card-body pb-3">
         <div class="row">            
            <div class="table-responsive">
               <DataTable ref="tableRef" id="table" class="table gs-0 table-hover border border-secondary border-1" :options="options">
                  <thead class="text-center fw-bold text-muted bg-light px-3" style="vertical-align: middle;">
                        <tr>
                           <th class="text-center px-3 border border-secondary border-1" rowspan="2">Bentuk Evaluasi</th>
                           <th class="text-center px-3 border border-secondary border-1" rowspan="2">Sub-CPMK</th>
                           <th class="text-center px-3 border border-secondary border-1" colspan="2">Instrumen Penilaian Frekuensi</th>
                           <th class="text-center px-3 border border-secondary border-1" rowspan="2">Tagihan (Bukti)</th>
                           <th class="text-center px-3 border border-secondary border-1" rowspan="2">Bobot Penilaian <br /> (%)</th>
                        </tr>
                        <tr>
                           <th class="text-center px-3 border border-secondary border-1">Formatif</th>
                           <th class="text-center px-3 border border-secondary border-1">Sumatif</th>
                        </tr>
                  </thead>
                  <tbody class="border border-secondary border-1" style="word-break: break-all; text-align: justify;"></tbody>
                  <tfoot>
                     <tr class="fw-bold text-muted bg-light">
                        <td colspan="5"><p class="text-end m-0">TOTAL</p></td>
                        <td class="px-3" id="totalBobotPenilaian"></td>
                     </tr>
                  </tfoot>
               </DataTable>
            </div>
         </div>
      </div>
      <div class="card-footer text-end" style="padding: 1.5rem 2.25rem;">
         <router-link :to="{ name: 'ShowViewRubrikPenilaian', params: { id: brp_id }}" class="border border-primary border-1 btn btn-light-primary btn-sm">
            Lanjut Ke Halaman Rubrik Penilaian
            <i class="fa fa-angle-double-right"></i>
         </router-link>
      </div>
   </div>
</template>

<script setup>
   import { onMounted, ref, toRaw, nextTick } from 'vue'
   import { useRouter } from 'vue-router'
   import LoadingOverlay from '@/components/LoadingOverlay'
   import request from '@/utils/request'
   import DataTable from 'datatables.net-vue3'
   import DataTablesLib from 'datatables.net'
   
   const loading = ref(true)
   const router = useRouter()
   const brp_id = router.currentRoute.value.params.brp_id

   const dataBRP = ref({})

   // === Datatable ===
   DataTable.use(DataTablesLib)
   let dtKriteriaPenilaian = ref([]);
   const fetchDataKriteriaPenilaianWithPagination = async (params) => {
      if (dtKriteriaPenilaian.value.length === 0) {
         try { dtKriteriaPenilaian.value = (await request({ url:`/kriteria-penilaian-datatable?draw=1&start=0&length=-1&brp_id=${brp_id}&loads=kriteriapenilaian_subcpmk` })).data.data } 
         catch (error) { return error }
      }

      let resultDatas = dtKriteriaPenilaian.value
      let countFilter = resultDatas.length

      if (params?.search.value) {
         resultDatas = dtKriteriaPenilaian.value.filter(kriteria_penilaian => {
            let str = kriteria_penilaian?.formatif_ind + kriteria_penilaian?.formatif_eng 
                     + kriteria_penilaian?.sumatif_ind + kriteria_penilaian?.sumatif_eng 
                     + kriteria_penilaian?.tagihan_ind + kriteria_penilaian?.tagihan_eng 
                     + kriteria_penilaian?.no_urut 
                     + kriteria_penilaian?.bobot_penilaian
                     + kriteria_penilaian.bentuk_evaluasi_detail.bentuk_evaluasi_nama_ind
            if (kriteria_penilaian.kriteriapenilaian_subcpmk && Array.isArray(kriteria_penilaian.kriteriapenilaian_subcpmk)) { kriteria_penilaian.kriteriapenilaian_subcpmk.forEach(sub => { str += sub.sub_cpmk.keterangan_ind; }); }
            return str.toLowerCase().includes(params?.search.value.toLowerCase())
         })

         countFilter = resultDatas.length
      }

      if (params?.order.length > 0) {
         resultDatas = resultDatas.sort((a, b) => {
            return params?.order[0].dir === 'asc' ? (a['no_urut'] - b['no_urut']) : (b['no_urut'] - a['no_urut']);
         })
      }
      resultDatas = resultDatas.slice(params?.start, params?.start + params?.length)

      return { draw: params?.draw, recordsTotal: dtKriteriaPenilaian.value.length, recordsFiltered: countFilter, data: resultDatas }
   };
   const fetchDataKriteriaPenilaian = async (data, callback) => {
      try {
         const response = await fetchDataKriteriaPenilaianWithPagination(data)
         const plainResponse = {
            ...toRaw(response),
            data: response.data.map(item => toRaw(item))
         }

         callback(plainResponse)
      } catch (error) {
         console.error('Error fetching data kriteria penilaian:', error)
      } finally {
         loading.value = false
      }
   }
   const columns = ref([
      { data: 'bentuk_evaluasi_detail', title: 'Bentuk Evaluasi', width: '25%', className: 'text-center px-3',
         render: function (data, type, row) {
            if (type === 'display' && row) {
               const bentukEvaluasi = data.bentuk_evaluasi_nama_ind +' - '+ data.deskripsi_ind;
               return `<td> <span>${bentukEvaluasi}</span> </td>`;
            } else {
               return `<td> <span>-</span> </td>`;
            }
         }
      },
      { data: 'kriteriapenilaian_subcpmk', title: 'Sub-CPMK', width: '12%',
         render: function (data, type, row) {
            if (type === 'display' && row && Array.isArray(data) && data.length > 0) {
               const subCpmks = [...new Set(data.map(item => item.sub_cpmk.keterangan_ind))].join(', ');
               return `<td> <span>${subCpmks}</span> </td>`;
            } else {
               return `<td> <span>-</span> </td>`;
            }
         }
      },
      { data: 'formatif_ind', title: 'Formatif', width: '18%' },
      { data: 'sumatif_ind', title: 'Sumatif', width: '18%' },
      { data: 'tagihan_ind', title: 'Tagihan (Bukti)', width: '18%' },
      { data: 'bobot_penilaian', title: 'Bobot Penilaian (%)', width: '9%', className: 'text-center px-3',
         render: function (data, type, row) {
            if (type === 'display' && row) return row.bobot_penilaian+`%`;
            else return '-';
         }
      }
   ]);
   const options = ref({ 
      serverSide: true, 
      ajax: fetchDataKriteriaPenilaian, 
      columns: columns.value,
      footerCallback: function (row, data) {
         let total = data.reduce((accum, current) => {
            return accum + (parseFloat(current.bobot_penilaian) || 0);
         }, 0);

         // eslint-disable-next-line no-undef
         $(row).find('#totalBobotPenilaian').html(total.toFixed(2) + '%');
      }
   })
   // === End Datatable ===
   
   async function fetchDataBRP() {
      try { dataBRP.value = (await request(`/brp/${brp_id}?loads=mk_kurikulum`)).data.mk_kurikulum } 
      catch (error) { console.error('Error:', error); } 
      finally { loading.value = false }
   }
   onMounted(async () => {
      // eslint-disable-next-line no-undef
      $('div.dataTables_length, div.dataTables_info').parent().removeClass('col-sm-12 col-md-6').closest('.row').removeClass('row').addClass('d-flex justify-content-between');
      // eslint-disable-next-line no-undef
      $('div.dataTables_filter, div.dataTables_paginate').parent().removeClass('col-sm-12 col-md-6');

      await nextTick(() => { fetchDataBRP(); });
   })
</script>