<template>
    <div class="row mb-5">
        <label class="form-label">
            <span v-if="isRequired" style="color: red">*</span>
            {{ labelInd }}
        </label>
        <div class="col-6">
            <flag-image bahasa="Indonesia" />
            <template v-if="ckeditor">
                <ckeditor ref="ckeditorInd" :editor="editor" :model-value="valueInd" @input="updateValueInd" @destroy="resetValueInd" :config="editorConfig" :disabled="isDisabled" @ready="onCloseEditorInd"></ckeditor>
                <Field type="hidden" :name="`${columnName+'_ind'}`" :model-value="hiddenValueInd" readonly />

                <div v-if="isRequired" :id="`${customName ? customName+'-error' : columnName+'_ind-error'}`" class="invalid-feedback">{{ errorInd ? errorInd : customErrorInd+' harus diisi' }}</div>
            </template>
            <template v-else>
                <Field
                    :type="inputType"
                    :name="`${customName ? customName : columnName+'_ind'}`"
                    :class="`form-control form-control-solid${ mode !== 'show' ? '-bg' : '' } ${customClass} ${errorInd ? 'is-invalid' : ''}`"
                    :placeholder="`Masukkan ${labelInd}`"
                    :disabled="isDisabled"
                    :as="inputType === 'textarea' ? 'textarea' : undefined"
                    :rows="inputType === 'textarea' ? rows : undefined"
                    :model-value="valueInd"
                    @input="updateValueInd"
                />
                <div v-if="isRequired" :id="`${customName ? customName+'-error' : columnName+'_ind-error'}`" class="invalid-feedback">{{ errorInd ? errorInd : customErrorInd+' harus diisi' }}</div>
            </template>
        </div>
        
        <div class="col-6" v-if="isEnglish">
            <flag-image bahasa="Inggris" />
            <template v-if="ckeditor">
                <ckeditor ref="ckeditorEng" :editor="editor" :model-value="valueEng" @input="updateValueEng" @destroy="resetValueEng" :config="editorConfig" :disabled="isDisabled" @ready="onCloseEditorEng"></ckeditor>
                <Field type="hidden" :name="`${columnName+'_eng'}`" :model-value="hiddenValueEng" readonly />

                <div v-if="isRequired" :id="`${columnName+'_eng-error'}`" class="invalid-feedback">{{ errorEng ? errorEng : customErrorEng+' harus diisi' }}</div>
            </template>
            <template v-else>
                <Field
                    :type="inputType"
                    :name="`${columnName+'_eng'}`"
                    :class="`form-control form-control-solid${ mode !== 'show' ? '-bg' : '' } ${errorEng ? 'is-invalid' : ''}`"
                    :placeholder="`Input ${labelEng}`"
                    :disabled="isDisabled"
                    :as="inputType === 'textarea' ? 'textarea' : undefined"
                    :rows="inputType === 'textarea' ? rows : undefined"
                    :model-value="valueEng"
                    @input="updateValueEng"
                />
                <div v-if="isRequired" :id="`${columnName+'_eng-error'}`" class="invalid-feedback">{{ errorEng ? errorEng : customErrorEng+' harus diisi' }}</div>
            </template>
        </div>
    </div>
</template>

<script>
import { Field } from 'vee-validate';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {},
            hiddenValueInd: '', hiddenValueEng: '',
            editorInstanceInd: '', editorInstanceEng: ''
        }
    },
    props: {
        labelInd: String,
        labelEng: String,
        columnName: String,
        customName: String,
        customClass: { 
            type: String,
            default: "" 
        },
        inputType: { 
            type: String, 
            default: "text" 
        },
        valueInd: { 
            type: [String, Number],
            default: "" 
        },
        valueEng: { 
            type: [String, Number],
            default: "" 
        },
        errorInd: String,
        errorEng: String,
        customErrorInd: String,
        customErrorEng: String,
        mode: String,
        isRequired: { 
            type: Boolean, 
            default: true 
        },
        isEnglish: { 
            type: Boolean, 
            default: true 
        },
        ckeditor: { 
            type: Boolean, 
            default: false 
        },
        rows: String,
    },
    components: { Field },
    computed: { isDisabled() { return this.mode === 'show'; } },
    watch: {
        valueInd(newValue) { this.hiddenValueInd = newValue; },
        valueEng(newValue) { this.hiddenValueEng = newValue; },
    },
    methods: {
        updateValueInd(newValue) { this.$emit('update:valueInd', newValue); this.hiddenValueInd = newValue; },
        updateValueEng(newValue) { this.$emit('update:valueEng', newValue); this.hiddenValueEng = newValue; },
        onCloseEditorInd(editor) { this.editorInstanceInd = editor; },
        onCloseEditorEng(editor) { this.editorInstanceEng = editor; },
        async resetValueInd() {
            try {
                if (this.editorInstanceInd) { await this.editorInstanceInd.setData(''); } 
                else { console.error('CKEditor instance not found.'); }
            } catch (error) {
                console.error('Error resetting CKEditor value:', error);
            }
        },
        async resetValueEng() {
            try {
                if (this.editorInstanceEng) { await this.editorInstanceEng.setData(''); } 
                else { console.error('CKEditor instance not found.'); }
            } catch (error) {
                console.error('Error resetting CKEditor value:', error);
            }
        },
    },
};
</script>