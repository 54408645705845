<template>
   <tr style="border:1px solid #c5c6cc !important;">
      <td colspan="3" style="padding-right:10px !important;">
         <table class="table table-row-bordered align-middle gy-3 display mb-0" style="border:1px solid rgb(197 198 204 / 32%) !important;">
            <thead>
               <tr class="text-center" style="border:1px solid rgb(197 198 204 / 32%) !important;">
                  <th style="width:80px !important;">No</th>
                  <th style="width:150px;">Nama</th>
                  <th>Deskripsi</th>
               </tr>
            </thead>
            <tbody style="border:1px solid rgb(197 198 204 / 32%) !important;">
               <tr v-for="(row, index) in props.CPLBK" :key="index">
                  <td class="text-center">{{ index + 1 }}</td>
                  <td>{{ row.bahan_kajian_nama_ind }}</td>
                  <td>{{ row.bahan_kajian_deskripsi_eng }}</td>
               </tr>
            </tbody>
         </table>
      </td>
   </tr>
</template>

<script setup>
   import { defineProps } from 'vue';

   const props = defineProps({ CPLBK: Array });
</script>