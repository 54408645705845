<template>
   <breadcrumb-content menu="Master Data" subMenu="Rumpun Matakuliah"/>
	<div class="card mb-5 mb-xxl-8">
      <div class="pb-10">
         <div class="card-body pt-9 pb-0">
            <h1 class="anchor fw-bold mb-5">Detail Rumpun Matakuliah</h1>
            <div class="p-5">
               <div class="row mb-5">
                  <label class="form-label">Nama Rumpun Matakuliah</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input class="form-control form-control-solid-bg" v-model="courseFamily.nama_ind" disabled="disabled" />
                  </div>
                  <div class="col-6">
                     <flag-image bahasa="Inggris" />
                     <input class="form-control form-control-solid-bg" v-model="courseFamily.nama_eng" disabled="disabled" />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Deskripsi Rumpun Matakuliah</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <textarea class="form-control form-control-solid-bg" rows="3" v-model="courseFamily.deskripsi_ind" disabled="disabled"></textarea>
                  </div>
                  <div class="col-6">
                     <flag-image bahasa="Inggris" />
                     <textarea class="form-control form-control-solid-bg" rows="3" v-model="courseFamily.deskripsi_eng" disabled="disabled"></textarea>
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Dibuat pada</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input class="form-control form-control-solid-bg" v-model="formattedCreatedAt" disabled="disabled" />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Diubah pada</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input class="form-control form-control-solid-bg" v-model="formattedUpdatedAt" disabled="disabled" />
                  </div>
               </div>
               <div class="mt-10">
                  <div class="col-lg-6">
                     <button @click="redirectToRumpunMatakuliahList" class="btn btn-secondary">Kembali</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup>
   import { onMounted, ref, computed } from 'vue';
   import { useRouter } from 'vue-router';
   import { formatReadableDate } from '@/utils/date';
   import request from '@/utils/request';

	const router = useRouter();
	const loading = ref(true);
   const id = router.currentRoute.value.params.id;
	const courseFamily = ref({});

	const formattedCreatedAt = computed(() => formatReadableDate(courseFamily.value.created_at));
	const formattedUpdatedAt = computed(() => formatReadableDate(courseFamily.value.updated_at));

	const redirectToRumpunMatakuliahList = () => router.push({ name: 'RumpunMatakuliah' });

	onMounted(async () => {
		try {
			const { data } = await request(`/rumpun-mata-kuliah/${id}`);
			courseFamily.value = data;
		} catch (error) {
			console.error('Error fetching rumpun matakuliah data: ', error);
		} finally {
			loading.value = false;
		}
	});
</script>