<template>
	<div>
		<breadcrumb-content menu="Master Data" subMenu="Jenis Kompetensi" />
		<div class="card mb-5 mb-xxl-8 placeholder-glow">
			<div class="pb-10">
				<div class="card-body pt-9 pb-0">
					<h1 class="anchor fw-bold mb-5">Ubah Jenis Kompetensi</h1>
					<div class="p-5">
						<Form
							id="form-jenis-kompetensi-update"
							class="kt-form"
							method="post"
							@submit="onSubmit"
							:validation-schema="schema"
							v-slot="{ errors }"
							ref="formRef"
						>
							<LoadingOverlay :loading="loading" />

							<FormInput
								labelInd="Nama Jenis Kompetensi"
								labelEng="Name of Competency Type"
								columnName="nama"
								:errorInd="errors.nama_ind"
								:errorEng="errors.nama_eng"
							/>
							<FormInput
								labelInd="Sumber"
								labelEng="Source"
								columnName="sumber"
								:errorInd="errors.sumber_ind"
								:errorEng="errors.sumber_eng"
								:isRequired="false"
							/>
							<FormInput
								inputType="textarea"
								rows="3"
								labelInd="Deskripsi"
								labelEng="Description"
								columnName="deskripsi"
								:errorInd="errors.deskripsi_ind"
								:errorEng="errors.deskripsi_eng"
								:isRequired="false"
							/>

							<div class="row mb-5">
								<label class="form-label">
									<span style="color: red">*</span>
									Status
								</label>
								<div class="col-6">
									<flag-image bahasa="Indonesia"/>

									<select class="form-control form-control-solid-bg is_aktif" data-control="select2" data-placeholder="-- Pilih Status --" v-model="selectedStatus">
										<option></option>
										<option v-for="data in status_list" :key="data.id" :value="data.val" :data-nama_eng="data.nama_eng"> {{ data.nama_ind }} </option>
									</select>
									<Field type="hidden" name="is_aktif" v-model="selectedStatus" readonly/>
								</div>
								<div class="col-6">
									<flag-image bahasa="Inggris"/>

									<input type="text" class="form-control form-control-solid-bg bg-light-dark is_aktif" readonly placeholder="Select Status" v-model="status_nama_eng">
								</div>
							</div>

							<div class="mt-10">
								<button type="submit" class="btn btn-primary">Simpan</button>
								&nbsp;
								<button @click="redirectToDataList" class="btn btn-secondary">Kembali</button>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script setup>
import { onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { Form, configure } from 'vee-validate'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'
import { sendRequest } from '@/api/common'

import { Field } from 'vee-validate'
import FormInput from '@/components/FormInput.vue'

const schema = {
	nama_ind: 'required',
	nama_eng: 'required',
}

const formRef = ref(null)
const loading = ref(true)
const router = useRouter()
const jenis_kompetensi_id = router.currentRoute.value.params.id

const redirectToDataList = () => router.push({ name: 'JenisKompetensi' })

const onSubmit = async values => {
	const param = {
		url: `/jenis-kompetensi/${jenis_kompetensi_id}`,
		method: 'PUT',
		body: values,
		loading: loading,
		context: 'JenisKompetensi'
	}

	return await sendRequest(param)
}

// custom message error
import { localize } from '@vee-validate/i18n'
const validationConfig = {
	id: {
		names: {
			nama_ind: 'Nama Jenis Kompetensi',
			nama_eng: 'Name of Competency Type',
		}
	}
}
configure({ generateMessage: localize(validationConfig) })


const status_list = ref([
    { id: 1, val: true, nama_ind: 'Aktif', nama_eng: 'Active' },
    { id: 2, val: false, nama_ind: 'Tidak Aktif', nama_eng: 'Inactive' },
]);
const selectedStatus = ref(null)
const status_nama_eng = ref('')

onMounted(async () => {
	try {
		// eslint-disable-next-line no-undef
		$("select").select2();

		const { data } = await request(`/jenis-kompetensi/${jenis_kompetensi_id}`)
		selectedStatus.value = data.is_aktif
		if (formRef.value) {
			formRef.value.setFieldValue('nama_ind', data.nama_ind)
			formRef.value.setFieldValue('nama_eng', data.nama_eng)
			formRef.value.setFieldValue('sumber_ind', data.sumber_ind)
			formRef.value.setFieldValue('sumber_eng', data.sumber_eng)
			formRef.value.setFieldValue('deskripsi_ind', data.deskripsi_ind)
			formRef.value.setFieldValue('deskripsi_eng', data.deskripsi_eng)
		}

		// eslint-disable-next-line no-undef
		$('body').on('change', 'select.is_aktif', async (event) => {
			selectedStatus.value = JSON.parse(event.target.value)
		})
		// eslint-disable-next-line no-undef
		$('select.is_aktif').val(JSON.stringify(selectedStatus.value)).trigger('change');
	} catch (error) {
		console.error('Error fetching data:', error)
	} finally {
		loading.value = false
	}
})

watch(selectedStatus, (newVal) => {
    const selectedStatus = status_list.value.find(status => status.val === newVal);
    if (selectedStatus) status_nama_eng.value = selectedStatus.nama_eng;
});
</script>