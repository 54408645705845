<template>
	<breadcrumb-content menu="Master Data" subMenu="Media & Teknologi" />

	<div class="card mb-5 mb-xxl-8">
		<div class="pb-10">
			<div class="card-body pt-9 pb-0">
				<h1 class="anchor fw-bold mb-5">Tambah Media & Teknologi</h1>
				<div class="p-5">
					<Form
						id="form-media-teknologi-create"
						class="kt-form"
						method="post"
						@submit="onSubmit"
						:validation-schema="schema"
						v-slot="{ errors }"
					>
						<LoadingOverlay :loading="loading" />

						<FormInput
							labelInd="Nama Media & Teknologi"
							labelEng="Name of Media & Technology"
							columnName="nama"
							:errorInd="errors.nama_ind"
							:errorEng="errors.nama_eng"
						/>
						<FormInput
							inputType="textarea"
							rows="3"
							labelInd="Deskripsi"
							labelEng="Description"
							columnName="deskripsi"
							:errorInd="errors.deskripsi_ind"
							:errorEng="errors.deskripsi_eng"
						/>

						<div class="mt-10">
							<button type="submit" class="btn btn-primary">Simpan</button>
							&nbsp;
							<button @click="redirectToDataList" class="btn btn-secondary">Kembali</button>
						</div>
					</Form>
				</div>
			</div>
		</div>
	</div>
</template>


<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { Form, configure } from 'vee-validate'
import LoadingOverlay from '@/components/LoadingOverlay'
import { sendRequest } from '@/api/common'

import FormInput from '@/components/FormInput.vue'

const schema = {
	nama_ind: 'required',
	nama_eng: 'required',
	deskripsi_ind: 'required',
	deskripsi_eng: 'required'
}

const loading = ref(false)
const router = useRouter()

const redirectToDataList = () => router.push({ name: 'MediaTeknologi' })

const onSubmit = async values => {
	const param = {
		url: '/media-teknologi',
		method: 'POST',
		body: values,
		loading: loading,
		context: 'MediaTeknologi'
	}

	return await sendRequest(param)
}

// custom message error
import { localize } from '@vee-validate/i18n'
const validationConfig = {
	id: {
		names: {
			nama_ind: 'Nama Media & Teknologi',
			nama_eng: 'Name of Media & Technology',
			deskripsi_ind: 'Deskripsi',
			deskripsi_eng: 'Description'
		}
	}
}
configure({ generateMessage: localize(validationConfig) })
</script>