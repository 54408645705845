<template>
   <breadcrumb-content menu="Profil" subMenu="Rumpun Ilmu" />
   <div class="card mb-5 mb-xxl-8">
      <div class="pb-10">
         <div class="card-body pt-9 pb-0">
            <h1 class="anchor fw-bold mb-5">Ubah Rumpun Ilmu</h1>
            <div class="p-5">
               <Form id="form-ri-update" class="kt-form" method="post" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
                  <LoadingOverlay :loading="loading" />
                  <div class="row mb-5">
                     <label class="form-label">Perguruan Tinggi</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <select class="form-control form-control-solid-bg university" data-control="select2" data-placeholder="-- Pilih Perguruan Tinggi --">
                           <option></option>
                           <option :key="university.pt_id" :value="university.pt_id" :selected="university.pt_id === group.pt_id_fk">{{ university.nama_ind }}</option>
                        </select>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input type="text" class="form-control form-control-solid-bg bg-light-dark" v-model="university.nama_eng" placeholder="Input University" readonly />
                     </div>
                  </div>
                  <div class="row mb-5">
                     <label class="form-label"><span style="color:red;">*</span> Kode Rumpun Ilmu</label>
                     <div class="col-6">
								<div style="display:flex; flex-wrap:nowrap;">
									<div style="width:80px;">
										<input class="form-control form-control-solid-bg" v-model="group.pt_kd_fk" readonly style="background-color:var(--kt-input-solid-bg);" />
									</div>
									<div style="width:100%;">
										<Field type="text" name="ri_kd" class="form-control form-control-solid-bg" v-model="group.ri_kd" placeholder="Masukkan Kode Rumpun Ilmu" />
									</div>
								</div>
                        <div v-if="errors.ri_kd" class="invalid-feedback" style="display:block;">Kode Rumpun Ilmu harus diisi</div>
                     </div>
                  </div>
                  <div class="row mb-5">
                     <label class="form-label"><span style="color: red">*</span> Nama Rumpun Ilmu </label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <Field type="text" name="nama_ind" class="form-control form-control-solid-bg" :class="{ 'is-invalid': errors.nama_ind }" v-model="group.nama_ind" placeholder="Masukkan Nama Rumpun Ilmu" />
                        <div id="nama_ind-error" class="invalid-feedback">{{ errors.nama_ind }}</div>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <Field type="text" name="nama_eng" class="form-control form-control-solid-bg" :class="{ 'is-invalid': errors.nama_eng }" v-model="group.nama_eng" placeholder="Input Group of Knowledge Name" />
                        <div id="nama_eng-error" class="invalid-feedback">{{ errors.nama_eng }}</div>
                     </div>
                  </div>
                  <div class="row mb-5">
                     <label class="form-label">Deskripsi Rumpun Ilmu</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <Field as="textarea" name="deskripsi_ind" class="form-control form-control-solid-bg" rows="3" v-model="group.deskripsi_ind" placeholder="Masukkan Deskripsi Rumpun Ilmu" />
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <Field as="textarea" name="deskripsi_eng" class="form-control form-control-solid-bg" rows="3" v-model="group.deskripsi_eng" placeholder="Input Group of Knowledge Description" />
                     </div>
                  </div>
                  <div class="row mb-5">
                     <label class="form-label">
                        <span style="color: red">*</span> Visi Rumpun Ilmu </label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <Field as="textarea" name="visi_ind" class="form-control form-control-solid-bg" rows="3" :class="{ 'is-invalid': errors.visi_ind }" v-model="group.visi_ind" placeholder="Masukkan Visi Rumpun Ilmu" />
                        <div id="visi_ind-error" class="invalid-feedback">{{ errors.visi_ind }}</div>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <Field as="textarea" name="visi_eng" class="form-control form-control-solid-bg" rows="3" :class="{ 'is-invalid': errors.visi_eng }" v-model="group.visi_eng" placeholder="Input Group of Knowledge Vision" />
                        <div id="visi_eng-error" class="invalid-feedback">{{ errors.visi_eng }}</div>
                     </div>
                  </div>
                  <div class="row mb-5">
                     <label class="form-label">
                        <span style="color: red">*</span> Misi Rumpun Ilmu </label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <Field as="textarea" name="misi_ind" class="form-control form-control-solid-bg" rows="3" :class="{ 'is-invalid': errors.misi_ind }" v-model="group.misi_ind" placeholder="Masukkan Misi Rumpun Ilmu" />
                        <div id="misi_ind-error" class="invalid-feedback">{{ errors.misi_ind }}</div>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <Field as="textarea" name="misi_eng" class="form-control form-control-solid-bg" rows="3" :class="{ 'is-invalid': errors.misi_eng }" v-model="group.misi_eng" placeholder="Input Group of Knowledge Mission" />
                        <div id="misi_eng-error" class="invalid-feedback">{{ errors.misi_eng }}</div>
                     </div>
                  </div>
                  <div class="mt-10">
                     <div class="col-lg-6">
                        <button type="submit" class="btn btn-primary">Simpan</button> &nbsp; <button @click="redirectToRumpunIlmuList" class="btn btn-secondary">Kembali</button>
                     </div>
                  </div>
               </Form>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup>
	import { onMounted, ref } from 'vue';
	import { useRouter } from 'vue-router';
	import { Form, Field } from 'vee-validate';
	import LoadingOverlay from '@/components/LoadingOverlay';
	import { sendRequest } from '@/api/common';
	import request from '@/utils/request';

	const schema = { ri_kd: 'required', nama_ind: 'required|max:200', nama_eng: 'required|max:200', visi_ind: 'required', visi_eng: 'required', misi_ind: 'required', misi_eng: 'required' };
	const loading = ref(true);
	const router = useRouter();
	const id = router.currentRoute.value.params.id;
	const university = ref({});
	const group = ref({});
	const redirectToRumpunIlmuList = () => router.push({ name: 'RumpunIlmu' });
   const handleElSelect = (async ({ target, self }) => eval(`${ self }Detail`).value = (await request({ url: `${ target }` })).data );
	const onSubmit = (async (values) => await sendRequest({ url: `/ri/${ id }`, method: 'PUT', body: values, loading: loading, context: 'RumpunIlmu' }));

	onMounted(async () => {
      try {
         group.value = (await request(`/ri/${ id }`)).data;
         university.value = group.value.universitas;

         // eslint-disable-next-line no-undef
         $('select.university').select2().on('change', () => handleElSelect({ target: '/pt', self: 'university' }));
		} catch (error) {
			console.error('Error fetching data: ', error);
		} finally {
			loading.value = false;
		}
	});
</script>