<template>
	<breadcrumb-content menu="Pembuatan Kurikulum" subMenu="CPL" />

	<div class="card mb-5 mb-xxl-8">
		<div class="pb-10">
			<div class="card-body pt-9 pb-0">
				<h1 class="anchor fw-bold mb-5">Detail CPL</h1>
				<div class="p-5">
                    <LoadingOverlay :loading="loading" />

                    <div class="row mb-5">
                        <label class="form-label">
                            Rumpun Ilmu
                        </label>
                        <div class="col-6">
                            <flag-image bahasa="Indonesia"/>

                            <select class="form-control form-control-solid-bg ri" data-control="select2" data-placeholder="-- Pilih Rumpun Ilmu --" v-model="dataFakultas.ri_id_fk" disabled>
                                <option></option>
                                <option v-for="data in ri_list" :key="data.ri_id" :value="data.ri_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind"> {{ data.nama_ind }} </option>
                            </select>
                            <Field type="hidden" id="ri_id" name="ri_id" v-model="selectedRI" readonly/>
                        </div>
                        <div class="col-6">
                            <flag-image bahasa="Inggris"/>

                            <input type="text" class="form-control form-control-solid-bg bg-light-dark rumpun_ilmu" readonly placeholder="Select Group of knowledge" v-model="ri_nama_eng">
                        </div>
                    </div>
                    
                    <div class="row mb-5">
                        <label class="form-label">
                            Fakultas
                        </label>
                        <div class="col-6">
                            <flag-image bahasa="Indonesia"/>

                            <select class="form-control form-control-solid-bg fakultas" data-control="select2" data-placeholder="-- Pilih Fakultas --" v-model="dataDepartemen.fakultas_id_fk" disabled>
                                <option></option>
                                <option v-for="data in fakultas_list" :key="data.fakultas_id" :value="data.fakultas_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind"> {{ data.nama_ind }} </option>
                            </select>
                            <Field type="hidden" id="fakultas_id" name="fakultas_id" v-model="selectedFakultas" readonly/>
                        </div>
                        <div class="col-6">
                            <flag-image bahasa="Inggris"/>

                            <input type="text" class="form-control form-control-solid-bg bg-light-dark fakultas" readonly placeholder="Select Faculty" v-model="fakultas_nama_eng">
                        </div>
                    </div>

                    <div class="row mb-5">
                        <label class="form-label">
                            Departemen
                        </label>
                        <div class="col-6">
                            <flag-image bahasa="Indonesia"/>

                            <select class="form-control form-control-solid-bg departemen" data-control="select2" data-placeholder="-- Pilih Departemen --" v-model="dataProdi.departemen_id_fk" disabled>
                                <option></option>
                                <option v-for="data in departemen_list" :key="data.departemen_id" :value="data.departemen_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind"> {{ data.nama_ind }} </option>
                            </select>
                            <Field type="hidden" id="departemen_id" name="departemen_id" v-model="selectedDepartemen" readonly/>
                        </div>
                        <div class="col-6">
                            <flag-image bahasa="Inggris"/>

                            <input type="text" class="form-control form-control-solid-bg bg-light-dark departemen" readonly placeholder="Select Department" v-model="departemen_nama_eng">
                        </div>
                    </div>

                    <div class="row mb-5">
                        <label class="form-label">
                            Program Studi
                        </label>
                        <div class="col-6">
                            <flag-image bahasa="Indonesia"/>

                            <select class="form-control form-control-solid-bg prodi" data-control="select2" data-placeholder="-- Pilih Program Studi --" v-model="dataKurikulum.prodi_id_fk" disabled>
                                <option></option>
                                <option v-for="data in prodi_list" :key="data.prodi_id" :value="data.prodi_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind"> {{ data.nama_ind }} </option>
                            </select>
                            <Field type="hidden" id="prodi_id" name="prodi_id" v-model="selectedProdi" readonly/>
                        </div>
                        <div class="col-6">
                            <flag-image bahasa="Inggris"/>

                            <input type="text" class="form-control form-control-solid-bg bg-light-dark prodi" readonly placeholder="Select Study Program" v-model="prodi_nama_eng">
                        </div>
                    </div>

                    <div class="row mb-5">
                        <label class="form-label">
                            Jenjang
                        </label>
                        <div class="col-6">
                            <flag-image bahasa="Indonesia"/>

                            <input type="text" class="form-control form-control-solid-bg bg-light-dark" readonly placeholder="-- Pilih Level --" v-model="jenjang_nama_ind">
                        </div>
                        <div class="col-6">
                            <flag-image bahasa="Inggris"/>

                            <input type="text" class="form-control form-control-solid-bg bg-light-dark" readonly placeholder="Select Level" v-model="jenjang_nama_eng">
                        </div>
                    </div>

                    <div class="row mb-5">
                        <label class="form-label">
                            Kurikulum
                        </label>
                        <div class="col-6">
                            <flag-image bahasa="Indonesia"/>

                            <select class="form-control form-control-solid-bg kurikulum" data-control="select2" data-placeholder="-- Pilih Kurikulum --" v-model="dataKurikulum.kurikulum_id" disabled>
                                <option></option>
                                <option v-for="data in kurikulum_list" :key="data.kurikulum_id" :value="data.kurikulum_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind" :data-kurikulum_kd="data.kurikulum_kd" :data-is_aktif="data.is_aktif === 1 ? 'Aktif' : 'Tidak Aktif'"> {{ data.nama_ind }} </option>
                            </select>

                            <Field type="hidden" id="kurikulum_id" name="kurikulum_id" v-model="selectedKurikulum" readonly/>
                            <Field type="hidden" id="kurikulum_kd" name="kurikulum_kd" v-model="kurikulumKD" readonly/>
                        </div>
                        <div class="col-6">
                            <flag-image bahasa="Inggris"/>

                            <div class="form-control form-control-solid-bg bg-light-dark" v-if="getCurriculumEng" v-html="getCurriculumEng"></div>
                            <div class="form-control form-control-solid-bg bg-light-dark text-gray-500" v-else>Select Curriculum</div>
                            <input type="hidden" class="form-control form-control-solid-bg bg-light-dark kurikulum" readonly :value="kurikulumKD" placeholder="Select Curriculum">
                        </div>
                    </div>

                    <hr />
                    
                    <FormInput
                        labelInd="CPL Kode"
                        customName="cpl_kd"
                        customClass="cpl_kd"
                        customErrorInd="CPL Kode"
                        :valueInd="dataCPL.cpl_kd"
                        :isEnglish="false"
                        mode="show"
                    />
                    <FormInput
                        labelInd="Nama CPL"
                        labelEng="Name Of CPL"
                        columnName="nama"
                        customErrorInd="Nama CPL"
                        customErrorEng="Name Of CPL"
                        :valueInd="dataCPL.name_ind"
                        :valueEng="dataCPL.name_eng"
                        mode="show"
                    />
                    <FormInput
                        inputType="textarea"
                        rows="5"
                        labelInd="Deskripsi"
                        labelEng="Description"
                        columnName="deskripsi"
                        :valueInd="dataCPL.deskripsi_ind"
                        :valueEng="dataCPL.deskripsi_eng"
                        :isRequired="false"
                        mode="show"
                    />
                    <FormInput
                        inputType="textarea"
                        rows="5"
                        labelInd="Sub CPL"
                        labelEng="Sub CPL"
                        columnName="sub_cpl"
                        :valueInd="dataCPL.sub_cpl_ind"
                        :valueEng="dataCPL.sub_cpl_eng"
                        :isRequired="false"
                        :ckeditor="true"
                        mode="show"
                    />

                    <!-- <FormInput
                        labelInd="Nilai CPL"
                        customName="cpl_nilai"
                        customClass="cpl_nilai"
                        :valueInd="dataCPL.cpl_nilai"
                        :isEnglish="false"
                        :isRequired="false"
                        mode="show"
                    /> -->

                    <div class="row mb-5">
                        <label class="form-label">
                            Taksonomi Bloom
                        </label>
                        <div class="col-6">
                            <flag-image bahasa="Indonesia"/>

                            <select class="form-control form-control-solid-bg taksonomi_bloom" data-control="select2" data-placeholder="-- Pilih Taksonomi Bloom --" multiple disabled>
                                <option></option>
                                <option v-for="data in tb_list" :key="data.taksonomi_h_id" :value="data.taksonomi_h_id" :data-nama_eng="data.nama_eng" :selected="selectedTB.includes(data.taksonomi_h_id)"> {{ data.nama_ind }} </option>
                            </select>
                            <Field type="hidden" id="taksonomi_h_id" name="taksonomi_h_id" v-model="selectedTB" readonly/>
                        </div>
                        <div class="col-6">
                            <flag-image bahasa="Inggris"/>

                            <textarea class="form-control form-control-solid-bg bg-light-dark taksonomi_bloom" rows="1" readonly placeholder="Select Bloom's Taxonomy" v-model="tb_nama_eng"></textarea>
                        </div>
                    </div>
                    
                    <div class="row mb-5">
                        <label class="form-label">
                            <span style="color: red">*</span>
                            Taksonomi Bloom Detail
                        </label>
                        <div class="col-6">
                            <flag-image bahasa="Indonesia"/>

                            <select class="form-control form-control-solid-bg taksonomi_bloom_detail" data-control="select2" data-placeholder="-- Pilih Taksonomi Bloom Detail --" multiple v-model="selectedTBD" disabled>
                                <option></option>
                                <option v-for="data in tbd_list" :key="data.taksonomi_d_id" :value="data.taksonomi_d_id" :data-nama_eng="data.nama_eng"> {{ data.taksonomi_h_kd + ' - ' + data.nama_ind }} </option>
                            </select>
                            <Field type="hidden" id="taksonomi_d_id" name="taksonomi_d_id" v-model="selectedTBD" readonly/>
                            <div id="taksonomi_d_id-error" class="invalid-feedback">Taksonomi Bloom Detail harus diisi</div>
                        </div>
                        <div class="col-6">
                            <flag-image bahasa="Inggris"/>

                            <textarea class="form-control form-control-solid-bg bg-light-dark taksonomi_bloom_detail" rows="1" readonly placeholder="Select Bloom's Taxonomy Details" v-model="tbd_nama_eng"></textarea>
                        </div>
                    </div>

                    <div class="cpl-kk-kompetensi">
                        <hr />                              
                        
                        <div class="cpl-kk mb-5">
                            <div class="d-flex justify-content-between align-items-center">
                                <h3>CPL Terhadap KK</h3>
                            </div>
                            <div class="table-responsive mt-3">
                                <table id="table" class="table table-row-bordered align-middle gy-3 display" style="border:1px solid #c5c6cc !important;">
                                    <thead>
                                        <tr style="border:1px solid #c5c6cc !important; font-weight:600;">
                                            <th class="text-center" style="width: 5% !important;">No</th>
                                            <th class="text-center" style="width: 20% !important;">Jenis Kerangka Kerja</th>
                                            <th class="text-center px-3">Kerangka Kerja</th>
                                        </tr>
                                    </thead>
                                    <tbody style="border:1px solid #c5c6cc !important;">
                                        <tr v-for="(dataGroup, index) in listCPLKKDetail" :key="index">
                                            <td class="text-center">{{ index + 1 }}</td>
                                            <td class="text-center">{{ dataGroup.jenis_kk_nama_ind }}</td>
                                            <td>
                                                <ol class="m-1 px-5">
                                                    <li v-for="(item, subIndex) in dataGroup.items" :key="subIndex" class="my-2" style="text-align: justify;">
                                                        <span class="fw-bold">{{ item.kk_kd }}</span> | <span>{{ item.nama_ind }}</span>
                                                    </li>
                                                </ol>
                                            </td>
                                        </tr>
                                        <tr v-if="listCPLKKDetail.length === 0">
                                            <td class="text-center fw-bold" colspan="3">Belum ada data</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                        <div class="cpl-kompetensi">
                            <div class="d-flex justify-content-between align-items-center">
                                <h3>CPL Terhadap Kompetensi</h3>
                            </div>
                            <div class="table-responsive mt-3">
                                <table id="table" class="table table-row-bordered align-middle gy-3 display" style="border:1px solid #c5c6cc !important;">
                                    <thead>
                                        <tr style="border:1px solid #c5c6cc !important; font-weight:600;">
                                            <th class="text-center" style="width: 5% !important;">No</th>
                                            <th class="text-center" style="width: 20% !important;">Jenis Kompetensi</th>
                                            <th class="text-center px-3">Kompetensi</th>
                                        </tr>
                                    </thead>
                                    <tbody style="border:1px solid #c5c6cc !important;">
                                        <tr v-for="(dataGroup, index) in listCPLKompDetail" :key="index">
                                            <td class="text-center">{{ index + 1 }}</td>
                                            <td class="text-center">{{ dataGroup.jenis_kompetensi_nama_ind }}</td>
                                            <td>
                                                <ol class="m-1 px-5">
                                                    <li v-for="(item, subIndex) in dataGroup.items" :key="subIndex" class="my-2" style="text-align: justify;">
                                                        <span class="fw-bold">{{ item.kompetensi_kd }}</span> | <span>{{ item.name_ind }}</span>
                                                    </li>
                                                </ol>
                                            </td>
                                        </tr>
                                        <tr v-if="listCPLKompDetail.length === 0">
                                            <td class="text-center fw-bold" colspan="3">Belum ada data</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="mt-10">
                        <button @click="redirectToDataList" class="btn btn-secondary">Kembali</button>
                    </div>
				</div>
			</div>
		</div>
	</div>
</template>


<script setup>
import { onMounted, ref, computed, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'

import { Field } from 'vee-validate'
import FormInput from '@/components/FormInput.vue'

const loading = ref(false)
const router = useRouter()
const cpl_id = router.currentRoute.value.params.id

const redirectToDataList = () => router.push({ name: 'CPL' })

const ri_list = ref([])
const fakultas_list = ref([])
const departemen_list = ref([])
const prodi_list = ref([])
const kurikulum_list = ref([])
const tb_list = ref([])
const tbd_list = ref([])
const jenis_kk_list = ref([])
const jenis_kompetensi_list = ref([])

const ri_nama_eng = ref('')
const fakultas_nama_eng = ref('')
const departemen_nama_eng = ref('')
const prodi_nama_eng = ref('')
const jenjang_nama_ind = ref('')
const jenjang_nama_eng = ref('')
const tb_nama_eng = ref('')
const tbd_nama_eng = ref('')

const selectedRI = ref('')
const selectedFakultas = ref('')
const selectedDepartemen = ref('')
const selectedProdi = ref('')
const selectedKurikulum = ref('')
const selectedTB = ref([])
const selectedTBD = ref([])

const hiddenValueCPLKD = ref('')

const dataCPL = ref({})
const dataKurikulum = ref({})
const dataProdi = ref({})
const dataDepartemen = ref({})
const dataFakultas = ref({})
const dataRI = ref({})

const kurikulumKD = ref('')
const renderCustomOption = () => {
    const customSelect = (ress) => {
        if (!ress.id) return ress.text;

        // eslint-disable-next-line no-undef
        return $(`<label style="display:flex; flex-direction:column; cursor:pointer;"><span style="font-size:10px;"><b>${ $(ress.element).data('kurikulum_kd') } (${ $(ress.element).data('is_aktif') })</b></span><span style="font-size:14px;">${ $(ress.element).data('nama_ind') }</span></label>`);
    };
    // eslint-disable-next-line no-undef
    $('select.kurikulum').select2({ templateResult: customSelect, templateSelection: customSelect });
}
const getCurriculumEng = computed(() => {
	if (!kurikulumKD.value) return '';

    const selectedData = kurikulum_list.value.find(data => data.kurikulum_kd === kurikulumKD.value);
    if (selectedData) {
        const { kurikulum_kd, is_aktif, nama_eng } = selectedData;
        return `<label style="display:flex; flex-direction:column; cursor:pointer;">
                <span style="font-size:10px;"><b>${kurikulum_kd} (${is_aktif === 1 ? 'Active' : 'Not Active'})</b></span>
                <span style="font-size:14px;">${nama_eng}</span>
                </label>`;
    }
    return '';
})

async function fetchDataKkKompetensi(relationTo) {
    loading.value = true;

    try {
        if (relationTo === 'kk') {
            const getDataJenisKK = await request(`/jenis-kerangka-kerja`)
            jenis_kk_list.value = getDataJenisKK.data.data
        } 
        if (relationTo === 'kompetensi') {
            const getDataJenisKomp = await request(`/jenis-kompetensi`)
            jenis_kompetensi_list.value = getDataJenisKomp.data.data
        }
    } catch (error) {
        console.error('Error:', error);
    } finally {
		loading.value = false
	}
}

onMounted(async () => {
    try {
        const getDataCPL = await request(`/cpl/${cpl_id}`)
		dataCPL.value = getDataCPL.data
        hiddenValueCPLKD.value = dataCPL.value.cpl_kd
        
        const getDataKurikulum = await request(`/kurikulum/${dataCPL.value.kurikulum_id_fk}`)
		dataKurikulum.value = getDataKurikulum.data
		kurikulumKD.value = getDataKurikulum.data.kurikulum_kd

		const getDataProdi = await request(`/prodi/${dataKurikulum.value.prodi_id_fk}`)
		dataProdi.value = getDataProdi.data
		
		const getDataDepartemen = await request(`/departemen/${dataProdi.value.departemen_id_fk}`)
		dataDepartemen.value = getDataDepartemen.data
		
		const getDataFakultas = await request(`/fakultas/${dataDepartemen.value.fakultas_id_fk}`)
		dataFakultas.value = getDataFakultas.data
		
		const getDataRI = await request(`/ri/${dataFakultas.value.ri_id_fk}`)
		dataRI.value = getDataRI.data
        
		// === Data ===
        const response = await request(`/ri?per_page=-1`)
		ri_list.value = response.data.data
		ri_nama_eng.value = dataRI.value.nama_eng
		selectedRI.value = dataRI.value.ri_id

		fakultas_list.value = getDataRI.data.fakultas
		fakultas_nama_eng.value = dataFakultas.value.nama_eng
		selectedFakultas.value = dataFakultas.value.fakultas_id

		departemen_list.value = getDataFakultas.data.departemen
		departemen_nama_eng.value = dataDepartemen.value.nama_eng
		selectedDepartemen.value = dataDepartemen.value.departemen_id

		prodi_list.value = getDataDepartemen.data.prodi
		prodi_nama_eng.value = dataProdi.value.nama_eng
		selectedProdi.value = dataProdi.value.prodi_id

		jenjang_nama_ind.value = dataProdi.value.jenjang_nama_ind
		jenjang_nama_eng.value = dataProdi.value.jenjang_nama_eng

		kurikulum_list.value = getDataProdi.data.kurikulum
		selectedKurikulum.value = dataKurikulum.value.kurikulum_id

        
        const responseCPLTaksonomi = await request(`/cpl-taksonomi?cpl_id=${dataCPL.value.cpl_id}`)
        if (responseCPLTaksonomi.data.data.length > 0) {
            // Response TB
            const taksonomiData = responseCPLTaksonomi.data.data[0].taksonomi_d;
            tb_nama_eng.value = taksonomiData.map(item => item.taksonomi_h_nama_eng + ' (' + item.taksonomi_h_kd_fk + ')').join(', ');
            selectedTB.value = [...new Set(taksonomiData.map(item => item.taksonomi_h_id_fk))];
            
            // Response TBD
            const responseTBD = selectedTB.value.map(async (id) => {
                const response = await request(`/taksonomi-h/${id}`)
                return response.data.taksonomi_d
            })
            const tbdResults = await Promise.all(responseTBD)
            tbd_list.value = tbdResults.flat()
            tbd_nama_eng.value = taksonomiData.map(item => item.taksonomi_h_kd_fk + ' - ' + item.name_eng).join(', ');
            selectedTBD.value = [...new Set(taksonomiData.map(item => item.taksonomi_d_id_fk))];
            
            // eslint-disable-next-line no-undef
            let heightTB = $("span.select2-selection.taksonomi_bloom").outerHeight();
            document.querySelector("textarea.taksonomi_bloom").style.height = heightTB+'px';
    
            // eslint-disable-next-line no-undef
            let heightTBD = $("span.select2-selection.taksonomi_bloom_detail").outerHeight();
            document.querySelector("textarea.taksonomi_bloom_detail").style.height = heightTBD+'px';
        }

        // list TB
        const responseTB = await request(`/taksonomi-h?per_page=-1`)
        tb_list.value = responseTB.data.data
		// === End Data ===


        await fetchDataCPLKK();
        await fetchDataCPLKomp();

        await fetchDataKkKompetensi('kk');
        await fetchDataKkKompetensi('kompetensi');

        await nextTick(() => { initializeSelect2(); });
        renderCustomOption();
    } catch (error) {
		console.error('Error fetching data:', error)
	} 
})

const listCPLKKDetail = ref([]);
const cpl_kk_list_by_cpl_id = ref([])
const kk_list_data = ref([])
async function fetchDataCPLKK() {
    loading.value = true;

    try {        
        const responseCPLKK = await request(`/cpl-kk?cpl_id=${cpl_id}`)
        if (responseCPLKK.data.data.length > 0) {
            cpl_kk_list_by_cpl_id.value = responseCPLKK.data.data[0].kk
    
            const responseKK = await request('/kerangka-kerja?per_page=-1')
            kk_list_data.value = responseKK.data.data
    
            const combinedData = cpl_kk_list_by_cpl_id.value.map(cpl => {
                const kerangkaKerja = kk_list_data.value.find(kk => kk.kk_id === cpl.kk_id);
                return {
                    ...cpl,
                    jenis_kk_nama_ind: kerangkaKerja ? kerangkaKerja.jenis_kk_nama_ind : '',
                    kk_kd: kerangkaKerja ? kerangkaKerja.kk_kd : '',
                    nama_ind: kerangkaKerja ? kerangkaKerja.nama_ind : '',
                };
            });
    
            combinedData.sort((a, b) => {
                if (a.kk_kd < b.kk_kd) return -1;
                if (a.kk_kd > b.kk_kd) return 1;
                return 0;
            });
    
            // listCPLKKDetail.value = combinedData;
            listCPLKKDetail.value = combinedData.reduce((groups, item) => {
                const group = groups.find(g => g.jenis_kk_nama_ind === item.jenis_kk_nama_ind);
                if (group) {
                    group.items.push(item);
                } else {
                    groups.push({
                        jenis_kk_nama_ind: item.jenis_kk_nama_ind,
                        items: [item]
                    });
                }
                return groups;
            }, []);
        }
    } catch (error) {
        console.error('Error:', error);
    } finally {
		loading.value = false
	}
}

const listCPLKompDetail = ref([]);
const cpl_kompetensi_list_by_cpl_id = ref([])
const kompetensi_list_data = ref([])
async function fetchDataCPLKomp() {
    loading.value = true;

    try {        
        const responseCPLKomp = await request(`/cpl-kompetensi?cpl_id=${cpl_id}`)
        if (responseCPLKomp.data.data.length > 0) {
            cpl_kompetensi_list_by_cpl_id.value = responseCPLKomp.data.data[0].kompetensi
    
            const responseKomp = await request('/kompetensi?per_page=-1')
            kompetensi_list_data.value = responseKomp.data.data
    
            const combinedData = cpl_kompetensi_list_by_cpl_id.value.map(cpl => {
                const kompetensi = kompetensi_list_data.value.find(kompetensi => kompetensi.kompetensi_id === cpl.kompetensi_id);
                return {
                    ...cpl,
                    jenis_kompetensi_nama_ind: kompetensi ? kompetensi.jenis_kompetensi_nama_ind : '',
                    kompetensi_kd: kompetensi ? kompetensi.kompetensi_kd : '',
                    nama_ind: kompetensi ? kompetensi.nama_ind : '',
                };
            });
    
            combinedData.sort((a, b) => {
                if (a.kompetensi_kd < b.kompetensi_kd) return -1;
                if (a.kompetensi_kd > b.kompetensi_kd) return 1;
                return 0;
            });
    
            // listCPLKompDetail.value = combinedData;
            listCPLKompDetail.value = combinedData.reduce((groups, item) => {
                const group = groups.find(g => g.jenis_kompetensi_nama_ind === item.jenis_kompetensi_nama_ind);
                if (group) {
                    group.items.push(item);
                } else {
                    groups.push({
                        jenis_kompetensi_nama_ind: item.jenis_kompetensi_nama_ind,
                        items: [item]
                    });
                }
                return groups;
            }, []);
        }
    } catch (error) {
        console.error('Error:', error);
    } finally {
		loading.value = false
	}
}

// eslint-disable-next-line no-undef
const initializeSelect2 = () => { $("select").select2(); };
</script>

<style scoped>
.btn-close { background: none; width:auto; cursor: pointer; }
</style>