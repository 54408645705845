<template>
	<breadcrumb-content menu="Role Management" subMenu="Role" />
	<div class="card mb-5 mb-xxl-8">
		<div class="card-body pt-9 pb-0">
			<LoadingOverlay :loading="loading" />
			<router-link :to="{ name: 'CreateViewRole' }" class="btn btn-primary btn-sm mb-5">
				<span class="fa fa-plus"></span>
				Tambah Role
			</router-link>

			<SSDatatable
				ref="datatableRef"
				url="/role-datatable"
				primaryKey="role_id"
				:columns="columns"
				:show="handleShow"
				:edit="handleEdit"
				:delete="handleDelete"
			/>
		</div>
	</div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import LoadingOverlay from '@/components/LoadingOverlay'
import SSDatatable from '@/components/SSDatatable.vue'
import { sendRequest } from '@/api/common'

const datatableRef = ref(null)
const loading = ref(false)
const router = useRouter()
const columns = [
	{
		title: 'No',
		data: 'rownum',
		searchable: false,
		width: '8%',
		className: 'px-1'
	},
	{ title: 'Nama', data: 'role_name' },
	{ title: 'Deskripsi', data: 'description' },
	{ title: 'Aksi', data: 'actions', searchable: false }
]

const handleShow = async id => router.push({ name: 'ShowViewRole', params: { id: id } })
const handleEdit = async id => router.push({ name: 'EditViewRole', params: { id: id } })
const handleDelete = async id => {
	const param = {
		url: `/role/${id}`,
		method: 'DELETE',
		loading: loading,
		context: 'Role'
	}

	return await sendRequest(param)
}
</script>
