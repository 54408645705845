<template>
	<breadcrumb-content menu="Pembuatan Kurikulum" subMenu="Matriks CPL dan TPP" />

	<div class="card mb-5 mb-xxl-8">
		<div class="pb-10">
			<div class="card-body pt-9 pb-0">
				<h1 class="anchor fw-bold mb-5">Matriks CPL dan TPP</h1>
				<div class="p-5">
					<Form
						id="form-cpl-tpp-create"
						class="kt-form"
						method="post"
						@submit="onSubmit"
                        ref="formRef"
					>
						<LoadingOverlay :loading="loading" />

                        <div class="row mb-5">
                            <label class="form-label">
                                Rumpun Ilmu
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <select class="form-control form-control-solid-bg ri" data-control="select2" data-placeholder="-- Pilih Rumpun Ilmu --" v-model="selectedRI">
                                    <option></option>
                                    <option v-for="data in ri_list" :key="data.ri_id" :value="data.ri_id" :data-nama_eng="data.nama_eng"> {{ data.nama_ind }} </option>
                                </select>
                                <Field type="hidden" id="ri_id" name="ri_id" v-model="selectedRI" readonly/>
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <input type="text" class="form-control form-control-solid-bg bg-light-dark rumpun_ilmu" readonly placeholder="Select Group of knowledge" v-model="ri_nama_eng">
                            </div>
                        </div>
                        
                        <div class="row mb-5">
                            <label class="form-label">
                                Fakultas
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <select class="form-control form-control-solid-bg fakultas" data-control="select2" data-placeholder="-- Pilih Fakultas --" v-model="selectedFakultas">
                                    <option></option>
                                    <option v-for="data in fakultas_list" :key="data.fakultas_id" :value="data.fakultas_id" :data-nama_eng="data.nama_eng"> {{ data.nama_ind }} </option>
                                </select>
                                <Field type="hidden" id="fakultas_id" name="fakultas_id" v-model="selectedFakultas" readonly/>
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <input type="text" class="form-control form-control-solid-bg bg-light-dark fakultas" readonly placeholder="Select Faculty" v-model="fakultas_nama_eng">
                            </div>
                        </div>

                        <div class="row mb-5">
                            <label class="form-label">
                                Departemen
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <select class="form-control form-control-solid-bg departemen" data-control="select2" data-placeholder="-- Pilih Departemen --" v-model="selectedDepartemen">
                                    <option></option>
                                    <option v-for="data in departemen_list" :key="data.departemen_id" :value="data.departemen_id" :data-nama_eng="data.nama_eng"> {{ data.nama_ind }} </option>
                                </select>
                                <Field type="hidden" id="departemen_id" name="departemen_id" v-model="selectedDepartemen" readonly/>
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <input type="text" class="form-control form-control-solid-bg bg-light-dark departemen" readonly placeholder="Select Department" v-model="departemen_nama_eng">
                            </div>
                        </div>

                        <div class="row mb-5">
                            <label class="form-label">
                                Program Studi
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <select class="form-control form-control-solid-bg prodi" data-control="select2" data-placeholder="-- Pilih Program Studi --" v-model="selectedProdi">
                                    <option></option>
                                    <option v-for="data in prodi_list" :key="data.prodi_id" :value="data.prodi_id" :data-nama_eng="data.nama_eng"> {{ data.nama_ind }} </option>
                                </select>
                                <Field type="hidden" id="prodi_id" name="prodi_id" v-model="selectedProdi" readonly/>
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <input type="text" class="form-control form-control-solid-bg bg-light-dark prodi" readonly placeholder="Select Study Program" v-model="prodi_nama_eng">
                            </div>
                        </div>

                        <div class="row mb-5">
                            <label class="form-label">
                                Jenjang
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <input type="text" class="form-control form-control-solid-bg bg-light-dark" readonly placeholder="-- Pilih Level --" v-model="jenjang_nama_ind">
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <input type="text" class="form-control form-control-solid-bg bg-light-dark" readonly placeholder="Select Level" v-model="jenjang_nama_eng">
                            </div>
                        </div>

                        <div class="row mb-5">
                            <label class="form-label">
                                <span style="color: red">*</span>
                                Kurikulum
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <select class="form-control form-control-solid-bg kurikulum" data-control="select2" data-placeholder="-- Pilih Kurikulum --" v-model="selectedKurikulum">
                                    <option></option>
                                    <option v-for="data in kurikulum_list" :key="data.kurikulum_id" :value="data.kurikulum_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind" :data-kurikulum_kd="data.kurikulum_kd" :data-is_aktif="data.is_aktif === 1 ? 'Aktif' : 'Tidak Aktif'"> {{ data.nama_ind }} </option>
                                </select>

                                <Field type="hidden" id="kurikulum_id" name="kurikulum_id" v-model="selectedKurikulum" readonly/>
                                <Field type="hidden" id="kurikulum_kd" name="kurikulum_kd" v-model="kurikulumKD" readonly/>
                                <div id="kurikulum_id-error" class="invalid-feedback">Kurikulum harus diisi</div>
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <div class="form-control form-control-solid-bg bg-light-dark" v-if="getCurriculumEng" v-html="getCurriculumEng"></div>
                                <div class="form-control form-control-solid-bg bg-light-dark text-gray-500" v-else>Select Curriculum</div>
                                <input type="hidden" class="form-control form-control-solid-bg bg-light-dark kurikulum" readonly :value="kurikulumKD" placeholder="Select Curriculum">
                            </div>
                        </div>

                        <hr>
                        <div class="matriks-cpl-tpp">
                            <div class="table-container table-responsive mt-5">
                                <div :class="{'table-header': true, 'mb-n4': cpl_list.length === 0}">
                                    <table id="table" class="table table-row-bordered align-middle gy-3 border border-1 border-secondary">
                                        <thead>
                                            <tr :class="{'border-bottom border-1 border-secondary': true, 'bg-light-dark': cpl_list.length === 0, 'bg-dark text-white': cpl_list.length > 0}">
                                                <th class="text-center fs-3 fw-bold" :colspan="tpp_header_list.length + 1">Matrik hubungan CPL Prodi dengan Tujuan Pendidikan Program Studi</th>
                                            </tr>
                                            <tr class="bg-light-dark" style="font-weight:bold;" v-if="cpl_list.length > 0">
                                                <th class="text-center" rowspan="2" :style="{'width': cpl_list.length > 0 ? '50%' : '0'}">Deskripsi CPL Prodi</th>
                                                <th v-for="(header, index) in tpp_header_list" :key="header.tpp_id" :class="{'text-center': true, 'px-7': index === tpp_header_list.length - 1}" :style="{'width': cpl_list.length > 0 ? (50 / tpp_header_list.length + '%') : '0'}">{{ header.tpp_ket_ind }}</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                <div :class="{'table-body border border-1 border-secondary': true, 'scrolling': kurikulumKD}">
                                    <table id="table" :class="{'border-end border-1 border-secondary': cpl_list.length > 0}" :style="{'height': cpl_list.length === 0 ? '35px' : '0'}">
                                        <tbody>
                                            <tr v-for="(cpl, index) in cpl_list" :key="cpl.cpl_id">
                                                <td :class="{'text-center border-end border-1 border-secondary': true, 'border-bottom': index !== cpl_list.length - 1}" :style="{'width': cpl_list.length > 0 ? '7%' : '0'}">
                                                    {{ cpl.cpl_kd }}
                                                </td>
                                                <td :class="{'align-top border-end border-1 border-secondary p-2': true, 'border-bottom': index !== cpl_list.length - 1}" :style="{'width': cpl_list.length > 0 ? '43%' : '0', 'text-align': 'justify'}">
                                                    {{ cpl.nama_ind }}
                                                    <hr class="m-0">
                                                    <i class="text-dark fw-bold">{{ cpl.nama_eng }}</i>
                                                </td>
                                                <td v-for="(tppHeader, idxHeader) in tpp_header_list" :key="tppHeader.tpp_id" :class="{'text-center border-end border-1 border-secondary py-2': true, 'border-bottom': index !== cpl_list.length - 1, 'px-3': idxHeader === tpp_header_list.length - 1}" :style="{'width': cpl_list.length > 0 ? (50 / tpp_header_list.length + '%') : '0'}">
                                                    <div class="form-check form-check-custom form-check-solid form-check-lg d-flex justify-content-center align-items-center">
                                                        <input type="checkbox" name="tpp_id" class="form-check-input check-cpl-tpp" :checked="cpl.tpp[idxHeader].is_selected" v-model="cpl.tpp[idxHeader].is_selected" style="cursor: pointer;"/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-if="cpl_list.length === 0">
                                                <td class="text-center fw-bold" :colspan="tpp_header_list.length + 1">Belum ada data</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

						<div class="mt-10">
							<button type="submit" class="btn btn-primary">Simpan</button>
							&nbsp;
							<button @click="redirectToDataList" class="btn btn-secondary">Kembali</button>
						</div>
					</Form>
				</div>
			</div>
		</div>
	</div>
</template>


<script setup>
import Swal from 'sweetalert2';

import { onMounted, ref, computed, nextTick, watch } from 'vue'
import { useRouter } from 'vue-router'
import { Form } from 'vee-validate'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'
import { sendRequest } from '@/api/common'

import { Field } from 'vee-validate'

const formRef = ref(null)
const loading = ref(true)
const router = useRouter()
const kurikulum_id = router.currentRoute.value.params.kurikulum_id

const redirectToDataList = () => router.push({ name: 'MatriksCPLdanTPP' })

const onSubmit = async () => {
    const dataToSend = {
        kurikulum_kd: kurikulumKD.value,
        cpl: cpl_list.value.map(cpl => ({
            cpl_id: cpl.cpl_id,
            cpl_kd: cpl.cpl_kd,
            kurikulum_id_fk: cpl.kurikulum_id_fk,
            kurikulum_kd_fk: cpl.kurikulum_kd_fk,
            nama_ind: cpl.nama_ind,
            nama_eng: cpl.nama_eng,
            tpp: cpl.tpp.map(tpp => ({
                tpp_id: tpp.tpp_id,
                is_selected: tpp.is_selected
            }))
        }))
    };

    if (!kurikulumKD.value) {
        Swal.fire({ title: 'Harap memilih Kurikulum terlebih dahulu!', icon: 'error', confirmButtonText: 'OK' });
        return;
    } else {
        const param = {
            url: '/matrix-cpl-tpp',
            method: 'PUT',
            body: dataToSend,
            loading: loading,
            context: 'MatriksCPLdanTPP'
        }


        return await sendRequest(param)
    }
}

const ri_list = ref([])
const fakultas_list = ref([])
const departemen_list = ref([])
const prodi_list = ref([])
const kurikulum_list = ref([])

const ri_nama_eng = ref('')
const fakultas_nama_eng = ref('')
const departemen_nama_eng = ref('')
const prodi_nama_eng = ref('')
const jenjang_nama_ind = ref('')
const jenjang_nama_eng = ref('')

const selectedRI = ref('')
const selectedFakultas = ref('')
const selectedDepartemen = ref('')
const selectedProdi = ref('')
const selectedKurikulum = ref('')

const dataKurikulum = ref({})
const dataProdi = ref({})
const dataDepartemen = ref({})
const dataFakultas = ref({})
const dataRI = ref({})

const kurikulumKD = ref('')
const renderCustomOption = () => {
    const customSelect = (ress) => {
        if (!ress.id) return ress.text;

        // eslint-disable-next-line no-undef
        return $(`<label style="display:flex; flex-direction:column; cursor:pointer;"><span style="font-size:10px;"><b>${ $(ress.element).data('kurikulum_kd') } (${ $(ress.element).data('is_aktif') })</b></span><span style="font-size:14px;">${ $(ress.element).data('nama_ind') }</span></label>`);
    };
    // eslint-disable-next-line no-undef
    $('select.kurikulum').select2({ templateResult: customSelect, templateSelection: customSelect });
}
const getCurriculumEng = computed(() => {
	if (!kurikulumKD.value) return '';

    const selectedData = kurikulum_list.value.find(data => data.kurikulum_kd === kurikulumKD.value);
    if (selectedData) {
        const { kurikulum_kd, is_aktif, nama_eng } = selectedData;
        return `<label style="display:flex; flex-direction:column; cursor:pointer;">
                <span style="font-size:10px;"><b>${kurikulum_kd} (${is_aktif === 1 ? 'Active' : 'Not Active'})</b></span>
                <span style="font-size:14px;">${nama_eng}</span>
                </label>`;
    }
    return '';
})

async function filteredSelect() {
    // eslint-disable-next-line no-undef
    $('body').on('change', 'select.ri', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/ri/${id}`);
        selectedRI.value = id
		ri_nama_eng.value = data.nama_eng
        
        loading.value = true
		
		selectedFakultas.value = null
		selectedDepartemen.value = null
		selectedProdi.value = null
		selectedKurikulum.value = null

        fakultas_list.value = data.fakultas
        fakultas_nama_eng.value = null
        departemen_list.value = null
        departemen_nama_eng.value = null
        prodi_list.value = null
        prodi_nama_eng.value = null
        jenjang_nama_ind.value = null
        jenjang_nama_eng.value = null
        kurikulum_list.value = null
        kurikulumKD.value = null

        cpl_list.value = []
        tpp_header_list.value = []

        loading.value = false
    }).on('change', 'select.fakultas', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/fakultas/${id}`);
		selectedFakultas.value = id
        fakultas_nama_eng.value = data.nama_eng
        
        loading.value = true
		
        selectedDepartemen.value = null
		selectedProdi.value = null
		selectedKurikulum.value = null

        departemen_list.value = data.departemen
        departemen_nama_eng.value = null
        prodi_list.value = null
        prodi_nama_eng.value = null
        jenjang_nama_ind.value = null
        jenjang_nama_eng.value = null
        kurikulum_list.value = null
        kurikulumKD.value = null

        cpl_list.value = []
        tpp_header_list.value = []

        loading.value = false
    }).on('change', 'select.departemen', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/departemen/${id}`);
		selectedDepartemen.value = id
        departemen_nama_eng.value = data.nama_eng
        
        loading.value = true
		
        selectedProdi.value = null
		selectedKurikulum.value = null

        prodi_list.value = data.prodi
        prodi_nama_eng.value = null
        jenjang_nama_ind.value = null
        jenjang_nama_eng.value = null
        kurikulum_list.value = null
        kurikulumKD.value = null

        cpl_list.value = []
        tpp_header_list.value = []

        loading.value = false
    }).on('change', 'select.prodi', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/prodi/${id}`);
		selectedProdi.value = id
        prodi_nama_eng.value = data.nama_eng
        
        loading.value = true
		selectedKurikulum.value = null

        jenjang_nama_ind.value = data.jenjang_nama_ind
        jenjang_nama_eng.value = data.jenjang_nama_eng
        kurikulum_list.value = data.kurikulum
        kurikulumKD.value = null

        cpl_list.value = []
        tpp_header_list.value = []

        loading.value = false
    }).on('change', 'select.kurikulum', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/kurikulum/${id}`);
		selectedKurikulum.value = id
        kurikulumKD.value = data.kurikulum_kd
    })

	renderCustomOption();
}

const cpl_list = ref([])
const tpp_header_list = ref([])
async function fetchDataMatriksCPLTPP() {
    loading.value = true;

    try {
        const responseCPL = await request(`/form-matrix-cpl-tpp?kurikulum_kd=${kurikulumKD.value}`)
        cpl_list.value = responseCPL.data.data.cpl
        tpp_header_list.value = responseCPL.data.data.tpp_header
    } catch (error) {
        console.error('Error:', error);
    } finally {
		loading.value = false
	}
}

onMounted(async () => {
    try {
        const getDataKurikulum = await request(`/kurikulum/${kurikulum_id}`)
        dataKurikulum.value = getDataKurikulum.data
		kurikulumKD.value = getDataKurikulum.data.kurikulum_kd

		const getDataProdi = await request(`/prodi/${dataKurikulum.value.prodi_id_fk}`)
		dataProdi.value = getDataProdi.data
		
		const getDataDepartemen = await request(`/departemen/${dataProdi.value.departemen_id_fk}`)
		dataDepartemen.value = getDataDepartemen.data
		
		const getDataFakultas = await request(`/fakultas/${dataDepartemen.value.fakultas_id_fk}`)
		dataFakultas.value = getDataFakultas.data
		
		const getDataRI = await request(`/ri/${dataFakultas.value.ri_id_fk}`)
		dataRI.value = getDataRI.data

        // Response List Data
        const response = await request(`/ri`)
		ri_list.value = response.data.data
		ri_nama_eng.value = dataRI.value.nama_eng
		selectedRI.value = dataRI.value.ri_id

		fakultas_list.value = getDataRI.data.fakultas
		fakultas_nama_eng.value = dataFakultas.value.nama_eng
		selectedFakultas.value = dataFakultas.value.fakultas_id

		departemen_list.value = getDataFakultas.data.departemen
		departemen_nama_eng.value = dataDepartemen.value.nama_eng
		selectedDepartemen.value = dataDepartemen.value.departemen_id

		prodi_list.value = getDataDepartemen.data.prodi
		prodi_nama_eng.value = dataProdi.value.nama_eng
		selectedProdi.value = dataProdi.value.prodi_id

		jenjang_nama_ind.value = dataProdi.value.jenjang_nama_ind
		jenjang_nama_eng.value = dataProdi.value.jenjang_nama_eng

		kurikulum_list.value = getDataProdi.data.kurikulum
		selectedKurikulum.value = dataKurikulum.value.kurikulum_id
        // End Response List Data

        await nextTick(() => { initializeSelect2(); });
        filteredSelect();
    } catch (error) {
		console.error('Error fetching data:', error)
	}
})

// eslint-disable-next-line no-undef
const initializeSelect2 = () => { $("select").select2(); };

watch(kurikulumKD, (newVal) => { if (newVal) fetchDataMatriksCPLTPP(); });
</script>

<style scoped>
.table-container { position: relative; }
.table-header { overflow: hidden; }
.table-body table { width: 100%; }
.table-body thead, .table-body tfoot { display: none; }

.scrolling { max-height: 600px; overflow-y: auto; margin: -14px 0 25px 0; }
</style>