<template>
   <table class="table table-row-bordered align-middle gy-3 display mt-10" style="border:1px solid #c5c6cc !important;">
      <thead>
         <tr class="text-center" style="border:1px solid #c5c6cc !important; font-weight:600; background-color:#5014d0; color:white;">
            <th style="width:80px !important;">No</th>
            <th style="width:150px;">Nama CPL</th>
            <th>Deskripsi CPL</th>
         </tr>
      </thead>
      <tbody style="border:1px solid #c5c6cc !important;">
         <template v-if="props.selectedCPLBK.CPL.length > 0">
            <template v-for="(row, index) in props.selectedCPLBK.CPL" :key="index">
               <tr style="border:1px solid #c5c6cc !important; font-weight:bold;">
                  <td class="text-center">
                     <i class="fa fa-plus" @click="handleClick(row.cpl_id, $event)" style="border:1px solid; border-radius:3px; padding:2px; font-size:12px; cursor:pointer;"></i>&nbsp;&nbsp;{{ index + 1 }}
                  </td>
                  <td>{{ row.name_ind }}</td>
                  <td style="padding-right:10px;">
                     <span>{{ row.deskripsi_ind }}</span>
                     <hr class="m-0">
                     <i>{{ row.deskripsi_eng }}</i>
                  </td>
               </tr>
               <template v-if="status[`${ row.cpl_id }`]">
                  <CPLBKDetailTable :CPLBK="(props.selectedCPLBK.CPLBK).filter(v => v.cpl_id_fk === row.cpl_id)" />
               </template>
            </template>
         </template>
         <template v-else>
            <tr>
               <td class="text-center" colspan="3">Belun Ada Data</td>
            </tr>
         </template>
      </tbody>
   </table>
</template>

<script setup>
   import { defineProps, ref } from 'vue';
   import CPLBKDetailTable from './CPLBKDetailTable.vue';

   const props = defineProps({ selectedCPLBK: Object });
   const status = ref({});
   const handleClick = (async (id, event) => {
      const thisEl = event.target;
      if (thisEl.classList.contains('fa-plus')) {
         status.value[`${ id }`] = true;
         thisEl.classList.replace('fa-plus', 'fa-minus');
      }
      else {
         status.value[`${ id }`] = false;
         thisEl.classList.replace('fa-minus', 'fa-plus');
      }
   });
</script>