import { defineStore } from "pinia";
import { accessMenu } from '@/api/auth'

export const useAccessStore = defineStore({
  id: "accessStore",
  state: () => ({
    menu: [],
    roles: {},
    permissions: [],
  }),
  persist: true,
  actions: {
    async initAccess() {
        const access = await accessMenu()
        if ('menu' in access) {
          this.menu = access.menu;
          this.role = access.role;
          this.permissions = access.permissions;
        }

        this.accessInitialized = true;

        return access
    },
    clearUserAccess() {
      this.menu = [];
      this.roles = {};
      this.permissions = [];
    }
  },
});
