<template>
   <breadcrumb-content menu="Master Data" subMenu="Bentuk Evaluasi Detail"/>
	<div class="card mb-5 mb-xxl-8">
      <div class="pb-10">
         <div class="card-body pt-9 pb-0">
            <h1 class="anchor fw-bold mb-5">Ubah Bentuk Evaluasi Detail</h1>
            <div class="p-5">
               <Form id="form-bentuk-evaluasi-detail-update" class="kt-form" method="post" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
                  <LoadingOverlay :loading="loading" />
                  <div class="row mb-5">
                     <label class="form-label">Bentuk Evaluasi</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <select :class="`form-control form-control-solid-bg bentuk_evaluasi_id`" data-control="select2" data-placeholder="-- Pilih Bentuk Evaluasi --">
									<option></option>
								</select>
								<Field type="hidden" name="bentuk_evaluasi_id_fk" class="form-control form-control-solid-bg" v-model="selectForm" readonly />
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
								<input type="text" class="form-control form-control-solid-bg bg-light-dark nama_eng_bentuk_evaluasi" v-model="detailEvaluationForm.bentuk_evaluasi_nama_eng" placeholder="Input Evaluation Form" readonly />
                     </div>
                  </div>
                  <div class="row mb-5">
                     <label class="form-label">
								<span style="color: red">*</span>Detail Bentuk Evaluasi </label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <Field as="textarea" name="deskripsi_ind" class="form-control form-control-solid-bg" rows="3" v-model="detailEvaluationForm.deskripsi_ind" placeholder="Masukkan Bentuk Evaluasi Detail" />
								<div id="deskripsi_ind-error" class="invalid-feedback">{{ errors.deskripsi_ind }}</div>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <Field as="textarea" name="deskripsi_eng" class="form-control form-control-solid-bg" rows="3" v-model="detailEvaluationForm.deskripsi_eng" placeholder="Input Detail Evaluation Form" />
								<div id="deskripsi_eng-error" class="invalid-feedback">{{ errors.deskripsi_eng }}</div>
                     </div>
                  </div>
                  <div class="mt-10">
                     <div class="col-lg-6">
                        <button type="submit" class="btn btn-primary">Simpan</button> &nbsp; <button @click="redirectToBentukEvaluasiDetailList" class="btn btn-secondary">Kembali</button>
                     </div>
                  </div>
               </Form>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup>
	import { onMounted, ref } from 'vue';
	import { useRouter } from 'vue-router';
	import { Form, Field } from 'vee-validate';
	import LoadingOverlay from '@/components/LoadingOverlay';
	import { sendRequest } from '@/api/common';
	import request from '@/utils/request';

	const schema = {
		deskripsi_ind: 'required',
		deskripsi_eng: 'required'
	};

	const loading = ref(true);
	const router = useRouter();
	const id = router.currentRoute.value.params.id;
	const detailEvaluationForm = ref({});
	const selectForm = ref(null);

	const redirectToBentukEvaluasiDetailList = () => router.push({ name: 'BentukEvaluasiDetail' });

	const onSubmit = async values => {
		values.bentuk_evaluasi_id_fk = parseInt(values.bentuk_evaluasi_id_fk);
		const param = {
			url: `/bentuk-evaluasi-detail/${id}`,
			method: 'PUT',
			body: values,
			loading: loading,
			context: 'BentukEvaluasiDetail'
		};

		return await sendRequest(param);
	};

	onMounted(async () => {
		try {         
			// eslint-disable-next-line no-undef
			$('select').select2();

			const evaluationData = await request({ url:'/bentuk-evaluasi?per_page=-1' });
			
			const evaluationDetailData = await request(`/bentuk-evaluasi-detail/${id}`);
			detailEvaluationForm.value = evaluationDetailData.data;

			let optionEvaluationForm = '<option></option>';
			(evaluationData.data.data).map((v, k) => {
				optionEvaluationForm += `<option key='${k}' value='${v.bentuk_evaluasi_id}' data-nama_eng='${v.nama_eng}' ${v.bentuk_evaluasi_id == detailEvaluationForm.value.bentuk_evaluasi_id_fk ? 'selected=true' : ''}>${v.nama_ind}</option>`;
			});

			document.getElementsByClassName('bentuk_evaluasi_id')[0].innerHTML = optionEvaluationForm;

			selectForm.value = detailEvaluationForm.value.bentuk_evaluasi_id_fk;

			// eslint-disable-next-line no-undef
			$('body').on('change', 'select.bentuk_evaluasi_id', function() {
				const _this = document.getElementsByClassName('bentuk_evaluasi_id')[0];
				detailEvaluationForm.value.bentuk_evaluasi_nama_eng = _this.options[_this.selectedIndex].dataset.nama_eng;
            selectForm.value = _this.value;
			});
		} catch (error) {
			console.error('Error fetching bentuk evalausi detail data: ', error);
		} finally {
			loading.value = false;
		}
	});
</script>