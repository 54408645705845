<template>
	<div>
		<breadcrumb-content menu="Master Data" subMenu="Jenis Kompetensi" />
		<div class="card mb-5 mb-xxl-8 placeholder-glow">
			<div class="pb-10">
				<div class="card-body pt-9 pb-0">
					<h1 class="anchor fw-bold mb-5">Detail Jenis Kompetensi</h1>
					<div class="p-5">
						<LoadingOverlay :loading="loading" />

						<FormInput
							labelInd="Nama Jenis Kompetensi"
							columnName="nama"
							:valueInd="dataJK.nama_ind"
							:valueEng="dataJK.nama_eng"
							mode="show"
						/>
						<FormInput
							labelInd="Sumber"
							labelEng="Source"
							columnName="sumber"
							:valueInd="dataJK.sumber_ind ?? '-'"
							:valueEng="dataJK.sumber_eng ?? '-'"
							:isRequired="false"
							mode="show"
						/>
						<FormInput
							inputType="textarea"
							rows="3"
							labelInd="Deskripsi"
							columnName="deskripsi"
							:valueInd="dataJK.deskripsi_ind ? dataJK.deskripsi_ind : '-'"
							:valueEng="dataJK.deskripsi_eng ? dataJK.deskripsi_eng : '-'"
							:isRequired="false"
							mode="show"
						/>
						<FormInput
							labelInd="Status"
							customName="is_aktif"
							:valueInd="dataJK.is_aktif == true ? 'Aktif' : 'Tidak Aktif'"
							:valueEng="dataJK.is_aktif == true ? 'Active' : 'Inactive'"
							mode="show"
						/>

						<FormInput
							labelInd="Dibuat pada"
							columnName="created"
							:isEnglish="false"
							:valueInd="formattedCreatedAt ? formattedCreatedAt : '-'"
							:isRequired="false"
							mode="show"
						/>
						<FormInput
							labelInd="Diubah pada"
							columnName="updated"
							:isEnglish="false"
							:valueInd="formattedUpdatedAt ? formattedUpdatedAt : '-'"
							:isRequired="false"
							mode="show"
						/>

						<div class="mt-10">
							<button @click="redirectToDataList" class="btn btn-secondary">Kembali</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script setup>
import { onMounted, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'

import FormInput from '@/components/FormInput.vue'

const loading = ref(true)
const router = useRouter()
const dataJK = ref({})
const jenis_kompetensi_id = router.currentRoute.value.params.id

import { formatReadableDate } from '@/utils/date'
const formattedCreatedAt = computed(() => formatReadableDate(dataJK.value.created_at))
const formattedUpdatedAt = computed(() => formatReadableDate(dataJK.value.updated_at))

const redirectToDataList = () => router.push({ name: 'JenisKompetensi' })

onMounted(async () => {
	try {
		const { data } = await request(`/jenis-kompetensi/${jenis_kompetensi_id}`)
		dataJK.value = data
	} catch (error) {
		console.error('Error fetching data:', error)
	} finally {
		loading.value = false
	}
})
</script>