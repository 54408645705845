<template>
	<breadcrumb-content menu="Pembuatan Kurikulum" subMenu="Diagram Alir"/>
	<div class="card mb-5 mb-xxl-8">
		<div class="pb-10">
			<div class="card-body pt-9 pb-0">
				<div class="p-5">
					<div v-if="CourseInCurriculumFilter">
						<component :is="CourseInCurriculumFilter" form="CourseInCurriculumFilter" target="FlowDiagram" @FlowDiagram="recieveFlowDiagram" />
					</div>
					<div v-if="FlowDiagram">
						<component :is="FlowDiagram" form="FlowDiagram" target="CourseInCurriculumFilter" :data="detailInfo" :FlowData="FlowData" @PreConditionModal="recievePreConditionModal" @CourseInCurriculumFilter="recieveCourseInCurriculumFilter" />
					</div>
					<div v-if="PreConditionModal">
						<component :is="PreConditionModal" form="PreConditionModal" :PreCondition="PreCondition" @removePreConditionModal="recieveRemoveForm" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { onMounted, shallowRef, ref } from 'vue';
   import request from '@/utils/request';

	const loading = ref(false);
	const FlowData = ref({});
	const detailInfo = ref({});
	const PreCondition = ref({});
	let CourseInCurriculumFilter = shallowRef(null);
	let FlowDiagram = shallowRef(null);
	let PreConditionModal = shallowRef(null);
	const recieveRemoveForm = ((dynamic) => eval(dynamic).value = null );
	const recieveCourseInCurriculumFilter = (({ open, dismiss }) => {
		eval(dismiss).value = null;
		import(`./${ open }.vue`).then(module => {
			eval(open).value = module.default;
		}).then(() => {
			// eslint-disable-next-line no-undef
			$(`#${ open }`).slideDown(1000);
		});
	});
	const recieveFlowDiagram = (async ({ open, dismiss, data }) => {
		eval(dismiss).value = null;
		FlowData.value = (await request({ url:`/kurikulum-flowchart?kurikulum_kd=${ data.kurikulum_kd }` })).data.data;
		if (Object.keys(FlowData.value).length > 0) {
			import(`./${ open }.vue`).then(module => {
				eval(open).value = module.default;
			}).then(() => {
				// eslint-disable-next-line no-undef
				$(`#${ open }`).slideDown(1000);
				detailInfo.value = data;
			});
		}
	});
	const recievePreConditionModal = (({ open, data }) => {
		console.log(data);
		eval(open).value = null;
		import(`./${ open }.vue`).then(module => {
			eval(open).value = module.default;
		}).then(() => {
			// eslint-disable-next-line no-undef
			$(`#${ open }`).fadeIn(1000);
			PreCondition.value = data;
		});
	});

	onMounted(async () => {
		try {
			import(`./CourseInCurriculumFilter.vue`).then(module => {
				CourseInCurriculumFilter.value = module.default;
			}).then(() => {
				// eslint-disable-next-line no-undef
				$(`#CourseInCurriculumFilter`).slideDown(1000);
			});
		} catch (error) {
			console.error('Error fetching data: ', error);
		} finally {
			loading.value = false;
		}
	});
</script>