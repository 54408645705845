import request from "@/utils/request";

export async function accessMenu() {
	return request({
			url: "/access-menu",
			method: "get",
			data: [],
		})
		.then(res => {
			return res.data
		})
		.catch((error) => {
			console.log("catch error", error)
			return error
		})
}
