<template>
    <div data-kt-menu-trigger="click" class="menu-item menu-accordion" :class="{ show : isActive() }">
        <span class="menu-link">
            <span class="menu-icon">
                <span :class="icon" v-if="icon"></span>
            </span>
            <span class="menu-title" v-if="menuName">{{ menuName }}</span>
            <span class="menu-arrow"></span>
        </span>

        <div class="menu-sub menu-sub-accordion"  kt-hidden-height="322" :class="{ show : isActive() }">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            default: null
        },
        menuName: {
            type: String,
            default: null
        }
    },
    methods: {
        isActive(){
            let splitRoutePath = this.$route.path.split('/');
            return splitRoutePath[1] == this.menuName.toLowerCase().split(' ').join('-');
        }
    }
}
</script>