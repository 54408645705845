<template>
	<breadcrumb-content menu="Master Data" subMenu="Dosen" />
	<div class="card mb-5 mb-xxl-8">
		<div class="card-body pt-9 pb-0">
			<div class="table-responsive table-loading min-h-500px">
				<div v-if="Loading" class="table-loading-message">Loading...</div>
				<DataTable class="table table-row-bordered gy-5" :options="options" ref="tableRef" id="table">
					<thead>
						<tr>
							<th
								v-for="(column, index) in columns"
								:key="index"
								:class="{ 'text-center py-1': column.data === 'actions' }"
							>
								{{ column.title }}
							</th>
						</tr>
					</thead>
				</DataTable>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import DataTable from 'datatables.net-vue3'
import DataTablesLib from 'datatables.net'

DataTable.use(DataTablesLib)
import { useDosenStore } from '@/stores/dosenStore'
import { useRouter } from 'vue-router'
const dosenStore = useDosenStore()

const router = useRouter()

const tableRef = ref()

const Loading = ref(false)

const fetchData = async (data, callback) => {
	Loading.value = true
	try {
		const response = await dosenStore.fetchDosenWithPaginasi(data)
		callback(response)
	} catch (error) {
		console.error('Error fetching data dosen:', error)
	} finally {
		Loading.value = false
	}
}

const columns = ref([
	{
		data: 'rownum',
		title: 'No',
		width: '8%',
		searchable: false,
		orderable: false
	},
	{
		data: 'nip',
		title: 'NIP',
		orderable: false
	},
	{
		data: 'nama',
		title: 'Nama',
		orderable: false
	},
	{
		data: 'org',
		title: 'ORG',
		orderable: false
	},
	{
		title: 'Aksi',
		align: 'center',
		searchable: false,
		orderable: false,
		render: function (data, type, row) {
			return `
					<button
						class="btn btn-light-primary btn-sm btn-show-datatable"
						data-toggle="tooltip"
						data-placement="top"
						title="Detail Data"
						data-id="${row.nip}"
					>
							<i class="fa fa-search"></i>
					</button>
			`
		}
	}
])

const options = ref({
	serverSide: true,
	ajax: fetchData,
	columns: columns.value
})

const handleButtonClick = async event => {
	const target = event.target
	const button = target.closest('.btn-show-datatable')

	if (button) {
		event.stopPropagation()
		event.preventDefault()
		if (button.matches('.btn-show-datatable')) {
			router.push({ name: 'ShowViewDosen', params: { id: button.dataset.id } })
		}
	}
}

onMounted(async () => {
	document.addEventListener('click', handleButtonClick)
})

onBeforeUnmount(() => {
	document.removeEventListener('click', handleButtonClick)
})
</script>
