import request from '@/utils/requestSSO'


export async function getUserProfile() {
    try {
		const data = await request.get('/realms/main/account')
		return data
	} catch (error) {
		console.log(error?.message)
	}
}

export async function getUserInfo() {
    try {
		const data = await request.get('/realms/main/protocol/openid-connect/userinfo')
		return data
	} catch (error) {
		console.log(error?.message)
	}
}