<template>
    <div class="d-flex flex-column flex-root login-bg full-screen" id="kt_app_root">
        <div class="d-flex flex-column flex-column-fluid flex-lg-row">
            <div class="d-flex flex-center pt-15 pt-lg-0 px-10 fix-center">
                <div class="d-flex flex-column">
                    <div class="text-center">
                        <router-link to="#" class="mb-7">
                            <img alt="Logo" src="/favicon-brp-ui.png" width="200" />
                        </router-link>
                        <hr />
                        <h2 class="fw-normal m-0">
                            <b>𝘚𝘵𝘶𝘥𝘦𝘯𝘵 𝘓𝘪𝘧𝘦 𝘊𝘺𝘤𝘭𝘦 𝘔𝘢𝘯𝘢𝘨𝘦𝘮𝘦𝘯𝘵 (SLCM) - Kurikulum</b>
                        </h2>
                        <h2 class="fw-normal m-0">Universitas Indonesia</h2>
                        <div v-if="loading" class="flex-column pt-10">
                            <span class="loader"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue'
import { useAccessStore } from '@/stores/accessStore' 

const loading = ref(true)
const accessStore = useAccessStore()

const checkMenuAndRedirect = (startTime) => {
    const currentTime = new Date().getTime()
    const elapsedTime = currentTime - startTime

    if (accessStore.menu.length > 0) {
        window.location.href = "/" // Redirect to dashboard if menu is not empty
    } else if (elapsedTime >= 10000) { // Check if 10 seconds have passed
        window.location.href = "/unauthorized" // Redirect to unauthorized page
    } else {
        setTimeout(() => checkMenuAndRedirect(startTime), 1000) // Check again after 1 second
    }
}


const startTime = new Date().getTime() // Record the start time
checkMenuAndRedirect(startTime)

</script>

<style>
.fix-center {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 10px solid;
  border-color: rgba(253,224,71, 0.15) rgba(253,224,71, 0.25) rgba(253,224,71, 0.35) rgba(253,224,71, 0.5);
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
  
</style>