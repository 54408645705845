<template>
    <breadcrumb-content menu="Pembuatan Kurikulum" subMenu="Bahan Kajian"/>

    <div class="card mb-5 mb-xxl-8">
        <div class="card-body pt-9 pb-0">
            <LoadingOverlay :loading="loading" />
            <router-link :to="{ name: 'CreateViewBahanKajian'}" class="btn btn-primary mb-5">
                <span class="fa fa-plus"></span>
                Tambah Bahan Kajian
            </router-link>
            
            <SSDatatable
                ref="datatableRef"
                url="/cpl-bahan-kajian-datatable"
                primaryKey="cpl_id"
                :columns="columns"
                :show="handleShow"
                :edit="handleEdit"
                :delete="handleDelete"
            />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import LoadingOverlay from '@/components/LoadingOverlay'
import SSDatatable from '@/components/SSDatatable.vue'
import { sendRequest } from '@/api/common'

const datatableRef = ref(null)
const loading = ref(false)
const router = useRouter()

const columns = [
	{ title: 'No', data: 'rownum', searchable: false, width: '8%', className: 'px-1' },
	{ title: 'Deskripsi CPL Prodi', data: 'cpl_taksonomi', render: (data, type, row) => renderMenuCPL(data, type, row) },
    { title: 'Bahan Kajian', data: 'cpl_bahan_kajian', render: (data, type, row) => renderMenuBK(data, type, row) },
	{ title: 'Aksi', data: 'actions', searchable: false }
]

function renderMenuCPL(data, type, row) {
    if (type === 'display' && row) {
        const taksonomiHkdString = [...new Set(data.map(item => item.taksonomi_h_kd))].join(', ');

        return `
            <td>
                <p class="m-0" style="text-align: justify;">${row.deskripsi_cpl_prodi_ind}[${taksonomiHkdString}]</p>
            </td>
        `;
    } else {
        return `
            <td>
                <p class="m-0" style="text-align: justify;">${row.deskripsi_cpl_prodi_ind}</p>
            </td>
        `;
    }
}
function renderMenuBK(data, type, row) {
    if (type === 'display' && row) {
        let menuHTML = data
            .map((item, index, array) => {
                return `
                    <td style="text-align: justify;">
                        ${item.bahan_kajian_ind}
                        ${array.length > 1 && index < array.length - 1 ? '<hr class="my-3">' : ''}
                    </td>
                `;
            })
            .join('');
    
    return menuHTML;
    }

    return ''
}

const handleShow = async cpl_id => router.push({ name: 'ShowViewBahanKajian', params: { id: cpl_id } })
const handleEdit = async cpl_id => router.push({ name: 'EditViewBahanKajian', params: { id: cpl_id } })
const handleDelete = async cpl_id => {
	const param = {
		url: `/cpl-bahan-kajian-by-cpl?cpl_id=${cpl_id}`,   
		method: 'DELETE',
		loading: loading,
		context: 'BahanKajian'
	}

	return await sendRequest(param)
}
</script>