import request from '@/utils/requestUiServer'

const getOrgDetail = async (orgId = '') => {
	try {
		const data = await request.get(`/org/${orgId}`)
		return data.data
	} catch (error) {
		console.log(error.response?.message)
	}
}

export { getOrgDetail }
