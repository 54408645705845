<template>
	<breadcrumb-content menu="Pembuatan Kurikulum" subMenu="Bahan Kajian" />

	<div class="card mb-5 mb-xxl-8">
		<div class="pb-10">
			<div class="card-body pt-9 pb-0">
				<h1 class="anchor fw-bold mb-5">Detail Bahan Kajian</h1>
				<div class="p-5">
                    <LoadingOverlay :loading="loading" />

                    <div class="row mb-5">
                        <label class="form-label">
                            Rumpun Ilmu
                        </label>
                        <div class="col-6">
                            <flag-image bahasa="Indonesia"/>

                            <select class="form-control form-control-solid-bg ri" data-control="select2" data-placeholder="-- Pilih Rumpun Ilmu --" v-model="dataFakultas.ri_id_fk" disabled>
                                <option></option>
                                <option v-for="data in ri_list" :key="data.ri_id" :value="data.ri_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind"> {{ data.nama_ind }} </option>
                            </select>
                            <Field type="hidden" id="ri_id" name="ri_id" v-model="selectedRI" readonly/>
                        </div>
                        <div class="col-6">
                            <flag-image bahasa="Inggris"/>

                            <input type="text" class="form-control form-control-solid-bg bg-light-dark rumpun_ilmu" readonly placeholder="Select Group of knowledge" v-model="ri_nama_eng">
                        </div>
                    </div>
                    
                    <div class="row mb-5">
                        <label class="form-label">
                            Fakultas
                        </label>
                        <div class="col-6">
                            <flag-image bahasa="Indonesia"/>

                            <select class="form-control form-control-solid-bg fakultas" data-control="select2" data-placeholder="-- Pilih Fakultas --" v-model="dataDepartemen.fakultas_id_fk" disabled>
                                <option></option>
                                <option v-for="data in fakultas_list" :key="data.fakultas_id" :value="data.fakultas_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind"> {{ data.nama_ind }} </option>
                            </select>
                            <Field type="hidden" id="fakultas_id" name="fakultas_id" v-model="selectedFakultas" readonly/>
                        </div>
                        <div class="col-6">
                            <flag-image bahasa="Inggris"/>

                            <input type="text" class="form-control form-control-solid-bg bg-light-dark fakultas" readonly placeholder="Select Faculty" v-model="fakultas_nama_eng">
                        </div>
                    </div>

                    <div class="row mb-5">
                        <label class="form-label">
                            Departemen
                        </label>
                        <div class="col-6">
                            <flag-image bahasa="Indonesia"/>

                            <select class="form-control form-control-solid-bg departemen" data-control="select2" data-placeholder="-- Pilih Departemen --" v-model="dataProdi.departemen_id_fk" disabled>
                                <option></option>
                                <option v-for="data in departemen_list" :key="data.departemen_id" :value="data.departemen_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind"> {{ data.nama_ind }} </option>
                            </select>
                            <Field type="hidden" id="departemen_id" name="departemen_id" v-model="selectedDepartemen" readonly/>
                        </div>
                        <div class="col-6">
                            <flag-image bahasa="Inggris"/>

                            <input type="text" class="form-control form-control-solid-bg bg-light-dark departemen" readonly placeholder="Select Department" v-model="departemen_nama_eng">
                        </div>
                    </div>

                    <div class="row mb-5">
                        <label class="form-label">
                            Program Studi
                        </label>
                        <div class="col-6">
                            <flag-image bahasa="Indonesia"/>

                            <select class="form-control form-control-solid-bg prodi" data-control="select2" data-placeholder="-- Pilih Program Studi --" v-model="dataKurikulum.prodi_id_fk" disabled>
                                <option></option>
                                <option v-for="data in prodi_list" :key="data.prodi_id" :value="data.prodi_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind"> {{ data.nama_ind }} </option>
                            </select>
                            <Field type="hidden" id="prodi_id" name="prodi_id" v-model="selectedProdi" readonly/>
                        </div>
                        <div class="col-6">
                            <flag-image bahasa="Inggris"/>

                            <input type="text" class="form-control form-control-solid-bg bg-light-dark prodi" readonly placeholder="Select Study Program" v-model="prodi_nama_eng">
                        </div>
                    </div>

                    <div class="row mb-5">
                        <label class="form-label">
                            Jenjang
                        </label>
                        <div class="col-6">
                            <flag-image bahasa="Indonesia"/>

                            <input type="text" class="form-control form-control-solid-bg bg-light-dark" readonly placeholder="-- Pilih Level --" v-model="jenjang_nama_ind">
                        </div>
                        <div class="col-6">
                            <flag-image bahasa="Inggris"/>

                            <input type="text" class="form-control form-control-solid-bg bg-light-dark" readonly placeholder="Select Level" v-model="jenjang_nama_eng">
                        </div>
                    </div>

                    <div class="row mb-5">
                        <label class="form-label">
                            Kurikulum
                        </label>
                        <div class="col-6">
                            <flag-image bahasa="Indonesia"/>

                            <select class="form-control form-control-solid-bg kurikulum" data-control="select2" data-placeholder="-- Pilih Kurikulum --" v-model="dataKurikulum.kurikulum_id" disabled>
                                <option></option>
                                <option v-for="data in kurikulum_list" :key="data.kurikulum_id" :value="data.kurikulum_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind" :data-kurikulum_kd="data.kurikulum_kd" :data-is_aktif="data.is_aktif === 1 ? 'Aktif' : 'Tidak Aktif'"> {{ data.nama_ind }} </option>
                            </select>

                            <Field type="hidden" id="kurikulum_id" name="kurikulum_id" v-model="selectedKurikulum" readonly/>
                            <Field type="hidden" id="kurikulum_kd" name="kurikulum_kd" v-model="kurikulumKD" readonly/>
                        </div>
                        <div class="col-6">
                            <flag-image bahasa="Inggris"/>

                            <div class="form-control form-control-solid-bg bg-light-dark" v-if="getCurriculumEng" v-html="getCurriculumEng"></div>
                            <div class="form-control form-control-solid-bg bg-light-dark text-gray-500" v-else>Select Curriculum</div>
                            <input type="hidden" class="form-control form-control-solid-bg bg-light-dark kurikulum" readonly :value="kurikulumKD" placeholder="Select Curriculum">
                        </div>
                    </div>

                    <div class="row mb-5">
                        <label class="form-label">
                            <span style="color: red">*</span>
                            Capaian Pembelajaran Lulusan
                        </label>
                        <div class="col-6">
                            <flag-image bahasa="Indonesia"/>

                            <select class="form-control form-control-solid-bg cpl" data-control="select2" data-placeholder="-- Pilih Capaian Pembelajaran Lulusan --" v-model="dataCPL.cpl_id" disabled>
                                <option></option>
                                <option v-for="data in cpl_list" :key="data.cpl_id" :value="data.cpl_id" :data-nama_eng="data.name_eng" :data-nama_ind="data.name_ind"> {{ data.cpl_kd + ' - ' + data.name_ind  }} </option>
                            </select>
                            <Field type="hidden" id="cpl_id_fk" name="cpl_id_fk" v-model="selectedCPL" readonly/>
                            <Field type="hidden" id="cpl_id_kd" name="cpl_id_kd" v-model="selectedCPLKD" readonly/>
                            <div id="cpl_id_fk-error" class="invalid-feedback">CPL harus diisi</div>
                        </div>
                        <div class="col-6">
                            <flag-image bahasa="Inggris"/>

                            <input type="text" class="form-control form-control-solid-bg bg-light-dark cpl" readonly placeholder="Select Learning Outcomes" v-model="cpl_nama_eng">
                        </div>
                    </div>

                    <div class="row mb-5">
                        <label class="form-label">
                            Deskripsi Capaian Pembelajaran Lulusan
                        </label>
                        <div class="col-6">
                            <flag-image bahasa="Indonesia"/>
                            <textarea class="form-control form-control-solid-bg bg-light-dark" rows="5" readonly placeholder="Deskripsi CPL..." v-model="cpl_deskripsi_ind"></textarea>
                        </div>
                        <div class="col-6">
                            <flag-image bahasa="Inggris"/>
                            <textarea class="form-control form-control-solid-bg bg-light-dark" rows="5" readonly placeholder="Description CPL..." v-model="cpl_deskripsi_eng"></textarea>
                        </div>
                    </div>

                    <hr class="my-7" />
                    <div class="bahan-kajian">
                        <div class="d-flex justify-content-between align-items-center">
                            <h3>Data Bahan Kajian</h3>
                        </div>

                        <div class="table-responsive mt-3">
                            <table id="table" class="table table-row-bordered align-middle gy-3 display" style="border:1px solid #c5c6cc !important;">
                                <thead>
                                    <tr style="border:1px solid #c5c6cc !important; font-weight:600;">
                                        <th class="text-center" style="width: 5% !important;">No</th>
                                        <th class="text-center" style="width: 20% !important;">Nama Bahan Kajian</th>
                                        <th class="text-center">Deskripsi Bahan Kajian</th>
                                    </tr>
                                </thead>
                                <tbody style="border:1px solid #c5c6cc !important;">
                                    <tr v-for="(data, index) in bk_list_by_cpl_kd" :key="index">
                                        <td class="text-center">{{ index + 1 }}</td>
                                        <td>{{ data.nama_ind }}</td>
                                        <td class="px-3" style="text-align: justify;">{{ data.deskripsi_ind }}</td>
                                    </tr>
                                    <tr v-if="bk_list_by_cpl_kd.length === 0">
                                        <td class="text-center fw-bold" colspan="4">Belum ada data</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="mt-10">
                        <button @click="redirectToDataList" class="btn btn-secondary">Kembali</button>
                    </div>
				</div>
			</div>
		</div>
	</div>
</template>


<script setup>
import { onMounted, ref, computed, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import { configure } from 'vee-validate'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'

import { Field } from 'vee-validate'

const loading = ref(false)
const router = useRouter()
const cpl_id_fk = router.currentRoute.value.params.id

const redirectToDataList = () => router.push({ name: 'BahanKajian' })

// custom message error
import { localize } from '@vee-validate/i18n'
const validationConfig = {
	id: {
		names: {
            cpl_id_fk: 'Capaian Pembelajaran Lulusan',
            bahan_kajian_id_fk: 'Bahan Kajian',
		}
	}
}
configure({ generateMessage: localize(validationConfig) })

const ri_list = ref([])
const fakultas_list = ref([])
const departemen_list = ref([])
const prodi_list = ref([])
const kurikulum_list = ref([])
const cpl_list = ref([])

const ri_nama_eng = ref('')
const fakultas_nama_eng = ref('')
const departemen_nama_eng = ref('')
const prodi_nama_eng = ref('')
const jenjang_nama_ind = ref('')
const jenjang_nama_eng = ref('')
const cpl_nama_eng = ref('')
const cpl_deskripsi_ind = ref('')
const cpl_deskripsi_eng = ref('')

const selectedRI = ref('')
const selectedFakultas = ref('')
const selectedDepartemen = ref('')
const selectedProdi = ref('')
const selectedKurikulum = ref('')
const selectedCPL = ref('')
const selectedCPLKD = ref('')

const dataCPL = ref({})
const dataKurikulum = ref({})
const dataProdi = ref({})
const dataDepartemen = ref({})
const dataFakultas = ref({})
const dataRI = ref({})

const kurikulumKD = ref('')
const renderCustomOption = () => {
    const customSelect = (ress) => {
        if (!ress.id) return ress.text;

        // eslint-disable-next-line no-undef
        return $(`<label style="display:flex; flex-direction:column; cursor:pointer;"><span style="font-size:10px;"><b>${ $(ress.element).data('kurikulum_kd') } (${ $(ress.element).data('is_aktif') })</b></span><span style="font-size:14px;">${ $(ress.element).data('nama_ind') }</span></label>`);
    };
    // eslint-disable-next-line no-undef
    $('select.kurikulum').select2({ templateResult: customSelect, templateSelection: customSelect });
}
const getCurriculumEng = computed(() => {
	if (!kurikulumKD.value) return '';

    const selectedData = kurikulum_list.value.find(data => data.kurikulum_kd === kurikulumKD.value);
    if (selectedData) {
        const { kurikulum_kd, is_aktif, nama_eng } = selectedData;
        return `<label style="display:flex; flex-direction:column; cursor:pointer;">
                <span style="font-size:10px;"><b>${kurikulum_kd} (${is_aktif === 1 ? 'Active' : 'Not Active'})</b></span>
                <span style="font-size:14px;">${nama_eng}</span>
                </label>`;
    }
    return '';
})

const bk_list_by_cpl_kd = ref([]);

onMounted(async () => {
    try {        
        const getDataCPL = await request(`/cpl/${cpl_id_fk}`)
		dataCPL.value = getDataCPL.data
        
        const getDataKurikulum = await request(`/kurikulum/${dataCPL.value.kurikulum_id_fk}`)
		dataKurikulum.value = getDataKurikulum.data
		kurikulumKD.value = getDataKurikulum.data.kurikulum_kd

		const getDataProdi = await request(`/prodi/${dataKurikulum.value.prodi_id_fk}`)
		dataProdi.value = getDataProdi.data
		
		const getDataDepartemen = await request(`/departemen/${dataProdi.value.departemen_id_fk}`)
		dataDepartemen.value = getDataDepartemen.data
		
		const getDataFakultas = await request(`/fakultas/${dataDepartemen.value.fakultas_id_fk}`)
		dataFakultas.value = getDataFakultas.data
		
		const getDataRI = await request(`/ri/${dataFakultas.value.ri_id_fk}`)
		dataRI.value = getDataRI.data
        
        
		// === Data ===
		const response = await request(`/ri`)
		ri_list.value = response.data.data
		ri_nama_eng.value = dataRI.value.nama_eng
		selectedRI.value = dataRI.value.ri_id

		fakultas_list.value = getDataRI.data.fakultas
		fakultas_nama_eng.value = dataFakultas.value.nama_eng
		selectedFakultas.value = dataFakultas.value.fakultas_id

		departemen_list.value = getDataFakultas.data.departemen
		departemen_nama_eng.value = dataDepartemen.value.nama_eng
		selectedDepartemen.value = dataDepartemen.value.departemen_id

		prodi_list.value = getDataDepartemen.data.prodi
		prodi_nama_eng.value = dataProdi.value.nama_eng
		selectedProdi.value = dataProdi.value.prodi_id

		jenjang_nama_ind.value = dataProdi.value.jenjang_nama_ind
		jenjang_nama_eng.value = dataProdi.value.jenjang_nama_eng

		kurikulum_list.value = getDataProdi.data.kurikulum
		selectedKurikulum.value = dataKurikulum.value.kurikulum_id

        cpl_list.value = getDataKurikulum.data.cpl
		cpl_nama_eng.value = dataCPL.value.cpl_kd + ' - ' + dataCPL.value.name_eng
        cpl_deskripsi_ind.value = dataCPL.value.deskripsi_ind
        cpl_deskripsi_eng.value = dataCPL.value.deskripsi_eng
		selectedCPL.value = dataCPL.value.cpl_id
		selectedCPLKD.value = dataCPL.value.cpl_kd
		// === End Data ===

        // eslint-disable-next-line no-undef
        $('button[type="submit"]').click(function() {
            // eslint-disable-next-line no-undef
            if ($(`select.cpl`).val() == '') {
                // eslint-disable-next-line no-undef
                $(`select.cpl.select2-hidden-accessible`).addClass('is-invalid')
                // eslint-disable-next-line no-undef
                $('span').find(`.select2-selection.cpl`).addClass('is-invalid')
            } else {
                // eslint-disable-next-line no-undef
                $(`select.cpl.select2-hidden-accessible`).removeClass('is-invalid')
                // eslint-disable-next-line no-undef
                $('span').find(`.select2-selection.cpl`).removeClass('is-invalid')
            }
        });

        await fetchDataBK();

        await nextTick(() => { initializeSelect2(); });
        renderCustomOption();
    } catch (error) {
		console.error('Error fetching data:', error)
	} 
})

async function fetchDataBK() {
    loading.value = true;

    try {
        const responseBK = await request(`/cpl-bahan-kajian?cpl_kd=${selectedCPLKD.value}`)
		bk_list_by_cpl_kd.value = responseBK.data.data[0].bahan_kajians
    } catch (error) {
        console.error('Error:', error);
    } finally {
		loading.value = false
	}
}

// eslint-disable-next-line no-undef
const initializeSelect2 = () => { $("select").select2(); };
</script>