<template>
    <breadcrumb-content menu="Master Data" subMenu="Kerangka Kerja"/>

    <div class="card mb-5 mb-xxl-8">
        <div class="card-body pt-9 pb-0">
            <LoadingOverlay :loading="loading" />
            <router-link :to="{ name: 'CreateViewKerangkaKerja'}" class="btn btn-primary mb-5">
                <span class="fa fa-plus"></span>
                Tambah Kerangka Kerja
            </router-link>
            
            <SSDatatable
                ref="datatableRef"
                url="/kerangka-kerja-datatable"
                primaryKey="kk_id"
                :columns="columns"
                :show="handleShow"
                :edit="handleEdit"
                :delete="handleDelete"
            />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import LoadingOverlay from '@/components/LoadingOverlay'
import SSDatatable from '@/components/SSDatatable.vue'
import { sendRequest } from '@/api/common'

const datatableRef = ref(null)
const loading = ref(false)
const router = useRouter()

const columns = [
	{ title: 'No', data: 'rownum', searchable: false, width: '8%', className: 'px-1' },
	{ title: 'Jenis KK', data: 'jenis_kk_nama_ind' },
	{ title: 'Kode KK', data: 'kk_kd' },
	{ title: 'Nama KK', data: 'nama_ind' },
	{ title: 'Deskripsi', data: 'deskripsi_ind' },
	{ title: 'Aksi', data: 'actions', searchable: false }
]

const handleShow = async kk_id => router.push({ name: 'ShowViewKerangkaKerja', params: { id: kk_id } })
const handleEdit = async kk_id => router.push({ name: 'EditViewKerangkaKerja', params: { id: kk_id } })
const handleDelete = async kk_id => {
	const param = {
		url: `/kerangka-kerja/${kk_id}`,
		method: 'DELETE',
		loading: loading,
		context: 'KerangkaKerja'
	}

	return await sendRequest(param)
}
</script>