<template>
	<div>
		<breadcrumb-content menu="Master Data" subMenu="Bahasa pengantar" />
		<div class="card mb-5 mb-xxl-8 placeholder-glow">
			<div class="pb-10">
				<div class="card-body pt-9 pb-0">
					<h1 class="anchor fw-bold mb-5">Ubah Bahasa pengantar</h1>
					<div class="p-5">
						<Form
							id="form-bahasa-pengantar-update"
							class="kt-form"
							method="post"
							@submit="onSubmit"
							:validation-schema="schema"
							v-slot="{ errors }"
							ref="formRef"
						>
							<LoadingOverlay :loading="loading" />

							<FormInput
								labelInd="Nama Bahasa pengantar"
								labelEng="Name Language of Instruction"
								columnName="nama"
								:errorInd="errors.nama_ind"
								:errorEng="errors.nama_eng"
							/>

							<div class="mt-10">
								<button type="submit" class="btn btn-primary">Simpan</button>
								&nbsp;
								<button @click="redirectToDataList" class="btn btn-secondary">Kembali</button>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { Form, configure } from 'vee-validate'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'
import { sendRequest } from '@/api/common'

import FormInput from '@/components/FormInput.vue'

const schema = {
	nama_ind: 'required',
	nama_eng: 'required'
}

const formRef = ref(null)
const loading = ref(true)
const router = useRouter()
const bhs_pengantar_id = router.currentRoute.value.params.id

const redirectToDataList = () => router.push({ name: 'BahasaPengantar' })

const onSubmit = async values => {
	const param = {
		url: `/bhs-pengantar/${bhs_pengantar_id}`,
		method: 'PUT',
		body: values,
		loading: loading,
		context: 'BahasaPengantar'
	}

	return await sendRequest(param)
}

onMounted(async () => {
	try {
		const { data } = await request(`/bhs-pengantar/${bhs_pengantar_id}`)
		if (formRef.value) {
			formRef.value.setFieldValue('nama_ind', data.nama_ind)
			formRef.value.setFieldValue('nama_eng', data.nama_eng)
		}
	} catch (error) {
		console.error('Error fetching data:', error)
	} finally {
		loading.value = false
	}
})

// custom message error
import { localize } from '@vee-validate/i18n'
const validationConfig = {
	id: {
		names: {
			nama_ind: 'Nama Bahasa pengantar',
			nama_eng: 'Name Language of Instruction'
		}
	}
}
configure({ generateMessage: localize(validationConfig) })
</script>